


.top-header {
    padding: 10px 0px;
    background-color: #ff4068;
    
}
ul.social-list.clearfix a {
    display: flex;
    color: white;
    font-size: 18px;
  
}
ul.social-list.clearfix {
display: flex;
color: white;
list-style: none;
margin-bottom: 0px;
}
ul.social-list.clearfix li {
    margin-left: 17px;
}
ul.social-list.clearfix li:first-child {
    margin-left: 0;
}
.navbar-collapse {
    height: 100%;
    width: 100%;
  }

  .TopBar{
      display: flex;
      justify-content: space-between;
      align-items: center;
  }
  a.bescom_vendor {
    border: 1px solid white;
    padding: 3px 13px;
  transition: 0.5s;
    color: white;
    text-decoration: none;
}
a.bescom_vendor:hover {
  background: white;
  color:  #ff4068;
  text-decoration: none;
}
.NavbarBrandTitle {
    color: #ff4068 !important;
    font-weight: 500;
    font-size: 26px !important;
}

.HeaderDropDownContainer .dropdown-menu.show{
    display: flex !important;
}
.HeaderDropDownContainer .dropdown-menu.show{
    display: flex !important;
}

.dropDownSections {
    padding: 4px 15px;
}

.dropDownSections .dropDownHeading{
    color: #ff4068;
    font-weight: bold;
}

.dropDownSections a {
    min-width: 221px;
    background: white !important;
    color: black !important;
    padding: 2px 4px;
    transition: 0.5s;
}
.dropDownSections a:hover {
    color: #ff4068 !important;
    padding-left: 10px;
}
nav.navigationBar .navbar-nav a{
    color: black !important;
}

.navigationBar .NavbarBrandTitle  a {
    color: #ff4068 !important;
}

.loadingRegiterbtn{
    color: black;
    text-decoration: none;
    font-weight: bold;
    transition: 0.2s;
}
.loadingRegiterbtn:hover{
    color: #ff4068 !important;
    text-decoration: none;
}
.banner{
    background-image: url("../../assets/imgs/img16.jpg");
    background-size: cover;
    height: 180px;
    background-position: center center;
    background-repeat: no-repeat;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);
    background-blend-mode: overlay;
}

.area{
    text-align: center;
}.bread-crumb{
    margin: 0;
    padding: 0;
    list-style: none;
    color: #535353;

}
.sign{
    margin: 0 0 0;
    font-weight: 500;
    color: #ff4068;
    line-height: normal;
    font-size: 30px;
}
.container-pic {
    padding-top: 1rem;
}
.signa{
    margin: 0 0 10px;
    font-weight: 50;
    color: #fff;
    line-height: normal;
    font-size: 16px;
}