/*****Vendor Dashboard Ends********/

.vendor-dashboard .gradient_one {
    background-image: linear-gradient(to right, rgba(186, 1, 181, 0.85) 0%, rgba(103, 25, 255, 0.85) 100%);
}

.vendor-dashboard .gradient-1 {
    background: #f0a907;
    background: -moz-linear-gradient(top, #f0a907 0%, #f53c79 100%);
    background: -webkit-linear-gradient(top, #f0a907 0%, #f53c79 100%);
    background: linear-gradient(to bottom, #f0a907 0%, #f53c79 100%) !important;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f0a907", endColorstr="#f53c79", GradientType=0);
}

.vendor-dashboard .gradient-2 {
    background: #4dedf5;
    background: -moz-linear-gradient(top, #4dedf5 0%, #480ceb 100%);
    background: -webkit-linear-gradient(top, #4dedf5 0%, #480ceb 100%);
    background: linear-gradient(to bottom, #4dedf5 0%, #480ceb 100%) !important;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#4dedf5", endColorstr="#480ceb", GradientType=0);
}

.vendor-dashboard .gradient-3 {
    background: #51f5ae;
    background: -moz-linear-gradient(top, #51f5ae 0%, #3fbcda 100%);
    background: -webkit-linear-gradient(top, #51f5ae 0%, #3fbcda 100%);
    background: linear-gradient(to bottom, #51f5ae 0%, #3fbcda 100%) !important;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#51f5ae", endColorstr="#3fbcda", GradientType=0);
}

.vendor-dashboard .gradient-4 {
    background: #f25521;
    background: -moz-linear-gradient(left, #f25521 0%, #f9c70a 100%);
    background: -webkit-linear-gradient(left, #f25521 0%, #f9c70a 100%);
    background: linear-gradient(to right, #f25521 0%, #f9c70a 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f25521", endColorstr="#f9c70a", GradientType=1);
}

.vendor-dashboard .gradient-5 {
    background: #f53c79;
    background: -moz-linear-gradient(left, #f53c79 0%, #f0a907 100%);
    background: -webkit-linear-gradient(left, #f53c79 0%, #f0a907 100%);
    background: linear-gradient(to right, #f53c79 0%, #f0a907 100%) !important;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f53c79", endColorstr="#f0a907", GradientType=1);
}

.vendor-dashboard .gradient-6 {
    background: #36b9d8;
    background: -moz-linear-gradient(left, #36b9d8 0%, #4bffa2 100%);
    background: -webkit-linear-gradient(left, #36b9d8 0%, #4bffa2 100%);
    background: linear-gradient(to right, #36b9d8 0%, #4bffa2 100%) !important;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#36b9d8", endColorstr="#4bffa2", GradientType=1);
}

.vendor-dashboard .gradient-7 {
    background: #4400eb;
    background: -moz-linear-gradient(left, #4400eb 0%, #44e7f5 100%);
    background: -webkit-linear-gradient(left, #4400eb 0%, #44e7f5 100%);
    background: linear-gradient(to right, #4400eb 0%, #44e7f5 100%) !important;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#4400eb", endColorstr="#44e7f5", GradientType=1);
}

.vendor-dashboard .gradient-8 {
    background: #F7B00F;
    background: -moz-linear-gradient(top, #F7B00F 0%, #F25521 100%);
    background: -webkit-linear-gradient(top, #F7B00F 0%, #F25521 100%);
    background: linear-gradient(to bottom, #F7B00F 0%, #F25521 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#F7B00F", endColorstr="#F25521", GradientType=1);
}

.vendor-dashboard .gradient-9,
.vendor-dashboard .datepicker.datepicker-dropdown td.day:hover,
.vendor-dashboard .datepicker.datepicker-dropdown th.next:hover,
.vendor-dashboard .datepicker.datepicker-dropdown th.prev:hover,
.vendor-dashboard .datepicker table tr td.selected,
.vendor-dashboard .datepicker table tr td.active,
.vendor-dashboard .datepicker table tr td.today,
.vendor-dashboard .datepicker table tr td.today:hover,
.vendor-dashboard .datepicker table tr td.today.disabled,
.vendor-dashboard .datepicker table tr td.today.disabled:hover {
    background: #f31e7a !important;
    background: -moz-linear-gradient(left, #f31e7a 0%, #fd712c 100%);
    background: -webkit-linear-gradient(left, #f31e7a 0%, #fd712c 100%);
    background: linear-gradient(to right, #f31e7a 0%, #fd712c 100%) !important;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f31e7a", endColorstr="#fd712c", GradientType=1);
}

.vendor-dashboard .gradient-10 {
    background: #f25521 !important;
    background: -moz-linear-gradient(left, #f25521 0%, #f9c70a 100%);
    background: -webkit-linear-gradient(left, #f25521 0%, #f9c70a 100%);
    background: linear-gradient(to top, #f25521 0%, #f9c70a 100%) !important;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f25521", endColorstr="#f9c70a", GradientType=1);
}

.vendor-dashboard .gradient-11 {
    background: #3398fb;
    background: -moz-linear-gradient(left, #3398fb 0%, #8553ee 100%);
    background: -webkit-linear-gradient(left, #3398fb 0%, #8553ee 100%);
    background: linear-gradient(to right, #3398fb 0%, #8553ee 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#3398fb", endColorstr="#8553ee", GradientType=1);
}

.vendor-dashboard .gradient-12 {
    background: #36e1b4;
    background: -moz-linear-gradient(left, #36e1b4 0%, #11cae7 100%);
    background: -webkit-linear-gradient(left, #36e1b4 0%, #11cae7 100%);
    background: linear-gradient(to right, #36e1b4 0%, #11cae7 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#36e1b4", endColorstr="#11cae7", GradientType=1);
}

.vendor-dashboard .gradient-13 {
    background: #ffbf31;
    background: -moz-linear-gradient(left, #ffbf31 0%, #ff890e 100%);
    background: -webkit-linear-gradient(left, #ffbf31 0%, #ff890e 100%);
    background: linear-gradient(to right, #ffbf31 0%, #ff890e 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffbf31", endColorstr="#ff890e", GradientType=1);
}

.vendor-dashboard .gradient-14 {
    background: #23bdb8;
    background: -moz-linear-gradient(-45deg, #23bdb8 0%, #43e794 100%);
    background: -webkit-linear-gradient(-45deg, #23bdb8 0%, #43e794 100%);
    background: linear-gradient(135deg, #23bdb8 0%, #43e794 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#23bdb8", endColorstr="#43e794", GradientType=1);
}

.vendor-dashboard .gradient-15 {
    background: #9a56ff;
    background: -moz-linear-gradient(-45deg, #9a56ff 0%, #e36cd9 100%);
    background: -webkit-linear-gradient(-45deg, #9a56ff 0%, #e36cd9 100%);
    background: linear-gradient(135deg, #9a56ff 0%, #e36cd9 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#9a56ff", endColorstr="#e36cd9", GradientType=1);
}

.vendor-dashboard .gradient-16 {
    background: #f48665;
    background: -moz-linear-gradient(-45deg, #f48665 0%, #fda23f 100%);
    background: -webkit-linear-gradient(-45deg, #f48665 0%, #fda23f 100%);
    background: linear-gradient(135deg, #f48665 0%, #fda23f 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f48665", endColorstr="#fda23f", GradientType=1);
}

.vendor-dashboard .gradient-17 {
    background: #e36cd9;
    background: -moz-linear-gradient(-45deg, #e36cd9 0%, #fe60ae 100%);
    background: -webkit-linear-gradient(-45deg, #e36cd9 0%, #fe60ae 100%);
    background: linear-gradient(135deg, #e36cd9 0%, #fe60ae 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#e36cd9", endColorstr="#fe60ae", GradientType=1);
}

.vendor-dashboard .gradient-18 {
    background: #a15cff;
    background: -moz-linear-gradient(left, #a15cff 0%, #ce82fd 100%);
    background: -webkit-linear-gradient(left, #a15cff 0%, #ce82fd 100%);
    background: linear-gradient(to right, #a15cff 0%, #ce82fd 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#a15cff", endColorstr="#ce82fd", GradientType=1);
}

.vendor-dashboard .c-pointer {
    cursor: pointer;
}

* {
    outline: none;
    padding: 0;
}

*:after {
    margin: 0;
    padding: 0;
}

*:before {
    margin: 0;
    padding: 0;
}

body .vendor-dashboard {
    overflow-x: hidden;
    height: 100vh;
    position: relative;
    max-width: 100%;
    font-size: 1rem;
}

.vendor-dashboard p {
    line-height: 1.8;
}

.vendor-dashboard .box-shadow-none {
    box-shadow: none !important;
}

.vendor-dashboard #main-wrapper {
    opacity: 0;
    transition: all 0.25s ease-in;
    overflow: hidden;
    position: relative;
}

.vendor-dashboard #main-wrapper.show {
    opacity: 1;
}

.vendor-dashboard .rounded-lg {
    border-radius: 0.75rem;
}

.vendor-dashboard ul {
    padding: 0;
    margin: 0;
    padding-left: 2rem; 
}

.vendor-dashboard li {
    list-style: none;
}

.vendor-dashboard a {
    color: #7e7e7e;
}

.vendor-dashboard a:hover,
.vendor-dashboard a:focus,
.vendor-dashboard a.active {
    text-decoration: none;
}

.vendor-dashboard .btn-link:hover,
.vendor-dashboard .btn-link:focus,
.vendor-dashboard .btn-link.active {
    text-decoration: none;
}

.vendor-dashboard .w-space-no {
    white-space: nowrap;
}

.vendor-dashboard .content-body .container {
    margin-top: 40px;
}

.vendor-dashboard .content-body .container-fluid {
    padding-right: 40px;
    padding-left: 40px;
}

@media only screen and (max-width: 1400px) {
    .vendor-dashboard .content-body .container-fluid {
        padding-right: 30px;
        padding-left: 30px;
    }
}

@media only screen and (max-width: 767px) {
    .vendor-dashboard .content-body .container-fluid {
        padding-right: 20px;
        padding-left: 20px;
    }
}

@media only screen and (max-width: 575px) {
    .vendor-dashboard .content-body .container-fluid {
        padding-right: 15px;
        padding-left: 15px;
    }
}

.vendor-dashboard .row.sp80,
.vendor-dashboard .sp80 {
    margin-left: -40px;
    margin-right: -40px;
}

.vendor-dashboard .row.sp80 [class*=col-],
.vendor-dashboard .sp80 [class*=col-] {
    padding-left: 40px;
    padding-right: 40px;
}

.vendor-dashboard .row.sp60,
.vendor-dashboard .sp60 {
    margin-left: -30px;
    margin-right: -30px;
}

.vendor-dashboard .row.sp60 [class*=col-],
.vendor-dashboard .sp60 [class*=col-] {
    padding-left: 30px;
    padding-right: 30px;
}

.vendor-dashboard .row.sp40,
.vendor-dashboard .sp40 {
    margin-left: -20px;
    margin-right: -20px;
}

.vendor-dashboard .row.sp40 [class*=col-],
.vendor-dashboard .sp40 [class*=col-] {
    padding-left: 20px;
    padding-right: 20px;
}

.vendor-dashboard .row.sp20,
.vendor-dashboard .sp20 {
    margin-left: -9px;
    margin-right: -9px;
}

.vendor-dashboard .row.sp20 [class*=col-],
.vendor-dashboard .sp20 [class*=col-] {
    padding-left: 9px;
    padding-right: 9px;
}

.vendor-dashboard .row.sp15,
.vendor-dashboard .sp15 {
    margin-left: -7.5px;
    margin-right: -7.5px;
}

.vendor-dashboard .row.sp15 [class*=col-],
.vendor-dashboard .sp15 [class*=col-] {
    padding-left: 7.5px;
    padding-right: 7.5px;
}

.vendor-dashboard .row.sp10,
.vendor-dashboard .sp10 {
    margin-left: -5px;
    margin-right: -5px;
}

.vendor-dashboard .row.sp10 [class*=col-],
.vendor-dashboard .sp10 [class*=col-] {
    padding-left: 5px;
    padding-right: 5px;
}

.vendor-dashboard .row.sp4,
.vendor-dashboard .sp4 {
    margin-left: -2px;
    margin-right: -2px;
}

.vendor-dashboard .row.sp4 [class*=col-],
.vendor-dashboard .sp4 [class*=col-] {
    padding-left: 2px;
    padding-right: 2px;
}

.vendor-dashboard .row.spno,
.vendor-dashboard .spno {
    margin-left: 0;
    margin-right: 0;
}

.vendor-dashboard .row.spno [class*=col-],
.vendor-dashboard .spno [class*=col-] {
    padding-left: 0;
    padding-right: 0;
}

.vendor-dashboard .content-heading {
    font-size: 16px;
    margin-bottom: 1.875rem;
    margin-top: 3.125rem;
    border-bottom: 1px solid #d7dae3;
    padding-bottom: 10px;
}


.vendor-dashboard .btn-primary:not(:disabled):not(.disabled):active:focus,
.vendor-dashboard .btn-primary:not(:disabled):not(.disabled).active:focus {
    box-shadow: none;
}

.vendor-dashboard .support-ticket {
    position: fixed;
    bottom: 30px;
    right: 15px;
    z-index: 999999;
}

.vendor-dashboard .support-ticket-btn {
    width: 100px;
    background: #7CB442;
    animation: 0.7s ease-in-out 0s infinite alternate none running crescendo;
    border-radius: 50px;
    color: #fff;
    font-size: 8px;
    font-size: 16px;
    padding: 5px 10px 7px;
    text-align: center;
    display: inline-block;
    box-shadow: rgba(124, 180, 66, 0.7) 0px 8px 35px 0px;
}

.vendor-dashboard .support-ticket-btn:hover,
.vendor-dashboard .support-ticket-btn:focus {
    color: #fff;
}

.vendor-dashboard .text-blue {
    color: #5e72e4;
}

.vendor-dashboard .text-indigo {
    color: #6610f2;
}

.vendor-dashboard .text-purple {
    color: #7d49eb;
}

.vendor-dashboard .text-pink {
    color: #e83e8c;
}

.vendor-dashboard .text-red {
    color: #EE3232;
}

.vendor-dashboard .text-orange {
    color: #ff9900;
}

.vendor-dashboard .text-yellow {
    color: #FFFA6F;
}

.vendor-dashboard .text-green {
    color: #297F00;
}

.vendor-dashboard .text-teal {
    color: #20c997;
}

.vendor-dashboard .text-cyan {
    color: #3065D0;
}

.vendor-dashboard .bg-gray-dark {
    background: #143b64 !important;
}

.vendor-dashboard .bg-purpal {
    background: #4527a0 !important;
}

.vendor-dashboard .bg-red {
    background: #c62828 !important;
}

.vendor-dashboard .bg-blue-dark {
    background: #283593 !important;
}

.vendor-dashboard .bg-blue {
    background: #7356f1 !important;
}

.vendor-dashboard .bg-blue-light {
    background: #3695eb !important;
}

.vendor-dashboard .bg-green-light {
    background: #00838f !important;
}

.vendor-dashboard .bg-green {
    background: #ff8f16 !important;
}

.vendor-dashboard .bg-black {
    background: #000;
}

.vendor-dashboard .text-black {
    color: #000 !important;
}

.vendor-dashboard .dz-scroll {
    position: relative;
}

.vendor-dashboard .fs-12 {
    font-size: 12px !important;
    line-height: 1.5;
}

.vendor-dashboard .fs-13 {
    font-size: 13px !important;
    line-height: 1.5;
}

.vendor-dashboard .fs-14 {
    font-size: 14px !important;
    line-height: 1.5;
}

.vendor-dashboard .fs-15 {
    font-size: 14px !important;
    line-height: 1.5;
}

.vendor-dashboard .fs-16 {
    font-size: 16px !important;
    line-height: 1.5;
}

.vendor-dashboard .fs-18 {
    font-size: 18px !important;
    line-height: 1.5;
}

.vendor-dashboard .fs-20 {
    font-size: 20px !important;
    line-height: 1.5;
}

.vendor-dashboard .fs-22 {
    font-size: 22px !important;
    line-height: 1.5;
}

.vendor-dashboard .fs-24 {
    font-size: 24px !important;
    line-height: 1.4;
}

.vendor-dashboard .fs-26 {
    font-size: 26px !important;
    line-height: 1.4;
}

.vendor-dashboard .fs-28 {
    font-size: 28px !important;
    line-height: 1.4;
}

.vendor-dashboard .fs-32 {
    font-size: 32px !important;
    line-height: 1.25;
}

.vendor-dashboard .fs-35 {
    font-size: 35px !important;
    line-height: 1.25;
}

.vendor-dashboard .font-w100 {
    font-weight: 100;
}

.vendor-dashboard .font-w200 {
    font-weight: 200;
}

.vendor-dashboard .font-w300 {
    font-weight: 300;
}

.vendor-dashboard .font-w400 {
    font-weight: 400;
}

.vendor-dashboard .font-w500 {
    font-weight: 500;
}

.vendor-dashboard .font-w600 {
    font-weight: 600;
}

.vendor-dashboard .font-w700 {
    font-weight: 700;
}

.vendor-dashboard .font-w800 {
    font-weight: 800;
}

.vendor-dashboard .font-w900 {
    font-weight: 900;
}

.vendor-dashboard .scale1 {
    transform: scale(1.1);
    -moz-transform: scale(1.1);
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
    display: inline-block;
}

.vendor-dashboard .scale2 {
    transform: scale(1.2);
    -moz-transform: scale(1.2);
    -webkit-transform: scale(1.2);
    -ms-transform: scale(1.2);
    -o-transform: scale(1.2);
    display: inline-block;
}

.vendor-dashboard .scale3 {
    transform: scale(1.3);
    -moz-transform: scale(1.3);
    -webkit-transform: scale(1.3);
    -ms-transform: scale(1.3);
    -o-transform: scale(1.3);
    display: inline-block;
}

.vendor-dashboard .scale4 {
    transform: scale(1.4);
    -moz-transform: scale(1.4);
    -webkit-transform: scale(1.4);
    -ms-transform: scale(1.4);
    -o-transform: scale(1.4);
    display: inline-block;
}

.vendor-dashboard .scale5 {
    transform: scale(1.5);
    -moz-transform: scale(1.5);
    -webkit-transform: scale(1.5);
    -ms-transform: scale(1.5);
    -o-transform: scale(1.5);
    display: inline-block;
}

@-webkit-keyframes crescendo {
    from {
        -webkit-transform: translateY(5px) scale(0.8);
        -ms-transform: translateY(5px) scale(0.8);
        transform: translateY(5px) scale(0.8);
    }
    to {
        -webkit-transform: translateY(0px) scale(1);
        -ms-transform: translateY(0px) scale(1);
        transform: translateY(0px) scale(1);
    }
}

@keyframes gXGDoR {
    from {
        -webkit-transform: translateY(5px) scale(0.8);
        -ms-transform: translateY(5px) scale(0.8);
        transform: translateY(5px) scale(0.8);
    }
    to {
        -webkit-transform: translateY(0px) scale(1);
        -ms-transform: translateY(0px) scale(1);
        transform: translateY(0px) scale(1);
    }
}

@keyframes crescendo {
    from {
        -webkit-transform: translateY(5px) scale(0.8);
        -ms-transform: translateY(5px) scale(0.8);
        transform: translateY(5px) scale(0.8);
    }
    to {
        -webkit-transform: translateY(0px) scale(1);
        -ms-transform: translateY(0px) scale(1);
        transform: translateY(0px) scale(1);
    }
}

@keyframes gXGDoR {
    from {
        -webkit-transform: translateY(5px) scale(0.8);
        -ms-transform: translateY(5px) scale(0.8);
        transform: translateY(5px) scale(0.8);
    }
    to {
        -webkit-transform: translateY(0px) scale(1);
        -ms-transform: translateY(0px) scale(1);
        transform: translateY(0px) scale(1);
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
    .vendor-dashboard .col-xxl-1 {
        flex: 0 0 8.3333333333%;
        max-width: 8.3333333333%;
    }

    .vendor-dashboard .col-xxl-2 {
        flex: 0 0 16.6666666667%;
        max-width: 16.6666666667%;
    }

    .vendor-dashboard .col-xxl-3 {
        flex: 0 0 25%;
        max-width: 25%;
    }

    .vendor-dashboard .col-xxl-4 {
        flex: 0 0 33.3333333333%;
        max-width: 33.3333333333%;
    }

    .vendor-dashboard .col-xxl-5 {
        flex: 0 0 41.6666666667%;
        max-width: 41.6666666667%;
    }

    .vendor-dashboard .col-xxl-6 {
        flex: 0 0 50%;
        max-width: 50%;
    }

    .vendor-dashboard .col-xxl-7 {
        flex: 0 0 58.3333333333%;
        max-width: 58.3333333333%;
    }

    .vendor-dashboard .col-xxl-8 {
        flex: 0 0 66.6666666667%;
        max-width: 66.6666666667%;
    }

    .vendor-dashboard .col-xxl-9 {
        flex: 0 0 75%;
        max-width: 75%;
    }

    .vendor-dashboard .col-xxl-10 {
        flex: 0 0 83.3333333333%;
        max-width: 83.3333333333%;
    }

    .vendor-dashboard .col-xxl-11 {
        flex: 0 0 91.6666666667%;
        max-width: 91.6666666667%;
    }

    .vendor-dashboard .col-xxl-12 {
        flex: 0 0 100%;
        max-width: 100%;
    }

    .vendor-dashboard .mb-xxl-4 {
        margin-bottom: 1.5rem !important;
    }
}

.vendor-dashboard #preloader {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: #fff;
}

.vendor-dashboard .sk-three-bounce {
    margin: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    background-color: white;
}

.vendor-dashboard .sk-three-bounce .sk-child {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    background-color: #0e131a;
    border-radius: 100%;
    display: inline-block;
    -webkit-animation: sk-three-bounce 1.4s ease-in-out 0s infinite both;
    animation: sk-three-bounce 1.4s ease-in-out 0s infinite both;
}

.vendor-dashboard .sk-three-bounce .sk-bounce1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
}

.vendor-dashboard .sk-three-bounce .sk-bounce2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
}

@-webkit-keyframes sk-three-bounce {
    0%,
    80%,
    100% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }
    40% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

@keyframes sk-three-bounce {
    0%,
    80%,
    100% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }
    40% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

.vendor-dashboard .footer {
    padding-left: 21.563rem;
    background-color: #ffffff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.vendor-dashboard .footer .copyright {
    padding: 0.9375rem;
}

.vendor-dashboard .footer .copyright p {
    text-align: center;
    margin: 0;
}

.vendor-dashboard .footer .copyright a {
    color: #0e131a;
}

.vendor-dashboard .nav-header {
    height: 4.5rem;
    width: 5rem;
    display: inline-block;
    text-align: left;
    position: relative;
    background-color: #353535;
    transition: all 0.2s ease;
    z-index: 99999;
}
/* 
.vendor-dashboard .header.extended .nav-header {
    width: 20vw;
} */

.vendor-dashboard .nav-header .logo-abbr {
    max-width: 130px;
}

.vendor-dashboard .menu-toggle .nav-header .logo-abbr {
    max-width: 80%;
}

@media only screen and (max-width: 575px) {
    .vendor-dashboard .nav-header .logo-abbr {
        max-width: 35px;
    }
}

.vendor-dashboard .nav-header .logo-compact {
    display: none;
}

.vendor-dashboard .nav-header .brand-logo {
    height: 100%;
    width: 100%;
    color: #fff;
    font-size: 1.25rem;
    text-decoration: none;
    padding-left: 50px;
    padding-right: 50px;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media only screen and (max-width: 767px) {
    .vendor-dashboard .nav-header .brand-logo {
        padding-left: 0;
        padding-right: 0;
        justify-content: center;
    }

    .vendor-dashboard .nav-header .brand-logo {
        font-size: 0.875rem;
    }
}

.vendor-dashboard .nav-header .brand-title {
    margin-left: 20px;
    max-width: 120px;
    margin-top: 5px;
}

[data-theme-version=dark] .nav-header .brand-title {
    background-position: 0 120%;
}

@media only screen and (max-width: 1199px) {
    .vendor-dashboard .nav-header {
        width: 5rem;
        height: 4.5rem;
    }

    .vendor-dashboard .nav-header .brand-title {
        display: none;
    }
}

.vendor-dashboard .nav-control {
    /*position: absolute;*/
    /*right: -4.0625rem;*/
    /*top: 50%;*/
    /*transform: translateY(-50%);*/
    z-index: 9999;
    cursor: pointer;
    font-size: 1.4rem;
    text-align: center;
    border-radius: 2px;
    padding: 2px 0.5rem 0;
}

@media only screen and (max-width: 767px) {
    .vendor-dashboard .nav-control {
        right: -3.188rem;
    }
}

.vendor-dashboard .hamburger {
    display: inline-block;
    left: 0px;
    position: relative;
    top: 0px;
    -webkit-transition: all 0.3s ease-in-out 0s;
    transition: all 0.3s ease-in-out 0s;
    width: 26px;
    z-index: 999;
    padding: 0;
}

.vendor-dashboard .hamburger .line {
    background: #fff;
    display: block;
    height: 3px;
    border-radius: 3px;
    margin-top: 6px;
    margin-bottom: 6px;
    margin-left: auto;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.vendor-dashboard .hamburger .arrow {
    color: #fff;
}

.vendor-dashboard .hamburger .line:nth-child(1) {
    width: 20px;
}

.vendor-dashboard .hamburger .line:nth-child(2) {
    width: 26px;
}

.vendor-dashboard .hamburger .line:nth-child(3) {
    width: 22px;
}

.vendor-dashboard .hamburger:hover {
    cursor: pointer;
}

.vendor-dashboard .hamburger:hover .line {
    width: 26px;
}

.vendor-dashboard .hamburger.is-active .line:nth-child(1),
.vendor-dashboard .hamburger.is-active .line:nth-child(3) {
    width: 10px;
    height: 2px;
}

.vendor-dashboard .hamburger.is-active .line:nth-child(2) {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
    width: 22px;
    height: 2px;
}

.vendor-dashboard .hamburger.is-active .line:nth-child(1) {
    -webkit-transform: translateY(4px) rotate(45deg);
    transform: translateY(4px) rotate(45deg);
}

.vendor-dashboard .hamburger.is-active .line:nth-child(3) {
    -webkit-transform: translateY(-4px) rotate(-45deg);
    transform: translateY(-4px) rotate(-45deg);
}

@media (min-width: 767px) {
    [data-sidebar-style=compact] .nav-control {
        display: none;
    }

    [data-sidebar-style=compact] .nav-header {
        width: 9.375rem;
    }
}

[data-sidebar-style=full][data-layout=vertical] .menu-toggle .brand-title {
    display: none;
}

[data-sidebar-style=full][data-layout=vertical] .menu-toggle .nav-header .logo-abbr {
    display: block;
}

[data-sidebar-style=full][data-layout=horizontal] .logo-compact {
    display: none;
}

[data-sidebar-style=mini] .nav-header .logo-abbr {
    display: block;
}

[data-sidebar-style=compact][data-layout=vertical] .nav-header .brand-title {
    display: none;
}

[data-sidebar-style=compact][data-layout=vertical] .nav-header .logo-compact {
    max-width: 75px;
}

[data-sidebar-style=compact][data-layout=horizontal] .nav-header .brand-logo {
    padding-left: 30px;
    padding-right: 30px;
    justify-content: start;
}

[data-sidebar-style=modern][data-layout=vertical] .nav-header {
    width: 9.375rem;
}

[data-sidebar-style=modern][data-layout=vertical] .nav-header .brand-title {
    display: none;
}

[data-sidebar-style=modern][data-layout=vertical] .nav-header .logo-compact {
    display: none;
}

.vendor-dashboard .header {
    background-color: #ffffff;
    transition: all 0.2s ease;
    height: 4.5rem;
    padding: 0;
    position: fixed;
    width: 100%;
    z-index: 1003;
    top: 0;
    display: flex;
}

/*.vendor-dashboard .header.extended {*/
/*    padding-left: 5rem;*/
/*}*/

@media only screen and (max-width: 1400px) {
    /*.vendor-dashboard .header {*/
    /*    padding-left: 18rem;*/
    /*}*/
}

.vendor-dashboard .header .header-content {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    background: #ff4068;
    align-items: center;
    display: flex;
    height: 4.5rem;
    /* width: 100%; */
    flex-grow: 1;
}

/* .vendor-dashboard .header.extended .header-content {
    width: calc(100% - 20vw);
} */

@media only screen and (max-width: 767px) {
    .vendor-dashboard .header .header-content {
        padding-left: 1rem;
        padding-right: 1rem;
    }
}

.vendor-dashboard .header .navbar {
    padding: 0;
    height: 100%;
    width: 100%;
}

.vendor-dashboard .header .navbar .navbar-collapse {
    height: 100%;
    width: 100%;
}

@media only screen and (max-width: 1199px) {
    .vendor-dashboard .header {
        height: 4.5rem;
    }
}

.vendor-dashboard svg.pulse-svg {
    overflow: visible;
}

.vendor-dashboard svg.pulse-svg .first-circle,
.vendor-dashboard svg.pulse-svg .second-circle,
.vendor-dashboard svg.pulse-svg .third-circle {
    -webkit-transform: scale(0.3);
    transform: scale(0.3);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-animation: pulse-me 3s linear infinite;
    animation: pulse-me 3s linear infinite;
    fill: #0e131a;
}

.vendor-dashboard svg.pulse-svg .second-circle {
    -webkit-animation-delay: 1s;
    animation-delay: 1s;
}

.vendor-dashboard svg.pulse-svg .third-circle {
    -webkit-animation-delay: 2s;
    animation-delay: 2s;
}

.vendor-dashboard .pulse-css {
    /*width: 1rem;*/
    /*height: 1rem;*/
    width: 0.4rem;
    height: 0.4rem;
    border-radius: 3.5rem;
    position: absolute;
    background: #0e131a;
    right: 5px;
    top: 0.6rem;
}

.vendor-dashboard .pulse-css:after,
.vendor-dashboard .pulse-css:before {
    content: "";
    width: 1rem;
    height: 1rem;
    border-radius: 0.5rem;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: -0.2rem;
    background-color: #0e131a;
    margin: auto;
    -webkit-transform: scale(0.3);
    transform: scale(0.3);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-animation: pulse-me 3s linear infinite;
    animation: pulse-me 3s linear infinite;
}

@-webkit-keyframes pulse-me {
    0% {
        -webkit-transform: scale(0.3);
        transform: scale(0.3);
        opacity: 0;
    }
    50% {
        opacity: 0.1;
    }
    70% {
        opacity: 0.09;
    }
    100% {
        -webkit-transform: scale(3);
        transform: scale(3);
        opacity: 0;
    }
}

@keyframes pulse-me {
    0% {
        -webkit-transform: scale(0.3);
        transform: scale(0.3);
        opacity: 0;
    }
    50% {
        opacity: 0.1;
    }
    70% {
        opacity: 0.09;
    }
    100% {
        -webkit-transform: scale(3);
        transform: scale(3);
        opacity: 0;
    }
}

.vendor-dashboard .header-left {
    height: 100%;
    display: flex;
    align-items: center;
}

.vendor-dashboard .header-left .dashboard_bar {
    font-size: 24px;
    font-weight: 600;
    color: #fff;
}

@media only screen and (max-width: 1199px) {
    .vendor-dashboard .header-left .dashboard_bar {
        font-size: 24px;
    }
}

@media only screen and (max-width: 767px) {
    .vendor-dashboard .header-left .dashboard_bar {
        font-size: 20px;
    }
    .vendor-dashboard .header {
/* top: 3.2em; */
    }
    .vendor-dashboard .content-body {
    margin-top: 6.5rem !important;

    }
}

@media only screen and (max-width: 575px) {
    /* .vendor-dashboard .header-left .dashboard_bar {
        display: none;
    } */
}

.vendor-dashboard .header-left input {
    background: #ffffff !important;
    min-width: 170px;
    min-height: 40px;
    border-color: transparent;
    color: #3e4954 !important;
    border-top-right-radius: 0.375rem;
    border-bottom-right-radius: 0.375rem;
    box-shadow: none;
}

.vendor-dashboard .header-left input:focus,
.vendor-dashboard .header-left input:active,
.vendor-dashboard .header-left input.active {
    border-color: transparent;
    box-shadow: none;
}

.vendor-dashboard .header-left input:-webkit-input-placeholder {
    /* Edge */
    color: #3e4954;
}

.vendor-dashboard .header-left input:-ms-input-placeholder {
    /* Internet Explorer */
    color: #3e4954;
}

.vendor-dashboard .header-left input:placeholder {
    color: #3e4954;
}

.vendor-dashboard .header-left .search_bar {
    display: flex;
    align-items: center;
    height: 100%;
}

.vendor-dashboard .header-left .search_bar .dropdown-menu {
    box-shadow: none;
}

.vendor-dashboard .header-left .search_bar .search_icon {
    background: #ffffff !important;
    height: 40px;
    padding: 8px 0 8px 15px !important;
    border-top-left-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem;
}

.vendor-dashboard .header-left .search_bar .search_icon i {
    font-size: 24px;
    color: #3e4954;
    line-height: 1;
}

@media only screen and (max-width: 767px) {
    .vendor-dashboard .header-left .search_bar .search_icon {
        border-radius: 4px;
        padding: 8px 10px !important;
    }
}

@media only screen and (max-width: 767px) {
    .vendor-dashboard .header-left .search_bar {
        position: static;
    }

    .vendor-dashboard .header-left .search_bar .dropdown-menu {
        width: 300px;
        left: -15vw;
        box-shadow: 0px 5px 10px 0 rgba(0, 0, 0, 0.1);
        border-radius: 4px !important;
    }
}

@media only screen and (max-width: 767px) and (max-width: 575px) {
    .vendor-dashboard .header-left .search_bar .dropdown-menu {
        width: 250px;
        left: -25vw;
    }
}

@media only screen and (max-width: 767px) {
    .vendor-dashboard .header-left .search_bar .dropdown-menu .form-control {
        border-radius: 4px !important;
        width: 100%;
    }
}

.vendor-dashboard .header-left .search_bar .dropdown-menu,
.vendor-dashboard .header-left .search_bar .dropdown-menu.show {
    border: 0px;
    background-color: transparent;
    border-top-right-radius: 5rem;
    border-bottom-right-radius: 5rem;
}

@media only screen and (min-width: 768px) {
    .vendor-dashboard .header-left .search_bar .dropdown-menu,
    .vendor-dashboard .header-left .search_bar .dropdown-menu.show {
        left: 34px;
        top: 0;
        transform: translateY(50%);
        display: block;
    }
}

[data-sidebar-style=compact] .header-left {
    margin-left: 0;
}

.vendor-dashboard .header-right {
    height: 100%;
}

.vendor-dashboard .header-right .nav-item {
    height: 100%;
    display: flex;
    align-items: center;
}

.vendor-dashboard .header-right .nav-item .nav-link {
    color: #464a53;
    font-size: 18px;
}

.vendor-dashboard .header-right .right-sidebar {
    margin-right: -30px;
}

.vendor-dashboard .header-right .right-sidebar a {
    height: 80px;
    width: 80px;
    text-align: center;
    justify-content: center;
    display: flex;
    align-items: center;
    border-left: 1px solid #f4f4f4;
}

.vendor-dashboard .header-right > li:not(:first-child) {
    padding-left: 1.5rem;
}

@media only screen and (max-width: 767px) {
    .vendor-dashboard .header-right > li:not(:first-child) {
        padding-left: 0.5rem;
    }
}

@media only screen and (max-width: 767px) {
    .vendor-dashboard .header-right .notification_dropdown {
        position: static;
    }
}

.vendor-dashboard .header-right .notification_dropdown .nav-link {
    position: relative;
    color: #3e4954;
    background: #f4f4f4;
    border-radius: 2rem;
    height: 40px;
    width: 40px;
    text-align: center;
    line-height: 1;
}

@media only screen and (max-width: 1199px) {
    .vendor-dashboard .header-right .notification_dropdown .nav-link {
        padding: 10px;
        height: 40px;
        width: 40px;
        line-height: 1;
    }
}

.vendor-dashboard .header-right .notification_dropdown .nav-link i {
    font-size: 24px;
}

@media only screen and (max-width: 1199px) {
    .vendor-dashboard .header-right .notification_dropdown .nav-link i {
        font-size: 18px;
    }
}

@media only screen and (max-width: 1199px) {
    .vendor-dashboard .header-right .notification_dropdown .nav-link svg {
        width: 18px;
        height: 18px;
    }
}

.vendor-dashboard .header-right .notification_dropdown .nav-link .badge {
    position: absolute;
    font-size: 10px;
    border-radius: 50%;
    right: -10px;
    top: -10px;
    padding: 0px;
    font-weight: normal;
    text-align: center;
    line-height: 20px;
    height: 20px;
    width: 20px;
}

@media only screen and (max-width: 767px) {
    .vendor-dashboard .header-right .notification_dropdown .nav-link .badge {
        line-height: 18px;
        height: 20px;
        width: 20px;
        font-size: 12px;
    }
}

.vendor-dashboard .header-right .notification_dropdown .dropdown-item:focus a,
.vendor-dashboard .header-right .notification_dropdown .dropdown-item:active a {
    color: #fff;
}

.vendor-dashboard .header-right .notification_dropdown .dropdown-item a {
    color: #3e4954;
}

.vendor-dashboard .header-right .notification_dropdown .dropdown-item a:hover {
    text-decoration: none;
}

.vendor-dashboard .header-right .dropdown-menu {
    border-width: 0;
    box-shadow: 0 0 37px rgba(8, 21, 66, 0.05);
}

[data-theme-version=dark] .header-right .dropdown-menu {
    box-shadow: none;
}

.vendor-dashboard .header-right .header-profile > a.nav-link {
    padding: 0;
    display: flex;
    align-items: center;
}

.vendor-dashboard .header-right .header-profile > a.nav-link i {
    font-weight: 700;
}

.vendor-dashboard .header-right .header-profile > a.nav-link .header-info {
    padding-right: 20px;
    text-align: right;
    /*border-left: 1px solid #eee;*/
    padding-left: 20px;
}

@media only screen and (max-width: 1199px) {
    .vendor-dashboard .header-right .header-profile > a.nav-link .header-info {
        padding-right: 10px;
        padding-left: 10px;
    }
}

@media only screen and (max-width: 767px) {
    .vendor-dashboard .header-right .header-profile > a.nav-link .header-info {
        display: none;
    }
}

.vendor-dashboard .header-right .header-profile > a.nav-link .header-info span {
    font-size: 18px;
    color: #fff;
    display: block;
    font-weight: 500;
}

@media only screen and (max-width: 1199px) {
    .vendor-dashboard .header-right .header-profile > a.nav-link .header-info span {
        font-size: 14px;
    }
}

.vendor-dashboard .header-right .header-profile > a.nav-link .header-info strong {
    color: #3e4954;
}

.vendor-dashboard .header-right .header-profile > a.nav-link .header-info small {
    display: block;
    font-size: 13px;
    color: #fff;
    font-weight: 400;
    line-height: 2.2;
}

@media only screen and (max-width: 1199px) {
    .vendor-dashboard .header-right .header-profile > a.nav-link .header-info small {
        font-size: 12px;
        line-height: 1.5;
    }
}

.vendor-dashboard .header-right .header-profile .dropdown-menu {
    padding: 15px 0;
    min-width: 12.5rem;
    box-shadow: 0 5px 5px 0 rgba(99, 53, 152, 0.15), 0 5px 10px 0 rgba(99, 53, 152, 0.15);
}

.vendor-dashboard .header-right .header-profile img {
    width: 55px;
    height: 55px;
    border-radius: 3rem;
}

@media only screen and (max-width: 1199px) {
    .vendor-dashboard .header-right .header-profile img {
        width: 40px;
        height: 40px;
    }
}

.vendor-dashboard .header-right .header-profile .dropdown-toggle i {
    font-size: 1.25rem;
}

@media only screen and (max-width: 575px) {
    .vendor-dashboard .header-right .header-profile .dropdown-toggle span {
        display: none;
    }
}

.vendor-dashboard .header-right .header-profile .profile_title {
    background: #0e131a;
    color: #fff;
    padding: 10px 20px;
}

.vendor-dashboard .header-right .header-profile .profile_title h5 {
    color: #fff;
    margin-bottom: 3px;
}

.vendor-dashboard .header-right .header-profile .dropdown-item {
    padding: 8px 24px;
}

.vendor-dashboard .dz-fullscreen #icon-minimize {
    display: none;
}

.vendor-dashboard .dz-fullscreen.active #icon-full {
    display: none;
}

.vendor-dashboard .dz-fullscreen.active #icon-minimize {
    display: inline-block;
}

.vendor-dashboard .notification_dropdown .dropdown-menu-right {
    min-width: 310px;
    padding: 0rem 0 1rem;
    top: 100%;
}

.vendor-dashboard .notification_dropdown .dropdown-menu-right .notification_title {
    background: #0e131a;
    color: #fff;
    padding: 10px 20px;
}

.vendor-dashboard .notification_dropdown .dropdown-menu-right .notification_title h5 {
    color: #fff;
    margin-bottom: 3px;
}

.vendor-dashboard .notification_dropdown .dropdown-menu-right .media {
    width: 45px !important;
    height: 45px !important;
    font-size: 18px !important;
}

[data-theme-version=dark] .notification_dropdown .dropdown-menu-right .media {
    border-color: #333a54;
}

.vendor-dashboard .notification_dropdown .dropdown-menu-right .media > span {
    width: 35px;
    height: 35px;
    border-radius: 50px;
    display: inline-block;
    padding: 7px 9px;
    margin-right: 10px;
}

.vendor-dashboard .notification_dropdown .dropdown-menu-right .media > span.success {
    background: #e7faec;
    color: #2bc155;
}

.vendor-dashboard .notification_dropdown .dropdown-menu-right .media > span.primary {
    background: #fdf4f8;
    color: #0e131a;
}

.vendor-dashboard .notification_dropdown .dropdown-menu-right .media > span.danger {
    background: #fde6e6;
    color: #f35757;
}

.vendor-dashboard .notification_dropdown .dropdown-menu-right .media .notify-time {
    width: 100% !important;
    margin-right: 0 !important;
    color: #828690;
}

.vendor-dashboard .notification_dropdown .dropdown-menu-right .media p {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 200px;
    margin-bottom: 0;
    margin-top: 5px;
}

@media only screen and (max-width: 575px) {
    .vendor-dashboard .notification_dropdown .dropdown-menu-right .media p {
        max-width: 100px;
    }
}

.vendor-dashboard .notification_dropdown .dropdown-menu-right .all-notification {
    display: block;
    padding: 15px 30px 0;
    text-align: center;
    border-top: 1px solid #f4f4f4;
}

.vendor-dashboard .notification_dropdown .dropdown-menu-right .all-notification i {
    margin-left: 10px;
}

.vendor-dashboard .nav-label {
    margin: 10px 30px 0;
    padding: 1.5625rem 0 10px;
    text-transform: uppercase;
    font-size: 0.75rem;
    letter-spacing: 0.05rem;
    border-top: 1px solid #eaeaea;
    color: #999999;
}

[data-theme-version=dark] .nav-label {
    border-color: #333a54;
}

.vendor-dashboard .nav-label.first {
    border: 0px;
    margin-top: 0px;
}

.vendor-dashboard .nav-badge {
    position: absolute;
    right: 2.8125rem;
    top: 0.625rem;
}

.vendor-dashboard .content-body {
    transition: all 0.2s ease;
    margin-left: 5rem;
    margin-top: 6.5rem;
    z-index: 0;
}

.vendor-dashboard .content-body.extended {
    /* margin-left: 20vw; */
}

.vendor-dashboard .bell img {
    -webkit-animation: ring 8s 0.7s ease-in-out infinite;
    -webkit-transform-origin: 50% 4px;
    -moz-animation: ring 8s 0.7s ease-in-out infinite;
    -moz-transform-origin: 50% 4px;
    animation: ring 8s 0.7s ease-in-out infinite;
}

@-webkit-keyframes ring {
    0% {
        -webkit-transform: rotateZ(0);
    }
    1% {
        -webkit-transform: rotateZ(30deg);
    }
    3% {
        -webkit-transform: rotateZ(-28deg);
    }
    5% {
        -webkit-transform: rotateZ(34deg);
    }
    7% {
        -webkit-transform: rotateZ(-32deg);
    }
    9% {
        -webkit-transform: rotateZ(30deg);
    }
    11% {
        -webkit-transform: rotateZ(-28deg);
    }
    13% {
        -webkit-transform: rotateZ(26deg);
    }
    15% {
        -webkit-transform: rotateZ(-24deg);
    }
    17% {
        -webkit-transform: rotateZ(22deg);
    }
    19% {
        -webkit-transform: rotateZ(-20deg);
    }
    21% {
        -webkit-transform: rotateZ(18deg);
    }
    23% {
        -webkit-transform: rotateZ(-16deg);
    }
    25% {
        -webkit-transform: rotateZ(14deg);
    }
    27% {
        -webkit-transform: rotateZ(-12deg);
    }
    29% {
        -webkit-transform: rotateZ(10deg);
    }
    31% {
        -webkit-transform: rotateZ(-8deg);
    }
    33% {
        -webkit-transform: rotateZ(6deg);
    }
    35% {
        -webkit-transform: rotateZ(-4deg);
    }
    37% {
        -webkit-transform: rotateZ(2deg);
    }
    39% {
        -webkit-transform: rotateZ(-1deg);
    }
    41% {
        -webkit-transform: rotateZ(1deg);
    }
    43% {
        -webkit-transform: rotateZ(0);
    }
    100% {
        -webkit-transform: rotateZ(0);
    }
}

@-moz-keyframes ring {
    0% {
        -moz-transform: rotate(0);
    }
    1% {
        -moz-transform: rotate(30deg);
    }
    3% {
        -moz-transform: rotate(-28deg);
    }
    5% {
        -moz-transform: rotate(34deg);
    }
    7% {
        -moz-transform: rotate(-32deg);
    }
    9% {
        -moz-transform: rotate(30deg);
    }
    11% {
        -moz-transform: rotate(-28deg);
    }
    13% {
        -moz-transform: rotate(26deg);
    }
    15% {
        -moz-transform: rotate(-24deg);
    }
    17% {
        -moz-transform: rotate(22deg);
    }
    19% {
        -moz-transform: rotate(-20deg);
    }
    21% {
        -moz-transform: rotate(18deg);
    }
    23% {
        -moz-transform: rotate(-16deg);
    }
    25% {
        -moz-transform: rotate(14deg);
    }
    27% {
        -moz-transform: rotate(-12deg);
    }
    29% {
        -moz-transform: rotate(10deg);
    }
    31% {
        -moz-transform: rotate(-8deg);
    }
    33% {
        -moz-transform: rotate(6deg);
    }
    35% {
        -moz-transform: rotate(-4deg);
    }
    37% {
        -moz-transform: rotate(2deg);
    }
    39% {
        -moz-transform: rotate(-1deg);
    }
    41% {
        -moz-transform: rotate(1deg);
    }
    43% {
        -moz-transform: rotate(0);
    }
    100% {
        -moz-transform: rotate(0);
    }
}

@keyframes ring {
    0% {
        transform: rotate(0);
    }
    1% {
        transform: rotate(30deg);
    }
    3% {
        transform: rotate(-28deg);
    }
    5% {
        transform: rotate(34deg);
    }
    7% {
        transform: rotate(-32deg);
    }
    9% {
        transform: rotate(30deg);
    }
    11% {
        transform: rotate(-28deg);
    }
    13% {
        transform: rotate(26deg);
    }
    15% {
        transform: rotate(-24deg);
    }
    17% {
        transform: rotate(22deg);
    }
    19% {
        transform: rotate(-20deg);
    }
    21% {
        transform: rotate(18deg);
    }
    23% {
        transform: rotate(-16deg);
    }
    25% {
        transform: rotate(14deg);
    }
    27% {
        transform: rotate(-12deg);
    }
    29% {
        transform: rotate(10deg);
    }
    31% {
        transform: rotate(-8deg);
    }
    33% {
        transform: rotate(6deg);
    }
    35% {
        transform: rotate(-4deg);
    }
    37% {
        transform: rotate(2deg);
    }
    39% {
        transform: rotate(-1deg);
    }
    41% {
        transform: rotate(1deg);
    }
    43% {
        transform: rotate(0);
    }
    100% {
        transform: rotate(0);
    }
}

.vendor-dashboard .deznav {
    width: 5rem;
    padding-bottom: 0;
    height: 100%;
    position: fixed;
    top: 4.5rem;
    padding-top: 0;
    z-index: 5;
    background-color: #353535;
    transition: all 0.2s ease;
}

.vendor-dashboard .deznav.extended {
    width: auto;
}

.vendor-dashboard .deznav .deznav-scroll {
    position: relative;
    height: 100%;
}

.vendor-dashboard .deznav ul {
    padding: 0;
    margin: 0;
    list-style: none;
}

.vendor-dashboard .deznav .metismenu {
    display: flex;
    flex-direction: column;
    padding-top: 15px;
}

.vendor-dashboard .deznav .metismenu.fixed {
    position: fixed;
    top: 0;
    width: 100%;
    left: 0;
}

.vendor-dashboard .deznav .metismenu > li {
    display: flex;
    flex-direction: column;
}

.vendor-dashboard .deznav .metismenu > li a > i {
    font-size: 1.5rem;
    display: inline-block;
    vertical-align: middle;
    padding: 0 0.875rem 0 0;
    position: relative;
    top: 0;
    line-height: 1;
        width: 30px;
}

[data-sidebar-style=compact] .deznav .metismenu > li a > i {
    display: block;
    padding: 0;
}

@media only screen and (max-width: 767px) {
    .vendor-dashboard .deznav .metismenu > li a > i {
        font-size: 1.25rem;
        padding: 0 0.75rem 0 0;
    }
}

.vendor-dashboard .deznav .metismenu > li > a {
    font-weight: 400;
    display: inline-block;
    font-size: 1rem;
}

.vendor-dashboard .deznav .metismenu > li > a svg {
    max-width: 24px;
    max-height: 24px;
    height: 100%;
    margin-right: 5px;
    margin-top: -3px;
    color: #0e131a;
}

.vendor-dashboard .deznav .metismenu > li > a g [fill] {
    fill: #8088a1;
}

.vendor-dashboard .deznav .metismenu > li:active > a,
.vendor-dashboard .deznav .metismenu > li:hover > a,
.vendor-dashboard .deznav .metismenu > li:focus > a {
    color: #fff;
}

.vendor-dashboard .deznav .metismenu > li:hover > a g [fill],
.vendor-dashboard .deznav .metismenu > li:focus > a g [fill] {
    fill: #0e131a;
}

.vendor-dashboard .deznav .metismenu > li.mm-active > a {
    color: #fff;
}

.vendor-dashboard .deznav .metismenu > li.mm-active > a g [fill] {
    fill: #0e131a;
}

.vendor-dashboard .deznav .metismenu li {
    position: relative;
}

.vendor-dashboard .deznav .metismenu ul {
    transition: all 0.2s ease-in-out;
    position: relative;
    z-index: 1;
    padding: 0.5rem 0;
}

.vendor-dashboard .deznav .metismenu ul a {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    position: relative;
    font-size: 14px;
    padding-left: 33px;
}

@media only screen and (max-width: 767px) {
    .vendor-dashboard .deznav .metismenu ul a {
        padding-left: 3.5rem;
        font-size: 14px;
    }
}

.vendor-dashboard .deznav .metismenu ul a:hover,
.vendor-dashboard .deznav .metismenu ul a:focus,
.vendor-dashboard .deznav .metismenu ul a.mm-active {
    text-decoration: none;
    color: #7e7e7e;
}

.vendor-dashboard .deznav .metismenu a {
    position: relative;
    display: block;
    padding: 0.625rem 1.875rem;
    outline-width: 0;
    color: #7e7e7e;
    text-decoration: none;
}

@media only screen and (max-width: 767px) {
    .vendor-dashboard .deznav .metismenu a {
        padding: 0.625rem 1.25rem;
    }
}

.vendor-dashboard .deznav .metismenu .has-arrow:after {
    width: 0.5rem;
    height: 0.5rem;
    right: 1.875rem;
    top: 48%;
    border-color: inherit;
    -webkit-transform: rotate(-225deg) translateY(-50%);
    transform: rotate(-225deg) translateY(-50%);
}

.vendor-dashboard .deznav .metismenu .has-arrow[aria-expanded=true]:after,
.vendor-dashboard .deznav .metismenu .mm-active > .has-arrow:after {
    -webkit-transform: rotate(-135deg) translateY(-50%);
    transform: rotate(-135deg) translateY(-50%);
}

@media only screen and (max-width: 1199px) {
    .vendor-dashboard .nav-header {
        width: 5rem;
    }
}

@media (max-width: 767px) {
    .vendor-dashboard .brand-title {
        display: none;
    }

    .vendor-dashboard .footer {
        padding-left: 0;
    }

    .vendor-dashboard .deznav {
        left: 0;
        top: 0px;
    }
}

.vendor-dashboard .sidebar-right {
    right: -15.625rem;
    position: fixed;
    top: 0;
    width: 15.625rem;
    background-color: #fff;
    height: calc(100% - 7.0625rem);
    margin-top: 5rem;
    transition: all 0.5s ease-in-out;
    padding-bottom: 1.875rem;
    box-shadow: -2px 3px 10px 0px rgba(119, 119, 119, 0.1);
}

.vendor-dashboard .sidebar-right .sidebar-right-trigger {
    position: absolute;
    z-index: 9;
    top: 4.75rem;
    right: 100%;
    background-color: #fff;
    color: #0e131a;
    display: inline-block;
    height: 3rem;
    width: 3rem;
    text-align: center;
    font-size: 1.75rem;
    line-height: 3rem;
    border-radius: 5px 0 0 5px;
    box-shadow: -5px 3px 5px 0px rgba(119, 119, 119, 0.15);
}

[data-theme-version=dark] .sidebar-right .sidebar-right-trigger {
    background-color: #31343b;
    color: #fff;
}

.vendor-dashboard .sidebar-right .sidebar-right-trigger:hover {
    color: #0e131a;
}

.vendor-dashboard .sidebar-right.show {
    right: 0;
    z-index: 999;
}

.vendor-dashboard .sidebar-right .nav-tabs {
    justify-content: space-between;
    position: sticky;
    top: 0;
    background-color: #fff;
    z-index: 2;
}

[data-theme-version=dark] .sidebar-right .nav-tabs {
    background-color: #181f39;
}

.vendor-dashboard .sidebar-right .nav-tabs .nav-item {
    margin-bottom: 0;
    flex: 1;
}

.vendor-dashboard .sidebar-right .nav-tabs .nav-item .nav-link {
    border: 0;
    font-size: 1.125rem;
    position: relative;
    text-align: center;
    background-color: #fff;
}

.vendor-dashboard .sidebar-right .nav-tabs .nav-item .nav-link:after {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 2px;
    content: "";
    background: transparent;
}

[data-theme-version=dark] .sidebar-right .nav-tabs .nav-item .nav-link {
    background-color: #181f39;
}

[data-theme-version=dark] .sidebar-right .nav-tabs .nav-item .nav-link.active {
    border-right: none;
    border-left: none;
    border-top: none;
}

.vendor-dashboard .sidebar-right .tab-content {
    padding: 1.25rem;
}

.vendor-dashboard .sidebar-right .tab-content .tab-pane .admin-settings > div {
    margin-bottom: 10px;
}

.vendor-dashboard .sidebar-right .tab-content .tab-pane .admin-settings p {
    margin-bottom: 0.125rem;
}

.vendor-dashboard .sidebar-right .tab-content .tab-pane .admin-settings input[type=radio] {
    display: none;
}

.vendor-dashboard .sidebar-right .tab-content .tab-pane .admin-settings input[type=radio] + label {
    display: inline-block;
    width: 25px;
    height: 25px;
    cursor: pointer;
    transition: all 0.1s ease;
    border-radius: 4px;
    margin-right: 5px;
    margin-bottom: 3px;
}

.vendor-dashboard .sidebar-right .tab-content .tab-pane .admin-settings input[type=radio]:checked + label {
    position: relative;
}

.vendor-dashboard .sidebar-right .tab-content .tab-pane .admin-settings input[type=radio]:checked + label:after {
    height: 33px;
    width: 33px;
    left: -4px;
    top: -4px;
    content: "";
    position: absolute;
    background-color: inherit;
    border-radius: 6px;
    opacity: 0.4;
}

.vendor-dashboard .sidebar-right #nav_header_color_1 + label,
.vendor-dashboard .sidebar-right #header_color_1 + label,
.vendor-dashboard .sidebar-right #sidebar_color_1 + label,
.vendor-dashboard .sidebar-right #primary_color_1 + label {
    background-color: #fff;
}

.vendor-dashboard .sidebar-right #nav_header_color_2 + label,
.vendor-dashboard .sidebar-right #header_color_2 + label,
.vendor-dashboard .sidebar-right #sidebar_color_2 + label,
.vendor-dashboard .sidebar-right #primary_color_2 + label {
    background-color: #143b64;
}

.vendor-dashboard .sidebar-right #nav_header_color_3 + label,
.vendor-dashboard .sidebar-right #header_color_3 + label,
.vendor-dashboard .sidebar-right #sidebar_color_3 + label,
.vendor-dashboard .sidebar-right #primary_color_3 + label {
    background-color: #0e131a;
}

.vendor-dashboard .sidebar-right #nav_header_color_4 + label,
.vendor-dashboard .sidebar-right #header_color_4 + label,
.vendor-dashboard .sidebar-right #sidebar_color_4 + label,
.vendor-dashboard .sidebar-right #primary_color_4 + label {
    background-color: #4527a0;
}

.vendor-dashboard .sidebar-right #nav_header_color_5 + label,
.vendor-dashboard .sidebar-right #header_color_5 + label,
.vendor-dashboard .sidebar-right #sidebar_color_5 + label,
.vendor-dashboard .sidebar-right #primary_color_5 + label {
    background-color: #c62828;
}

.vendor-dashboard .sidebar-right #nav_header_color_6 + label,
.vendor-dashboard .sidebar-right #header_color_6 + label,
.vendor-dashboard .sidebar-right #sidebar_color_6 + label,
.vendor-dashboard .sidebar-right #primary_color_6 + label {
    background-color: #283593;
}

.vendor-dashboard .sidebar-right #nav_header_color_7 + label,
.vendor-dashboard .sidebar-right #header_color_7 + label,
.vendor-dashboard .sidebar-right #sidebar_color_7 + label,
.vendor-dashboard .sidebar-right #primary_color_7 + label {
    background-color: #7356f1;
}

.vendor-dashboard .sidebar-right #nav_header_color_8 + label,
.vendor-dashboard .sidebar-right #header_color_8 + label,
.vendor-dashboard .sidebar-right #sidebar_color_8 + label,
.vendor-dashboard .sidebar-right #primary_color_8 + label {
    background-color: #3695eb;
}

.vendor-dashboard .sidebar-right #nav_header_color_9 + label,
.vendor-dashboard .sidebar-right #header_color_9 + label,
.vendor-dashboard .sidebar-right #sidebar_color_9 + label,
.vendor-dashboard .sidebar-right #primary_color_9 + label {
    background-color: #00838f;
}

.vendor-dashboard .sidebar-right #nav_header_color_10 + label,
.vendor-dashboard .sidebar-right #header_color_10 + label,
.vendor-dashboard .sidebar-right #sidebar_color_10 + label,
.vendor-dashboard .sidebar-right #primary_color_10 + label {
    background-color: #ff8f16;
}

.vendor-dashboard .sidebar-right #nav_header_color_11 + label,
.vendor-dashboard .sidebar-right #header_color_11 + label,
.vendor-dashboard .sidebar-right #sidebar_color_11 + label,
.vendor-dashboard .sidebar-right #primary_color_11 + label {
    background-color: #6673fd;
}

.vendor-dashboard .sidebar-right #nav_header_color_12 + label,
.vendor-dashboard .sidebar-right #header_color_12 + label,
.vendor-dashboard .sidebar-right #sidebar_color_12 + label,
.vendor-dashboard .sidebar-right #primary_color_12 + label {
    background-color: #558b2f;
}

.vendor-dashboard .sidebar-right #nav_header_color_13 + label,
.vendor-dashboard .sidebar-right #header_color_13 + label,
.vendor-dashboard .sidebar-right #sidebar_color_13 + label,
.vendor-dashboard .sidebar-right #primary_color_13 + label {
    background-color: #2a2a2a;
}

.vendor-dashboard .sidebar-right #nav_header_color_14 + label,
.vendor-dashboard .sidebar-right #header_color_14 + label,
.vendor-dashboard .sidebar-right #sidebar_color_14 + label,
.vendor-dashboard .sidebar-right #primary_color_14 + label {
    background-color: #1367c8;
}

.vendor-dashboard .sidebar-right #nav_header_color_15 + label,
.vendor-dashboard .sidebar-right #header_color_15 + label,
.vendor-dashboard .sidebar-right #sidebar_color_15 + label,
.vendor-dashboard .sidebar-right #primary_color_15 + label {
    background-color: #ed0b4c;
}

.vendor-dashboard .sidebar-right #nav_header_color_1 + label,
.vendor-dashboard .sidebar-right #header_color_1 + label,
.vendor-dashboard .sidebar-right #sidebar_color_1 + label,
.vendor-dashboard .sidebar-right #primary_color_1 + label {
    border: 1px solid #89879f;
}

@keyframes bounce {
    0% {
        transform: translateX(-8%);
        -webkit-transform: translateX(-8%);
    }
    50% {
        transform: translateX(8%);
        -webkit-transform: translateX(8%);
    }
    100% {
        transform: translateX(-8%);
        -webkit-transform: translateX(-8%);
    }
}

@-webkit-keyframes bounce {
    0% {
        transform: translateX(-8%);
        -webkit-transform: translateX(-8%);
    }
    50% {
        transform: translateX(8%);
        -webkit-transform: translateX(8%);
    }
    100% {
        transform: translateY(-8%);
        -webkit-transform: translateY(-8%);
    }
}

.vendor-dashboard .nav-user {
    background: #0e131a;
    margin-bottom: 10px;
    padding: 20px 25px 15px;
}

@media only screen and (min-width: 768px) and (max-width: 1199px) {
    .vendor-dashboard .nav-user {
        padding: 20px 15px 15px;
    }
}

.vendor-dashboard .nav-user img {
    width: 35px;
    height: 35px;
}

@media only screen and (min-width: 768px) and (max-width: 1199px) {
    .vendor-dashboard .nav-user img {
        width: 35px;
        height: 35px;
        margin-bottom: 10px;
    }
}

.vendor-dashboard .nav-user h5 {
    margin-left: 10px;
    margin-bottom: 3px;
    color: #fff;
}

@media only screen and (min-width: 768px) and (max-width: 1199px) {
    .vendor-dashboard .nav-user h5 {
        display: none;
    }
}

[data-sibebarbg=color_2] .nav-user h5 {
    color: #fff;
}

.vendor-dashboard .nav-user p {
    margin-left: 10px;
    margin-bottom: 8px;
    color: #afcff7;
}

@media only screen and (min-width: 768px) and (max-width: 1199px) {
    .vendor-dashboard .nav-user p {
        display: none;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1199px) {
    .vendor-dashboard .nav-user i {
        margin-top: 15px;
        display: block;
    }
}

.vendor-dashboard .menu-toggle .nav-user {
    padding: 20px 15px 15px;
}

.vendor-dashboard .menu-toggle .nav-user img {
    width: 35px;
    height: 35px;
    margin-bottom: 10px;
}

.vendor-dashboard .menu-toggle .nav-user h5,
.vendor-dashboard .menu-toggle .nav-user p {
    display: none;
}

.vendor-dashboard .menu-toggle .nav-user i {
    margin-top: 15px;
    display: block;
}

.vendor-dashboard .menu-toggle .nav-user .dropdown-menu {
    left: 45px !important;
    top: 22px !important;
}

.vendor-dashboard .chatbox {
    width: 340px;
    height: 100vh;
    position: fixed;
    right: -500px;
    top: 0;
    z-index: 999;
    background: #fff;
    box-shadow: 0px 0px 30px 0px rgba(82, 63, 105, 0.15);
    -webkit-transition: all 0.8s;
    -ms-transition: all 0.8s;
    transition: all 0.8s;
}

[data-theme-version=dark] .chatbox {
    background: #1e2746;
}

.vendor-dashboard .chatbox .chatbox-close {
    position: absolute;
    -webkit-transition: all 0.2s;
    -ms-transition: all 0.2s;
    transition: all 0.2s;
    width: 0;
    height: 100%;
    right: 340px;
    background: #000;
    z-index: 1;
    opacity: 0.1;
    cursor: pointer;
}

.vendor-dashboard .chatbox .card-fotter {
    padding: 0.75rem 1rem;
}

.vendor-dashboard .chatbox .card-body {
    padding: 1rem;
}

.vendor-dashboard .chatbox.active {
    right: 0;
}

.vendor-dashboard .chatbox.active .chatbox-close {
    width: 100vw;
}

.vendor-dashboard .chatbox .type_msg {
    padding-top: 10px;
}

.vendor-dashboard .chatbox .nav {
    padding: 1rem 1rem 0 1rem;
    background: #392540;
    border: 0;
    justify-content: space-between;
}

.vendor-dashboard .chatbox .nav .nav-link {
    color: #fff;
    opacity: 0.7;
    text-transform: uppercase;
}

.vendor-dashboard .chatbox .nav .nav-link:hover,
.vendor-dashboard .chatbox .nav .nav-link.active {
    background: transparent;
    color: #fff;
    opacity: 1;
    border-color: #f35757;
}

.vendor-dashboard .chatbox .img_cont {
    width: 40px;
    border-radius: 40px;
    margin-right: 10px;
    position: relative;
    height: 40px;
    background: #eee;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 14px;
    min-width: 40px;
    min-height: 40px;
}

.vendor-dashboard .chatbox .img_cont .icon {
    color: #fff;
}

.vendor-dashboard .chatbox .img_cont.primary {
    background: #fdf4f8;
    color: #0e131a;
}

[data-theme-version=dark] .chatbox .img_cont.primary {
    background: rgba(221, 47, 110, 0.2);
}

.vendor-dashboard .chatbox .img_cont.warning {
    background: #fff6e0;
    color: #ffb800;
}

[data-theme-version=dark] .chatbox .img_cont.warning {
    background: rgba(255, 184, 0, 0.1);
}

.vendor-dashboard .chatbox .img_cont.success {
    background: #e7faec;
    color: #2bc155;
}

[data-theme-version=dark] .chatbox .img_cont.success {
    background: rgba(43, 193, 85, 0.1);
}

.vendor-dashboard .chatbox .img_cont.info {
    background: #dee3fa;
    color: #2F4CDD;
}

[data-theme-version=dark] .chatbox .img_cont.info {
    background: rgba(47, 76, 221, 0.1);
}

.vendor-dashboard .chatbox .img_cont img {
    width: 100%;
}

.vendor-dashboard .chatbox .img_cont .online_icon {
    background: #2bc155;
    position: absolute;
    width: 12px;
    height: 12px;
    border-radius: 15px;
    right: -1px;
    bottom: 0px;
    border: 2px solid #fff;
}

.vendor-dashboard .chatbox .img_cont .online_icon.offline {
    background: #f35757;
}

.vendor-dashboard .chatbox .card {
    box-shadow: none;
}

.vendor-dashboard .chatbox .search {
    height: 40px;
}

.vendor-dashboard .chatbox .user_info span {
    font-size: 15px;
    color: #000;
    display: block;
    font-weight: 500;
    line-height: 1;
    margin-bottom: 5px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: block;
    max-width: 170px;
}

[data-theme-version=dark] .chatbox .user_info span {
    color: #fff;
}

.vendor-dashboard .chatbox .user_info p {
    font-size: 13px;
    margin-bottom: 0;
    line-height: 1;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: block;
    max-width: 170px;
}

.vendor-dashboard .chatbox .contacts li {
    padding: 7px 1rem;
    border-bottom: 1px solid #eee;
    cursor: pointer;
}

[data-theme-version=dark] .chatbox .contacts li {
    border-color: #333a54;
}

.vendor-dashboard .chatbox .contacts li > div {
    display: flex;
    align-items: center;
}

.vendor-dashboard .chatbox .contacts li:hover {
    background: #f4f7ff;
}

[data-theme-version=dark] .chatbox .contacts li:hover {
    background-color: #181f39;
}

.vendor-dashboard .chatbox .contacts .name-first-letter {
    background: #ffffff;
    padding: 4px 1rem;
    font-weight: 700;
    color: #000;
    position: sticky;
    top: 0;
    z-index: 1;
}

[data-theme-version=dark] .chatbox .contacts .name-first-letter {
    color: #fff;
    background: #181f39;
}

.vendor-dashboard .chatbox .msg_card_body {
    height: calc(100vh - 195px);
}

.vendor-dashboard .chatbox .contacts_body {
    height: calc(100vh - 120px);
}

.vendor-dashboard .chatbox .card-header {
    background: #f4f7ff;
    padding: 15px 20px;
    justify-content: center;
}

.vendor-dashboard .chatbox .card-header h6 {
    font-size: 15px;
}

.vendor-dashboard .chatbox .card-header p {
    line-height: 1.2;
    font-size: 12px;
    color: #7e7e7e;
}

.vendor-dashboard .chatbox .chat-list-header {
    justify-content: space-between;
    background: #fff;
}

[data-theme-version=dark] .chatbox .chat-list-header {
    background: #1e2746;
}

.vendor-dashboard .chatbox .chat-list-header a {
    text-align: center;
    width: 30px;
    height: 30px;
    background: #ffffff;
    border-radius: 6px;
    line-height: 30px;
    display: block;
}

[data-theme-version=dark] .chatbox .chat-list-header a {
    background: rgba(221, 47, 110, 0.2);
}

[data-theme-version=dark] .chatbox .chat-list-header a svg g [fill] {
    fill: #fff;
}

.vendor-dashboard .chatbox .img_cont_msg {
    width: 30px;
    height: 30px;
    display: block;
    max-width: 30px;
    min-width: 30px;
}

.vendor-dashboard .chatbox .img_cont_msg img {
    width: 100%;
}

.vendor-dashboard .chatbox .msg_cotainer {
    background: #0e131a;
    margin-left: 10px;
    border-radius: 0 0.75rem 0.75rem 0.75rem;
    padding: 10px 15px;
    color: #fff;
    position: relative;
}

.vendor-dashboard .chatbox .msg_cotainer .msg_time {
    display: block;
    font-size: 11px;
    color: #fff;
    margin-top: 5px;
    opacity: 0.5;
}

.vendor-dashboard .chatbox .msg_cotainer:after {
    content: "";
    position: absolute;
    left: -10px;
    border-right: 10px solid #0e131a;
    border-bottom: 10px solid transparent;
    border-top: 0px solid;
    top: 0;
}

.vendor-dashboard .chatbox .msg_cotainer_send {
    background: #ffffff;
    padding: 10px 15px;
    border-radius: 6px 0px 6px 6px;
    margin-right: 10px;
    color: #222;
    position: relative;
    text-align: right;
}

[data-theme-version=dark] .chatbox .msg_cotainer_send {
    background: #181f39;
    color: #fff;
}

.vendor-dashboard .chatbox .msg_cotainer_send .msg_time_send {
    display: block;
    font-size: 11px;
    text-align: right;
    margin-top: 5px;
    opacity: 0.6;
}

.vendor-dashboard .chatbox .msg_cotainer_send:after {
    content: "";
    position: absolute;
    right: -10px;
    border-left: 10px solid #ffffff;
    border-bottom: 10px solid transparent;
    border-top: 0px solid;
    top: 0;
}

[data-theme-version=dark] .chatbox .msg_cotainer_send:after {
    border-left: 10px solid #181f39;
}

.vendor-dashboard .chatbox .type_msg .form-control {
    padding: 10px 0;
    height: 50px;
    border: 0;
}

.vendor-dashboard .chatbox .type_msg .btn {
    font-size: 18px;
    border-radius: 38px !important;
    width: 38px;
    height: 38px;
    padding: 0;
    margin-top: 6px;
}

.vendor-dashboard .chatbox .video_cam {
    margin-left: 15px;
}

.vendor-dashboard .chatbox .video_cam span {
    width: 35px;
    height: 35px;
    background: #10ca93;
    text-align: center;
    line-height: 35px;
    border-radius: 35px;
    color: #fff;
    margin-right: 5px;
    align-self: center;
    font-size: 16px;
    padding: 0 3px;
    display: inline-block;
}

.vendor-dashboard .chatbox .note_card .contacts li {
    padding: 12px 1rem;
}

@media only screen and (max-width: 576px) {
    .vendor-dashboard .chatbox {
        width: 280px;
    }

    .vendor-dashboard .chatbox .chatbox-close {
        right: 280px;
    }
}


.vendor-dashboard .bg-primary-alt {
    background-color: rgba(221, 47, 110, 0.1);
}

.vendor-dashboard .bg-secondary-alt {
    background-color: rgba(57, 37, 64, 0.1);
}

.vendor-dashboard .bg-success-alt {
    background-color: rgba(43, 193, 85, 0.1);
}

.vendor-dashboard .bg-info-alt {
    background-color: rgba(47, 76, 221, 0.1);
}

.vendor-dashboard .bg-warning-alt {
    background-color: rgba(255, 184, 0, 0.1);
}

.vendor-dashboard .bg-danger-alt {
    background-color: rgba(243, 87, 87, 0.1);
}

.vendor-dashboard .bg-light-alt {
    background-color: rgba(244, 244, 244, 0.1);
}

.vendor-dashboard .bg-dark-alt {
    background-color: rgba(62, 73, 84, 0.1);
}

.vendor-dashboard .app-fullcalender button {
    border-radius: 0px;
    color: #3e4954;
}

.vendor-dashboard .app-fullcalender td {
    border-color: #d7dae3;
}

.vendor-dashboard .calendar {
    float: left;
    margin-bottom: 0;
}

.vendor-dashboard .fc-view {
    margin-top: 1.875rem;
}

.vendor-dashboard .fc-toolbar {
    margin-bottom: 0.3125rem;
    margin-top: 0.9375rem;
}

@media (max-width: 575.98px) {
    .vendor-dashboard .fc-toolbar .fc-left {
        display: flex;
        justify-content: space-between;
        margin-bottom: 0.625rem;
        float: none;
    }
}

@media (max-width: 575.98px) {
    .vendor-dashboard .fc-toolbar .fc-right {
        display: flex;
        justify-content: center;
        float: none;
        margin-bottom: 0.3125rem;
    }
}

@media (max-width: 575.98px) {
    .vendor-dashboard .fc-toolbar .fc-center {
        display: flex;
        justify-content: center;
    }

    .vendor-dashboard .fc-toolbar .fc-center * {
        float: none;
    }
}

.vendor-dashboard .fc-toolbar h2 {
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.875rem;
    text-transform: uppercase;
}

.vendor-dashboard .fc-toolbar .fc-state-active,
.vendor-dashboard .fc-toolbar .ui-state-active,
.vendor-dashboard .fc-toolbar .ui-state-hover,
.vendor-dashboard .fc-toolbar button:focus,
.vendor-dashboard .fc-toolbar button:hover {
    z-index: 0;
    box-shadow: none;
}

.vendor-dashboard .fc-widget-header {
    border: 1px solid #d7dae3;
    border-bottom: 0 !important;
}

.vendor-dashboard .fc th.fc-widget-header {
    background: #d7dae3 !important;
    font-size: 0.875rem;
    line-height: 1.25rem;
    padding: 0.625rem 0;
    text-transform: uppercase;
}

.vendor-dashboard .fc-button {
    border: 1px solid #d7dae3;
    text-transform: capitalize;
}

.vendor-dashboard .fc-button.active {
    box-shadow: none !important;
}

.vendor-dashboard .fc-text-arrow {
    font-family: inherit;
    font-size: 1rem;
}

.vendor-dashboard .fc-event {
    border-radius: 0.125rem;
    border: none;
    cursor: move;
    font-size: 0.8125rem;
    margin: 0.3125rem 0.4375rem;
    padding: 0.3125rem;
    text-align: center;
}

.vendor-dashboard .external-event {
    cursor: move;
    margin: 0.625rem 0;
    padding: 0.125rem 0;
}

.vendor-dashboard .fc-basic-view td.fc-week-number span {
    padding-right: 0.3125rem;
}

.vendor-dashboard .fc-basic-view td.fc-day-number {
    padding-right: 0.3125rem;
}

.vendor-dashboard #drop-remove {
    margin-right: 8px;
    top: 0.1875rem;
}

.vendor-dashboard #add-category .modal-dialog,
.vendor-dashboard #event-modal .modal-dialog {
    max-width: 37.5rem;
}

.vendor-dashboard .fc-content {
    color: #fff;
}

.vendor-dashboard .fc th.fc-widget-header {
    background: transparent !important;
}

.vendor-dashboard .fc-button {
    background: #fff;
}

.vendor-dashboard .fc-state-hover {
    background: #fff !important;
}

.vendor-dashboard .fc-state-highlight {
    background: #f2f4fa !important;
}

[data-theme-version=dark] .fc-state-highlight {
    color: #fff !important;
}

.vendor-dashboard .fc-cell-overlay {
    background: #fff !important;
}

.vendor-dashboard .fc-unthemed .fc-today {
    background: #f2f4fa !important;
}

.vendor-dashboard .fc-day-top {
    color: #3e4954 !important;
}

[data-theme-version=dark] .fc-day-top {
    color: #fff !important;
}

.vendor-dashboard .external-event {
    color: #fff;
}

.vendor-dashboard .fc-basic-view .fc-body .fc-row {
    min-height: 1rem;
}

.vendor-dashboard .fc-scroller.fc-day-grid-container {
    height: 490px !important;
}

.vendor-dashboard .fc-row.fc-week.fc-widget-content.fc-rigid {
    height: 81px !important;
}

@media only screen and (max-width: 1440px) {
    .vendor-dashboard .email_left_pane {
        display: none;
    }
}

.vendor-dashboard #external-events .external-event:before {
    content: "";
    display: inline-block;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    margin-right: 0.9rem;
    position: relative;
    top: 2px;
}

.vendor-dashboard #external-events [data-class=bg-primary] {
    background-color: transparent !important;
    color: #3e4954;
}

[data-theme-version=dark] #external-events [data-class=bg-primary] {
    color: #fff !important;
}

.vendor-dashboard #external-events [data-class=bg-primary]:before {
    background: #0e131a;
}

.vendor-dashboard #external-events [data-class=bg-success] {
    background-color: transparent !important;
    color: #3e4954;
}

.vendor-dashboard #external-events [data-class=bg-success]:before {
    background: #2bc155;
}

.vendor-dashboard #external-events [data-class=bg-warning] {
    background-color: transparent !important;
    color: #3e4954;
}

.vendor-dashboard #external-events [data-class=bg-warning]:before {
    background: #ffb800;
}

.vendor-dashboard #external-events [data-class=bg-dark] {
    background-color: transparent !important;
    color: #3e4954;
}

.vendor-dashboard #external-events [data-class=bg-dark]:before {
    background: #3e4954;
}

.vendor-dashboard #external-events [data-class=bg-danger] {
    background-color: transparent !important;
    color: #3e4954;
}

.vendor-dashboard #external-events [data-class=bg-danger]:before {
    background: #f35757;
}

.vendor-dashboard #external-events [data-class=bg-info] {
    background-color: transparent !important;
    color: #3e4954;
}

.vendor-dashboard #external-events [data-class=bg-info]:before {
    background: #2F4CDD;
}

.vendor-dashboard #external-events [data-class=bg-pink] {
    background-color: transparent !important;
    color: #3e4954;
}

.vendor-dashboard #external-events [data-class=bg-pink]:before {
    background: #e83e8c;
}

.vendor-dashboard .fc .fc-row .fc-content-skeleton table,
.vendor-dashboard .fc .fc-row .fc-content-skeleton td,
.vendor-dashboard .fc .fc-row .fc-helper-skeleton td {
    border-color: #d7dae3;
}

.vendor-dashboard .email-left-box {
    width: 15rem;
    float: left;
    padding: 0 1.25rem 1.25rem 1rem;
    border-top: 0;
    border-left: 0;
}

@media (min-width: 576px) and (max-width: 991.98px) {
    .vendor-dashboard .email-left-box {
        width: 12rem;
    }
}

@media (max-width: 575.98px) {
    .vendor-dashboard .email-left-box {
        width: 100%;
        float: none;
        border: none;
    }
}

.vendor-dashboard .email-left-box .intro-title {
    background: rgba(221, 47, 110, 0.1);
    padding: 1rem;
    margin: 1.875rem 0 1.25rem 0;
}

.vendor-dashboard .email-left-box .intro-title h5 {
    margin-bottom: 0;
    color: #6a707e;
    font-size: 14px;
}

.vendor-dashboard .email-left-box .intro-title h5 i {
    font-size: 0.75rem;
    position: relative;
    bottom: 1px;
}

.vendor-dashboard .email-left-box .intro-title i {
    color: #0e131a;
}

.vendor-dashboard .email-right-box {
    padding-left: 15rem;
    padding-right: 1rem;
}

@media (min-width: 576px) and (max-width: 991.98px) {
    .vendor-dashboard .email-right-box {
        padding-left: 12rem;
    }
}

@media (max-width: 575.98px) {
    .vendor-dashboard .email-right-box {
        padding-left: 0;
        padding-right: 0;
    }
}

.vendor-dashboard .email-right-box .right-box-border {
    border-right: 2px solid rgba(221, 47, 110, 0.1);
}

@media screen and (min-width: 649px) and (max-width: 1200px) {
    .vendor-dashboard .email-right-box .right-box-padding {
        padding-left: 1.25rem;
    }
}

@media (min-width: 1700px) {
    .vendor-dashboard .email-right-box .right-box-padding {
        padding-left: 0.9375rem;
    }
}

.vendor-dashboard .toolbar .btn-group .btn {
    border: 0;
}

.vendor-dashboard .toolbar .btn-group input {
    position: relative;
    top: 2px;
}

.vendor-dashboard .read-content textarea {
    height: 150px;
}

.vendor-dashboard .read-content-email {
    font-size: 0.875rem;
}

.vendor-dashboard .read-content h5 {
    color: #6a707e;
}

.vendor-dashboard .read-content p strong {
    color: #6a707e;
}

.vendor-dashboard .read-content-body p {
    margin-bottom: 1.875rem;
}

.vendor-dashboard .read-content-attachment {
    padding: 0.5rem 0;
}

.vendor-dashboard .read-content-attachment h6 {
    font-size: 1.125rem;
    color: #6a707e;
}

.vendor-dashboard .read-content-attachment h6 i {
    padding-right: 0.3125rem;
}

.vendor-dashboard .read-content-attachment .attachment > div:not(:last-child) {
    border-right: 1px solid #DDDFE1;
}

.vendor-dashboard .compose-content .wysihtml5-toolbar {
    border-color: #eaeaea;
}

.vendor-dashboard .compose-content .dropzone {
    background: #f2f4fa !important;
}

.vendor-dashboard .compose-content h5 {
    font-size: 1.0625rem;
    color: #6a707e;
}

.vendor-dashboard .compose-content h5 i {
    font-size: 1.125rem;
    transform: rotate(90deg);
}

.vendor-dashboard .compose-content .dropzone {
    border: 1px dashed #DDDFE1;
    min-height: 13.125rem;
    position: relative;
}

.vendor-dashboard .compose-content .dropzone .dz-message {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.vendor-dashboard .email-list {
    display: block;
    padding-left: 0;
}

.vendor-dashboard .email-list .message {
    position: relative;
    display: block;
    height: 3.125rem;
    line-height: 3.125rem;
    cursor: default;
    transition-duration: 0.3s;
}

.vendor-dashboard .email-list .message a {
    color: #828690;
}

.vendor-dashboard .email-list .message-single .custom-checkbox {
    margin-top: 2px;
}

.vendor-dashboard .email-list .message-single i {
    color: #89879f;
    font-size: 1.125rem;
    padding-left: 0.4rem;
}

.vendor-dashboard .email-list .message:hover {
    transition-duration: 0.05s;
    background: rgba(152, 166, 173, 0.15);
}

.vendor-dashboard .email-list .message .col-mail {
    float: left;
    position: relative;
}

.vendor-dashboard .email-list .message .col-mail-1 {
    width: 5.625rem;
}

.vendor-dashboard .email-list .message .col-mail-1 .star-toggle {
    display: block;
    float: left;
    margin-top: 1.125rem;
    font-size: 1rem;
    margin-left: 0.3125rem;
}

.vendor-dashboard .email-list .message .col-mail-1 .email-checkbox {
    display: block;
    float: left;
    margin: 0.9375rem 0.625rem 0 1.25rem;
}

.vendor-dashboard .email-list .message .col-mail-1 .dot {
    display: block;
    float: left;
    border: 0.4rem solid transparent;
    border-radius: 6.25rem;
    margin: 1.375rem 1.625rem 0;
    height: 0;
    width: 0;
    line-height: 0;
    font-size: 0;
}

.vendor-dashboard .email-list .message .col-mail-2 {
    position: absolute;
    top: 0;
    left: 5.625rem;
    right: 0;
    bottom: 0;
}

.vendor-dashboard .email-list .message .col-mail-2 .subject {
    position: absolute;
    top: 0;
    left: 0;
    right: 5.5rem;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.vendor-dashboard .email-list .message .col-mail-2 .date {
    position: absolute;
    top: 0;
    right: 0;
}

.vendor-dashboard .email-checkbox {
    cursor: pointer;
    height: 0.9375rem;
    width: 0.9375rem;
    position: relative;
    display: inline-block;
    border-radius: 0.1rem;
    position: relative;
    top: 0.3125rem;
    box-shadow: inset 0 0 0 0.1rem #828690;
}

.vendor-dashboard .email-checkbox input {
    opacity: 0;
    cursor: pointer;
}

.vendor-dashboard .email-checkbox input:checked label {
    opacity: 1;
}

.vendor-dashboard .email-checkbox label {
    position: absolute;
    top: 0.3rem;
    left: 0.3rem;
    right: 0.3rem;
    bottom: 0.3rem;
    cursor: pointer;
    opacity: 0;
    margin-bottom: 0 !important;
    transition-duration: 0.05s;
}

.vendor-dashboard .mail-list a {
    vertical-align: middle;
    padding: 0.625rem 0.9375rem;
    display: block;
    background: transparent;
    color: #464a53;
    font-weight: 600;
}

.vendor-dashboard .mail-list .list-group-item {
    color: #6a707e;
    padding: 0.75rem 1.0625rem;
}

.vendor-dashboard .mail-list .list-group-item i {
    font-size: 1rem;
    padding-right: 0.625rem;
    color: #cccccc;
}

.vendor-dashboard .mail-list .list-group-item.active {
    color: #fff;
}

.vendor-dashboard .mail-list .list-group-item.active i {
    color: #fff;
}

.vendor-dashboard .chat-wrap {
    padding: 1.0625rem 1.875rem;
}

.vendor-dashboard .chat-wrap .media .media-body h6 {
    font-size: 1.0625rem;
    color: #6a707e;
}

.vendor-dashboard .chat-wrap .media .media-body p {
    font-size: 0.875rem;
}

@media (min-width: 648px) {
    .vendor-dashboard .email-filter {
        padding-left: 1.25rem;
    }
}

@media (min-width: 1700px) {
    .vendor-dashboard .email-filter {
        padding-left: 1.875rem;
    }
}

.vendor-dashboard .email-filter .input-group-prepend i {
    font-size: 0.875rem;
    color: #89879f;
}

.vendor-dashboard .email-filter .input-group-prepend .input-group-text {
    border: 0;
    border-bottom: 1px solid #DDDFE1 !important;
    background: transparent;
}

.vendor-dashboard .email-filter .input-group .form-control {
    padding: 0 0 0 0.3125rem;
    border: 0;
    font-size: 0.875rem;
    height: 1.875rem;
    color: #89879f;
    border-bottom: 1px solid #DDDFE1;
}

.vendor-dashboard .email-filter .input-group .form-control:placeholder {
    font-size: 0.875rem;
    color: #89879f;
}

.vendor-dashboard .email-filter .input-group > .form-control {
    min-height: 1.875rem;
}

.vendor-dashboard .single-mail {
    display: block;
    padding: 1.5625rem 0;
}

.vendor-dashboard .single-mail .media {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
}

@media (min-width: 1700px) {
    .vendor-dashboard .single-mail .media {
        padding-left: 1.875rem;
        padding-right: 1.875rem;
    }
}

.vendor-dashboard .single-mail .media img {
    width: 55px;
    height: 55px;
    border-radius: 50%;
    margin-right: 0.9375rem;
}

@media (min-width: 1700px) {
    .vendor-dashboard .single-mail .media img {
        margin-right: 1.875rem;
    }
}

.vendor-dashboard .single-mail .media-body h6 {
    color: #abafb3;
}

.vendor-dashboard .single-mail .media-body h4 {
    font-size: 1rem;
    color: #6a707e;
}

.vendor-dashboard .single-mail .media-body h4 button i {
    font-size: 1.125rem;
    color: #abafb3;
    font-weight: bold;
    transform: rotate(90deg);
}

.vendor-dashboard .single-mail .media-body p {
    font-size: 0.875rem;
    color: #abafb3;
}

.vendor-dashboard .single-mail.active {
    background: #0e131a;
}

.vendor-dashboard .single-mail.active h6,
.vendor-dashboard .single-mail.active h4,
.vendor-dashboard .single-mail.active p,
.vendor-dashboard .single-mail.active i {
    color: #fff !important;
}

.vendor-dashboard .profile-info {
    padding: 15px 20px;
}

@media only screen and (max-width: 575px) {
    .vendor-dashboard .profile-info {
        padding: 0 0 20px;
        text-align: center;
    }
}

.vendor-dashboard .profile-info h4 {
    color: #464a53 !important;
}

.vendor-dashboard .profile-info h4.text-primary {
    color: #0e131a !important;
}

.vendor-dashboard .profile-info p {
    color: #828690;
}

.vendor-dashboard .profile-info .prf-col {
    min-width: 250px;
    padding: 10px 50px 0;
}

.vendor-dashboard .profile-interest .row {
    margin: 0 -0.7px;
}

.vendor-dashboard .profile-interest .row .int-col {
    padding: 0 0.7px;
}

.vendor-dashboard .profile-interest .row .int-col .interest-cat {
    margin-bottom: 1.4px;
    position: relative;
    display: block;
}

.vendor-dashboard .profile-interest .row .int-col .interest-cat:after {
    background: #000;
    bottom: 0;
    content: "";
    left: 0;
    opacity: 0.5;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 0;
}

.vendor-dashboard .profile-interest .row .int-col .interest-cat p {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    padding: 5px;
    left: 0;
    margin: 0;
    z-index: 1;
    color: white;
    font-size: 1.2px;
}

.vendor-dashboard .profile-tab .nav-item .nav-link {
    font-size: 16px;
    margin-right: 30px;
    transition: all 0.5s ease-in-out;
    border: none;
    border-bottom: 0.2px solid transparent;
    color: #828690;
}

.vendor-dashboard .profile-tab .nav-item .nav-link:hover,
.vendor-dashboard .profile-tab .nav-item .nav-link.active {
    border: 0;
    background: transparent;
    background: transparent;
    border-bottom: 0.2px solid #0e131a;
    color: #0e131a;
}

.vendor-dashboard .profile-info {
    display: flex;
}

@media only screen and (max-width: 575px) {
    .vendor-dashboard .profile-info {
        display: block;
    }
}

.vendor-dashboard .profile-info .profile-details {
    display: flex;
    width: 100%;
}

@media only screen and (max-width: 575px) {
    .vendor-dashboard .profile-info .profile-details {
        display: block;
    }

    .vendor-dashboard .profile-info .profile-details .dropdown {
        position: absolute;
        top: 30px;
        right: 30px;
    }
}

.vendor-dashboard .post-input {
    margin-bottom: 30px;
}

.vendor-dashboard .post-input .form-control {
    height: 75px;
    font-weight: 400;
    margin: 15px 0;
}

.vendor-dashboard .profile-uoloaded-post img {
    margin-bottom: 20px;
}

.vendor-dashboard .profile-uoloaded-post a h4 {
    margin-bottom: 10px;
    color: #464a53;
}

.vendor-dashboard .event-chat-ryt .chat-area .chat-reciver,
.vendor-dashboard .event-chat-ryt .chat-area .chat-sender {
    margin-bottom: 1.875rem;
    padding: 0;
}

.vendor-dashboard .event-chat-ryt .chat-area .chat-reciver img,
.vendor-dashboard .event-chat-ryt .chat-area .chat-sender img {
    border-radius: 30px;
}

.vendor-dashboard .event-chat-ryt .chat-area .media {
    position: relative;
}

.vendor-dashboard .event-chat-ryt .chat-area .media-body p {
    margin: 0;
    max-width: 100%;
    display: inline-block;
    position: relative;
}

.vendor-dashboard .event-chat-ryt .chat-area .media-body p span {
    padding: 1rem;
    display: inline-block;
    top: 103%;
    position: relative;
    border: 1px solid #d7dae3;
}

.vendor-dashboard .event-chat-ryt .chat-reciver {
    padding: 0.5rem 1rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
}

.vendor-dashboard .event-chat-ryt .chat-reciver .media {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
}

.vendor-dashboard .event-chat-ryt .chat-reciver .media .media-body {
    margin-right: 2rem;
    text-align: right;
}

.vendor-dashboard .event-chat-ryt .chat-reciver .media .media-body p {
    background: #fff;
    margin-bottom: 0;
    border-radius: 5px 5px 0 5px;
}

.vendor-dashboard .event-chat-ryt .chat-reciver .media .media-body p span {
    text-align: left;
    border: 1px solid #d7dae3;
}

.vendor-dashboard .event-chat-ryt .chat-reciver .media .media-body p span:after {
    content: "";
    width: 20px;
    height: 20px;
    border-bottom: 1px solid #d7dae3;
    border-right: 1px solid #d7dae3;
    position: absolute;
    right: 0;
    bottom: 0;
    background: #fff;
    -webkit-transform: rotate(-45deg) translateX(15px);
    transform: rotate(-45deg) translateX(15px);
}

.vendor-dashboard .event-chat-ryt .chat-reciver .media .media-body p .time {
    position: absolute;
    font-size: 12px;
    color: #7e7e7e;
    font-weight: 400;
    bottom: 0;
    left: -80px;
}


.vendor-dashboard .event-chat-ryt .chat-sender {
    text-align: left;
    padding: 0.5rem 1rem;
}

.vendor-dashboard .event-chat-ryt .chat-sender .media .media-body {
    margin-left: 2rem;
}

.vendor-dashboard .event-chat-ryt .chat-sender .media .media-body p {
    background-color: #fff;
    margin-bottom: 0;
}

.vendor-dashboard .event-chat-ryt .chat-sender .media .media-body p span:after {
    content: "";
    width: 20px;
    height: 20px;
    border-bottom: 1px solid #d7dae3;
    border-left: 1px solid #d7dae3;
    position: absolute;
    left: 0;
    bottom: 0;
    background: #fff;
    -webkit-transform: rotate(45deg) translateX(-15px);
    transform: rotate(45deg) translateX(-15px);
}

.vendor-dashboard .event-chat-ryt .chat-sender .media .media-body p .time {
    position: absolute;
    font-size: 10px;
    color: #7e7e7e;
    font-weight: 400;
    bottom: 0;
    right: -5rem;
}

.vendor-dashboard .char-type {
    padding-top: 30px;
    padding-bottom: 30px;
}

.vendor-dashboard .char-type form .form-control {
    height: 45px;
    padding-left: 18px;
    background: #ffffff;
    border-right: 0;
}

.vendor-dashboard .char-type form .input-group-append i {
    color: #898989;
    font-size: 18px;
}

.vendor-dashboard .char-type form .input-group-append .input-group-text {
    padding-left: 0.7rem;
    padding-right: 0.7rem;
    background: #ffffff;
    border-color: #d7dae3;
    border-left: 0;
}

.vendor-dashboard .char-type form .input-group-append .input-group-text:last-child {
    padding-right: 1.8rem;
}

.vendor-dashboard .media-avatar {
    padding: 25px 0;
    border-bottom: 1px solid #d7dae3;
}

.vendor-dashboard .media-avatar:last-child {
    border-bottom: 0px;
}

.vendor-dashboard .media-avatar p {
    margin-bottom: 0;
}

.vendor-dashboard .media-avatar .avatar-status {
    position: relative;
}

.vendor-dashboard .media-avatar .avatar-status i {
    position: absolute;
    right: 0;
    bottom: 0;
}

.vendor-dashboard .ct-golden-section:before {
    float: none;
}

.vendor-dashboard .ct-chart {
    max-height: 15.7rem;
}

.vendor-dashboard .ct-chart .ct-label {
    fill: #a3afb7;
    color: #a3afb7;
    font-size: 0.75rem;
    line-height: 1;
}

.vendor-dashboard .ct-grid {
    stroke: rgba(49, 58, 70, 0.1);
}

.vendor-dashboard .ct-chart.simple-pie-chart-chartist .ct-label {
    color: #ffffff;
    fill: #ffffff;
    font-size: 0.625rem;
}

.vendor-dashboard .ct-chart .ct-series.ct-series-a .ct-bar,
.vendor-dashboard .ct-chart .ct-series.ct-series-a .ct-line,
.vendor-dashboard .ct-chart .ct-series.ct-series-a .ct-point,
.vendor-dashboard .ct-chart .ct-series.ct-series-a .ct-slice-donut {
    stroke: #0e131a;
}

.vendor-dashboard .ct-chart .ct-series.ct-series-b .ct-bar,
.vendor-dashboard .ct-chart .ct-series.ct-series-b .ct-line,
.vendor-dashboard .ct-chart .ct-series.ct-series-b .ct-point,
.vendor-dashboard .ct-chart .ct-series.ct-series-b .ct-slice-donut {
    stroke: #2bc155;
}

.vendor-dashboard .ct-chart .ct-series.ct-series-c .ct-bar,
.vendor-dashboard .ct-chart .ct-series.ct-series-c .ct-line,
.vendor-dashboard .ct-chart .ct-series.ct-series-c .ct-point,
.vendor-dashboard .ct-chart .ct-series.ct-series-c .ct-slice-donut {
    stroke: #ffb800;
}

.vendor-dashboard .ct-chart .ct-series.ct-series-d .ct-bar,
.vendor-dashboard .ct-chart .ct-series.ct-series-d .ct-line,
.vendor-dashboard .ct-chart .ct-series.ct-series-d .ct-point,
.vendor-dashboard .ct-chart .ct-series.ct-series-d .ct-slice-donut {
    stroke: #f35757;
}

.vendor-dashboard .ct-chart .ct-series.ct-series-e .ct-bar,
.vendor-dashboard .ct-chart .ct-series.ct-series-e .ct-line,
.vendor-dashboard .ct-chart .ct-series.ct-series-e .ct-point,
.vendor-dashboard .ct-chart .ct-series.ct-series-e .ct-slice-donut {
    stroke: #2F4CDD;
}

.vendor-dashboard .ct-chart .ct-series.ct-series-f .ct-bar,
.vendor-dashboard .ct-chart .ct-series.ct-series-f .ct-line,
.vendor-dashboard .ct-chart .ct-series.ct-series-f .ct-point,
.vendor-dashboard .ct-chart .ct-series.ct-series-f .ct-slice-donut {
    stroke: #3e4954;
}

.vendor-dashboard .ct-chart .ct-series.ct-series-g .ct-bar,
.vendor-dashboard .ct-chart .ct-series.ct-series-g .ct-line,
.vendor-dashboard .ct-chart .ct-series.ct-series-g .ct-point,
.vendor-dashboard .ct-chart .ct-series.ct-series-g .ct-slice-donut {
    stroke: #8d6e63;
}

.vendor-dashboard .ct-series-a .ct-area,
.vendor-dashboard .ct-series-a .ct-slice-pie {
    fill: #392540;
}

.vendor-dashboard .ct-series-b .ct-area,
.vendor-dashboard .ct-series-b .ct-slice-pie {
    fill: #00A2FF;
}

.vendor-dashboard .ct-series-c .ct-area,
.vendor-dashboard .ct-series-c .ct-slice-pie {
    fill: #ff9800;
}

.vendor-dashboard .ct-series-d .ct-area,
.vendor-dashboard .ct-series-d .ct-slice-pie {
    fill: #ff9800;
}

.vendor-dashboard .chartist-tooltip {
    position: absolute;
    display: inline-block;
    opacity: 0;
    min-width: 0.625rem;
    padding: 2px 0.625rem;
    border-radius: 3px;
    background: #313a46;
    color: #ffffff;
    text-align: center;
    pointer-events: none;
    z-index: 1;
    -webkit-transition: opacity 0.2s linear;
    -moz-transition: opacity 0.2s linear;
    -o-transition: opacity 0.2s linear;
    transition: opacity 0.2s linear;
}

.vendor-dashboard .chartist-tooltip.tooltip-show {
    opacity: 1;
}

.vendor-dashboard #donught_graph .ct-series.ct-series-a .ct-slice-donut {
    stroke: #3FC6D4;
}

.vendor-dashboard #donught_graph .ct-series.ct-series-b .ct-slice-donut {
    stroke: #333333;
}

.vendor-dashboard #donught_graph .ct-series.ct-series-c .ct-slice-donut {
    stroke: #F63465;
}

.vendor-dashboard #visitor_graph {
    height: 253px !important;
}

.vendor-dashboard #user_rating_graph {
    height: 280px !important;
}

.vendor-dashboard #activity {
    height: 270px !important;
}

.vendor-dashboard #visitorOnline {
    height: 72px !important;
}

.vendor-dashboard #trendMeter {
    height: 72px !important;
}

.vendor-dashboard #widget-revenue1,
.vendor-dashboard #widget-revenue2,
.vendor-dashboard #widget-revenue3 {
    height: 117px !important;
}

.vendor-dashboard #widget-profit1,
.vendor-dashboard #widget-profit2,
.vendor-dashboard #widget-profit3 {
    height: 160px !important;
}

.vendor-dashboard #comparison-rate {
    height: 230px !important;
}

.vendor-dashboard #session_day {
    height: 175px !important;
    width: auto !important;
    margin: 0 auto;
}

.vendor-dashboard #walet-status {
    height: 140px !important;
}

.vendor-dashboard #bar1 {
    height: 150px !important;
}

.vendor-dashboard #sold-product {
    height: 230px !important;
}

.vendor-dashboard #chart-venue-expenses,
.vendor-dashboard #chart-online-sale,
.vendor-dashboard #chart-gross-sale {
    height: 150px !important;
}

.vendor-dashboard #areaChart_3 {
    height: 295px !important;
}

.vendor-dashboard .chart-point {
    display: flex;
    align-items: center;
}

.vendor-dashboard .chart-point .check-point-area {
    width: 100px;
    height: 100px;
    margin-top: -10px;
    margin-left: -10px;
}

.vendor-dashboard .chart-point .chart-point-list {
    margin: 0;
    padding-left: 5px;
}

.vendor-dashboard .chart-point .chart-point-list li {
    list-style: none;
    font-size: 13px;
    padding: 2px 0;
}

.vendor-dashboard .chart-point .chart-point-list li i {
    margin-right: 5px;
    font-size: 11px;
    position: relative;
    top: -1px;
}

.vendor-dashboard .c3 {
    height: 250px;
}

.vendor-dashboard .c3-legend-item {
    fill: #9fabb1;
}

.vendor-dashboard .c3 .c3-axis-x path,
.vendor-dashboard .c3 .c3-axis-x line,
.vendor-dashboard .c3 .c3-axis-y path,
.vendor-dashboard .c3 .c3-axis-y line,
.vendor-dashboard .tick text {
    stroke: #fff;
}

.vendor-dashboard .flot-chart {
    height: 15.7rem;
}

.vendor-dashboard .tooltipflot {
    background-color: transparent;
    font-size: 1.4rem;
    padding: 0.5rem 1rem;
    color: rgba(255, 255, 255, 0.7);
    border-radius: 0.2rem;
}

.vendor-dashboard .legendColorBox > div {
    border: 0 !important;
    padding: 0 !important;
}

.vendor-dashboard .legendLabel {
    font-size: 0.825rem;
    padding-left: 0.5rem;
    color: #fff;
}

.vendor-dashboard .flotTip {
    background: #000;
    border: 1px solid #000;
    color: #fff;
}

.vendor-dashboard .legend > div {
    background: transparent !important;
}

.vendor-dashboard #balance_graph {
    height: 260px;
}

.vendor-dashboard .morris-hover {
    position: absolute;
    z-index: 1;
    background: #0e131a;
    color: #fff;
}

.vendor-dashboard .morris-hover .morris-hover-point {
    color: #fff !important;
    margin: 3px 0;
    text-align: center;
    padding: 0 25px;
}

.vendor-dashboard .morris-hover .morris-hover-row-label {
    background-color: #3e4954;
    text-align: center;
    padding: 5px;
    margin-bottom: 5px;
}

.vendor-dashboard .morris-hover.morris-default-style {
    border-radius: 5px;
    padding: 0;
    margin: 0;
    border: none;
    overflow: hidden;
}

.vendor-dashboard svg text {
    font-weight: 600 !important;
}

.vendor-dashboard #morris_donught,
.vendor-dashboard #morris_donught_2,
.vendor-dashboard #line_chart_2,
.vendor-dashboard #morris_bar,
.vendor-dashboard #morris_bar_stalked,
.vendor-dashboard #morris_bar_2,
.vendor-dashboard #morris_area_2,
.vendor-dashboard #morris_area {
    height: 240px !important;
}

.vendor-dashboard #morris_line {
    height: 278px !important;
}

.vendor-dashboard #crypto-btc-card,
.vendor-dashboard #crypto-eth-card,
.vendor-dashboard #crypto-rpl-card,
.vendor-dashboard #crypto-ltc-card {
    height: 9.375rem;
}

.vendor-dashboard #daily-sales,
.vendor-dashboard #comparison-rate,
.vendor-dashboard #usage-chart,
.vendor-dashboard #walet-status {
    width: 100%;
    display: block;
}

.vendor-dashboard #daily-sales canvas,
.vendor-dashboard #comparison-rate canvas,
.vendor-dashboard #usage-chart canvas,
.vendor-dashboard #walet-status canvas {
    max-width: 100% !important;
    width: 100% !important;
}

.vendor-dashboard #sparkline-composite-chart canvas,
.vendor-dashboard #composite-bar canvas,
.vendor-dashboard #sparkline11 canvas,
.vendor-dashboard #StackedBarChart canvas,
.vendor-dashboard #spark-bar canvas,
.vendor-dashboard #tristate canvas {
    height: 100px !important;
}

.vendor-dashboard #sparkline11 canvas {
    width: 100px !important;
}

.vendor-dashboard .easy-pie-chart {
    position: relative;
    text-align: center;
}

.vendor-dashboard .easy-pie-chart .inner {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: inline-block;
}

.vendor-dashboard .easy-pie-chart img {
    width: 75px;
    height: 75px;
    border-radius: 50%;
}

.vendor-dashboard .easy-pie-chart canvas {
    display: block;
    margin: 0 auto;
}

.vendor-dashboard #revenue-chart {
    height: 27.7rem;
}

.vendor-dashboard #duration-value-axis {
    height: 27.6rem;
    width: 100%;
}

.vendor-dashboard #combined-bullet {
    height: 28.125rem;
    width: 100%;
}

.vendor-dashboard #zoomable-chart {
    height: 28.125rem;
    width: 100%;
}

.vendor-dashboard #chartMap {
    height: 28.125rem;
    width: 100%;
}

.vendor-dashboard #professional-candlesticks {
    width: 100%;
    height: 28.125rem;
}

.vendor-dashboard #comparing-stock-indices {
    width: 100%;
    height: 28.125rem;
}

.vendor-dashboard #multiple-panel-data {
    width: 100%;
    height: 28.125rem;
}

.vendor-dashboard #depth-chart {
    width: 100%;
    height: 28.125rem;
}

.vendor-dashboard .amcharts-export-menu {
    display: none;
}

.vendor-dashboard .amcharts-data-set-selector-div {
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;
    width: 16.875rem;
    margin: 0 auto;
}

.vendor-dashboard .amcharts-data-set-selector-div select {
    border: 0;
    margin-left: 0.625rem;
    background: #ddd;
    color: #000;
}

.vendor-dashboard .amChartsInputField {
    border: 0;
    background: #0e131a;
    color: #fff;
    padding: 0.3125rem 0.9375rem;
    margin: 0 0.9375rem;
}

.vendor-dashboard .amcharts-data-set-select {
    border: 0;
    background: #ddd;
    color: #000;
}

.vendor-dashboard .amcharts-period-input,
.vendor-dashboard .amcharts-period-input-selected {
    border: 0;
    margin-left: 0.625rem;
    background: #0e131a;
    color: #fff;
    padding: 0.3125rem 0.9375rem;
}

.vendor-dashboard .amcharts-graph-g2 .amcharts-graph-stroke {
    stroke-dasharray: 3px 3px;
    stroke-linejoin: round;
    stroke-linecap: round;
    -webkit-animation: am-moving-dashes 1s linear infinite;
    animation: am-moving-dashes 1s linear infinite;
}

@-webkit-keyframes am-moving-dashes {
    100% {
        stroke-dashoffset: -1.9375rem;
    }
}

@keyframes am-moving-dashes {
    100% {
        stroke-dashoffset: -1.9375rem;
    }
}

.vendor-dashboard .lastBullet {
    -webkit-animation: am-pulsating 1s ease-out infinite;
    animation: am-pulsating 1s ease-out infinite;
}

@-webkit-keyframes am-pulsating {
    0% {
        stroke-opacity: 1;
        stroke-width: 0px;
    }
    100% {
        stroke-opacity: 0;
        stroke-width: 3.125rem;
    }
}

@keyframes am-pulsating {
    0% {
        stroke-opacity: 1;
        stroke-width: 0px;
    }
    100% {
        stroke-opacity: 0;
        stroke-width: 3.125rem;
    }
}

.vendor-dashboard .amcharts-graph-column-front {
    -webkit-transition: all 0.3s 0.3s ease-out;
    transition: all 0.3s 0.3s ease-out;
}

.vendor-dashboard .amcharts-graph-column-front:hover {
    fill: #496375;
    stroke: #496375;
    -webkit-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
}

@-webkit-keyframes am-draw {
    0% {
        stroke-dashoffset: 500%;
    }
    100% {
        stroke-dashoffset: 0%;
    }
}

@keyframes am-draw {
    0% {
        stroke-dashoffset: 500%;
    }
    100% {
        stroke-dashoffset: 0%;
    }
}

@media only screen and (max-width: 991px) {
    .vendor-dashboard .amChartsPeriodSelector > fieldset > div {
        float: none !important;
        display: block !important;
        margin-bottom: 0.625rem;
    }
}

.vendor-dashboard .highcharts-root text {
    font-weight: 300 !important;
}

.vendor-dashboard .highcharts-credits {
    display: none;
}

.vendor-dashboard #chart_employee_gender,
.vendor-dashboard #chart_employee_status {
    width: auto;
    height: 350px;
}

.vendor-dashboard .form-control {
    background: #fff;
    border: 1px solid #d7dae3;
    color: #3e4954;
    height: 46px;
    border-radius: 2px;
}

.vendor-dashboard .form-control {
    background: #fff;
    border: 1px solid #d7dae3;
    color: #3e4954;
    height: 46px;
    border-radius: 2px;
}

.vendor-dashboard .control-label {
    display: block;
}

.vendor-dashboard .form-control:hover,
.vendor-dashboard .form-control:focus,
.vendor-dashboard .form-control.active {
    box-shadow: none;
    background: #fff;
    color: #3e4954;
}

.vendor-dashboard .input-rounded {
    border-radius: 100px;
}

.vendor-dashboard .input-group-text {
    background: #d7dae3;
    border: 1px solid transparent;
    min-width: 50px;
    display: flex;
    justify-content: center;
    padding: 0.532rem 0.75rem;
}

.vendor-dashboard .input-group-text i {
    font-size: 16px;
}

.vendor-dashboard .custom-file-label {
    height: 40px;
    padding: 0.5rem 0.75rem;
}

.vendor-dashboard .input-group-prepend .btn,
.vendor-dashboard .input-group-append .btn {
    z-index: 0;
}

.vendor-dashboard .custom-select {
    background: none;
    border-color: #d7dae3;
    color: #3e4954;
}

.vendor-dashboard .custom-select:focus {
    box-shadow: none;
    border-color: #0e131a;
    color: #0e131a;
}

.vendor-dashboard .custom-file-label:after {
    background: #ff3a63;
    border: 1px solid #ff3a63;
    color: #fff;
    height: 56px;
    display: flex;
    align-items: center;
}

.vendor-dashboard .custom_file_input .custom-file-label:after {
    height: 100%;
}

.vendor-dashboard .form-control:disabled,
.vendor-dashboard .form-control[readonly] {
    background: #fff;
    opacity: 1;
}

.vendor-dashboard .custom-file-label {
    background: #fff;
    border-color: #d7dae3;
    height: 56px;
    display: flex;
    align-items: center;
}

[data-theme-version=dark] .custom-file-label {
    background: #181f39;
    border-color: #333a54;
}

.vendor-dashboard input[type=checkbox]:after {
    content: "";
    display: block;
    width: 1rem;
    height: 1rem;
    margin-top: 0px;
    margin-left: -1px;
    border: 1px solid transparent;
    border-radius: 3px;
    background: #d4d7da;
    line-height: 1.3;
}

.vendor-dashboard input[type=checkbox]:checked:after {
    width: 1rem;
    height: 1rem;
    display: block;
    content: "\f00c";
    font-family: "FontAwesome";
    color: #fff;
    font-weight: 100;
    font-size: 12px;
    text-align: center;
    border-radius: 3px;
    background: #0e131a;
}

.vendor-dashboard .form-check-label {
    margin-left: 5px;
    margin-top: 3px;
}

.vendor-dashboard .form-check-inline .form-check-input {
    margin-right: 0.625rem;
}

.vendor-dashboard .custom-control-label:before,
.vendor-dashboard .custom-control-label:after {
    top: 2px;
    width: 16px;
    height: 16px;
    border-color: #e7e7e7;
}

.vendor-dashboard .aminities-form-info .custom-control.custom-checkbox {
    margin-bottom: 5px;
}

.vendor-dashboard .custom-control {
    line-height: normal;
}

.vendor-dashboard .custom-control-input:focus ~ .custom-control-label:before {
    box-shadow: none !important;
}

.vendor-dashboard .custom-control-label:before {
    background-color: #e8e8e8;
    border-color: #e8e8e8;
    border-width: 2px;
    border-radius: 0.25rem !important;
}

[data-theme-version=dark] .custom-control-label:before {
    background-color: transparent;
    border-color: #333a54;
}

.vendor-dashboard .check-xs .custom-control-label:before,
.vendor-dashboard .check-xs .custom-control-label:after {
    width: 18px;
    height: 18px;
}

.vendor-dashboard .check-lg .custom-control-label:before,
.vendor-dashboard .check-lg .custom-control-label:after {
    width: 24px;
    height: 24px;
}

.vendor-dashboard .check-xl .custom-control-label:before,
.vendor-dashboard .check-xl .custom-control-label:after {
    width: 28px;
    height: 28px;
}

.vendor-dashboard .checkbox-info .custom-control-label:before {
    background-color: transparent;
    border-color: #dee3fa;
}

.vendor-dashboard .checkbox-info .custom-control-input:checked ~ .custom-control-label:before {
    background-color: #2F4CDD;
    border-color: #2F4CDD;
}

.vendor-dashboard .checkbox-danger .custom-control-label:before {
    background-color: transparent;
    border-color: #fde6e6;
}

[data-theme-version=dark] .checkbox-danger .custom-control-label:before {
    background-color: rgba(243, 87, 87, 0.15);
    border-color: transparent;
}

.vendor-dashboard .checkbox-danger .custom-control-input:checked ~ .custom-control-label:before {
    background-color: #f35757;
    border-color: #f35757;
}

.vendor-dashboard .checkbox-success .custom-control-label:before {
    background-color: transparent;
    border-color: #e7faec;
}

[data-theme-version=dark] .checkbox-success .custom-control-label:before {
    background-color: rgba(43, 193, 85, 0.1);
    border-color: transparent;
}

.vendor-dashboard .checkbox-success .custom-control-input:checked ~ .custom-control-label:before {
    background-color: #2bc155;
    border-color: #2bc155;
}

.vendor-dashboard .checkbox-warning .custom-control-label:before {
    background-color: transparent;
    border-color: #fff6e0;
}

[data-theme-version=dark] .checkbox-warning .custom-control-label:before {
    background-color: rgba(255, 184, 0, 0.1);
    border-color: transparent;
}

.vendor-dashboard .checkbox-warning .custom-control-input:checked ~ .custom-control-label:before {
    background-color: #ffb800;
    border-color: #ffb800;
}

.vendor-dashboard .checkbox-secondary .custom-control-label:before {
    background-color: transparent;
    border-color: #e8deec;
}

[data-theme-version=dark] .checkbox-secondary .custom-control-label:before {
    background-color: rgba(57, 37, 64, 0.5);
    border-color: transparent;
}

.vendor-dashboard .checkbox-secondary .custom-control-input:checked ~ .custom-control-label:before {
    background-color: #392540;
    border-color: #392540;
}

.vendor-dashboard .js-switch + .switchery {
    border-radius: 50px;
    margin-right: 4rem;
}

@media (max-width: 767.98px) {
    .vendor-dashboard .js-switch + .switchery {
        margin-right: 1rem;
    }
}

.vendor-dashboard .js-switch + .switchery > small {
    top: 2px;
}

.vendor-dashboard .js-switch.js-switch-lg + .switchery {
    height: 2rem;
    width: 4.5rem;
}

.vendor-dashboard .js-switch.js-switch-lg + .switchery > small {
    width: 1.75rem;
    height: 1.75rem;
}

.vendor-dashboard .js-switch.js-switch-md + .switchery {
    height: 1.5rem;
    width: 3.5rem;
}

.vendor-dashboard .js-switch.js-switch-md + .switchery > small {
    width: 1.25rem;
    height: 1.25rem;
}

.vendor-dashboard .js-switch.js-switch-sm + .switchery {
    height: 1rem;
    width: 2.2rem;
}

.vendor-dashboard .js-switch.js-switch-sm + .switchery > small {
    width: 0.875rem;
    height: 0.875rem;
    top: 1px;
}

.vendor-dashboard .js-switch-square + .switchery {
    border-radius: 0;
}

.vendor-dashboard .js-switch-square + .switchery > small {
    border-radius: 0;
    top: 2px;
}

.vendor-dashboard .js-switch-square.js-switch-lg + .switchery {
    height: 2rem;
    width: 4.5rem;
}

.vendor-dashboard .js-switch-square.js-switch-lg + .switchery > small {
    width: 1.75rem;
    height: 1.75rem;
}

.vendor-dashboard .js-switch-square.js-switch-md + .switchery {
    height: 1.5rem;
    width: 3.5rem;
}

.vendor-dashboard .js-switch-square.js-switch-md + .switchery > small {
    width: 1.25rem;
    height: 1.25rem;
}

.vendor-dashboard .js-switch-square.js-switch-sm + .switchery {
    height: 1rem;
    width: 2.2rem;
}

.vendor-dashboard .js-switch-square.js-switch-sm + .switchery > small {
    width: 0.875rem;
    height: 0.875rem;
    top: 1px;
}

.vendor-dashboard .form-control.is-valid {
    border-color: #2bc155 !important;
    border-right: 0px !important;
}

.vendor-dashboard .form-control.is-valid:focus {
    box-shadow: none;
}

.vendor-dashboard .form-control.is-warning {
    border-color: #ffb800 !important;
    border-right: 0px !important;
}

.vendor-dashboard .form-control.is-warning:focus {
    box-shadow: none;
}

.vendor-dashboard .form-control.is-invalid {
    border-color: #f35757 !important;
    border-right: 0px !important;
}

.vendor-dashboard .form-control.is-invalid:focus {
    box-shadow: none;
}

.vendor-dashboard .is-valid .input-group-prepend .input-group-text i {
    color: #2bc155;
}

.vendor-dashboard .is-invalid .input-group-prepend .input-group-text i {
    color: #e8719c;
}

.vendor-dashboard .asColorPicker-dropdown {
    max-width: 26rem;
}

.vendor-dashboard .asColorPicker-trigger {
    border: 0 none;
    height: 100%;
    position: absolute;
    left: 99%;
    top: 0;
    width: 2.1875rem;
    background: transparent;
}

.vendor-dashboard .asColorPicker-clear {
    display: none;
    position: absolute;
    right: 1rem;
    text-decoration: none;
    top: 0.5rem;
}

.vendor-dashboard .asColorPicker-trigger span {
    border-radius: 0 0.75rem 0.75rem 0;
}

.vendor-dashboard .daterangepicker td.active {
    background-color: #0e131a;
}

.vendor-dashboard .daterangepicker td.active:hover {
    background-color: #0e131a;
}

.vendor-dashboard .daterangepicker button.applyBtn {
    background-color: #0e131a;
    border-color: #0e131a;
}

.vendor-dashboard .datepicker.datepicker-dropdown {
    background: #f2f4fa;
    border-radius: 1px;
    border: 1px solid #EEEEEE;
}

.vendor-dashboard .datepicker.datepicker-dropdown td.day,
.vendor-dashboard .datepicker.datepicker-dropdown th.next,
.vendor-dashboard .datepicker.datepicker-dropdown th.prev {
    height: 30px;
    width: 30px !important;
    padding: 0;
    text-align: center;
    font-weight: 300;
    border-radius: 50px;
}

.vendor-dashboard .datepicker.datepicker-dropdown td.day:hover,
.vendor-dashboard .datepicker.datepicker-dropdown th.next:hover,
.vendor-dashboard .datepicker.datepicker-dropdown th.prev:hover {
    box-shadow: 0px 0px 30px 5px rgba(243, 30, 122, 0.3);
    color: #fff;
}

.vendor-dashboard .datepicker.datepicker-dropdown th.datepicker-switch,
.vendor-dashboard .datepicker.datepicker-dropdown th.next,
.vendor-dashboard .datepicker.datepicker-dropdown th.prev {
    font-weight: 300;
    color: #333;
}

.vendor-dashboard .datepicker.datepicker-dropdown th.dow {
    font-weight: 300;
}

.vendor-dashboard .datepicker table tr td.selected,
.vendor-dashboard .datepicker table tr td.active.active {
    box-shadow: 0px 0px 30px 5px rgba(243, 30, 122, 0.3);
    border: 0;
}

.vendor-dashboard .datepicker table tr td.today {
    box-shadow: 0px 0px 30px 5px rgba(243, 30, 122, 0.3);
    color: #ffffff;
}

.vendor-dashboard .datepicker table tr td.today:hover {
    box-shadow: 0px 0px 30px 5px rgba(243, 30, 122, 0.3);
    color: #ffffff;
}

.vendor-dashboard .datepicker table tr td.today.disabled {
    box-shadow: 0px 0px 30px 5px rgba(243, 30, 122, 0.3);
    color: #ffffff;
}

.vendor-dashboard .datepicker table tr td.today.disabled:hover {
    box-shadow: 0px 0px 30px 5px rgba(243, 30, 122, 0.3);
    color: #ffffff;
}

.vendor-dashboard .picker__select--month,
.vendor-dashboard .picker__select--year {
    height: 2.5em;
}

.vendor-dashboard .picker__input {
    background-color: transparent !important;
}

[data-theme-version=dark] .picker__input {
    background-color: transparent !important;
    border: 1px solid #333a54;
}

.vendor-dashboard .asColorPicker-wrap .form-control {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.vendor-dashboard #image {
    max-width: 100%;
}

.vendor-dashboard .docs-options .dropdown-menu {
    padding: 1.5rem;
}

.vendor-dashboard .docs-preview {
    margin-bottom: 3rem;
}

.vendor-dashboard .docs-preview .img-preview {
    float: left;
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
    overflow: hidden;
    max-width: 100% !important;
}

.vendor-dashboard .docs-preview .img-preview > img {
    max-width: 100% !important;
}

.vendor-dashboard .docs-preview .img-preview.preview-lg {
    width: 16rem;
    height: 9rem;
}

.vendor-dashboard .docs-preview .img-preview.preview-md {
    width: 8rem;
    height: 4.5rem;
}

.vendor-dashboard .docs-preview .img-preview.preview-sm {
    width: 4rem;
    height: 2.25rem;
}

.vendor-dashboard .docs-preview .img-preview.preview-xs {
    width: 2rem;
    height: 1.125rem;
    margin-right: 0;
}

.vendor-dashboard .select2-container {
    width: 100% !important;
}

.vendor-dashboard .select2-container--default .select2-selection--single {
    border-radius: 0.75rem;
    border: 1px solid #f4f4f4;
    height: 40px;
    background: #fff;
}

[data-theme-version=dark] .select2-container--default .select2-selection--single {
    background: #181f39;
    border-color: #333a54;
}

.vendor-dashboard .select2-container--default .select2-selection--single:hover,
.vendor-dashboard .select2-container--default .select2-selection--single:focus,
.vendor-dashboard .select2-container--default .select2-selection--single.active {
    box-shadow: none;
}

.vendor-dashboard .select2-container--default .select2-selection--single .select2-selection__rendered {
    line-height: 40px;
    color: #7e7e7e;
    padding-left: 15px;
    min-height: 40px;
}

.vendor-dashboard .select2-container--default .select2-selection--multiple {
    border-color: #d7dae3;
    border-radius: 0;
}

.vendor-dashboard .select2-dropdown {
    border-radius: 0;
}

.vendor-dashboard .select2-container--default .select2-results__option--highlighted[aria-selected] {
    background-color: #0e131a;
}

.vendor-dashboard .select2-container--default.select2-container--focus .select2-selection--multiple {
    border-color: #d7dae3;
    background: #fff;
}

.vendor-dashboard .select2-container--default .select2-selection--single .select2-selection__arrow {
    top: 6px;
    right: 15px;
}

.vendor-dashboard .select2-container .select2-selection--multiple {
    min-height: 40px;
    color: #7e7e7e;
    border-radius: 0.75rem;
    border: 1px solid #f4f4f4;
}

[data-theme-version=dark] .select2-container .select2-selection--multiple {
    background: #181f39;
    border-color: #333a54;
}

[data-theme-version=dark] .select2-search--dropdown .select2-search__field {
    background: #1e2746;
    border-color: #333a54;
}

.vendor-dashboard .select2-dropdown {
    border-color: #f4f4f4;
}

[data-theme-version=dark] .select2-dropdown {
    background: #181f39;
    border-color: #333a54;
}

.vendor-dashboard .swal2-popup .swal2-content {
    color: #7e7e7e;
}

.vendor-dashboard .wizard > .content > .body input.error {
    position: absolute;
    top: 100% !important;
}

.vendor-dashboard .wizard {
    position: relative;
}

.vendor-dashboard .wizard > .steps {
    width: 100%;
    margin-bottom: 20px;
}

@media (min-width: 1200px) {
    .vendor-dashboard .wizard > .steps {
        width: 100%;
    }
}

.vendor-dashboard .wizard > .steps li.current a {
    background-color: #0e131a;
    color: #fff;
}

.vendor-dashboard .wizard > .steps li.disabled a,
.vendor-dashboard .wizard > .steps li.done a {
    background-color: #fff;
    color: #333;
}

.vendor-dashboard .wizard > .steps li.disabled a:hover,
.vendor-dashboard .wizard > .steps li.done a:hover {
    margin: 0;
    background-color: #fff;
    border-radius: 0;
    color: #333;
}

.vendor-dashboard .wizard > .steps li a {
    text-align: center;
    margin: 0;
    border-radius: 0;
    background-color: #fff;
}

.vendor-dashboard .wizard > .steps li a:hover {
    margin: 0;
    background-color: #fff;
    border-radius: 0;
    color: #333;
}

@media only screen and (max-width: 767px) {
    .vendor-dashboard .wizard > .steps li a {
        padding: 1em 4px;
    }
}

.vendor-dashboard .wizard > .steps li a .number {
    display: none;
}

.vendor-dashboard .wizard .content {
    min-height: 30rem;
    margin: 0;
}

@media (max-width: 991.98px) {
    .vendor-dashboard .wizard .content {
        min-height: 41rem;
    }
}

.vendor-dashboard .wizard .content > .body {
    background-color: transparent;
    width: 100%;
    height: 100%;
    padding: 0;
}

@media only screen and (max-width: 575px) {
    .vendor-dashboard .wizard .content > .body {
        padding: 0;
    }
}

.vendor-dashboard .wizard .content > .body .form-control {
    border: 1px solid #d7dae3;
}

[data-theme-version=dark] .wizard .content > .body .form-control {
    border-color: #333a54;
}

.vendor-dashboard .wizard .content > .body input.error {
    position: relative;
}

.vendor-dashboard .wizard .content > .body label.error {
    margin-top: 5px;
    margin-left: 0;
    margin-bottom: 0;
}

.vendor-dashboard .wizard .emial-setup label.mailclinet {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 10rem;
    height: 10rem;
    border-radius: 50%;
    cursor: pointer;
    background-color: #EEF5F9;
    text-align: center;
    margin: auto;
}

[data-theme-version=dark] .wizard .emial-setup label.mailclinet {
    background-color: #181f39;
}

@media only screen and (max-width: 575px) {
    .vendor-dashboard .wizard .emial-setup label.mailclinet {
        width: 7rem;
        height: 7rem;
    }
}

.vendor-dashboard .wizard .emial-setup label.mailclinet .mail-icon {
    font-size: 3rem;
    display: inline-block;
    line-height: 1;
    margin-top: -1rem;
}

@media only screen and (max-width: 575px) {
    .vendor-dashboard .wizard .emial-setup label.mailclinet .mail-icon {
        font-size: 2rem;
    }
}

.vendor-dashboard .wizard .emial-setup label.mailclinet .mail-text {
    font-size: 1rem;
    text-align: center;
    margin-top: 0.5rem;
}

@media only screen and (max-width: 575px) {
    .vendor-dashboard .wizard .emial-setup label.mailclinet .mail-text {
        font-size: 16px;
        line-height: 20px;
    }
}

.vendor-dashboard .wizard .emial-setup label.mailclinet input[type=radio] {
    display: none;
}

.vendor-dashboard .wizard .skip-email {
    margin-top: 6rem;
}

.vendor-dashboard .wizard .skip-email p {
    margin-bottom: 1rem;
}

.vendor-dashboard .wizard .skip-email a {
    color: #0e131a;
    text-decoration: underline;
}

.vendor-dashboard .wizard > .actions {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 2rem;
}

.vendor-dashboard .wizard > .actions li:not(.disabled) a {
    background-color: #0e131a;
    color: #fff;
}

.vendor-dashboard .wizard .actions ul li.disabled a {
    color: #333333 !important;
    background: #F7FAFC !important;
    border: 1px solid #EEEEEE;
}

[data-theme-version=dark] .wizard .actions ul li.disabled a {
    background: #181f39 !important;
    border-color: #333a54;
    color: #fff !important;
}

.vendor-dashboard .wizard .actions ul li a {
    border-radius: 4px;
    font-size: 14px;
    font-weight: 600;
    padding: 0.55em 2em;
}

.vendor-dashboard .wizard > .actions a,
.vendor-dashboard .wizard > .actions a:hover,
.vendor-dashboard .wizard > .actions a:active {
    padding: 0.55em 2em;
}

.vendor-dashboard .wizard > .content {
    background: transparent;
}

.vendor-dashboard .wizard,
.vendor-dashboard .tabcontrol {
    overflow: visible;
}

.vendor-dashboard .step-form-horizontal .wizard {
    padding-top: 3.5rem;
}

.vendor-dashboard .step-form-horizontal .wizard .steps li {
    position: relative;
}

.vendor-dashboard .step-form-horizontal .wizard .steps li.done a {
    text-align: center;
    background-color: #fff;
    color: #3e4954;
}

[data-theme-version=dark] .step-form-horizontal .wizard .steps li.done a {
    background-color: #181f39;
    color: #7e7e7e;
}

.vendor-dashboard .step-form-horizontal .wizard .steps li.done a:hover {
    text-align: center;
    background-color: #fff;
    color: #3e4954;
}

.vendor-dashboard .step-form-horizontal .wizard .steps li.done a .number {
    color: #fff;
    background: #0e131a;
    border-color: transparent;
}

[data-theme-version=dark] .step-form-horizontal .wizard .steps li.done a .number {
    border-color: #0e131a;
}

.vendor-dashboard .step-form-horizontal .wizard .steps li.current a,
.vendor-dashboard .step-form-horizontal .wizard .steps li.disabled a {
    background-color: transparent;
    text-align: center;
    color: #fff;
}

.vendor-dashboard .step-form-horizontal .wizard .steps li.current a:hover,
.vendor-dashboard .step-form-horizontal .wizard .steps li.current a:active,
.vendor-dashboard .step-form-horizontal .wizard .steps li.disabled a:hover,
.vendor-dashboard .step-form-horizontal .wizard .steps li.disabled a:active {
    background-color: transparent;
    color: #fff;
}

.vendor-dashboard .step-form-horizontal .wizard .steps li.current a .number {
    color: #0e131a;
    background: #fff;
    border: 2px solid #0e131a;
}

.vendor-dashboard .step-form-horizontal .wizard .steps li.disabled a .number {
    color: #0e131a;
}

.vendor-dashboard .step-form-horizontal .wizard .steps li a {
    position: relative;
    z-index: 1;
}

@media (max-width: 767.98px) {
    .vendor-dashboard .step-form-horizontal .wizard .steps li a {
        padding-left: 0;
        padding-right: 0;
        margin: 0;
    }
}

@media (max-width: 575.98px) {
    .vendor-dashboard .step-form-horizontal .wizard .steps li a {
        font-size: 0.75rem;
        padding-top: 0.9375rem;
    }
}

.vendor-dashboard .step-form-horizontal .wizard .steps li a .number {
    position: absolute;
    top: -55px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #fff;
    border: 3px solid #d7dae3;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 3rem;
    border-radius: 50px;
    width: 3rem;
}

@media only screen and (max-width: 575px) {
    .vendor-dashboard .step-form-horizontal .wizard .steps li a .number {
        top: -45px;
    }
}

.vendor-dashboard .step-form-horizontal .wizard .steps li:not(:last-child) {
    z-index: 1;
}

.vendor-dashboard .step-form-horizontal .wizard .steps li:not(:last-child):after {
    position: absolute;
    width: 100%;
    height: 0.375rem;
    content: "";
    left: 50%;
    top: -2.1rem;
    background-color: #0e131a;
    z-index: -1;
}

@media (max-width: 575.98px) {
    .vendor-dashboard .step-form-horizontal .wizard .steps li:not(:last-child):after {
        height: 0.375rem;
        top: -1.3125rem;
    }
}

.vendor-dashboard .step-form-horizontal .wizard .steps li:not(:last-child).current:after {
    background-color: #F3F3F3;
}

[data-theme-version=dark] .step-form-horizontal .wizard .steps li:not(:last-child).current:after {
    background: #181f39;
}

.vendor-dashboard .step-form-horizontal .wizard .steps li:not(:last-child).current ~ li:after {
    background-color: #F3F3F3;
}

[data-theme-version=dark] .step-form-horizontal .wizard .steps li:not(:last-child).current ~ li:after {
    background: #181f39;
}

.vendor-dashboard .step-form-horizontal .wizard .actions {
    padding: 0;
}

@media only screen and (max-width: 575px) {
    .vendor-dashboard .step-form-horizontal .wizard .actions {
        padding: 0;
    }
}

.vendor-dashboard .note-icon-caret,
.vendor-dashboard .note-popover {
    display: none;
}

.vendor-dashboard .note-editor.note-frame .panel-heading {
    padding: 0.6rem 1rem 1rem;
    z-index: 1;
}

.vendor-dashboard .note-editor.note-frame {
    border-color: #d7dae3;
}

.vendor-dashboard .note-editor.note-frame .note-editing-area .note-editable {
    background-color: #fff;
}

[data-theme-version=dark] .note-editor.note-frame .note-editing-area .note-editable {
    background-color: #181f39;
}

.vendor-dashboard .note-editor.note-frame .note-statusbar {
    background-color: #fff;
}

.vendor-dashboard .ql-container {
    height: 25rem;
}

.vendor-dashboard #world-datamap {
    padding-bottom: 46% !important;
}

.vendor-dashboard .datamaps-hoverover {
    background: #fff;
    padding: 0.3125rem;
    border-radius: 0.3125rem;
    font-family: "Roboto" !important;
    color: #0e131a;
    border: 1px solid rgba(221, 47, 110, 0.3);
}

@media only screen and (max-width: 1440px) {
    .vendor-dashboard .world_map_card ul.list-group {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        margin-top: 35px;
    }
}

.vendor-dashboard .jqvmap-zoomin,
.vendor-dashboard .jqvmap-zoomout {
    height: 20px;
    width: 20px;
    line-height: 14px;
    background-color: #0e131a;
    color: #fff;
}

.vendor-dashboard .jqvmap-zoomout {
    top: 35px;
}

.vendor-dashboard #world-map {
    height: 400px;
}

.vendor-dashboard .blockUI.blockMsg.blockPage {
    border: 0 !important;
}

.vendor-dashboard #loginForm {
    cursor: auto;
}

.vendor-dashboard .blockMsg {
    border: 0px !important;
    width: 20% !important;
}

.vendor-dashboard .blockMsg h1 {
    font-size: 16px;
    padding: 8px 0;
    margin-bottom: 0;
}

.vendor-dashboard .bootstrap-select {
    margin-bottom: 0;
}

.vendor-dashboard .bootstrap-select .btn {
    border: 1px solid #d7dae3 !important;
    background-color: transparent !important;
    font-weight: 400;
    color: #7e7e7e !important;
}

[data-theme-version=dark] .bootstrap-select .btn {
    border-color: #333a54 !important;
}

.vendor-dashboard .bootstrap-select .btn:active,
.vendor-dashboard .bootstrap-select .btn:focus,
.vendor-dashboard .bootstrap-select .btn:hover {
    outline: none !important;
    outline-offset: 0;
}

[data-theme-version=dark] .bootstrap-select .btn:active,
[data-theme-version=dark] .bootstrap-select .btn:focus,
[data-theme-version=dark] .bootstrap-select .btn:hover {
    color: #7e7e7e !important;
}

.vendor-dashboard .bootstrap-select .dropdown-menu {
    border-color: #d7dae3 !important;
    box-shadow: 0px 0px 40px 0px rgba(82, 63, 105, 0.1);
}

.vendor-dashboard .bootstrap-select .dropdown-menu .dropdown-item {
    padding: 0.25rem 1rem;
}

.vendor-dashboard .input-group > .bootstrap-select:not(:first-child) .dropdown-toggle {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.vendor-dashboard .input-group > .bootstrap-select:not(:last-child) .dropdown-toggle {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.vendor-dashboard .clipboard-btn {
    transition: all 0.1s ease-in-out;
}

.vendor-dashboard .clipboard-btn:hover {
    background-color: #0e131a;
    color: #fff;
}

.vendor-dashboard .crypto-ticker {
    background: rgba(0, 0, 0, 0.5);
    margin-top: 20px;
    padding: 10px 20px;
    border-radius: 3px;
    box-shadow: 0 0 35px 0 rgba(154, 161, 171, 0.15);
}

[data-theme-version=dark] .crypto-ticker {
    background: #1e2746;
}

.vendor-dashboard #webticker-big {
    font: inherit !important;
    font-size: inherit !important;
    font-weight: normal !important;
}

.vendor-dashboard #webticker-big li i {
    font-size: 18px;
    margin-right: 7px;
}

.vendor-dashboard #webticker-big li p {
    margin-bottom: 0px;
    font-size: 12px;
    font-weight: 700;
}

.vendor-dashboard .twitter-typeahead {
    width: 100%;
}

.vendor-dashboard .twitter-typeahead .tt-dataset.tt-dataset-states {
    border: 1px solid #d7dae3;
}

.vendor-dashboard .twitter-typeahead .tt-menu {
    width: 100%;
    background-color: #fff;
}

.vendor-dashboard .twitter-typeahead .tt-menu .tt-suggestion {
    padding: 0.625rem;
    cursor: pointer;
}

.vendor-dashboard .twitter-typeahead .tt-menu .tt-suggestion:hover {
    background-color: #0e131a;
    color: #fff;
}

.vendor-dashboard .weather-one i {
    font-size: 8rem;
    position: relative;
    top: 0.5rem;
}

.vendor-dashboard .weather-one h2 {
    display: inline-block;
    float: right;
    font-size: 4.8rem;
}

.vendor-dashboard .weather-one .city {
    position: relative;
    text-align: right;
    top: -2.5rem;
}

.vendor-dashboard .weather-one .currently {
    font-size: 1.6rem;
    font-weight: 400;
    position: relative;
    top: 2.5rem;
}

.vendor-dashboard .weather-one .celcious {
    text-align: right;
    font-size: 2rem;
}

.vendor-dashboard .noUi-target {
    border-color: transparent;
    border-radius: 0;
}

.vendor-dashboard .noUi-connect {
    background-color: #0e131a;
}

.vendor-dashboard .noUi-connects {
    background-color: #D2D6DE;
}

.vendor-dashboard .noUi-connect.c-1-color {
    background-color: #2bc155;
}

.vendor-dashboard .noUi-connect.c-2-color {
    background-color: #2F4CDD;
}

.vendor-dashboard .noUi-connect.c-3-color {
    background-color: #0e131a;
}

.vendor-dashboard .noUi-connect.c-4-color {
    background-color: #ffb800;
}

.vendor-dashboard .noUi-vertical {
    width: 0.375rem;
}

.vendor-dashboard .noUi-horizontal {
    height: 0.375rem;
    margin-bottom: 10px;
}

.vendor-dashboard .noUi-horizontal .noUi-handle,
.vendor-dashboard .noUi-vertical .noUi-handle {
    height: 12px;
    width: 12px;
    border-radius: 50px;
    box-shadow: none;
    border: none;
    background-color: #0e131a;
}

.vendor-dashboard .noUi-horizontal .noUi-handle:after,
.vendor-dashboard .noUi-horizontal .noUi-handle:before,
.vendor-dashboard .noUi-vertical .noUi-handle:after,
.vendor-dashboard .noUi-vertical .noUi-handle:before {
    display: none;
}

.vendor-dashboard .noUi-vertical .noUi-handle {
    left: -4px;
    top: -6px;
}

.vendor-dashboard .noUi-horizontal .noUi-handle {
    top: -4px;
}

.vendor-dashboard #slider-toggle {
    height: 50px;
}

.vendor-dashboard #slider-toggle.off .noUi-handle {
    border-color: #0e131a;
}

.vendor-dashboard .colorpicker-slider .sliders.noUi-target#red,
.vendor-dashboard .colorpicker-slider .sliders.noUi-target#green,
.vendor-dashboard .colorpicker-slider .sliders.noUi-target#blue {
    margin: 10px;
    display: inline-block;
    height: 200px;
}

.vendor-dashboard .colorpicker-slider .sliders.noUi-target#red .noUi-connect {
    background: #c0392b;
}

.vendor-dashboard .colorpicker-slider .sliders.noUi-target#green .noUi-connect {
    background: #27ae60;
}

.vendor-dashboard .colorpicker-slider .sliders.noUi-target#blue .noUi-connect {
    background: #2980b9;
}

.vendor-dashboard .colorpicker-slider #result {
    margin: 60px 26px;
    height: 100px;
    width: 100px;
    display: inline-block;
    vertical-align: top;
    color: #7f7f7f;
    background: #7f7f7f;
    border: 1px solid #fff;
    box-shadow: 0 0 10px;
}

.vendor-dashboard .slider-vertical {
    height: 18rem;
}

.vendor-dashboard .nestable-cart {
    overflow: hidden;
}

.vendor-dashboard .dd-handle {
    color: #fff;
    background: #0e131a;
    border-radius: 0;
    border: 1px solid #d7dae3;
}

.vendor-dashboard .dd-handle:hover {
    color: #fff;
    background: #0e131a;
}

.vendor-dashboard .dd3-content:hover {
    color: #fff;
    background: #0e131a;
}

.vendor-dashboard .dd3-content {
    color: #fff;
}

.vendor-dashboard .pignose-calendar {
    box-shadow: none;
    width: 100%;
    max-width: none;
    border-color: #0e131a;
}

.vendor-dashboard .pignose-calendar .pignose-calendar-top-date {
    background-color: #0e131a;
}

.vendor-dashboard .pignose-calendar .pignose-calendar-top-date .pignose-calendar-top-month {
    color: #fff;
}

.vendor-dashboard .pignose-calendar.pignose-calendar-blue .pignose-calendar-body .pignose-calendar-row .pignose-calendar-unit.pignose-calendar-unit-active a {
    background-color: #0e131a;
    box-shadow: none;
}

.vendor-dashboard .pignose-calendar .pignose-calendar-top {
    box-shadow: none;
    border-bottom: 0;
}

.vendor-dashboard .pignose-calendar.pignose-calendar-blue {
    background-color: rgba(0, 0, 0, 0.15);
}

.vendor-dashboard .pignose-calendar .pignose-calendar-unit {
    height: 4.8em;
}

.vendor-dashboard .cd-h-timeline {
    opacity: 0;
    transition: opacity 0.2s;
}

.vendor-dashboard .cd-h-timeline--loaded {
    opacity: 1;
}

.vendor-dashboard .cd-h-timeline__container {
    position: relative;
    height: 100px;
    max-width: 800px;
}

.vendor-dashboard .cd-h-timeline__dates {
    position: relative;
    height: 100%;
    margin: 0 40px;
    overflow: hidden;
}

.vendor-dashboard .cd-h-timeline__dates:after,
.vendor-dashboard .cd-h-timeline__dates:before {
    content: "";
    position: absolute;
    z-index: 2;
    top: 0;
    height: 100%;
    width: 20px;
}

.vendor-dashboard .cd-h-timeline__dates:before {
    left: 0;
    background: #0e131a;
}

.vendor-dashboard .cd-h-timeline__dates:after {
    right: 0;
    background: #0e131a;
}

.vendor-dashboard .cd-h-timeline__line {
    position: absolute;
    z-index: 1;
    left: 0;
    top: 49px;
    height: 2px;
    background-color: #0e131a;
    transition: transform 0.4s;
}

.vendor-dashboard .cd-h-timeline__filling-line {
    position: absolute;
    z-index: 1;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background-color: #2bc155;
    transform: scaleX(0);
    transform-origin: left center;
    transition: transform 0.3s;
}

.vendor-dashboard .cd-h-timeline__date {
    position: absolute;
    bottom: 0;
    z-index: 2;
    text-align: center;
    font-size: 0.8em;
    /*padding-bottom: var(--space-sm);*/
    /*color: var(--cd-color-1);*/
    user-select: none;
    text-decoration: none;
}

.vendor-dashboard .cd-h-timeline__date:after {
    content: "";
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -5px;
    height: 12px;
    width: 12px;
    border-radius: 50%;
    border-width: 2px;
    border-style: solid;
    border-color: #de3371;
    background-color: #0e131a;
    transition: background-color 0.3s, border-color 0.3s;
}

.vendor-dashboard .cd-h-timeline__date:hover:after {
    background-color: #2bc155;
    border-color: #2bc155;
}

.vendor-dashboard .cd-h-timeline__date--selected {
    pointer-events: none;
}

.vendor-dashboard .cd-h-timeline__date--selected:after {
    background-color: #2bc155;
    border-color: #2bc155;
}

.vendor-dashboard .cd-h-timeline__date--older-event:after {
    border-color: #2bc155;
}

.vendor-dashboard .cd-h-timeline__navigation {
    position: absolute;
    z-index: 1;
    top: 50%;
    transform: translateY(-50%);
    height: 34px;
    width: 34px;
    border-radius: 50%;
    border-width: 2px;
    border-style: solid;
    border-color: #de3371;
    transition: border-color 0.3s;
}

.vendor-dashboard .cd-h-timeline__navigation:after {
    content: "";
    position: absolute;
    height: 16px;
    width: 16px;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    /* background: url(../images/svg/cd-arrow.html) no-repeat 0 0; */
}

.vendor-dashboard .cd-h-timeline__navigation:hover {
    border-color: #2bc155;
}

.vendor-dashboard .cd-h-timeline__navigation--prev {
    left: 0;
    transform: translateY(-50%) rotate(180deg);
}

.vendor-dashboard .cd-h-timeline__navigation--next {
    right: 0;
}

.vendor-dashboard .cd-h-timeline__navigation--inactive {
    cursor: not-allowed;
}

.vendor-dashboard .cd-h-timeline__navigation--inactive:after {
    background-position: 0 -16px;
}

.vendor-dashboard .cd-h-timeline__navigation--inactive:hover {
    border-color: #de3371;
}

.vendor-dashboard .cd-h-timeline__events {
    position: relative;
    width: 100%;
    overflow: hidden;
    transition: height 0.4s;
}

.vendor-dashboard .cd-h-timeline__event {
    position: absolute;
    z-index: 1;
    width: 100%;
    left: 0;
    top: 0;
    transform: translateX(-100%);
    padding: 1px 5%;
    opacity: 0;
    animation-duration: 0.4s;
    animation-timing-function: ease-in-out;
}

.vendor-dashboard .cd-h-timeline__event--selected {
    position: relative;
    z-index: 2;
    opacity: 1;
    transform: translateX(0);
}

.vendor-dashboard .cd-h-timeline__event--enter-right,
.vendor-dashboard .cd-h-timeline__event--leave-right {
    animation-name: cd-enter-right;
}

.vendor-dashboard .cd-h-timeline__event--enter-left,
.vendor-dashboard .cd-h-timeline__event--leave-left {
    animation-name: cd-enter-left;
}

.vendor-dashboard .cd-h-timeline__event--leave-right,
.vendor-dashboard .cd-h-timeline__event--leave-left {
    animation-direction: reverse;
}

.vendor-dashboard .cd-h-timeline__event-content {
    max-width: 800px;
}

.vendor-dashboard .cd-h-timeline__event-date:before {
    content: "- ";
}

@keyframes cd-enter-right {
    0% {
        opacity: 0;
        transform: translateX(100%);
    }
    100% {
        opacity: 1;
        transform: translateX(0%);
    }
}

@keyframes cd-enter-left {
    0% {
        opacity: 0;
        transform: translateX(-100%);
    }
    100% {
        opacity: 1;
        transform: translateX(0%);
    }
}

.vendor-dashboard .toast-success {
    background-color: #0e131a;
}

.vendor-dashboard .toast-info {
    background-color: #2F4CDD;
}

.vendor-dashboard .toast-warning {
    background-color: #ffb800;
}

.vendor-dashboard .toast-error {
    background-color: #f35757;
}

.vendor-dashboard #toast-container > div {
    box-shadow: none;
    border-radius: 0;
    width: auto;
    max-width: 250px;
    opacity: 1;
}


.vendor-dashboard #toast-container > div:hover {
    box-shadow: none;
}

.vendor-dashboard #toast-container .toast-title {
    margin-bottom: 5px;
    font-weight: 600;
}

.vendor-dashboard #toast-container .toast-message {
    font-size: 12px;
}

.vendor-dashboard #toast-container .toast-close-button {
    opacity: 1;
    font-size: 8px;
    font-weight: normal;
    text-shadow: none;
}

.vendor-dashboard .jqvmap-zoomin,
.vendor-dashboard .jqvmap-zoomout {
    position: absolute;
    left: 10px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    background: #000000;
    padding: 3px;
    color: white;
    width: 17px;
    height: 17px;
    cursor: pointer;
    line-height: 10px;
    text-align: center;
}

.vendor-dashboard .jqvmap-zoomin {
    top: 10px;
}

.vendor-dashboard .jqvmap-zoomout {
    top: 30px;
}

.vendor-dashboard .ps__rail-x:hover,
.vendor-dashboard .ps__rail-y:hover,
.vendor-dashboard .ps__rail-x:focus,
.vendor-dashboard .ps__rail-y:focus,
.vendor-dashboard .ps__rail-x.ps--clicking,
.vendor-dashboard .ps__rail-y.ps--clicking {
    background-color: transparent;
    opacity: 0.9;
}

.vendor-dashboard .ps__rail-y:hover > .ps__thumb-y,
.vendor-dashboard .ps__rail-y:focus > .ps__thumb-y,
.vendor-dashboard .ps__rail-y.ps--clicking .ps__thumb-y {
    background-color: #DAE2F3;
    width: 4px;
}

.vendor-dashboard .ps__thumb-y {
    background-color: #DAE2F3;
    width: 4px;
}

.vendor-dashboard .total-average {
    position: relative;
    height: 300px;
}

.vendor-dashboard .widget-chat {
    position: relative;
    height: 250px;
}

.vendor-dashboard .widget-todo {
    position: relative;
    height: 210px;
}

.vendor-dashboard .widget-team {
    position: relative;
    height: 285px;
}

.vendor-dashboard .widget-timeline {
    position: relative;
    height: 290px;
}

.vendor-dashboard .widget-comments {
    position: relative;
    height: 400px;
}

.vendor-dashboard .sidebar-right-inner {
    position: relative;
    height: 100%;
}

.vendor-dashboard .widget-team .ps .ps__rail-x:hover,
.vendor-dashboard .widget-team .ps .ps__rail-y:hover,
.vendor-dashboard .widget-team .ps .ps__rail-x:focus,
.vendor-dashboard .widget-team .ps .ps__rail-y:focus,
.vendor-dashboard .widget-team .ps .ps__rail-x.ps--clicking,
.vendor-dashboard .widget-team .ps .ps__rail-y.ps--clicking {
    background-color: transparent !important;
    opacity: 0.9;
}

.vendor-dashboard .accordion-primary .accordion__header {
    background: #0e131a;
    border-color: #0e131a;
    color: #fff;
    box-shadow: 0 15px 20px 0 rgba(221, 47, 110, 0.15);
}

.vendor-dashboard .accordion-primary .accordion__header.collapsed {
    background: #fdf4f8;
    border-color: #fdf4f8;
    color: #211c37;
    box-shadow: none;
}

[data-theme-version=dark] .accordion-primary .accordion__header.collapsed {
    background: rgba(221, 47, 110, 0.2);
    border-color: rgba(221, 47, 110, 0.2);
    color: #7e7e7e;
}

.vendor-dashboard .accordion-primary-solid .accordion__header {
    background: #0e131a;
    border-color: #0e131a;
    color: #fff;
    box-shadow: 0 -10px 20px 0 rgba(221, 47, 110, 0.15);
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.vendor-dashboard .accordion-primary-solid .accordion__header.collapsed {
    background: #fdf4f8;
    border-color: #fdf4f8;
    color: #211c37;
    box-shadow: none;
    border-bottom-left-radius: 0.75rem;
    border-bottom-right-radius: 0.75rem;
}

[data-theme-version=dark] .accordion-primary-solid .accordion__header.collapsed {
    background: rgba(221, 47, 110, 0.2);
    border-color: rgba(221, 47, 110, 0.2);
    color: #7e7e7e;
}

.vendor-dashboard .accordion-primary-solid .accordion__body {
    border: 2px solid #0e131a;
    border-top: none;
    box-shadow: 0 15px 20px 0 rgba(221, 47, 110, 0.15);
    border-bottom-left-radius: 0.75rem;
    border-bottom-right-radius: 0.75rem;
}

.vendor-dashboard .accordion-danger .accordion__header {
    background: #f35757;
    border-color: #f35757;
    color: #fff;
    box-shadow: 0 15px 20px 0 rgba(243, 87, 87, 0.15);
}

.vendor-dashboard .accordion-danger .accordion__header.collapsed {
    background: #fde6e6;
    border-color: #fde6e6;
    color: #211c37;
    box-shadow: none;
}

.vendor-dashboard .accordion-danger-solid .accordion__header {
    background: #f35757;
    border-color: #f35757;
    color: #fff;
    box-shadow: 0 -10px 20px 0 rgba(243, 87, 87, 0.15);
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.vendor-dashboard .accordion-danger-solid .accordion__header.collapsed {
    background: #fde6e6;
    border-color: #fde6e6;
    color: #211c37;
    box-shadow: none;
    border-bottom-left-radius: 0.75rem;
    border-bottom-right-radius: 0.75rem;
}

[data-theme-version=dark] .accordion-danger-solid .accordion__header.collapsed {
    background: rgba(243, 87, 87, 0.15);
    border-color: rgba(243, 87, 87, 0.15);
    color: #7e7e7e;
}

.vendor-dashboard .accordion-danger-solid .accordion__body {
    border: 2px solid #f35757;
    border-top: none;
    box-shadow: 0 15px 20px 0 rgba(243, 87, 87, 0.15);
    border-bottom-left-radius: 0.75rem;
    border-bottom-right-radius: 0.75rem;
}

.vendor-dashboard .accordion__item {
    margin-bottom: 1.25rem;
}

.vendor-dashboard .accordion__header {
    padding: 1rem 1.75rem;
    border: 1px solid #d7dae3;
    cursor: pointer;
    position: relative;
    color: #333;
    font-weight: 400;
    border-radius: 0.75rem;
    -webkit-transition: all 0.5s;
    -ms-transition: all 0.5s;
    transition: all 0.5s;
}

[data-theme-version=dark] .accordion__header {
    color: #fff;
    border-color: #333a54;
}

.vendor-dashboard .accordion__header--indicator {
    font-family: "themify";
    position: absolute;
    right: 1.5625rem;
    top: 50%;
    transform: translateY(-50%);
}

.vendor-dashboard .accordion__header--indicator.indicator_bordered {
    display: inline-block;
    width: 25px;
    text-align: center;
    height: 25px;
    border: 1px solid #d7dae3;
    border-radius: 50%;
    line-height: 25px;
}

.vendor-dashboard .accordion__header:not(.collapsed) .accordion__header--indicator:before {
    content: "\e622";
}

.vendor-dashboard .accordion__header:not(.collapsed) .accordion__header--indicator.style_two:before {
    content: "\e648";
}

.vendor-dashboard .accordion__header.collapsed .accordion__header--indicator:before {
    content: "\e61a";
}

.vendor-dashboard .accordion__header.collapsed .accordion__header--indicator.style_two:before {
    content: "\e64b";
}

.vendor-dashboard .accordion__body--text {
    padding: 0.875rem 1.25rem;
}

.vendor-dashboard .accordion-bordered .accordion__body {
    border: 1px solid #d7dae3;
    border-top: none;
    border-bottom-left-radius: 0.75rem;
    border-bottom-right-radius: 0.75rem;
}

[data-theme-version=dark] .accordion-bordered .accordion__body {
    border-color: #333a54;
}

.vendor-dashboard .accordion-bordered .accordion__header.collapsed {
    border-radius: 0.75rem;
}

.vendor-dashboard .accordion-bordered .accordion__header {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.vendor-dashboard .accordion-no-gutter .accordion__item {
    margin-bottom: 0;
}

.vendor-dashboard .accordion-no-gutter .accordion__item .accordion__header.collapsed {
    border-bottom: none;
}

.vendor-dashboard .accordion-no-gutter .accordion__item:last-child .accordion__header {
    border-bottom: 1px solid #d7dae3;
}

[data-theme-version=dark] .accordion-no-gutter .accordion__item:last-child .accordion__header {
    border-color: #333a54;
}

.vendor-dashboard .accordion-no-gutter.accordion__bordered .accordion__item:not(:last-child) .accordion__body {
    border-bottom: none;
}

.vendor-dashboard .accordion-left-indicator .accordion__header--text {
    padding-left: 2.5rem;
}

.vendor-dashboard .accordion-left-indicator .accordion__header--indicator {
    right: auto;
    left: 1.5625rem;
}

.vendor-dashboard .accordion-with-icon .accordion__header--text {
    padding-left: 2.5rem;
}

.vendor-dashboard .accordion-with-icon .accordion__header--icon {
    position: absolute;
    right: auto;
    left: 1.5625rem;
    font-family: "themify";
}

.vendor-dashboard .accordion-with-icon .accordion__header--icon:before {
    content: "\e645";
}

.vendor-dashboard .accordion-header-bg .accordion__header {
    background-color: #f4f4f4;
}

[data-theme-version=dark] .accordion-header-bg .accordion__header {
    background-color: #181f39;
    color: #fff;
}

.vendor-dashboard .accordion-header-bg .accordion__header--primary {
    background-color: #0e131a;
    color: #fff;
    border-color: #0e131a;
}

[data-theme-version=dark] .accordion-header-bg .accordion__header--primary {
    background-color: #0e131a;
}

.vendor-dashboard .accordion-header-bg .accordion__header--info {
    background-color: #2F4CDD;
    color: #fff;
    border-color: #2F4CDD;
}

[data-theme-version=dark] .accordion-header-bg .accordion__header--info {
    background-color: #2F4CDD;
}

.vendor-dashboard .accordion-header-bg .accordion__header--success {
    background-color: #2bc155;
    color: #fff;
    border-color: #2bc155;
}

[data-theme-version=dark] .accordion-header-bg .accordion__header--success {
    background-color: #2bc155;
}

.vendor-dashboard .accordion-header-bg.accordion-no-gutter .accordion__header {
    border-color: transparent;
    border-radius: 0;
}

.vendor-dashboard .accordion-header-bg.accordion-no-gutter .accordion__item:first-child .accordion__header {
    border-top-left-radius: 0.75rem;
    border-top-right-radius: 0.75rem;
}

.vendor-dashboard .accordion-header-bg.accordion-no-gutter .accordion__item:last-child .accordion__header {
    border-bottom-left-radius: 0.75rem;
    border-bottom-right-radius: 0.75rem;
}

.vendor-dashboard .accordion.accordion-no-gutter .accordion__header {
    border-radius: 0;
}

.vendor-dashboard .accordion.accordion-no-gutter .accordion__header.collapsed {
    border-radius: 0;
}

.vendor-dashboard .accordion.accordion-no-gutter .accordion__body {
    border-radius: 0;
}

.vendor-dashboard .accordion.accordion-no-gutter .accordion__item:first-child .accordion__header {
    border-top-left-radius: 0.75rem;
    border-top-right-radius: 0.75rem;
}

.vendor-dashboard .accordion.accordion-no-gutter .accordion__item:last-child .accordion__header.collapsed {
    border-bottom-left-radius: 0.75rem;
    border-bottom-right-radius: 0.75rem;
}

.vendor-dashboard .accordion.accordion-no-gutter .accordion__item:last-child .accordion__body {
    border-bottom-left-radius: 0.75rem;
    border-bottom-right-radius: 0.75rem;
}

.vendor-dashboard .accordion-solid-bg .accordion__header {
    border-color: transparent;
    background-color: #fdf4f8;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

[data-theme-version=dark] .accordion-solid-bg .accordion__header {
    background-color: #181f39;
}

.vendor-dashboard .accordion-solid-bg .accordion__header.collapsed {
    border-radius: 0.75rem;
}

.vendor-dashboard .accordion-solid-bg .accordion__body {
    border-color: transparent;
    background-color: #fdf4f8;
    border-bottom-left-radius: 0.75rem;
    border-bottom-right-radius: 0.75rem;
}

[data-theme-version=dark] .accordion-solid-bg .accordion__body {
    background-color: #181f39;
}

.vendor-dashboard .accordion-active-header .accordion__header:not(.collapsed) {
    background-color: #2F4CDD;
    border-color: #2F4CDD;
    color: #fff;
}

.vendor-dashboard .accordion-header-shadow .accordion__header {
    border: none;
    box-shadow: 0 0 0.9375rem -3px rgba(0, 0, 0, 0.3);
}

.vendor-dashboard .accordion-rounded-stylish .accordion__header {
    border-top-left-radius: 0.375rem;
    border-top-right-radius: 0.375rem;
}

.vendor-dashboard .accordion-rounded-stylish .accordion__body {
    border-bottom-left-radius: 0.375rem;
    border-bottom-right-radius: 0.375rem;
}

.vendor-dashboard .accordion-rounded .accordion__header {
    border-radius: 0.3125rem;
}

.vendor-dashboard .accordion-gradient .accordion__header {
    color: #fff;
    background-image: linear-gradient(to right, rgba(186, 1, 181, 0.85) 0%, rgba(103, 25, 255, 0.85) 100%);
    border-color: transparent;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.vendor-dashboard .accordion-gradient .accordion__header.collapsed {
    border-bottom-left-radius: 0.75rem;
    border-bottom-right-radius: 0.75rem;
}

.vendor-dashboard .accordion-gradient .accordion__body {
    color: #fff;
    background-image: linear-gradient(to right, rgba(186, 1, 181, 0.85) 0%, rgba(103, 25, 255, 0.85) 100%);
    border-color: transparent;
}

.vendor-dashboard .alert {
    border-radius: 0.75rem;
    padding: 1rem 1.5rem;
}

.vendor-dashboard .alert p {
    line-height: 1.5;
}

.vendor-dashboard .alert-rounded {
    border-radius: 30px;
}

.vendor-dashboard .alert-primary {
    background: #fdf4f8;
    border-color: #fdf4f8;
    color: #0e131a;
}

[data-theme-version=dark] .alert-primary {
    background: rgba(221, 47, 110, 0.2);
    border-color: rgba(221, 47, 110, 0.2);
}

.vendor-dashboard .alert-secondary {
    background: #e8deec;
    border-color: #e8deec;
    color: #392540;
}

[data-theme-version=dark] .alert-secondary {
    background: rgba(57, 37, 64, 0.5);
    border-color: rgba(57, 37, 64, 0.5);
    color: #7e7e7e;
}

.vendor-dashboard .alert-success {
    background: #e7faec;
    border-color: #e7faec;
    color: #2bc155;
}

[data-theme-version=dark] .alert-success {
    background: rgba(43, 193, 85, 0.1);
    border-color: rgba(43, 193, 85, 0.1);
}

.vendor-dashboard .alert-warning {
    background: #fff6e0;
    border-color: #fff6e0;
    color: #ffb800;
}

[data-theme-version=dark] .alert-warning {
    background: rgba(255, 184, 0, 0.1);
    border-color: rgba(255, 184, 0, 0.1);
}

.vendor-dashboard .alert-danger {
    background: #fde6e6;
    border-color: #fde6e6;
    color: #f35757;
}

[data-theme-version=dark] .alert-danger {
    background: rgba(243, 87, 87, 0.15);
    border-color: rgba(243, 87, 87, 0.15);
}

.vendor-dashboard .alert-info {
    background: #dee3fa;
    border-color: #dee3fa;
    color: #2F4CDD;
}

[data-theme-version=dark] .alert-info {
    background: rgba(47, 76, 221, 0.1);
    border-color: rgba(47, 76, 221, 0.1);
}

.vendor-dashboard .alert-dark {
    background: #dee2e6;
    border-color: #dee2e6;
    color: #3e4954;
}

[data-theme-version=dark] .alert-dark {
    background: rgba(62, 73, 84, 0.35);
    border-color: rgba(62, 73, 84, 0.35);
    color: #7e7e7e;
}

.vendor-dashboard .alert-light {
    background: #f4f4f4;
    border-color: #f4f4f4;
    color: #3e4954;
}

.vendor-dashboard .alert-alt.alert-primary {
    border-left: 4px solid #0e131a;
}

.vendor-dashboard .alert-alt.alert-secondary {
    border-left: 4px solid #392540;
}

.vendor-dashboard .alert-alt.alert-success {
    border-left: 4px solid #2bc155;
}

.vendor-dashboard .alert-alt.alert-warning {
    border-left: 4px solid #ffb800;
}

.vendor-dashboard .alert-alt.alert-danger {
    border-left: 4px solid #f35757;
}

.vendor-dashboard .alert-alt.alert-info {
    border-left: 4px solid #2F4CDD;
}

.vendor-dashboard .alert-alt.alert-dark {
    border-left: 4px solid #3e4954;
}

.vendor-dashboard .alert-alt.alert-light {
    border-left: 4px solid #cecece;
}

.vendor-dashboard .alert-alt.alert-primary.solid {
    border-left: 4px solid #791438 !important;
}

.vendor-dashboard .alert-alt.alert-secondary.solid {
    border-left: 4px solid black !important;
}

.vendor-dashboard .alert-alt.alert-success.solid {
    border-left: 4px solid #145927 !important;
}

.vendor-dashboard .alert-alt.alert-warning.solid {
    border-left: 4px solid #805c00 !important;
}

.vendor-dashboard .alert-alt.alert-danger.solid {
    border-left: 4px solid #bd0e0e !important;
}

.vendor-dashboard .alert-alt.alert-info.solid {
    border-left: 4px solid #142579 !important;
}

.vendor-dashboard .alert-alt.alert-dark.solid {
    border-left: 4px solid #08090b !important;
}

.vendor-dashboard .alert-alt.alert-light.solid {
    border-left: 4px solid #b4b4b4 !important;
}

.vendor-dashboard .alert-dismissible.solid .close:hover {
    color: #fff;
    opacity: 1;
}

.vendor-dashboard .alert.alert-primary.solid {
    background: #0e131a;
    color: #fff;
    border-color: #0e131a;
}

.vendor-dashboard .alert.alert-secondary.solid {
    background: #392540;
    color: #fff;
    border-color: #392540;
}

.vendor-dashboard .alert.alert-success.solid {
    background: #2bc155;
    color: #fff;
    border-color: #2bc155;
}

.vendor-dashboard .alert.alert-warning.solid {
    background: #ffb800;
    color: #fff;
    border-color: #ffb800;
}

.vendor-dashboard .alert.alert-danger.solid {
    background: #f35757;
    color: #fff;
    border-color: #f35757;
}

.vendor-dashboard .alert.alert-info.solid {
    background: #2F4CDD;
    color: #fff;
    border-color: #2F4CDD;
}

.vendor-dashboard .alert.alert-dark.solid {
    background: #3e4954;
    color: #fff;
    border-color: #3e4954;
}

.vendor-dashboard .alert.alert-light.solid {
    background: #f4f4f4;
    color: #3e4954;
    border-color: #f4f4f4;
}

.vendor-dashboard .alert-right-icon > span i {
    font-size: 18px;
    margin-right: 5px;
}

.vendor-dashboard .alert-right-icon .close i {
    font-size: 16px;
}

.vendor-dashboard .alert.alert-outline-primary {
    background: transparent;
    color: #0e131a;
    border-color: #0e131a;
}

.vendor-dashboard .alert.alert-outline-secondary {
    background: transparent;
    color: #7e7e7e;
    border-color: #392540;
}

.vendor-dashboard .alert.alert-outline-success {
    background: transparent;
    color: #2bc155;
    border-color: #2bc155;
}

.vendor-dashboard .alert.alert-outline-info {
    background: transparent;
    color: #2F4CDD;
    border-color: #2F4CDD;
}

.vendor-dashboard .alert.alert-outline-warning {
    background: transparent;
    color: #ffb800;
    border-color: #ffb800;
}

.vendor-dashboard .alert.alert-outline-danger {
    background: transparent;
    color: #f35757;
    border-color: #f35757;
}

.vendor-dashboard .alert.alert-outline-dark {
    background: transparent;
    color: #7e7e7e;
    border-color: #3e4954;
}

.vendor-dashboard .alert.alert-outline-light {
    background: transparent;
    color: #3e4954;
    border-color: #f4f4f4;
}

.vendor-dashboard .alert-social {
    color: #fff;
}

.vendor-dashboard .alert-social .alert-social-icon {
    align-self: center;
    margin-right: 0.9375rem;
}

.vendor-dashboard .alert-social .alert-social-icon i {
    font-size: 42px;
}

.vendor-dashboard .alert-social.facebook {
    background-color: #3b5998;
}

.vendor-dashboard .alert-social.twitter {
    background-color: #1da1f2;
}

.vendor-dashboard .alert-social.linkedin {
    background-color: #007bb6;
}

.vendor-dashboard .alert-social.google-plus {
    background-color: #db4439;
}

.vendor-dashboard .alert-social .close:hover {
    opacity: 1 !important;
    color: #fff !important;
}

.vendor-dashboard .left-icon-big .alert-left-icon-big {
    align-self: center;
    margin-right: 0.9375rem;
}

.vendor-dashboard .left-icon-big .alert-left-icon-big i {
    font-size: 35px;
    line-height: 1;
}

.vendor-dashboard .badge {
    line-height: 1.5;
    border-radius: 0.75rem;
    padding: 4px 10px;
    border: 1px solid transparent;
}

.vendor-dashboard .badge-rounded {
    border-radius: 20px;
    padding: 3px 13px;
}

.vendor-dashboard .badge-circle {
    border-radius: 100px;
    padding: 3px 7px;
}

.vendor-dashboard .badge-outline-primary {
    border: 1px solid #0e131a;
    color: #0e131a;
}

.vendor-dashboard .badge-outline-secondary {
    border: 1px solid #392540;
    color: #392540;
}

[data-theme-version=dark] .badge-outline-secondary {
    color: #7e7e7e;
}

.vendor-dashboard .badge-outline-success {
    border: 1px solid #2bc155;
    color: #2bc155;
}

.vendor-dashboard .badge-outline-info {
    border: 1px solid #2F4CDD;
    color: #2F4CDD;
}

.vendor-dashboard .badge-outline-warning {
    border: 1px solid #ffb800;
    color: #ffb800;
}

.vendor-dashboard .badge-outline-danger {
    border: 1px solid #f35757;
    color: #f35757;
}

.vendor-dashboard .badge-outline-light {
    border: 1px solid #d7dae3;
    color: #3e4954;
}

[data-theme-version=dark] .badge-outline-light {
    color: #7e7e7e;
}

.vendor-dashboard .badge-outline-dark {
    border: 1px solid #3e4954;
    color: #3e4954;
}

[data-theme-version=dark] .badge-outline-dark {
    color: #7e7e7e;
}

.vendor-dashboard .badge-xs {
    font-size: 10px;
    padding: 0px 5px;
    line-height: 18px;
}

.vendor-dashboard .badge-sm {
    font-size: 11px;
    padding: 5px 8px;
    line-height: 11px;
}

.vendor-dashboard .badge-lg {
    font-size: 14px;
    padding: 0px 10px;
    line-height: 30px;
}

.vendor-dashboard .badge-xl {
    font-size: 15px;
    padding: 0px 15px;
    line-height: 35px;
}

.vendor-dashboard .badge-default {
    background: #ADB6C7;
}

.vendor-dashboard .badge-success {
    background-color: #2bc155;
}

.vendor-dashboard .badge-info {
    background-color: #2F4CDD;
}

.vendor-dashboard .badge-primary {
    background-color: #0e131a;
}

.vendor-dashboard .badge-warning {
    background-color: #ffb800;
}

.vendor-dashboard .badge-danger {
    background-color: #f35757;
}

.vendor-dashboard .badge-dark {
    background-color: #3e4954;
}

.vendor-dashboard .badge-light {
    background-color: #f4f4f4;
}

.vendor-dashboard .light.badge-default {
    background: #ADB6C7;
}

.vendor-dashboard .light.badge-success {
    background-color: #e7faec;
    color: #2bc155;
}

[data-theme-version=dark] .light.badge-success {
    background-color: rgba(43, 193, 85, 0.1);
}

.vendor-dashboard .light.badge-info {
    background-color: #dee3fa;
    color: #2F4CDD;
}

[data-theme-version=dark] .light.badge-info {
    background-color: rgba(47, 76, 221, 0.1);
}

.vendor-dashboard .light.badge-primary {
    background-color: #fdf4f8;
    color: #0e131a;
}

[data-theme-version=dark] .light.badge-primary {
    background-color: rgba(221, 47, 110, 0.2);
}

.vendor-dashboard .light.badge-secondary {
    background-color: #e8deec;
    color: #392540;
}

[data-theme-version=dark] .light.badge-secondary {
    background-color: rgba(57, 37, 64, 0.5);
    color: #7e7e7e;
}

.vendor-dashboard .light.badge-warning {
    background-color: #fff6e0;
    color: #ffb800;
}

[data-theme-version=dark] .light.badge-warning {
    background-color: rgba(255, 184, 0, 0.1);
}

.vendor-dashboard .light.badge-danger {
    background-color: #fde6e6;
    color: #f35757;
}

[data-theme-version=dark] .light.badge-danger {
    background-color: rgba(243, 87, 87, 0.15);
}

.vendor-dashboard .light.badge-dark {
    background-color: #dee2e6;
    color: #3e4954;
}

[data-theme-version=dark] .light.badge-dark {
    background-color: rgba(62, 73, 84, 0.35);
    color: #7e7e7e;
}

.vendor-dashboard .bootstrap-label .label {
    display: inline-block;
    margin-right: 1rem;
}

.vendor-dashboard .bootstrap-label .label:last-child {
    margin-right: 0;
}

.vendor-dashboard .badge-demo .badge {
    margin-right: 5px;
    margin-bottom: 5px;
}

.vendor-dashboard .badge-demo .badge:last-child {
    margin-right: 0;
}

.vendor-dashboard .bootstrap-badge-buttons button {
    margin-right: 0.2rem;
    margin-bottom: 1rem;
}

.vendor-dashboard .bootstrap-badge-buttons button:last-child {
    margin-right: 0;
}

.vendor-dashboard .page-titles {
    padding: 0.9375rem 1.25rem;
    background: #fff;
    margin-bottom: 30px;
    border-radius: 5px;
}

[data-theme-version=dark] .page-titles {
    background: #1e2746;
}

@media only screen and (max-width: 767px) {
    .vendor-dashboard .page-titles {
        margin-left: -15px;
        margin-right: -15px;
        margin-bottom: 15px;
        padding: 15px 0;
    }
}

.vendor-dashboard .page-titles .justify-content-sm-end {
    align-items: center;
}

.vendor-dashboard .page-titles h4 {
    margin-bottom: 0;
    margin-top: 0;
    color: #0e131a;
    font-size: 1.25rem;
}

.vendor-dashboard .page-titles h4 span {
    font-size: 0.875rem;
    font-weight: 400;
}

.vendor-dashboard .page-titles .breadcrumb {
    margin-bottom: 0;
    padding: 0;
    background: transparent;
}

.vendor-dashboard .page-titles .breadcrumb li {
    margin-top: 0;
    margin-bottom: 0;
}

.vendor-dashboard .page-titles .breadcrumb li a {
    color: #828690;
}

.vendor-dashboard .page-titles .breadcrumb li.active {
    color: #555555;
}

.vendor-dashboard .page-titles .breadcrumb .breadcrumb-item + .breadcrumb-item:before {
    content: "\e606";
    font-family: "simple-line-icons";
    font-size: 10px;
}

.vendor-dashboard .page-titles .breadcrumb-datepicker {
    font-size: 0.75rem;
    color: #89879f;
}

.vendor-dashboard .page-titles .breadcrumb-datepicker__icon {
    font-size: 0.875rem;
}

.vendor-dashboard .page-titles .breadcrumb-widget .border-dark {
    border-color: #dee2e6 !important;
}

.vendor-dashboard .page-titles .breadcrumb-widget h4 {
    color: #646c9a;
    font-weight: 600;
}

@media only screen and (max-width: 575px) {
    .vendor-dashboard .page-titles .breadcrumb-widget {
        text-align: left !important;
        margin-bottom: 0.9375rem;
    }
}

.vendor-dashboard button {
    cursor: pointer;
}

.vendor-dashboard button:focus {
    outline: 0;
    box-shadow: none;
}

.vendor-dashboard .btn {
    padding: 10px 20px;
    border-radius: 5px;
    font-weight: 500;
    font-size: 1rem;
}

.vendor-dashboard .btn:hover,
.vendor-dashboard .btn:focus,
.vendor-dashboard .btn:active,
.vendor-dashboard .btn.active {
    outline: 0 !important;
}

@media only screen and (max-width: 1400px) {
    .vendor-dashboard .btn {
        padding: 0.625rem 1rem;
        font-size: 0.813rem;
    }
}

.vendor-dashboard .btn-transparent {
    background-color: transparent;
}

.vendor-dashboard .sharp {
    min-width: 40px;
    padding: 7px;
    height: 40px;
    min-height: 40px;
}

.vendor-dashboard .sharp.btn-xs {
    padding: 3px;
    width: 26px;
    height: 26px;
    min-width: 26px;
    min-height: 26px;
}

.vendor-dashboard .light.tp-btn {
    background-color: transparent;
}

.vendor-dashboard .light.btn-default {
    background: #ADB6C7;
}

.vendor-dashboard .light.btn-success {
    background-color: #e7faec;
    border-color: #e7faec;
    color: #2bc155;
}

.vendor-dashboard .light.btn-success g [fill] {
    fill: #2bc155;
}

[data-theme-version=dark] .light.btn-success {
    background-color: rgba(43, 193, 85, 0.1);
    border-color: transparent;
}

.vendor-dashboard .light.btn-success:hover {
    background-color: #2bc155;
    border-color: #2bc155;
    color: #fff;
}

.vendor-dashboard .light.btn-success:hover g [fill] {
    fill: #fff;
}

.vendor-dashboard .light.btn-info {
    background-color: #dee3fa;
    border-color: #dee3fa;
    color: #2F4CDD;
}

.vendor-dashboard .light.btn-info g [fill] {
    fill: #2F4CDD;
}

[data-theme-version=dark] .light.btn-info {
    background-color: rgba(47, 76, 221, 0.1);
    border-color: transparent;
}

.vendor-dashboard .light.btn-info:hover {
    background-color: #2F4CDD;
    border-color: #2F4CDD;
    color: #fff;
}

.vendor-dashboard .light.btn-info:hover g [fill] {
    fill: #fff;
}

.vendor-dashboard .light.btn-primary {
    background-color: #fdf4f8;
    border-color: #fdf4f8;
    color: #0e131a;
}

.vendor-dashboard .light.btn-primary g [fill] {
    fill: #0e131a;
}

[data-theme-version=dark] .light.btn-primary {
    background-color: rgba(221, 47, 110, 0.2);
    border-color: transparent;
}

.vendor-dashboard .light.btn-primary:hover {
    background-color: #0e131a;
    border-color: #0e131a;
    color: #fff;
}

.vendor-dashboard .light.btn-primary:hover g [fill] {
    fill: #fff;
}

.vendor-dashboard .light.btn-secondary {
    background-color: #e8deec;
    border-color: #e8deec;
    color: #392540;
}

.vendor-dashboard .light.btn-secondary g [fill] {
    fill: #392540;
}

[data-theme-version=dark] .light.btn-secondary {
    background-color: rgba(57, 37, 64, 0.5);
    border-color: transparent;
    color: #fff;
}

.vendor-dashboard .light.btn-secondary:hover {
    background-color: #392540;
    border-color: #392540;
    color: #fff;
}

.vendor-dashboard .light.btn-secondary:hover g [fill] {
    fill: #fff;
}

.vendor-dashboard .light.btn-warning {
    background-color: #fff6e0;
    border-color: #fff6e0;
    color: #ffb800;
}

.vendor-dashboard .light.btn-warning g [fill] {
    fill: #ffb800;
}

[data-theme-version=dark] .light.btn-warning {
    background-color: rgba(255, 184, 0, 0.1);
    border-color: transparent;
}

.vendor-dashboard .light.btn-warning:hover {
    background-color: #ffb800;
    border-color: #ffb800;
    color: #fff;
}

.vendor-dashboard .light.btn-warning:hover g [fill] {
    fill: #fff;
}

.vendor-dashboard .light.btn-danger {
    background-color: #fde6e6;
    border-color: #fde6e6;
    color: #f35757;
}

.vendor-dashboard .light.btn-danger g [fill] {
    fill: #f35757;
}

[data-theme-version=dark] .light.btn-danger {
    background-color: rgba(243, 87, 87, 0.15);
    border-color: transparent;
}

.vendor-dashboard .light.btn-danger:hover {
    background-color: #f35757;
    border-color: #f35757;
    color: #fff;
}

.vendor-dashboard .light.btn-danger:hover g [fill] {
    fill: #fff;
}

.vendor-dashboard .light.btn-dark {
    background-color: #dee2e6;
    border-color: #dee2e6;
    color: #3e4954;
}

.vendor-dashboard .light.btn-dark g [fill] {
    fill: #3e4954;
}

[data-theme-version=dark] .light.btn-dark {
    background-color: rgba(62, 73, 84, 0.35);
    border-color: transparent;
    color: #fff;
}

.vendor-dashboard .light.btn-dark:hover {
    background-color: #3e4954;
    border-color: #3e4954;
    color: #fff;
}

.vendor-dashboard .light.btn-dark:hover g [fill] {
    fill: #fff;
}

.vendor-dashboard .btn.tp-btn {
    background-color: transparent;
    border-color: transparent;
}

.vendor-dashboard .btn.tp-btn.btn-default {
    background: #ADB6C7;
}

.vendor-dashboard .btn.tp-btn.btn-success {
    color: #2bc155;
}

.vendor-dashboard .btn.tp-btn.btn-success g [fill] {
    fill: #2bc155;
}

.vendor-dashboard .btn.tp-btn.btn-success:hover {
    background-color: #2bc155;
    border-color: #2bc155;
    color: #fff;
}

.vendor-dashboard .btn.tp-btn.btn-success:hover g [fill] {
    fill: #fff;
}

.vendor-dashboard .btn.tp-btn.btn-info {
    color: #2F4CDD;
}

.vendor-dashboard .btn.tp-btn.btn-info g [fill] {
    fill: #2F4CDD;
}

.vendor-dashboard .btn.tp-btn.btn-info:hover {
    background-color: #2F4CDD;
    border-color: #2F4CDD;
    color: #fff;
}

.vendor-dashboard .btn.tp-btn.btn-info:hover g [fill] {
    fill: #fff;
}

.vendor-dashboard .btn.tp-btn.btn-primary {
    color: #0e131a;
}

.vendor-dashboard .btn.tp-btn.btn-primary g [fill] {
    fill: #0e131a;
}

.vendor-dashboard .btn.tp-btn.btn-primary:hover {
    background-color: #0e131a;
    border-color: #0e131a;
    color: #fff;
}

.vendor-dashboard .btn.tp-btn.btn-primary:hover g [fill] {
    fill: #fff;
}

.vendor-dashboard .btn.tp-btn.btn-secondary {
    color: #392540;
}

.vendor-dashboard .btn.tp-btn.btn-secondary g [fill] {
    fill: #392540;
}

.vendor-dashboard .btn.tp-btn.btn-secondary:hover {
    background-color: #392540;
    border-color: #392540;
    color: #fff;
}

.vendor-dashboard .btn.tp-btn.btn-secondary:hover g [fill] {
    fill: #fff;
}

.vendor-dashboard .btn.tp-btn.btn-warning {
    color: #ffb800;
}

.vendor-dashboard .btn.tp-btn.btn-warning g [fill] {
    fill: #ffb800;
}

.vendor-dashboard .btn.tp-btn.btn-warning:hover {
    background-color: #ffb800;
    border-color: #ffb800;
    color: #fff;
}

.vendor-dashboard .btn.tp-btn.btn-warning:hover g [fill] {
    fill: #fff;
}

.vendor-dashboard .btn.tp-btn.btn-danger {
    color: #f35757;
}

.vendor-dashboard .btn.tp-btn.btn-danger g [fill] {
    fill: #f35757;
}

.vendor-dashboard .btn.tp-btn.btn-danger:hover {
    background-color: #f35757;
    border-color: #f35757;
    color: #fff;
}

.vendor-dashboard .btn.tp-btn.btn-danger:hover g [fill] {
    fill: #fff;
}

.vendor-dashboard .btn.tp-btn.btn-light {
    color: #3e4954;
}

.vendor-dashboard .btn.tp-btn.btn-light g [fill] {
    fill: #3e4954;
}

.vendor-dashboard .btn.tp-btn.btn-light:hover {
    background-color: #f4f4f4;
    border-color: #f4f4f4;
    color: #3e4954;
}

.vendor-dashboard .btn.tp-btn.btn-light:hover g [fill] {
    fill: #fff;
}

.vendor-dashboard .btn.tp-btn.btn-dark {
    color: #3e4954;
}

.vendor-dashboard .btn.tp-btn.btn-dark g [fill] {
    fill: #3e4954;
}

.vendor-dashboard .btn.tp-btn.btn-dark:hover {
    background-color: #3e4954;
    border-color: #3e4954;
    color: #fff;
}

.vendor-dashboard .btn.tp-btn.btn-dark:hover g [fill] {
    fill: #fff;
}

.vendor-dashboard .btn.tp-btn-light {
    background-color: transparent;
    border-color: transparent;
}

.vendor-dashboard .btn.tp-btn-light.btn-success {
    color: #2bc155;
}

.vendor-dashboard .btn.tp-btn-light.btn-success g [fill] {
    fill: #2bc155;
}

.vendor-dashboard .btn.tp-btn-light.btn-success:hover {
    background-color: #e7faec;
    border-color: #e7faec;
    color: #2bc155;
}

.vendor-dashboard .btn.tp-btn-light.btn-success:hover g [fill] {
    fill: #2bc155;
}

.vendor-dashboard .btn.tp-btn-light.btn-info {
    color: #2F4CDD;
}

.vendor-dashboard .btn.tp-btn-light.btn-info g [fill] {
    fill: #2F4CDD;
}

.vendor-dashboard .btn.tp-btn-light.btn-info:hover {
    background-color: #dee3fa;
    border-color: #dee3fa;
    color: #2F4CDD;
}

.vendor-dashboard .btn.tp-btn-light.btn-info:hover g [fill] {
    fill: #2F4CDD;
}

.vendor-dashboard .btn.tp-btn-light.btn-primary {
    color: #0e131a;
}

.vendor-dashboard .btn.tp-btn-light.btn-primary g [fill] {
    fill: #0e131a;
}

.vendor-dashboard .btn.tp-btn-light.btn-primary:hover {
    background-color: #fdf4f8;
    border-color: #fdf4f8;
    color: #0e131a;
}

.vendor-dashboard .btn.tp-btn-light.btn-primary:hover g [fill] {
    fill: #0e131a;
}

.vendor-dashboard .btn.tp-btn-light.btn-secondary {
    color: #392540;
}

.vendor-dashboard .btn.tp-btn-light.btn-secondary g [fill] {
    fill: #392540;
}

.vendor-dashboard .btn.tp-btn-light.btn-secondary:hover {
    background-color: #e8deec;
    border-color: #e8deec;
    color: #392540;
}

.vendor-dashboard .btn.tp-btn-light.btn-secondary:hover g [fill] {
    fill: #392540;
}

.vendor-dashboard .btn.tp-btn-light.btn-warning {
    color: #ffb800;
}

.vendor-dashboard .btn.tp-btn-light.btn-warning g [fill] {
    fill: #ffb800;
}

.vendor-dashboard .btn.tp-btn-light.btn-warning:hover {
    background-color: #fff6e0;
    border-color: #fff6e0;
    color: #ffb800;
}

.vendor-dashboard .btn.tp-btn-light.btn-warning:hover g [fill] {
    fill: #ffb800;
}

.vendor-dashboard .btn.tp-btn-light.btn-danger {
    color: #f35757;
}

.vendor-dashboard .btn.tp-btn-light.btn-danger g [fill] {
    fill: #f35757;
}

.vendor-dashboard .btn.tp-btn-light.btn-danger:hover {
    background-color: #fde6e6;
    border-color: #fde6e6;
    color: #f35757;
}

.vendor-dashboard .btn.tp-btn-light.btn-danger:hover g [fill] {
    fill: #fff;
}

.vendor-dashboard .btn.tp-btn-light.btn-dark {
    color: #3e4954;
}

.vendor-dashboard .btn.tp-btn-light.btn-dark g [fill] {
    fill: #3e4954;
}

.vendor-dashboard .btn.tp-btn-light.btn-dark:hover {
    background-color: #dee2e6;
    border-color: #dee2e6;
    color: #3e4954;
}

.vendor-dashboard .btn.tp-btn-light.btn-dark:hover g [fill] {
    fill: #fff;
}

.vendor-dashboard .shadow.btn-primary {
    -webkit-box-shadow: 0 5px 15px 0 rgba(221, 47, 110, 0.2) !important;
    box-shadow: 0 5px 15px 0 rgba(221, 47, 110, 0.2) !important;
}

.vendor-dashboard .shadow.btn-secondary {
    -webkit-box-shadow: 0 5px 15px 0 rgba(57, 37, 64, 0.2) !important;
    box-shadow: 0 5px 15px 0 rgba(57, 37, 64, 0.2) !important;
}

.vendor-dashboard .shadow.btn-warning {
    -webkit-box-shadow: 0 5px 15px 0 rgba(255, 184, 0, 0.2) !important;
    box-shadow: 0 5px 15px 0 rgba(255, 184, 0, 0.2) !important;
}

.vendor-dashboard .shadow.btn-danger {
    -webkit-box-shadow: 0 5px 15px 0 rgba(243, 87, 87, 0.2) !important;
    box-shadow: 0 5px 15px 0 rgba(243, 87, 87, 0.2) !important;
}

.vendor-dashboard .shadow.btn-info {
    -webkit-box-shadow: 0 5px 15px 0 rgba(47, 76, 221, 0.2) !important;
    box-shadow: 0 5px 15px 0 rgba(47, 76, 221, 0.2) !important;
}

.vendor-dashboard .shadow.btn-success {
    -webkit-box-shadow: 0 5px 15px 0 rgba(43, 193, 85, 0.2) !important;
    box-shadow: 0 5px 15px 0 rgba(43, 193, 85, 0.2) !important;
}

.vendor-dashboard .btn-xxs {
    padding: 6px 15px;
    font-size: 11px;
    line-height: 1.3;
}

.vendor-dashboard .btn-xs {
    font-size: 0.75rem;
    padding: 0.438rem 1rem;
    font-weight: 600;
}

.vendor-dashboard .btn-sm,
.vendor-dashboard .btn-group-sm > .btn {
    font-size: 0.813rem !important;
    padding: 0.625rem 1rem;
}

.vendor-dashboard .btn-md {
    font-size: 0.813rem !important;
    padding: 0.875rem 1.25rem;
}

.vendor-dashboard .btn-lg,
.vendor-dashboard .btn-group-lg > .btn {
    padding: 1rem 2rem;
    font-size: 1.125rem !important;
}

@media only screen and (max-width: 575px) {
    .vendor-dashboard .btn-lg,
    .vendor-dashboard .btn-group-lg > .btn {
        padding: 0.75rem 1.25rem;
    }
}

.vendor-dashboard .btn-xl {
    padding: 0.6rem 1rem;
}

.vendor-dashboard .btn-xl.btn-default {
    font-weight: 600;
}

.vendor-dashboard .btn-square {
    border-radius: 0;
}

.vendor-dashboard .btn-rounded {
    border-radius: 30px !important;
}

.vendor-dashboard .btn-icon-right {
    border-left: 1px solid white;
    display: inline-block;
    margin: -0.8rem 0 -0.8rem 1rem;
    padding: 0.4375rem 0 0.4375rem 1rem;
    margin: -1rem -0.25rem -1rem 1rem;
    padding: 1rem 0 1rem 1.25rem;
}

.vendor-dashboard .btn-icon-left {
    background: #fff;
    border-radius: 10rem;
    display: inline-block;
    margin: -0.5rem 0.75rem -0.5rem -1.188rem;
    padding: 0.5rem 0.8rem 0.5rem;
    float: left;
}

@media only screen and (max-width: 1199px) {
    .vendor-dashboard .btn-icon-left {
        margin: -0.5rem 0.75rem -0.5rem -0.8rem;
    }
}

.vendor-dashboard .toggle-dropdown:after {
    margin-left: 0.755em;
}

.vendor-dashboard .social-btn-icon .btn {
    min-width: 7.5rem;
    margin-bottom: 1.5rem;
}

.vendor-dashboard .social-icon .btn {
    padding: 0.7rem 1.4rem;
}

.vendor-dashboard .btn-circle {
    height: 5rem;
    width: 5rem;
    border-radius: 50% !important;
}

.vendor-dashboard .btn-circle-sm {
    width: 4.5rem;
    height: 4.5rem;
    font-size: 1.8rem;
}

.vendor-dashboard .btn-circle-md {
    width: 6rem;
    height: 6rem;
    font-size: 2.5rem;
}

.vendor-dashboard .btn-circle-md i {
    font-size: 2.4rem;
}

.vendor-dashboard .btn-circle-lg {
    width: 8rem;
    height: 8rem;
    font-size: 3.2rem;
}

.vendor-dashboard .btn-circle-lg i {
    font-size: 3.1rem;
}

.vendor-dashboard .btn-page .btn {
    min-width: 110px;
    margin-right: 4px;
    margin-bottom: 8px;
}

.vendor-dashboard .size-1 {
    min-width: 160px !important;
    font-size: 24px;
    padding: 0.68rem 0.75rem;
}

.vendor-dashboard .size-2 {
    font-size: 20px;
    min-width: 130px !important;
    padding: 0.57rem 0.75rem;
}

.vendor-dashboard .size-3 {
    font-size: 14px;
    min-width: 110px !important;
    padding: 0.536rem 0.75rem;
}

.vendor-dashboard .size-4 {
    font-size: 14px;
    min-width: 100px !important;
}

.vendor-dashboard .size-5 {
    font-size: 14px;
    min-width: 90px !important;
    padding: 0.22rem 0.75rem;
}

.vendor-dashboard .size-6 {
    font-size: 13px;
    min-width: 80px !important;
    padding: 0.097rem 0.75rem;
}

.vendor-dashboard .size-7 {
    font-size: 12px;
    min-width: 60px !important;
    padding: 0.001rem 0.75rem;
}

.vendor-dashboard .btn-light {
    background: #f4f4f4;
    border-color: #f4f4f4;
}

.vendor-dashboard .btn-light:active,
.vendor-dashboard .btn-light:focus,
.vendor-dashboard .btn-light:hover {
    background: white;
    color: #3e4954;
    border-color: white;
}

.vendor-dashboard .btn-outline-light {
    color: #3e4954;
}

[data-theme-version=dark] .btn-outline-light {
    color: #7e7e7e;
}

[data-theme-version=dark] .btn-outline-secondary {
    color: #7e7e7e;
}

[data-theme-version=dark] .btn-outline-dark {
    color: #7e7e7e;
}

.vendor-dashboard .btn-dark {
    background: #3e4954;
    border-color: #3e4954;
    color: #fff;
}

.vendor-dashboard .btn-dark:active,
.vendor-dashboard .btn-dark:focus,
.vendor-dashboard .btn-dark:hover {
    background: #283037;
    color: #fff;
    border-color: #283037;
}

.vendor-dashboard .btn-group.btn-rounded .btn:first-child {
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
}

.vendor-dashboard .btn-group.btn-rounded .btn:last-child {
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
}

.vendor-dashboard .btn-facebook {
    background: #3b5998;
    border-color: #3b5998;
    color: #fff;
}

.vendor-dashboard .btn-facebook:active,
.vendor-dashboard .btn-facebook:focus,
.vendor-dashboard .btn-facebook:hover {
    background: #2d4373;
    color: #fff;
    border-color: #2d4373;
}

.vendor-dashboard .btn-twitter {
    background: #1da1f2;
    border-color: #1da1f2;
    color: #fff;
}

.vendor-dashboard .btn-twitter:active,
.vendor-dashboard .btn-twitter:focus,
.vendor-dashboard .btn-twitter:hover {
    background: #0c85d0;
    color: #fff;
    border-color: #0c85d0;
}

.vendor-dashboard .btn-youtube {
    background: #FF0000;
    border-color: #FF0000;
    color: #fff;
}

.vendor-dashboard .btn-youtube:active,
.vendor-dashboard .btn-youtube:focus,
.vendor-dashboard .btn-youtube:hover {
    background: #cc0000;
    color: #fff;
    border-color: #cc0000;
}

.vendor-dashboard .btn-instagram {
    background: #c32aa3;
    border-color: #c32aa3;
    color: #fff;
}

.vendor-dashboard .btn-instagram:active,
.vendor-dashboard .btn-instagram:focus,
.vendor-dashboard .btn-instagram:hover {
    background: #992180;
    color: #fff;
    border-color: #992180;
}

.vendor-dashboard .btn-pinterest {
    background: #bd081c;
    border-color: #bd081c;
    color: #fff;
}

.vendor-dashboard .btn-pinterest:active,
.vendor-dashboard .btn-pinterest:focus,
.vendor-dashboard .btn-pinterest:hover {
    background: #8c0615;
    color: #fff;
    border-color: #8c0615;
}

.vendor-dashboard .btn-linkedin {
    background: #007bb6;
    border-color: #007bb6;
    color: #fff;
}

.vendor-dashboard .btn-linkedin:active,
.vendor-dashboard .btn-linkedin:focus,
.vendor-dashboard .btn-linkedin:hover {
    background: #005983;
    color: #fff;
    border-color: #005983;
}

.vendor-dashboard .btn-google-plus {
    background: #db4439;
    border-color: #db4439;
    color: #fff;
}

.vendor-dashboard .btn-google-plus:active, .vendor-dashboard .btn-google-plus:focus, .vendor-dashboard .btn-google-plus:hover {
    background: #be2d23;
    color: #fff;
    border-color: #be2d23;
}

.vendor-dashboard .btn-google {
    background: #4285f4;
    border-color: #4285f4;
    color: #fff;
}

.vendor-dashboard .btn-google:active,
.vendor-dashboard .btn-google:focus,
.vendor-dashboard .btn-google:hover {
    background: #1266f1;
    color: #fff;
    border-color: #1266f1;
}

.vendor-dashboard .btn-snapchat {
    background: #fffc00;
    border-color: #fffc00;
    color: #000;
}

.vendor-dashboard .btn-snapchat:active,
.vendor-dashboard .btn-snapchat:focus,
.vendor-dashboard .btn-snapchat:hover {
    background: #ccca00;
    color: #000;
    border-color: #ccca00;
}

.vendor-dashboard .btn-whatsapp {
    background: #25d366;
    border-color: #25d366;
    color: #fff;
}

.vendor-dashboard .btn-whatsapp:active,
.vendor-dashboard .btn-whatsapp:focus,
.vendor-dashboard .btn-whatsapp:hover {
    background: #1da851;
    color: #fff;
    border-color: #1da851;
}

.vendor-dashboard .btn-tumblr {
    background: #35465d;
    border-color: #35465d;
    color: #fff;
}

.vendor-dashboard .btn-tumblr:active,
.vendor-dashboard .btn-tumblr:focus,
.vendor-dashboard .btn-tumblr:hover {
    background: #222e3d;
    color: #fff;
    border-color: #222e3d;
}

.vendor-dashboard .btn-reddit {
    background: #ff4500;
    border-color: #ff4500;
    color: #fff;
}

.vendor-dashboard .btn-reddit:active,
.vendor-dashboard .btn-reddit:focus,
.vendor-dashboard .btn-reddit:hover {
    background: #cc3700;
    color: #fff;
    border-color: #cc3700;
}

.vendor-dashboard .btn-spotify {
    background: #1ed760;
    border-color: #1ed760;
    color: #fff;
}

.vendor-dashboard .btn-spotify:active,
.vendor-dashboard .btn-spotify:focus,
.vendor-dashboard .btn-spotify:hover {
    background: #18aa4c;
    color: #fff;
    border-color: #18aa4c;
}

.vendor-dashboard .btn-yahoo {
    background: #430297;
    border-color: #430297;
    color: #fff;
}

.vendor-dashboard .btn-yahoo:active,
.vendor-dashboard .btn-yahoo:focus,
.vendor-dashboard .btn-yahoo:hover {
    background: #2d0165;
    color: #fff;
    border-color: #2d0165;
}

.vendor-dashboard .btn-dribbble {
    background: #ea4c89;
    border-color: #ea4c89;
    color: #fff;
}

.vendor-dashboard .btn-dribbble:active,
.vendor-dashboard .btn-dribbble:focus,
.vendor-dashboard .btn-dribbble:hover {
    background: #e51e6b;
    color: #fff;
    border-color: #e51e6b;
}

.vendor-dashboard .btn-skype {
    background: #00aff0;
    border-color: #00aff0;
    color: #fff;
}

.vendor-dashboard .btn-skype:active,
.vendor-dashboard .btn-skype:focus,
.vendor-dashboard .btn-skype:hover {
    background: #008abd;
    color: #fff;
    border-color: #008abd;
}

.vendor-dashboard .btn-quora {
    background: #aa2200;
    border-color: #aa2200;
    color: #fff;
}

.vendor-dashboard .btn-quora:active,
.vendor-dashboard .btn-quora:focus,
.vendor-dashboard .btn-quora:hover {
    background: #771800;
    color: #fff;
    border-color: #771800;
}

.vendor-dashboard .btn-vimeo {
    background: #1ab7ea;
    border-color: #1ab7ea;
    color: #fff;
}

.vendor-dashboard .btn-vimeo:active,
.vendor-dashboard .btn-vimeo:focus,
.vendor-dashboard .btn-vimeo:hover {
    background: #1295bf;
    color: #fff;
    border-color: #1295bf;
}

.vendor-dashboard .card {
    margin-bottom: 1.875rem;
    background-color: #fff;
    transition: all 0.5s ease-in-out;
    position: relative;
    border: 0px solid transparent;
    border-radius: 2px;
    -webkit-box-shadow: 0 0 0 0px rgba(61, 70, 79, 0.05), 0 1px 15px 0 rgba(61, 70, 79, 0.15);
    box-shadow: 0 0 0 0px rgba(61, 70, 79, 0.05), 0 1px 15px 0 rgba(61, 70, 79, 0.15);
    height: calc(100% - 30px);
}

@media only screen and (max-width: 575px) {
    .vendor-dashboard .card {
        margin-bottom: 0.938rem;
        height: calc(100% - 0.938rem);
    }
}

.vendor-dashboard .card-body {
    padding: 1.875rem;
}

@media only screen and (max-width: 575px) {
    .vendor-dashboard .card-body {
        padding: 1rem;
    }
}

.vendor-dashboard .card-title {
    font-size: 20px;
    font-weight: 500;
    color: #ff3a63;
    text-transform: capitalize;
}

.vendor-dashboard .card-title--large {
    font-size: 1.5rem;
}

.vendor-dashboard .card-title--medium {
    font-size: 1rem;
}

.vendor-dashboard .card-title--small {
    font-size: 0.875rem;
}

.vendor-dashboard .card-header {
    border-color: #d7dae3;
    position: relative;
    background: transparent;
    padding: 1.5rem 1.875rem 1.25rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

@media only screen and (max-width: 575px) {
    .vendor-dashboard .card-header {
        padding: 1.25rem 1rem 1.25rem;
    }
}

[data-theme-version=dark] .card-header {
    border-color: #333a54;
}

.vendor-dashboard .card-header .card-title {
    margin-bottom: 0px;
}

.vendor-dashboard .card-header .subtitle {
    padding-top: 5px;
    font-size: 14px;
    line-height: 1.5;
}

.vendor-dashboard .card-footer {
    border-color: #d7dae3;
    background: transparent;
    padding: 1.25rem 1.875rem 1.25rem;
}

[data-theme-version=dark] .card-footer {
    border-color: #333a54;
}

.vendor-dashboard .transparent-card.card {
    background: transparent;
    border: 1px solid transparent;
    box-shadow: none;
}

.vendor-dashboard .card-action > a {
    display: inline-block;
    width: 30px;
    height: 30px;
    line-height: 30px;
    border-radius: 5px;
    border-color: transparent;
    text-align: center;
    background: black;
    color: #fff;
    margin-right: 8px;
}

[data-theme-version=dark] .card-action > a {
    background: #181f39;
}

.vendor-dashboard .card-action > a:last-child {
    margin-right: 0;
}

.vendor-dashboard .card-action > a:hover,
.vendor-dashboard .card-action > a:focus {
    background: black;
}

[data-theme-version=dark] .card-action > a:hover,
[data-theme-version=dark] .card-action > a:focus {
    background: #181f39;
}

.vendor-dashboard .card-action > a i,
.vendor-dashboard .card-action > a span {
    font-size: 1rem;
}

.vendor-dashboard .card-action .dropdown {
    width: 30px;
    height: 30px;
    border-radius: 5px;
    border-color: transparent;
    text-align: center;
    margin-right: 8px;
    top: -2px;
    position: relative;
    display: inline-block;
    background: black;
    color: #0e131a;
}

[data-theme-version=dark] .card-action .dropdown {
    background: #181f39;
}

.vendor-dashboard .card-action .dropdown:hover,
.vendor-dashboard .card-action .dropdown:focus {
    background: black;
}

[data-theme-version=dark] .card-action .dropdown:hover,
[data-theme-version=dark] .card-action .dropdown:focus {
    background: #181f39;
}

.vendor-dashboard .card-action .dropdown .btn {
    padding: 0;
    line-height: 27px;
    color: #fff;
}

.vendor-dashboard .card-action .dropdown .btn:focus {
    box-shadow: none;
}

.vendor-dashboard .card-fullscreen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99999999;
    overflow: auto;
}

.vendor-dashboard .card-loader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.75);
    z-index: 999;
}

.vendor-dashboard .card-loader i {
    margin: 0 auto;
    background: #c8215d;
    padding: 10px;
    border-radius: 50%;
    color: #fff;
    font-size: 1rem;
}

.vendor-dashboard .rotate-refresh {
    -webkit-animation: mymove 0.8s infinite linear;
    animation: mymove 0.8s infinite linear;
    display: inline-block;
}

.vendor-dashboard .card-header .date_picker {
    display: inline-block;
    padding: 8px;
    border: 1px solid #d7dae3;
    cursor: pointer;
    border-radius: 0.375rem;
}

.vendor-dashboard .card-header .border-0 {
    padding-bottom: 0;
}

@-webkit-keyframes mymove {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes mymove {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.vendor-dashboard .carousel-custom-prev,
.vendor-dashboard .carousel-custom-next {
    width: 2.5rem;
    height: 2.5rem;
    line-height: 2.5rem;
    display: inline-block;
    border-radius: 50%;
    background: #fff;
    text-align: center;
}

.vendor-dashboard .carousel-custom-prev i,
.vendor-dashboard .carousel-custom-next i {
    font-size: 1rem;
}

.vendor-dashboard .carousel-custom-prev:hover,
.vendor-dashboard .carousel-custom-next:hover {
    background: linear-gradient(to right, rgba(245, 60, 121, 0.99) 0%, rgba(246, 104, 47, 0.99) 100%);
    color: #fff;
}

.vendor-dashboard .carousel-custom-next {
    position: absolute;
    top: 50%;
    right: 30px;
    transform: translateY(-50%);
}

.vendor-dashboard .carousel-custom-prev {
    position: absolute;
    top: 50%;
    left: 30px;
    transform: translateY(-50%);
}

.vendor-dashboard .carousel-caption {
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.78);
}

.vendor-dashboard .carousel-caption h5 {
    color: #fff;
    font-size: 1.8rem;
}

.vendor-dashboard .carousel-caption p {
    margin-bottom: 0;
}

.vendor-dashboard .dropdown-toggle:focus {
    box-shadow: none !important;
}

.vendor-dashboard .dropdown-outline {
    border: 0.1rem solid #0e131a;
}

.vendor-dashboard .dropdown-menu {
    font-size: inherit;
    border: 0;
    box-shadow: 0 10px 40px 0 rgba(32, 28, 69, 0.1);
    margin-top: 0;
}

.vendor-dashboard .dropdown-menu .dropdown-item {
    font-size: 16px;
    color: #7e7e7e;
    padding: 0.5rem 1.75rem;
}

@media only screen and (max-width: 1400px) {
    .vendor-dashboard .dropdown-menu .dropdown-item {
        padding: 0.375rem 1rem;
        font-size: 14px;
    }
}

.vendor-dashboard .dropdown-menu .dropdown-item:hover,
.vendor-dashboard .dropdown-menu .dropdown-item:focus,
.vendor-dashboard .dropdown-menu .dropdown-item:active,
.vendor-dashboard .dropdown-menu .dropdown-item.active {
    color: #514e5f;
}

.vendor-dashboard .dropdown-menu .dropdown-item.active,
.vendor-dashboard .dropdown-menu .dropdown-item:active {
    color: #fff !important;
}

.vendor-dashboard .dropdown-menu .dropdown-item:active i,
.vendor-dashboard .dropdown-menu .dropdown-item:active svg {
    color: #fff !important;
}

.vendor-dashboard .dropdown-toggle-split {
    padding: 0 10px;
    opacity: 0.85;
}

.vendor-dashboard .dropdown-toggle-split:after {
    margin-left: 0 !important;
}

.vendor-dashboard .dropdown-toggle-split:active,
.vendor-dashboard .dropdown-toggle-split:focus,
.vendor-dashboard .dropdown-toggle-split:hover {
    opacity: 1;
}

.vendor-dashboard .dropleft .dropdown-toggle:before,
.vendor-dashboard .dropright .dropdown-toggle:before,
.vendor-dashboard .dropup .dropdown-toggle:after,
.vendor-dashboard .dropdown-toggle:after {
    content: "\f107";
    font-family: FontAwesome;
    border: 0;
    vertical-align: middle;
    margin-left: 0.25em;
    line-height: 1;
}

.vendor-dashboard .bootstrap-select > .dropdown-toggle:after {
    margin-top: -10px !important;
}

.vendor-dashboard .dropup .dropdown-toggle:after {
    content: "\f106";
}

.vendor-dashboard .dropleft .dropdown-toggle:before {
    content: "\f104";
}

.vendor-dashboard .dropright .dropdown-toggle:before {
    content: "\f105";
}

.vendor-dashboard .dropright .dropdown-toggle:after {
    content: none;
}

.vendor-dashboard .custom-dropdown {
    display: inline-block;
    margin-bottom: 1rem;
}

.vendor-dashboard .custom-dropdown .dropdown-menu {
    border: 0px;
    min-width: 160px;
}

.vendor-dashboard .card-action .custom-dropdown {
    margin: 0px;
    background: #f2b3ca;
}

.vendor-dashboard .card-action .custom-dropdown.show,
.vendor-dashboard .card-action .custom-dropdown:focus,
.vendor-dashboard .card-action .custom-dropdown:hover {
    background: #0e131a;
    color: #fff;
}

.vendor-dashboard .card-action .custom-dropdown i {
    display: inline-block;
    padding-top: 9px;
}

.vendor-dashboard .dropdown .dropdown-dots {
    position: relative;
    height: 5px;
    width: 5px;
    background: rgba(62, 73, 84, 0.4);
    border-radius: 5px;
    display: block;
}

.vendor-dashboard .dropdown .dropdown-dots:after,
.vendor-dashboard .dropdown .dropdown-dots:before {
    content: "";
    height: 5px;
    width: 5px;
    background: rgba(62, 73, 84, 0.4);
    position: absolute;
    border-radius: 5px;
}

.vendor-dashboard .dropdown .dropdown-dots:after {
    right: -8px;
}

.vendor-dashboard .dropdown .dropdown-dots:before {
    left: -8px;
}

.vendor-dashboard .dropdown .dropdown-dots.text-white {
    background: rgba(255, 255, 255, 0.7);
}

.vendor-dashboard .dropdown .dropdown-dots.text-white:after,
.vendor-dashboard .dropdown .dropdown-dots.text-white:before {
    background: rgba(255, 255, 255, 0.7);
}

.vendor-dashboard .grid-col {
    padding: 0.5rem !important;
    background: #f2f4fa;
}

.vendor-dashboard .row.grid {
    margin-bottom: 1.5rem;
    text-align: center;
}

.vendor-dashboard .row.grid .grid-col:first-child {
    text-align: left;
}

.vendor-dashboard .label {
    display: inline-block;
    text-align: center;
    font-size: 0.75rem;
    padding: 0.2rem 0.8rem;
}

.vendor-dashboard .label-fixed {
    width: 7.5rem;
    padding: 0.6rem 0;
}

.vendor-dashboard .label-fixed-lg {
    width: 9.5rem;
    padding: 0.6rem 0;
}

.vendor-dashboard .label-big {
    width: 16.8rem;
    font-size: 1.4rem;
    padding: 1.1rem 0;
}

.vendor-dashboard .label-xl {
    width: 10.5rem;
    padding: 1.1rem 0;
    font-size: 1.5rem;
}

.vendor-dashboard .label-lg {
    width: 9.5rem;
    padding: 1.1rem 0;
}

.vendor-dashboard .label-md {
    width: 8.5rem;
    padding: 1.1rem 0;
}

.vendor-dashboard .label-sm {
    width: 7.5rem;
    padding: 1.1rem 0;
}

.vendor-dashboard .label-default {
    background: #ADB6C7;
}

.vendor-dashboard .label-primary {
    background: #0e131a;
    color: #fff;
}

.vendor-dashboard .label-secondary {
    background: #392540;
    color: #fff;
}

.vendor-dashboard .label-info {
    background: #2F4CDD;
    color: #fff;
}

.vendor-dashboard .label-success {
    background: #2bc155;
    color: #fff;
}

.vendor-dashboard .label-warning {
    background: #ffb800;
    color: #fff;
}

.vendor-dashboard .label-danger {
    background: #f35757;
    color: #fff;
}

.vendor-dashboard .label-light {
    background: #f4f4f4;
    color: #000;
}

.vendor-dashboard .label-dark {
    background: #3e4954;
    color: #fff;
}

.vendor-dashboard code {
    word-break: break-word;
    padding: 2px 5px;
    border-radius: 3px;
    background: #fef4f4;
    color: #f35757;
}

[data-theme-version=dark] code {
    background: rgba(243, 87, 87, 0.1);
}

.vendor-dashboard .heading-labels {
    color: #333333;
}

.vendor-dashboard .heading-labels > * {
    margin-bottom: 0.8rem;
}

.vendor-dashboard .heading-labels h1 .label {
    font-size: 18px;
    font-weight: normal;
    padding: 0.4rem 0.9rem;
}

.vendor-dashboard .heading-labels h2 .label {
    font-size: 16px;
    font-weight: normal;
    padding: 0.3rem 0.9rem;
}

.vendor-dashboard .heading-labels h3 .label {
    font-size: 14px;
    font-weight: normal;
}

.vendor-dashboard .heading-labels h4 .label {
    font-size: 12px;
    font-weight: normal;
}

.vendor-dashboard .heading-labels h5 .label {
    font-size: 12px;
    font-weight: normal;
}

.vendor-dashboard .heading-labels h6 .label {
    font-size: 12px;
    font-weight: normal;
}

.vendor-dashboard .list-group-item {
    background-color: rgba(255, 255, 255, 0);
    border: 1px solid #d7dae3;
    padding: 1rem 1.5rem;
}

[data-theme-version=dark] .list-group-item {
    border-color: #333a54;
}

.vendor-dashboard .list-group-item.disabled,
.vendor-dashboard .list-group-item:disabled {
    color: #fff;
    background-color: #392540;
    border-color: #392540;
}

.vendor-dashboard [class*=bg-] .list-group-item {
    border-color: rgba(255, 255, 255, 0.05);
}

.vendor-dashboard .bg-warning .list-group-item {
    border-color: rgba(0, 0, 0, 0.05);
}

.vendor-dashboard .media img {
    border-radius: 3px;
}

.vendor-dashboard .vertical-card__menu:hover {
    box-shadow: none;
}

.vendor-dashboard .vertical-card__menu--image {
    text-align: center;
}

.vendor-dashboard .vertical-card__menu--image img {
    width: 100%;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
}

.vendor-dashboard .vertical-card__menu--status.closed {
    background: #f35757;
}

.vendor-dashboard .vertical-card__menu--status.closed:after {
    border-top: 10px solid #f35757;
}

.vendor-dashboard .vertical-card__menu--status.closed .ribbon-curve {
    border-top: 11px solid #f35757;
    border-bottom: 10px solid #f35757;
}

.vendor-dashboard .vertical-card__menu--desc p {
    margin-bottom: 0.625rem;
}

.vendor-dashboard .vertical-card__menu--price {
    font-size: 1.25rem;
    margin: 0;
    font-weight: 700;
}

.vendor-dashboard .vertical-card__menu--rating {
    font-size: 0.75rem;
}

.vendor-dashboard .vertical-card__menu--title {
    font-weight: 700;
}

.vendor-dashboard .vertical-card__menu--button button {
    font-size: 0.75rem;
}

.vendor-dashboard .vertical-card__menu .card-footer {
    padding: 15px 20px;
    background-color: #d7dae3;
    border-top: none;
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .vendor-dashboard .vertical-card__menu .card-footer {
        padding: 15px;
    }
}

.vendor-dashboard .vertical-card__menu .card-footer span {
    color: #3e4954;
    margin-right: 0.3125rem;
}

.vendor-dashboard .horizontal-card__menu {
    box-shadow: 0 0 7px rgba(173, 173, 173, 0.32);
    display: flex;
    flex-direction: row;
}

@media (max-width: 575.98px) {
    .vendor-dashboard .horizontal-card__menu {
        display: block;
    }
}

.vendor-dashboard .horizontal-card__menu .card-body {
    padding: 0;
    padding: 20px 30px;
}

.vendor-dashboard .horizontal-card__menu--image {
    flex-basis: 30%;
    max-width: 30%;
}

.vendor-dashboard .horizontal-card__menu--image img {
    height: 100%;
    width: 100%;
}

@media (max-width: 575.98px) {
    .vendor-dashboard .horizontal-card__menu--image {
        max-width: unset;
        flex-basis: 100%;
    }
}

.vendor-dashboard .horizontal-card__menu--title {
    font-size: 1rem;
    font-weight: 700;
    margin-bottom: 0.3125rem;
}

.vendor-dashboard .horizontal-card__menu--fav {
    margin-right: 0.5rem;
}

.vendor-dashboard .horizontal-card__menu--price {
    margin: 0;
    font-size: 1rem;
    font-weight: 700;
}

.vendor-dashboard .horizontal-card__menu--rating {
    font-size: 0.625rem;
}

.vendor-dashboard .horizontal-card__menu--footer {
    margin-top: 10px;
}

.vendor-dashboard .prev_price {
    text-decoration: line-through;
    font-size: 80%;
    opacity: 0.5;
}

.vendor-dashboard .modal-header {
    padding: 1rem 1.875rem;
}

.vendor-dashboard .modal-header .close {
    padding: 0.875rem 1.815rem;
    margin: 0;
    position: absolute;
    right: 0;
    float: none;
    top: 0;
    font-size: 30px;
    font-weight: 100;
}

.vendor-dashboard .modal-body {
    padding: 1.875rem;
}

.vendor-dashboard .modal-footer {
    padding: 1rem 1.875rem;
}

.vendor-dashboard .modal-content {
    border-radius: 0.75rem;
}

.vendor-dashboard .modal-backdrop {
    z-index: 10 !important;
}

.vendor-dashboard .pagination .page-item .page-link:hover {
    background: #0e131a;
    border-color: #0e131a;
    color: #fff;
}

.vendor-dashboard .pagination .page-item.active .page-link {
    background: #0e131a;
    border-color: #0e131a;
    color: #fff;
}

.vendor-dashboard .pagination {
    margin-bottom: 20px;
}

.vendor-dashboard .pagination .page-item.page-indicator .page-link {
    padding: 0.65rem 0.8rem;
    font-size: 14px;
}

.vendor-dashboard .pagination .page-item.page-indicator:hover .page-link {
    color: #3e4954;
}

.vendor-dashboard .pagination .page-item .page-link {
    text-align: center;
    padding: 0.55rem 1rem;
    font-size: 1rem;
    background: rgba(255, 255, 255, 0.15);
    color: #3e4954;
    border: 1px solid #d7dae3;
}

[data-theme-version=dark] .pagination .page-item .page-link {
    border-color: #333a54;
    color: #828690;
    background: rgba(255, 255, 255, 0);
}

.vendor-dashboard .pagination .page-item .page-link:hover i,
.vendor-dashboard .pagination .page-item .page-link span {
    color: #fff;
}

.vendor-dashboard .pagination .page-item .page-link:focus {
    outline: 0;
    box-shadow: none;
}

.vendor-dashboard .pagination .page-item .page-link:hover {
    background: #0e131a;
    color: #fff;
    border-color: #0e131a;
}

.vendor-dashboard .pagination .page-item.active .page-link {
    background-color: #0e131a;
    border-color: #0e131a;
    color: #fff;
    box-shadow: 0 10px 20px 0px rgba(221, 47, 110, 0.2);
}

[data-theme-version=dark] .pagination .page-item.active .page-link {
    color: #fff;
}

.vendor-dashboard .pagination .page-item .page-link {
    color: #3e4954;
    -webkit-transition: all 0.5s;
    -ms-transition: all 0.5s;
    transition: all 0.5s;
}

.vendor-dashboard .pagination .page-item:last-child .page-link {
    margin-right: 0;
}

.vendor-dashboard .pagination.no-bg li:not(.page-indicator):not(.active) .page-link {
    background: transparent;
    border-color: transparent;
}

.vendor-dashboard .pagination.no-bg.pagination-primary li:not(.page-indicator):not(.active):hover .page-link {
    background: #0e131a;
    border-color: #0e131a;
}

[data-theme-version=dark] .pagination.no-bg.pagination-primary li:not(.page-indicator):not(.active):hover .page-link {
    background: #0e131a;
    border-color: #0e131a;
}

.vendor-dashboard .pagination.no-bg.pagination-danger li:not(.page-indicator):not(.active):hover .page-link {
    background: #f35757;
    border-color: #f35757;
}

[data-theme-version=dark] .pagination.no-bg.pagination-danger li:not(.page-indicator):not(.active):hover .page-link {
    background: #f35757;
    border-color: #f35757;
}

.vendor-dashboard .pagination.no-bg.pagination-info li:not(.page-indicator):not(.active):hover .page-link {
    background: #2F4CDD;
    border-color: #2F4CDD;
}

[data-theme-version=dark] .pagination.no-bg.pagination-info li:not(.page-indicator):not(.active):hover .page-link {
    background: #2F4CDD;
    border-color: #2F4CDD;
}

.vendor-dashboard .pagination.no-bg.pagination-warning li:not(.page-indicator):not(.active):hover .page-link {
    background: #ffb800;
    border-color: #ffb800;
}

[data-theme-version=dark] .pagination.no-bg.pagination-warning li:not(.page-indicator):not(.active):hover .page-link {
    background: #ffb800;
    border-color: #ffb800;
}

.vendor-dashboard .pagination-primary .page-item .page-link {
    background: #fdf4f8;
    border-color: #fdf4f8;
    color: #0e131a;
}

[data-theme-version=dark] .pagination-primary .page-item .page-link {
    background: rgba(221, 47, 110, 0.2);
    border-color: transparent;
    color: #0e131a;
}

.vendor-dashboard .pagination-primary .page-item:hover .page-link,
.vendor-dashboard .pagination-primary .page-item.active .page-link {
    background: #0e131a;
    border-color: #0e131a;
    box-shadow: 0 10px 20px 0px rgba(221, 47, 110, 0.2);
}

[data-theme-version=dark] .pagination-primary .page-item:hover .page-link,
[data-theme-version=dark] .pagination-primary .page-item.active .page-link {
    color: #fff;
}

.vendor-dashboard .pagination-danger .page-item .page-link {
    background: #fde6e6;
    border-color: #fde6e6;
    color: #f35757;
}

[data-theme-version=dark] .pagination-danger .page-item .page-link {
    background: rgba(243, 87, 87, 0.15);
    border-color: transparent;
    color: #f35757;
}

.vendor-dashboard .pagination-danger .page-item:hover .page-link,
.vendor-dashboard .pagination-danger .page-item.active .page-link {
    background: #f35757;
    border-color: #f35757;
    box-shadow: 0 10px 20px 0px rgba(243, 87, 87, 0.2);
}

[data-theme-version=dark] .pagination-danger .page-item:hover .page-link,
[data-theme-version=dark] .pagination-danger .page-item.active .page-link {
    color: #fff;
}

.vendor-dashboard .pagination-info .page-item .page-link {
    background: #dee3fa;
    border-color: #dee3fa;
    color: #2F4CDD;
}

[data-theme-version=dark] .pagination-info .page-item .page-link {
    background: rgba(47, 76, 221, 0.1);
    border-color: transparent;
    color: #2F4CDD;
}

.vendor-dashboard .pagination-info .page-item:hover .page-link,
.vendor-dashboard .pagination-info .page-item.active .page-link {
    background: #2F4CDD;
    border-color: #2F4CDD;
    box-shadow: 0 10px 20px 0px rgba(47, 76, 221, 0.2);
}

.vendor-dashboard .pagination-warning .page-item .page-link {
    background: #fff6e0;
    border-color: #fff6e0;
    color: #ffb800;
}

[data-theme-version=dark] .pagination-warning .page-item .page-link {
    background: rgba(255, 184, 0, 0.1);
    border-color: transparent;
    color: #ffb800;
}

.vendor-dashboard .pagination-warning .page-item:hover .page-link,
.vendor-dashboard .pagination-warning .page-item.active .page-link {
    background: #ffb800;
    border-color: #ffb800;
    box-shadow: 0 10px 20px 0px rgba(255, 184, 0, 0.2);
}

.vendor-dashboard .pagination-gutter .page-item {
    margin-right: 7px;
}

.vendor-dashboard .pagination-gutter .page-item .page-link {
    border-radius: 0.75rem !important;
}

.vendor-dashboard .pagination-circle .page-item {
    margin-right: 7px;
}

.vendor-dashboard .pagination-circle .page-item .page-link,
.vendor-dashboard .pagination-circle .page-item.page-indicator .page-link {
    width: 40px;
    height: 40px;
    padding: 0;
    line-height: 40px;
    border-radius: 50% !important;
    padding: 0;
}

.vendor-dashboard .pagination.pagination-md .page-item .page-link {
    width: 30px;
    height: 30px;
    line-height: 30px;
    font-size: 14px;
}

.vendor-dashboard .pagination.pagination-sm .page-item.page-indicator .page-link {
    font-size: 12px;
}

.vendor-dashboard .pagination.pagination-sm .page-item .page-link {
    padding: 0;
    width: 30px;
    height: 30px;
    line-height: 30px;
    font-size: 14px;
}

.vendor-dashboard .pagination.pagination-xs .page-item.page-indicator .page-link {
    font-size: 10px;
}

.vendor-dashboard .pagination.pagination-xs .page-item .page-link {
    padding: 0;
    width: 25px;
    height: 25px;
    line-height: 25px;
    font-size: 12px;
}

.vendor-dashboard .popover {
    border: 2px solid #392540;
    min-width: 210px;
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1);
}

[data-theme-version=dark] .popover {
    background-color: #181f39;
}

.vendor-dashboard .popover-header {
    background: #392540;
    color: #fff;
    font-weight: 300;
}

.vendor-dashboard .popover-header:before {
    border-bottom: 0 !important;
}

.vendor-dashboard .popover-body {
    font-size: 0.75rem;
}

[data-theme-version=dark] .popover .popover-header {
    border-color: #1e2746;
}

.vendor-dashboard .bootstrap-popover-wrapper .bootstrap-popover:not(:last-child) {
    margin-right: 8px;
}

.vendor-dashboard .bootstrap-popover-wrapper .bootstrap-popover {
    margin-bottom: 0.5rem;
}

.vendor-dashboard .bootstrap-popover-wrapper .bootstrap-popover button:hover,
.vendor-dashboard .bootstrap-popover-wrapper .bootstrap-popover button:focus {
    background: #0e131a;
    color: #fff;
    box-shadow: none;
}

.vendor-dashboard .bs-popover-top .arrow:after,
.vendor-dashboard .bs-popover-auto[x-placement^=top] .arrow:after,
.vendor-dashboard .bs-popover-auto[x-placement^=top] .arrow:after {
    bottom: 0px;
    border-top-color: #392540;
}

.vendor-dashboard .bs-popover-left .arrow:after,
.vendor-dashboard .bs-popover-auto[x-placement^=left] .arrow:after,
.vendor-dashboard .bs-popover-auto[x-placement^=left] .arrow:after {
    right: 0px;
    border-left-color: #392540;
}

.vendor-dashboard .bs-popover-right .arrow:after,
.vendor-dashboard .bs-popover-auto[x-placement^=right] .arrow:after,
.vendor-dashboard .bs-popover-auto[x-placement^=right] .arrow:after {
    left: 0px;
    border-right-color: #392540;
}

.vendor-dashboard .bs-popover-bottom .arrow:after,
.vendor-dashboard .bs-popover-auto[x-placement^=bottom] .arrow:after,
.vendor-dashboard .bs-popover-auto[x-placement^=bottom] .arrow:after {
    top: 0px;
    border-bottom-color: #392540;
}

.vendor-dashboard .note-popover {
    display: none;
}

.vendor-dashboard .progress-bar {
    background-color: #0e131a;
}

.vendor-dashboard .progress-bar-primary {
    background-color: #0e131a;
}

.vendor-dashboard .progress-bar-success {
    background-color: #2bc155;
}

.vendor-dashboard .progress-bar-info {
    background-color: #2F4CDD;
}

.vendor-dashboard .progress-bar-danger {
    background-color: #f35757;
}

.vendor-dashboard .progress-bar-warning {
    background-color: #ffb800;
}

.vendor-dashboard .progress-bar-pink {
    background-color: #e83e8c;
}

.vendor-dashboard .progress {
    height: 6px;
    overflow: hidden;
}

[data-theme-version=dark] .progress {
    background-color: #181f39;
}

.vendor-dashboard .progress-bar {
    border-radius: 4px;
}

.vendor-dashboard .progress-vertical {
    display: inline-block;
    margin-bottom: 0;
    margin-right: 2rem;
    min-height: 17rem;
    position: relative;
}

@media (max-width: 991.98px) {
    .vendor-dashboard .progress-vertical {
        margin-right: 1rem;
    }
}

.vendor-dashboard .progress-vertical-bottom {
    display: inline-block;
    margin-bottom: 0;
    margin-right: 2rem;
    min-height: 17rem;
    position: relative;
    transform: rotate(180deg);
}

@media (max-width: 991.98px) {
    .vendor-dashboard .progress-vertical-bottom {
        margin-right: 1rem;
    }
}

.vendor-dashboard .progress-animated {
    animation-duration: 5s;
    animation-name: myanimation;
    transition: all 5s ease 0s;
}

@keyframes myanimation {
    0% {
        width: 0;
    }
}

@keyframes myanimation {
    0% {
        width: 0;
    }
}

.vendor-dashboard .ribbon {
    position: absolute;
    z-index: 1;
    text-transform: uppercase;
}

.vendor-dashboard .ribbon__one {
    top: 15px;
    left: -11px;
    min-height: 20px;
    min-width: 52px;
    text-align: center;
    padding: 3px 10px;
    background: #3AB54B;
    color: #fff;
    font-size: 0.625rem;
}

.vendor-dashboard .ribbon__one:after {
    position: absolute;
    width: 0;
    height: 0;
    border-top: 10px solid #239132;
    border-left: 11px solid transparent;
    left: 0;
    content: "";
    bottom: -10px;
}

.vendor-dashboard .ribbon__two {
    width: 50px;
    height: 50px;
    display: inline-block;
    background: #ffb800;
    line-height: 50px;
    text-align: center;
    font-size: 16px;
    color: #fff;
    right: 15px;
    top: 15px;
    border-radius: 3px;
}

.vendor-dashboard .ribbon__three {
    left: -1.875rem;
    top: 0.875rem;
    width: 6.875rem;
    height: 1.5625rem;
    background-color: #f35757;
    clip-path: polygon(20% 0%, 80% 0%, 100% 100%, 0% 100%);
    transform: rotate(-45deg);
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
}

.vendor-dashboard .ribbon__four {
    left: 8px;
    top: -8px;
    width: 110px;
    height: 50px;
    background-color: #0e131a;
    z-index: auto;
    color: #fff;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.vendor-dashboard .ribbon__four:after {
    right: -5px;
}

.vendor-dashboard .ribbon__four:before {
    left: -5px;
}

.vendor-dashboard .ribbon__four:after,
.vendor-dashboard .ribbon__four:before {
    z-index: -1;
    background-color: #eb87ab;
    top: 3px;
    transform: rotate(45deg);
    content: "";
    height: 10px;
    width: 10px;
    position: absolute;
}

.vendor-dashboard .ribbon__five {
    left: -1.875rem;
    top: 0.625rem;
    width: 6.875rem;
    height: 1.875rem;
    background-color: #0e131a;
    transform: rotate(-45deg);
    font-size: 0.75rem;
    color: #fff;
    padding-bottom: 0.3125rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
}

.vendor-dashboard .ribbon__five:before {
    position: absolute;
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 50px 50px 50px;
    border-color: transparent transparent #0e131a transparent;
    left: 0.25rem;
    top: -2.8125rem;
}

.vendor-dashboard .ribbon__six {
    left: 0;
    top: 1.125rem;
    width: 6.875rem;
    height: 2.375rem;
    background-color: #0e131a;
    -webkit-clip-path: polygon(0 0, 100% 0%, 100% 0, 85% 50%, 100% 100%, 100% 100%, 0 100%);
    clip-path: polygon(0 0, 100% 0%, 100% 0, 85% 50%, 100% 100%, 100% 100%, 0 100%);
    display: flex;
    font-size: 1.25rem;
    align-items: center;
    justify-content: center;
    color: #fff;
}

.vendor-dashboard .ribbon-curve {
    position: absolute;
    top: 0;
    right: -6px;
    width: 10px;
    height: 11px;
    border-top: 11px solid #3AB54B;
    border-bottom: 10px solid #3AB54B;
    border-right: 5px solid transparent;
}

.vendor-dashboard .jsgrid-grid-body:-webkit-scrollbar,
.vendor-dashboard .dataTables_scrollBody:-webkit-scrollbar,
.vendor-dashboard .table-responsive:-webkit-scrollbar {
    background-color: #F5F5F5;
    width: 8px;
    height: 8px;
}

.vendor-dashboard .jsgrid-grid-body:-webkit-scrollbar-track,
.vendor-dashboard .dataTables_scrollBody:-webkit-scrollbar-track,
.vendor-dashboard .table-responsive:-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #F5F5F5;
}

.vendor-dashboard .jsgrid-grid-body:-webkit-scrollbar-thumb,
.vendor-dashboard .dataTables_scrollBody:-webkit-scrollbar-thumb,
.vendor-dashboard .table-responsive:-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #7e7e7e;
}

.vendor-dashboard .multi-steps > li.is-active:before,
.vendor-dashboard .multi-steps > li.is-active ~ li:before {
    content: counter(stepNum);
    font-family: inherit;
    font-weight: 400;
}

.vendor-dashboard .multi-steps > li.is-active:after,
.vendor-dashboard .multi-steps > li.is-active ~ li:after {
    background-color: #d7dae3;
}

.vendor-dashboard .multi-steps {
    display: table;
    table-layout: fixed;
    width: 100%;
}

.vendor-dashboard .multi-steps > li {
    counter-increment: stepNum;
    text-align: center;
    display: table-cell;
    position: relative;
    color: #0e131a;
}

@media (max-width: 575.98px) {
    .vendor-dashboard .multi-steps > li {
        font-size: 0.75rem;
    }
}

.vendor-dashboard .multi-steps > li:before {
    content: "âœ“";
    display: block;
    margin: 0 auto 4px;
    background-color: #fff;
    width: 25px;
    height: 25px;
    line-height: 22px;
    text-align: center;
    font-weight: bold;
    position: relative;
    z-index: 1;
    border-width: 2px;
    border-style: solid;
    border-color: #0e131a;
    border-radius: 5px;
}

@media (max-width: 575.98px) {
    .vendor-dashboard .multi-steps > li:before {
        width: 25px;
        height: 25px;
        line-height: 21px;
    }
}

.vendor-dashboard .multi-steps > li:after {
    content: "";
    height: 2px;
    width: 100%;
    background-color: #0e131a;
    position: absolute;
    top: 12px;
    left: 50%;
}

@media (max-width: 575.98px) {
    .vendor-dashboard .multi-steps > li:after {
        top: 12px;
    }
}

.vendor-dashboard .multi-steps > li:last-child:after {
    display: none;
}

.vendor-dashboard .multi-steps > li.is-active:before {
    background-color: #fff;
    border-color: #0e131a;
}

.vendor-dashboard .multi-steps > li.is-active ~ li {
    color: #7e7e7e;
}

.vendor-dashboard .multi-steps > li.is-active ~ li:before {
    background-color: #d7dae3;
    border-color: #d7dae3;
}

.vendor-dashboard .nav-pills .nav-link {
    border-radius: 0.75rem;
    padding: 0.75rem 1.25rem;
}

.vendor-dashboard .default-tab .nav-link {
    background: transparent;
    border-radius: 0px;
    font-weight: 500;
}

.vendor-dashboard .default-tab .nav-link i {
    display: inline-block;
    transform: scale(1.5);
    color: #0e131a;
}

.vendor-dashboard .default-tab .nav-link:focus,
.vendor-dashboard .default-tab .nav-link:hover,
.vendor-dashboard .default-tab .nav-link.active {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff #ebeef6;
    border-radius: 0.75rem 0.75rem 0 0;
    color: #0e131a;
}

[data-theme-version=dark] .default-tab .nav-link:focus,
[data-theme-version=dark] .default-tab .nav-link:hover,
[data-theme-version=dark] .default-tab .nav-link.active {
    background-color: rgba(221, 47, 110, 0.2);
    border-color: transparent transparent #333a54 transparent;
}

.vendor-dashboard .custom-tab-1 .nav-link {
    background: transparent;
    border-radius: 0px;
    font-weight: 500;
    border-bottom: 3px solid transparent;
    border-width: 0 0px 3px 0;
}

.vendor-dashboard .custom-tab-1 .nav-link i {
    display: inline-block;
    transform: scale(1.5);
    color: #0e131a;
}

.vendor-dashboard .custom-tab-1 .nav-link:focus,
.vendor-dashboard .custom-tab-1 .nav-link:hover,
.vendor-dashboard .custom-tab-1 .nav-link.active {
    color: #495057;
    background-color: #fff;
    border-color: #0e131a;
    border-radius: 0;
    color: #0e131a;
    border-width: 0 0px 3px 0;
}

[data-theme-version=dark] .custom-tab-1 .nav-link:focus,
[data-theme-version=dark] .custom-tab-1 .nav-link:hover,
[data-theme-version=dark] .custom-tab-1 .nav-link.active {
    background-color: rgba(221, 47, 110, 0.2);
}

.vendor-dashboard .nav-pills.light .nav-link.active,
.vendor-dashboard .nav-pills.light .show > .nav-link {
    background: #fdf4f8;
    color: #0e131a;
    box-shadow: none;
}

[data-theme-version=dark] .nav-pills.light .nav-link.active,
[data-theme-version=dark] .nav-pills.light .show > .nav-link {
    background: rgba(221, 47, 110, 0.2);
}

.vendor-dashboard .nav-pills .nav-link.active,
.vendor-dashboard .nav-pills .show > .nav-link {
    box-shadow: 0 0 20px 0 rgba(221, 47, 110, 0.2);
}

.vendor-dashboard .tooltip-wrapper button:not(:last-child) {
    margin-right: 8px;
}

.vendor-dashboard .tooltip-wrapper button:hover {
    background: #0e131a;
    color: #fff;
}

.vendor-dashboard .tooltip-wrapper button {
    margin-bottom: 0.5rem;
}

.vendor-dashboard .tooltip-wrapper button:focus {
    box-shadow: none;
}

.vendor-dashboard .tooltip-inner {
    border-radius: 0;
    background: #333333;
    font-size: 12px;
    font-weight: 300;
    padding: 0.35rem 0.7rem;
}

.vendor-dashboard .bs-tooltip-bottom .arrow:before,
.vendor-dashboard .bs-tooltip-auto[x-placement^=bottom] .arrow:before,
.vendor-dashboard .bs-tooltip-auto[x-placement^=bottom] .arrow:before {
    border-bottom-color: #333333;
}

.vendor-dashboard .bs-tooltip-right .arrow:before,
.vendor-dashboard .bs-tooltip-auto[x-placement^=right] .arrow:before,
.vendor-dashboard .bs-tooltip-auto[x-placement^=right] .arrow:before {
    border-right-color: #333333;
}

.vendor-dashboard .bs-tooltip-left .arrow:before,
.vendor-dashboard .bs-tooltip-auto[x-placement^=left] .arrow:before,
.vendor-dashboard .bs-tooltip-auto[x-placement^=left] .arrow:before {
    border-left-color: #333333;
}

.vendor-dashboard .bs-tooltip-top .arrow:before,
.vendor-dashboard .bs-tooltip-auto[x-placement^=top] .arrow:before,
.vendor-dashboard .bs-tooltip-auto[x-placement^=top] .arrow:before {
    border-top-color: #333333;
}

.vendor-dashboard .widget-timeline .timeline {
    list-style: none;
    position: relative;
}

.vendor-dashboard .widget-timeline .timeline:before {
    top: 20px;
    bottom: 0;
    position: absolute;
    content: " ";
    width: 3px;
    left: 9px;
    margin-right: -1.5px;
    background: #f4f4f4;
}

[data-theme-version=dark] .widget-timeline .timeline:before {
    background: #333a54;
}

.vendor-dashboard .widget-timeline .timeline > li {
    margin-bottom: 15px;
    position: relative;
}

.vendor-dashboard .widget-timeline .timeline > li:before {
    content: " ";
    display: table;
}

.vendor-dashboard .widget-timeline .timeline > li:after {
    content: " ";
    display: table;
    clear: both;
}

.vendor-dashboard .widget-timeline .timeline > li > .timeline-panel {
    border-radius: 0.75rem;
    padding: 15px 20px;
    position: relative;
    display: block;
    margin-left: 40px;
    border-width: 1px;
    border-style: solid;
}

.vendor-dashboard .widget-timeline .timeline > li > .timeline-panel span {
    font-size: 12px;
    display: block;
    margin-bottom: 5px;
    opacity: 0.8;
    letter-spacing: 1px;
}

.vendor-dashboard .widget-timeline .timeline > li > .timeline-panel p {
    font-size: 14px;
}

.vendor-dashboard .widget-timeline .timeline > li > .timeline-panel:after {
    content: "";
    width: 10px;
    height: 10px;
    background: inherit;
    border-color: inherit;
    border-style: solid;
    border-width: 0px 0px 1px 1px;
    display: block;
    position: absolute;
    left: -5px;
    transform: rotate(45deg);
    top: 15px;
}

.vendor-dashboard .widget-timeline .timeline > li > .timeline-badge {
    border-radius: 50%;
    height: 22px;
    left: 0;
    position: absolute;
    top: 10px;
    width: 22px;
    border-width: 2px;
    border-style: solid;
    background: #fff;
    padding: 4px;
}

[data-theme-version=dark] .widget-timeline .timeline > li > .timeline-badge {
    background-color: #1e2746;
}

.vendor-dashboard .widget-timeline .timeline > li > .timeline-badge:after {
    content: "";
    width: 10px;
    height: 10px;
    border-radius: 100%;
    display: block;
}

.vendor-dashboard .widget-timeline .timeline-body > p {
    font-size: 12px;
}

.vendor-dashboard .widget-timeline .timeline-badge.primary {
    border-color: #fdf4f8;
}

[data-theme-version=dark] .widget-timeline .timeline-badge.primary {
    border-color: rgba(221, 47, 110, 0.2);
}

.vendor-dashboard .widget-timeline .timeline-badge.primary:after {
    background-color: #0e131a !important;
    box-shadow: 0 5px 10px 0 rgba(221, 47, 110, 0.2);
}

.vendor-dashboard .widget-timeline .timeline-badge.primary + .timeline-panel {
    background: #fdf4f8;
    border-color: #fdf4f8;
}

[data-theme-version=dark] .widget-timeline .timeline-badge.primary + .timeline-panel {
    border-color: transparent;
    background-color: rgba(221, 47, 110, 0.2);
}

.vendor-dashboard .widget-timeline .timeline-badge.success {
    border-color: #e7faec;
}

[data-theme-version=dark] .widget-timeline .timeline-badge.success {
    border-color: rgba(43, 193, 85, 0.1);
}

.vendor-dashboard .widget-timeline .timeline-badge.success:after {
    background-color: #2bc155 !important;
    box-shadow: 0 5px 10px 0 rgba(43, 193, 85, 0.2);
}

.vendor-dashboard .widget-timeline .timeline-badge.success + .timeline-panel {
    background: #e7faec;
    border-color: #e7faec;
}

[data-theme-version=dark] .widget-timeline .timeline-badge.success + .timeline-panel {
    background-color: rgba(43, 193, 85, 0.1);
    border-color: transparent;
}

.vendor-dashboard .widget-timeline .timeline-badge.warning {
    border-color: #fff6e0;
}

[data-theme-version=dark] .widget-timeline .timeline-badge.warning {
    border-color: rgba(255, 184, 0, 0.1);
}

.vendor-dashboard .widget-timeline .timeline-badge.warning:after {
    background-color: #ffb800 !important;
    box-shadow: 0 5px 10px 0 rgba(255, 184, 0, 0.2);
}

.vendor-dashboard .widget-timeline .timeline-badge.warning + .timeline-panel {
    background: #fff6e0;
    border-color: #fff6e0;
}

[data-theme-version=dark] .widget-timeline .timeline-badge.warning + .timeline-panel {
    background-color: rgba(255, 184, 0, 0.1);
    border-color: transparent;
}

.vendor-dashboard .widget-timeline .timeline-badge.danger {
    border-color: #fde6e6;
}

[data-theme-version=dark] .widget-timeline .timeline-badge.danger {
    border-color: rgba(243, 87, 87, 0.15);
}

.vendor-dashboard .widget-timeline .timeline-badge.danger:after {
    background-color: #f35757 !important;
    box-shadow: 0 5px 10px 0 rgba(243, 87, 87, 0.2);
}

.vendor-dashboard .widget-timeline .timeline-badge.danger + .timeline-panel {
    background: #fde6e6;
    border-color: #fde6e6;
}

[data-theme-version=dark] .widget-timeline .timeline-badge.danger + .timeline-panel {
    background-color: rgba(243, 87, 87, 0.15);
    border-color: transparent;
}

.vendor-dashboard .widget-timeline .timeline-badge.info {
    border-color: #dee3fa;
}

[data-theme-version=dark] .widget-timeline .timeline-badge.info {
    border-color: rgba(47, 76, 221, 0.1);
}

.vendor-dashboard .widget-timeline .timeline-badge.info:after {
    background-color: #2F4CDD !important;
    box-shadow: 0 5px 10px 0 rgba(47, 76, 221, 0.2);
}

.vendor-dashboard .widget-timeline .timeline-badge.info + .timeline-panel {
    background: #dee3fa;
    border-color: #dee3fa;
}

[data-theme-version=dark] .widget-timeline .timeline-badge.info + .timeline-panel {
    background-color: rgba(47, 76, 221, 0.1);
    border-color: transparent;
}

.vendor-dashboard .widget-timeline .timeline-badge.dark {
    border-color: #dee2e6;
}

[data-theme-version=dark] .widget-timeline .timeline-badge.dark {
    border-color: rgba(62, 73, 84, 0.35);
}

.vendor-dashboard .widget-timeline .timeline-badge.dark:after {
    background-color: #3e4954 !important;
    box-shadow: 0 5px 10px 0 rgba(62, 73, 84, 0.2);
}

.vendor-dashboard .widget-timeline .timeline-badge.dark + .timeline-panel {
    background: #dee2e6;
    border-color: #dee2e6;
}

[data-theme-version=dark] .widget-timeline .timeline-badge.dark + .timeline-panel {
    background-color: rgba(62, 73, 84, 0.35);
    border-color: transparent;
}

.vendor-dashboard .widget-timeline.style-1 .timeline-panel {
    background: transparent;
}

.vendor-dashboard .widget-timeline.style-1 .timeline .timeline-badge.timeline-badge + .timeline-panel {
    background: transparent !important;
    border-style: solid;
    border-width: 0 0 0 5px;
    border-radius: 0;
    padding: 5px 10px 5px 15px;
}

.vendor-dashboard .widget-timeline.style-1 .timeline .timeline-badge.timeline-badge + .timeline-panel:after {
    border: 0;
    left: -9px;
    width: 7px;
    height: 7px;
}

.vendor-dashboard .widget-timeline.style-1 .timeline .timeline-badge.primary + .timeline-panel {
    border-color: #0e131a;
}

.vendor-dashboard .widget-timeline.style-1 .timeline .timeline-badge.primary + .timeline-panel:after {
    background: #0e131a;
}

.vendor-dashboard .widget-timeline.style-1 .timeline .timeline-badge.success + .timeline-panel {
    border-color: #2bc155;
}

.vendor-dashboard .widget-timeline.style-1 .timeline .timeline-badge.success + .timeline-panel:after {
    background: #2bc155;
}

.vendor-dashboard .widget-timeline.style-1 .timeline .timeline-badge.warning + .timeline-panel {
    border-color: #ffb800;
}

.vendor-dashboard .widget-timeline.style-1 .timeline .timeline-badge.warning + .timeline-panel:after {
    background: #ffb800;
}

.vendor-dashboard .widget-timeline.style-1 .timeline .timeline-badge.danger + .timeline-panel {
    border-color: #f35757;
}

.vendor-dashboard .widget-timeline.style-1 .timeline .timeline-badge.danger + .timeline-panel:after {
    background: #f35757;
}

.vendor-dashboard .widget-timeline.style-1 .timeline .timeline-badge.info + .timeline-panel {
    border-color: #2F4CDD;
}

.vendor-dashboard .widget-timeline.style-1 .timeline .timeline-badge.info + .timeline-panel:after {
    background: #2F4CDD;
}

.vendor-dashboard .widget-timeline.style-1 .timeline .timeline-badge.dark + .timeline-panel {
    border-color: #3e4954;
}

.vendor-dashboard .widget-timeline.style-1 .timeline .timeline-badge.dark + .timeline-panel:after {
    background: #3e4954;
}

.vendor-dashboard #chart_widget_4 {
    height: 255px !important;
}

.vendor-dashboard #chart_widget_5 .ct-series-a .ct-line,
.vendor-dashboard #chart_widget_5 .ct-series-a .ct-point {
    stroke: rgba(255, 255, 255, 0.5);
}

.vendor-dashboard #chart_widget_5 .ct-line {
    stroke-width: 1px;
}

.vendor-dashboard #chart_widget_5 .ct-point {
    stroke-width: 2px;
}

.vendor-dashboard #chart_widget_5 .ct-series-a .ct-area {
    fill: rgba(255, 255, 255, 0.1);
}

.vendor-dashboard #chart_widget_5 .ct-area {
    fill-opacity: 1;
}

.vendor-dashboard #chart_widget_6 .ct-series-a .ct-line,
.vendor-dashboard #chart_widget_6 .ct-series-a .ct-point {
    stroke: #2F4CDD;
}

.vendor-dashboard #chart_widget_6 .ct-line {
    stroke-width: 2px;
}

.vendor-dashboard #chart_widget_6 .ct-point {
    stroke-width: 5px;
}

.vendor-dashboard #chart_widget_6 .ct-series-a .ct-area {
    fill: #2F4CDD;
}

.vendor-dashboard #chart_widget_6 .ct-area {
    fill-opacity: 0.5;
}

.vendor-dashboard #chart_widget_8 {
    height: 255px;
}

.vendor-dashboard #chart_widget_8 .ct-series-a .ct-line,
.vendor-dashboard #chart_widget_8 .ct-series-a .ct-point {
    stroke: #2F4CDD;
}

.vendor-dashboard #chart_widget_8 .ct-line {
    stroke-width: 2px;
}

.vendor-dashboard #chart_widget_8 .ct-point {
    stroke-width: 5px;
}

.vendor-dashboard #chart_widget_8 .ct-series-a .ct-area {
    fill: #2F4CDD;
}

.vendor-dashboard #chart_widget_8 .ct-area {
    fill-opacity: 0.5;
}

.vendor-dashboard #chart_widget_9,
.vendor-dashboard #chart_widget_10 {
    height: 250px !important;
}

.vendor-dashboard #chart_widget_11 .ct-slice-donut,
.vendor-dashboard #chart_widget_12 .ct-slice-donut,
.vendor-dashboard #chart_widget_13 .ct-slice-donut {
    stroke-width: 25px !important;
}

.vendor-dashboard #chart_widget_11 {
    height: 270px !important;
}

.vendor-dashboard #chart_widget_17 {
    height: 150px !important;
}

.vendor-dashboard .chart_widget_tab_one .nav-link {
    border: 1px solid #ddd;
}

.vendor-dashboard .chart_widget_tab_one .nav-link.active {
    background-color: #0e131a;
    border: 1px solid #0e131a;
    color: #fff;
}

.vendor-dashboard .chart_widget_tab_one .nav-link.active:hover {
    border: 1px solid #0e131a;
}

.vendor-dashboard .chart_widget_tab_one .nav-link:hover {
    border: 1px solid #ddd;
}

[data-theme-version=dark] .ccc-widget > div {
    background: #1e2746 !important;
    border-color: #333a54 !important;
}

.vendor-dashboard .social-icon {
    display: inline-block;
    width: 40px;
    height: 40px;
    line-height: 40px;
    border-radius: 4px;
    text-align: center;
    background: #ffffff;
    margin-bottom: 0.5rem;
    font-size: 20px;
}

.vendor-dashboard .social-icon i {
    color: #fff;
}

.vendor-dashboard .social-icon.youtube {
    background: #FF0000;
}

.vendor-dashboard .social-icon.facebook {
    background: #3b5998;
}

.vendor-dashboard .social-icon.twitter {
    background: #1da1f2;
}

.vendor-dashboard .social-icon2 a {
    border: 0.1rem solid #0e131a;
    display: block;
    padding: 1rem;
    margin-top: 1.5rem;
    margin-bottom: 0.5rem;
    border-radius: 0.3rem;
    font-size: 1.6rem;
}

.vendor-dashboard .social-icon2 i {
    font-size: 12px;
    color: #0e131a;
}

.vendor-dashboard .social-icon3 ul li {
    display: inline-block;
}

.vendor-dashboard .social-icon3 ul li a {
    display: block;
}

.vendor-dashboard .social-icon3 ul li a i {
    font-size: 1.4rem;
    padding: 1rem 0.7rem;
    color: #464a53;
}

.vendor-dashboard .social-icon3 ul li a:hover i {
    color: #0e131a;
}

.vendor-dashboard .social-icons-muted ul li a i {
    color: #89879f;
}

.vendor-dashboard .social-links a {
    padding: 0.5rem;
}

.vendor-dashboard .widget-stat .media {
    padding: 0px 0;
    align-items: center;
}

.vendor-dashboard .widget-stat .media > span {
    height: 85px;
    width: 85px;
    border-radius: 50px;
    padding: 10px 12px;
    font-size: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #464a53;
    min-width: 85px;
}

.vendor-dashboard .widget-stat .media .media-body p {
    text-transform: uppercase;
    font-weight: 500;
    font-size: 14px;
}

[data-theme-version=dark] .widget-stat .media .media-body p {
    color: #c4c9d5;
}

.vendor-dashboard .widget-stat .media .media-body h3 {
    font-size: 40px;
    font-weight: 600;
    margin: 0;
    line-height: 1.2;
}

.vendor-dashboard .widget-stat .media .media-body h4 {
    font-size: 24px;
    display: inline-block;
    vertical-align: middle;
}

.vendor-dashboard .widget-stat .media .media-body span {
    margin-left: 5px;
}

.vendor-dashboard .widget-stat[class*=bg-] .media > span {
    background-color: rgba(255, 255, 255, 0.25);
    color: #fff;
}

.vendor-dashboard .widget-stat[class*=bg-] .progress {
    background-color: rgba(255, 255, 255, 0.25) !important;
}

.vendor-dashboard .dez-widget-1 .card {
    background: #FFE7DB;
}

.vendor-dashboard .dez-widget-1 .card .card-body p {
    color: #f87533;
}

.vendor-dashboard .dez-widget-1 .icon {
    float: right;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    font-size: 28px;
}

.vendor-dashboard .bgl-primary {
    background: #fdf4f8;
    border-color: #fdf4f8;
}

[data-theme-version=dark] .bgl-primary {
    background-color: rgba(221, 47, 110, 0.2);
    border-color: rgba(221, 47, 110, 0.2);
}

[data-theme-version=dark] .bgl-secondary {
    background-color: rgba(57, 37, 64, 0.5);
    border-color: rgba(57, 37, 64, 0.5);
}

.vendor-dashboard .bgl-success {
    background: #e7faec;
    border-color: #e7faec;
}

.vendor-dashboard .bgl-warning {
    background: #fff6e0;
    border-color: #fff6e0;
}

.vendor-dashboard .bgl-danger {
    background: #fde6e6;
    border-color: #fde6e6;
}

.vendor-dashboard .bgl-info {
    background: #dee3fa;
    border-color: #dee3fa;
}

[data-theme-version=dark] .bgl-info {
    background-color: rgba(47, 76, 221, 0.1);
    border-color: rgba(47, 76, 221, 0.1);
}

.vendor-dashboard .bg-primary-light {
    background: rgba(253, 244, 248, 0.5);
}

.vendor-dashboard .bg-secondary-light {
    background: rgba(232, 222, 236, 0.5);
}

.vendor-dashboard .bg-success-light {
    background: rgba(231, 250, 236, 0.5);
}

.vendor-dashboard .bg-warning-light {
    background: rgba(255, 246, 224, 0.5);
}

.vendor-dashboard .bg-danger-light {
    background: rgba(253, 230, 230, 0.5);
}

.vendor-dashboard .bg-info-light {
    background: rgba(222, 227, 250, 0.5);
}

[data-theme-version=dark] .bg-info-light {
    background-color: rgba(47, 76, 221, 0.05);
}

.vendor-dashboard .bgl-dark {
    background: #dee2e6;
    border-color: #dee2e6;
}

.vendor-dashboard .bgl-light {
    background: #f4f4f4;
    border-color: #f4f4f4;
}

.vendor-dashboard .overlay-box {
    position: relative;
    z-index: 1;
}

.vendor-dashboard .overlay-box:after {
    content: "";
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    position: absolute;
    opacity: 0.85;
    background: #0e131a;
    z-index: -1;
}

.vendor-dashboard .rating-bar {
    font-size: 13px;
}

.vendor-dashboard .tdl-holder {
    margin: 0 auto;
}

.vendor-dashboard .tdl-holder ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.vendor-dashboard .tdl-holder li {
    background-color: #fff;
    border-bottom: 1px solid #d7dae3;
    list-style: none none;
    margin: 0;
    padding: 0;
}

.vendor-dashboard .tdl-holder li span {
    margin-left: 35px;
    font-size: 1rem;
    vertical-align: middle;
    transition: all 0.2s linear;
}

.vendor-dashboard .tdl-holder label {
    cursor: pointer;
    display: block;
    line-height: 50px;
    padding-left: 1.5rem;
    position: relative;
    margin: 0 !important;
}

.vendor-dashboard .tdl-holder label:hover {
    background-color: #eef1f6;
    color: #3e4954;
}

.vendor-dashboard .tdl-holder label:hover a {
    color: #f35757;
}

.vendor-dashboard .tdl-holder label a {
    color: #fff;
    display: inline-block;
    line-height: normal;
    height: 100%;
    text-align: center;
    text-decoration: none;
    width: 50px;
    -webkit-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    transition: all 0.2s linear;
    padding: 18px 0px;
    font-size: 18px;
    position: absolute;
    right: 0;
}

.vendor-dashboard .tdl-holder input[type=checkbox] {
    cursor: pointer;
    opacity: 0;
    position: absolute;
}

.vendor-dashboard .tdl-holder input[type=checkbox] + i {
    background-color: #fff;
    border: 1px solid #e7e7e7;
    display: block;
    height: 20px;
    position: absolute;
    top: 15px;
    width: 20px;
    z-index: 1;
    border-radius: 0px;
    font-size: 11px;
    border-radius: 4px;
}

.vendor-dashboard .tdl-holder input[type=checkbox]:checked + i {
    background: #0e131a;
    border-color: transparent;
}

.vendor-dashboard .tdl-holder input[type=checkbox]:checked + i:after {
    content: "\f00c";
    font-family: "fontAwesome";
    display: block;
    left: 2px;
    position: absolute;
    top: -14px;
    z-index: 2;
    color: #fff;
}

.vendor-dashboard .tdl-holder input[type=checkbox]:checked ~ span {
    text-decoration: line-through;
    position: relative;
}

.vendor-dashboard .tdl-holder input[type=text] {
    background-color: #fff;
    height: 50px;
    margin-top: 29px;
    border-radius: 3px;
}

.vendor-dashboard .tdl-holder input[type=text]:placeholder {
    color: #3e4954;
}

.vendor-dashboard .widget-message p {
    font-size: 14px;
    line-height: 1.5;
}

.vendor-dashboard .picker .picker__frame {
    min-width: 450px !important;
    max-width: 450px !important;
}

.vendor-dashboard .picker .picker__frame .picker__box {
    padding: 20px;
    border: 0;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
}

.vendor-dashboard .picker .picker__frame .picker__box .picker__header {
    margin-top: 0;
}

.vendor-dashboard .picker .picker__frame .picker__box .picker__header .picker__year {
    color: #0e131a;
    font-style: inherit;
    font-size: 20px;
}

.vendor-dashboard .picker .picker__frame .picker__box .picker__header .picker__nav--prev,
.vendor-dashboard .picker .picker__frame .picker__box .picker__header .picker__nav--next {
    width: 40px;
    height: 40px;
    padding: 0;
    line-height: 40px;
    border-radius: 2px;
}

.vendor-dashboard .picker .picker__frame .picker__box .picker__header .picker__nav--prev:before,
.vendor-dashboard .picker .picker__frame .picker__box .picker__header .picker__nav--next:before {
    content: none !important;
}

.vendor-dashboard .picker .picker__frame .picker__box .picker__header .picker__nav--prev:after,
.vendor-dashboard .picker .picker__frame .picker__box .picker__header .picker__nav--next:after {
    font-family: fontawesome;
    border: 0;
    margin: 0 auto;
    font-size: 22px;
}

.vendor-dashboard .picker .picker__frame .picker__box .picker__header .picker__nav--prev:hover,
.vendor-dashboard .picker .picker__frame .picker__box .picker__header .picker__nav--next:hover {
    background-color: #f2b3ca !important;
    color: #0e131a !important;
}

.vendor-dashboard .picker .picker__frame .picker__box .picker__header .picker__nav--prev {
    left: 0;
}

.vendor-dashboard .picker .picker__frame .picker__box .picker__header .picker__nav--prev:after {
    content: "ïƒ™";
}

.vendor-dashboard .picker .picker__frame .picker__box .picker__header .picker__nav--next {
    right: 0;
}

.vendor-dashboard .picker .picker__frame .picker__box .picker__header .picker__nav--next:after {
    content: "ïƒš";
}

.vendor-dashboard .picker .picker__frame .picker__box .picker__table .picker__weekday {
    padding: 10px 0;
    font-size: 16px;
}

.vendor-dashboard .picker .picker__frame .picker__box .picker__table .picker__day {
    width: 40px;
    height: 40px;
    border-radius: 50px;
    padding: 0 !important;
    font-size: 16px;
    line-height: 40px;
    margin: auto;
    border: 0 !important;
}

.vendor-dashboard .picker .picker__frame .picker__box .picker__table .picker__day.picker__day--today:before {
    content: none !important;
}

.vendor-dashboard .picker .picker__frame .picker__box .picker__table .picker__day.picker__day--highlighted {
    border: 0 !important;
    padding: 0;
    background-color: #f2b3ca !important;
    color: #fff !important;
}

.vendor-dashboard .picker .picker__frame .picker__box .picker__table .picker__day:hover {
    background-color: #f2b3ca !important;
    color: #fff !important;
}

.vendor-dashboard .picker .picker__frame .picker__box .picker__footer .picker__button--today {
    background-color: #f2b3ca !important;
    color: #fff !important;
}

.vendor-dashboard .picker .picker__frame .picker__box .picker__footer .picker__button--today:before {
    content: none !important;
}

.vendor-dashboard .picker .picker__frame .picker__box .picker__footer .picker__button--clear,
.vendor-dashboard .picker .picker__frame .picker__box .picker__footer .picker__button--close,
.vendor-dashboard .picker .picker__frame .picker__box .picker__footer .picker__button--today {
    border: 0;
    border-radius: 2px;
    font-size: 16px;
}

.vendor-dashboard .picker .picker__frame .picker__box .picker__footer .picker__button--clear:hover,
.vendor-dashboard .picker .picker__frame .picker__box .picker__footer .picker__button--close:hover,
.vendor-dashboard .picker .picker__frame .picker__box .picker__footer .picker__button--today:hover {
    background-color: #f2b3ca !important;
    color: #fff !important;
}

@media only screen and (max-width: 575px) {
    .vendor-dashboard .picker .picker__frame {
        min-width: 100% !important;
        max-width: 100% !important;
    }

    .vendor-dashboard .picker .picker__frame .picker__box {
        padding: 15px;
        margin: 0 10px;
    }
}

.vendor-dashboard .card-list {
    overflow: unset;
    height: calc(100% - 50px);
}

.vendor-dashboard .card-list .card-header {
    padding-top: 0;
    padding-bottom: 0;
}

.vendor-dashboard .card-list .card-header .photo {
    overflow: hidden;
    border-radius: 5px;
    width: 100%;
}

@media only screen and (max-width: 1199px) {
    .vendor-dashboard .card-list {
        height: calc(100% - 40px);
    }
}

.vendor-dashboard .card-list.list-left {
    margin-top: 15px;
    margin-left: 15px;
}

.vendor-dashboard .card-list.list-left .card-header {
    padding-left: 0;
}

.vendor-dashboard .card-list.list-left .card-header .photo {
    margin: -15px 15px 0 -15px;
}

.vendor-dashboard .card-list.list-right {
    margin-top: 15px;
    margin-right: 15px;
    text-align: right;
}

.vendor-dashboard .card-list.list-right .card-header {
    padding-right: 0;
}

.vendor-dashboard .card-list.list-right .card-header .photo {
    margin: -15px -15px 0 15px;
}

.vendor-dashboard .card-list.list-right .card-header .photo img {
    width: 100%;
}

.vendor-dashboard .card-list.list-center {
    margin-top: 15px;
}

.vendor-dashboard .card-list.list-center .card-header .photo {
    margin: -15px 0 0 0;
}

.vendor-dashboard .card-list .photo img {
    width: 100%;
}

.vendor-dashboard .card-profile .profile-photo {
    margin-top: -20px;
}

.vendor-dashboard .widget-media .timeline .timeline-panel {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #eaeaea;
    padding-bottom: 15px;
    margin-bottom: 15px;
}

.vendor-dashboard .widget-media .timeline .timeline-panel .media {
    width: 50px;
    height: 50px;
    background: #eee;
    border-radius: 12px;
    overflow: hidden;
    font-size: 20px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    align-self: start;
}

.vendor-dashboard .widget-media .timeline .timeline-panel .media-primary {
    background: #fdf4f8;
    color: #0e131a;
}

[data-theme-version=dark] .widget-media .timeline .timeline-panel .media-primary {
    background-color: rgba(221, 47, 110, 0.2);
}

.vendor-dashboard .widget-media .timeline .timeline-panel .media-info {
    background: #dee3fa;
    color: #2F4CDD;
}

[data-theme-version=dark] .widget-media .timeline .timeline-panel .media-info {
    background-color: rgba(47, 76, 221, 0.1);
}

.vendor-dashboard .widget-media .timeline .timeline-panel .media-warning {
    background: #fff6e0;
    color: #ffb800;
}

[data-theme-version=dark] .widget-media .timeline .timeline-panel .media-warning {
    background-color: rgba(255, 184, 0, 0.1);
}

.vendor-dashboard .widget-media .timeline .timeline-panel .media-danger {
    background: #fde6e6;
    color: #f35757;
}

[data-theme-version=dark] .widget-media .timeline .timeline-panel .media-danger {
    background-color: rgba(243, 87, 87, 0.15);
}

.vendor-dashboard .widget-media .timeline .timeline-panel .media-success {
    background: #e7faec;
    color: #2bc155;
}

[data-theme-version=dark] .widget-media .timeline .timeline-panel .media-success {
    background-color: rgba(43, 193, 85, 0.1);
}

.vendor-dashboard .widget-media .timeline .timeline-panel .media-body p {
    font-size: 14px;
    line-height: 1.5;
}

.vendor-dashboard .widget-media .timeline .timeline-panel .dropdown {
    align-self: self-end;
    margin-top: 5px;
}

.vendor-dashboard .widget-media .timeline li:last-child .timeline-panel {
    margin-bottom: 0;
    border-bottom: 0;
    padding-bottom: 0;
}

.vendor-dashboard .card[class*=bg-] .timeline .timeline-panel {
    border-color: rgba(255, 255, 255, 0.2) !important;
}

.vendor-dashboard .table {
    color: #3e4954;
}

.vendor-dashboard .table th {
    padding: 25px 30px;
    border-bottom: 1px solid rgba(215, 218, 227, 0.3);
    border-top: none;
    color: #ff3a63;
}

@media only screen and (max-width: 767px) {
    .vendor-dashboard .table th {
        padding: 10px 15px;
    }
}

.vendor-dashboard .table td {
    padding: 15px 30px;
    border-color: rgba(215, 218, 227, 0.5);
    border-bottom: 1px solid;
    border-top: none;
}

@media only screen and (max-width: 767px) {
    .vendor-dashboard .table td {
        padding: 10px 15px;
    }
}

[data-theme-version=dark] .table td {
    border-color: #333a54;
}

.vendor-dashboard .table.table-striped tbody tr:nth-of-type(odd),
.vendor-dashboard .table.table-hover tr:hover {
    background-color: #f2f4fa;
}

[data-theme-version=dark] .table.table-striped tbody tr:nth-of-type(odd),
[data-theme-version=dark] .table.table-hover tr:hover {
    background-color: #181f39;
}

.vendor-dashboard .table thead th {
    border-bottom-width: 1px;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.5px;
    border-color: rgba(215, 218, 227, 0.3);
    color: #151515;
    /*white-space: nowrap;*/
}

.vendor-dashboard .table tbody tr td {
    vertical-align: middle;
    border-color: rgba(215, 218, 227, 0.3);
    font-size: 14px;
    /*white-space:nowrap;*/
}

[data-theme-version=dark] .table tbody tr td {
    border-color: #333a54;
}

.vendor-dashboard .table:not(.table-bordered) thead th {
    border-top: none;
}

.vendor-dashboard .table .thead-primary th {
    background-color: #0e131a;
    color: #fff;
}

.vendor-dashboard .table .thead-info th {
    background-color: #2F4CDD;
    color: #fff;
}

.vendor-dashboard .table .thead-warning th {
    background-color: #ffb800;
    color: #fff;
}

.vendor-dashboard .table .thead-danger th {
    background-color: #f35757;
    color: #fff;
}

.vendor-dashboard .table .thead-success th {
    background-color: #2bc155;
    color: #fff;
}

.vendor-dashboard .table.primary-table-bordered {
    border: 1px solid #d7dae3;
}

[data-theme-version=dark] .table.primary-table-bordered {
    border-color: #333a54;
}

.vendor-dashboard .table.primary-table-bg-hover thead th {
    background-color: #d02261;
    color: #fff;
    border-bottom: none;
}

.vendor-dashboard .table.primary-table-bg-hover tbody tr {
    background-color: #0e131a;
    color: #fff;
    transition: all 0.1s ease;
}

.vendor-dashboard .table.primary-table-bg-hover tbody tr:hover {
    background-color: #e1457d;
}

.vendor-dashboard .table.primary-table-bg-hover tbody tr:not(:last-child) td,
.vendor-dashboard .table.primary-table-bg-hover tbody tr:not(:last-child) th {
    border-bottom: 1px solid #d02261;
}

.vendor-dashboard .table-responsive-tiny {
    min-width: 18.75rem;
}

.vendor-dashboard .table-responsive-sm {
    min-width: 30rem !important;
}

.vendor-dashboard .table-responsive-md {
    min-width: 36rem !important;
}

.vendor-dashboard .table-responsive-lg {
    min-width: 60.9375rem !important;
}

.vendor-dashboard .table-responsive-xl {
    min-width: 70.9375rem !important;
}

.vendor-dashboard .table-primary,
.vendor-dashboard .table-primary > th,
.vendor-dashboard .table-primary > td {
    background-color: #fdf4f8;
    color: #0e131a;
}

[data-theme-version=dark] .table-primary,
[data-theme-version=dark] .table-primary > th,
[data-theme-version=dark] .table-primary > td {
    background-color: rgba(221, 47, 110, 0.2);
}

.vendor-dashboard .table-success,
.vendor-dashboard .table-success > th,
.vendor-dashboard .table-success > td {
    background-color: #e7faec;
    color: #2bc155;
}

[data-theme-version=dark] .table-success,
[data-theme-version=dark] .table-success > th,
[data-theme-version=dark] .table-success > td {
    background-color: rgba(43, 193, 85, 0.1);
}

.vendor-dashboard .table-info,
.vendor-dashboard .table-info > th,
.vendor-dashboard .table-info > td {
    background-color: #dee3fa;
    color: #2F4CDD;
}

[data-theme-version=dark] .table-info,
[data-theme-version=dark] .table-info > th,
[data-theme-version=dark] .table-info > td {
    background-color: rgba(47, 76, 221, 0.1);
}

.vendor-dashboard .table-warning,
.vendor-dashboard .table-warning > th,
.vendor-dashboard .table-warning > td {
    background-color: #fff6e0;
    color: #ffb800;
}

[data-theme-version=dark] .table-warning,
[data-theme-version=dark] .table-warning > th,
[data-theme-version=dark] .table-warning > td {
    background-color: rgba(255, 184, 0, 0.1);
}

.vendor-dashboard .table-danger,
.vendor-dashboard .table-danger > th,
.vendor-dashboard .table-danger > td {
    background-color: #fde6e6;
    color: #f35757;
}

[data-theme-version=dark] .table-danger,
[data-theme-version=dark] .table-danger > th,
[data-theme-version=dark] .table-danger > td {
    background-color: rgba(243, 87, 87, 0.15);
}

.vendor-dashboard .table-active,
.vendor-dashboard .table-active > th,
.vendor-dashboard .table-active > td {
    background-color: #f2f4fa;
}

[data-theme-version=dark] .table-active,
[data-theme-version=dark] .table-active > th,
[data-theme-version=dark] .table-active > td {
    background-color: #181f39;
}

.vendor-dashboard .bootgrid-header {
    padding: 0 !important;
    margin: 0;
}

@media only screen and (max-width: 575px) {
    .vendor-dashboard .bootgrid-header .actionBar {
        padding: 0;
    }

    .vendor-dashboard .bootgrid-header .search {
        margin: 0 0 10px 0;
    }
}

.vendor-dashboard table#example {
    padding: 0rem 0 2rem 0;
}

.vendor-dashboard table.dataTable {
    color: #3e4954;
}

.vendor-dashboard #example2_wrapper .dataTables_scrollBody {
    max-height: 33.25rem !important;
}

.vendor-dashboard #employees,
.vendor-dashboard #custommers {
    padding: 0.5rem 0 1rem 0;
}

.vendor-dashboard .dataTables_wrapper .dataTables_paginate {
    padding: 10px 0;
}

@media only screen and (max-width: 1199px) {
    .vendor-dashboard .dataTables_wrapper .dataTables_paginate {
        padding: 5px 0;
    }
}

.vendor-dashboard table.dataTable thead th,
.vendor-dashboard table.dataTable thead td {
    padding: 15px 30px;
    border-bottom: 1px solid #d7dae3;
}

@media only screen and (max-width: 1199px) {
    .vendor-dashboard table.dataTable thead th,
    .vendor-dashboard table.dataTable thead td {
        padding: 8px 15px;
    }
}

.vendor-dashboard table.dataTable tfoot th,
.vendor-dashboard table.dataTable tfoot td {
    border-top: 0;
}

.vendor-dashboard table.dataTable tbody tr,
.vendor-dashboard table.dataTable tbody td {
    background: transparent !important;
}

.vendor-dashboard table.dataTable thead th {
    color: #000;
    font-size: 18px;
    font-weight: 600;
}

[data-theme-version=dark] table.dataTable thead th {
    color: #fff;
}

@media only screen and (max-width: 1199px) {
    .vendor-dashboard table.dataTable thead th {
        font-size: 16px;
    }
}

.vendor-dashboard table.dataTable tbody td {
    padding: 15px 30px;
}

@media only screen and (max-width: 1199px) {
    .vendor-dashboard table.dataTable tbody td {
        padding: 8px 15px;
    }
}

.vendor-dashboard table.dataTable tr.selected {
    color: #0e131a;
}

.vendor-dashboard table.dataTable tfoot th {
    color: #3e4954;
    font-weight: 600;
}

[data-theme-version=dark] table.dataTable tfoot th {
    color: #fff;
}

.vendor-dashboard .dataTables_wrapper input[type=search],
.vendor-dashboard .dataTables_wrapper input[type=text],
.vendor-dashboard .dataTables_wrapper select {
    border: 1px solid #e2e2e2;
    padding: 0.3rem 0.5rem;
    color: #715d5d;
    border-radius: 5px;
}

[data-theme-version=dark] .dataTables_wrapper input[type=search],
[data-theme-version=dark] .dataTables_wrapper input[type=text],
[data-theme-version=dark] .dataTables_wrapper select {
    background: #181f39;
    border-color: #333a54;
    color: #fff;
}

.vendor-dashboard .dataTables_wrapper .dataTables_length {
    margin-bottom: 15px;
}

.vendor-dashboard .dataTables_wrapper .dataTables_length .bootstrap-select {
    width: 80px !important;
    margin: 0 5px;
}

.vendor-dashboard table.dataTable.no-footer {
    border-bottom: 0;
}

.vendor-dashboard .rounded-lg {
    min-width: 30px;
}

.vendor-dashboard .dataTables_scroll {
    padding: 1rem 0;
}

.vendor-dashboard .dataTables_scrollFoot {
    padding-top: 1rem;
}

@media (max-width: 991.98px) {
    .vendor-dashboard .dataTables_wrapper .dataTables_length,
    .vendor-dashboard .dataTables_wrapper .dataTables_filter {
        text-align: left;
    }
}

.vendor-dashboard .dataTablesCard {
    background-color: #fff;
    box-shadow: 0px 12px 23px 0px rgba(62, 73, 84, 0.04);
    border-radius: 20px;
}

.vendor-dashboard .dataTablesCard tbody tr:hover {
    background: #f5f7ff;
}

.vendor-dashboard .dataTables_wrapper .dataTables_info {
    padding: 10px 0;
}

@media only screen and (max-width: 1199px) {
    .vendor-dashboard .dataTables_wrapper .dataTables_info {
        padding: 8px 0;
    }
}

.vendor-dashboard .no-data-img {
    background-image: none !important;
}

.vendor-dashboard table.dataTable.row-border tbody th,
.vendor-dashboard table.dataTable.row-border tbody td,
.vendor-dashboard table.dataTable.display tbody th,
.vendor-dashboard table.dataTable.display tbody td {
    border-color: #d7dae3;
}

[data-theme-version=dark] table.dataTable.row-border tbody th,
[data-theme-version=dark] table.dataTable.row-border tbody td,
[data-theme-version=dark] table.dataTable.display tbody th,
[data-theme-version=dark] table.dataTable.display tbody td {
    border-color: #333a54;
}

.vendor-dashboard table.dataTable thead .sorting {
    background-position: center right 15px;
}

.vendor-dashboard .dataTables_wrapper .dataTables_paginate span {
    background: #e3e4eb;
    border-radius: 0.75rem;
    display: inline-block;
    margin: 0 10px;
}

.vendor-dashboard .dataTables_wrapper .dataTables_paginate span a {
    color: #0e131a;
    background: transparent !important;
}

.vendor-dashboard .dataTables_wrapper .dataTables_paginate .paginate_button {
    border: 0 !important;
    padding: 10px 20px;
    background: #000;
    border-radius: 6px;
    color: #0e131a !important;
    font-size: 16px;
    display: inline-block;
}

@media only screen and (max-width: 575px) {
    .vendor-dashboard .dataTables_wrapper .dataTables_paginate .paginate_button {
        padding: 8px 15px;
        font-size: 14px;
    }
}

.vendor-dashboard .dataTables_wrapper .dataTables_paginate .paginate_button:hover,
.vendor-dashboard .dataTables_wrapper .dataTables_paginate .paginate_button.current {
    color: #392540 !important;
}

.vendor-dashboard .dataTables_wrapper .dataTables_paginate .paginate_button.previous,
.vendor-dashboard .dataTables_wrapper .dataTables_paginate .paginate_button.next {
    background: #969ba0;
    color: #fff !important;
}

.vendor-dashboard .dataTables_wrapper .dataTables_paginate .paginate_button.previous:hover,
.vendor-dashboard .dataTables_wrapper .dataTables_paginate .paginate_button.next:hover {
    background: #0e131a;
    color: #fff !important;
}

.vendor-dashboard .fooicon {
    font-size: 1.25rem;
    color: #3e4954;
}

.vendor-dashboard .jsgrid-table .jsgrid-header-row > .jsgrid-header-cell {
    color: #3e4954;
}

.vendor-dashboard .jsgrid-table > tbody > tr > td {
    padding: 1.2em;
}

.vendor-dashboard .jsgrid-table .jsgrid-insert-row input,
.vendor-dashboard .jsgrid-table .jsgrid-insert-row select,
.vendor-dashboard .jsgrid-table .jsgrid-edit-row input,
.vendor-dashboard .jsgrid-table .jsgrid-edit-row select {
    border: 1px solid #DDDFE1;
}

.vendor-dashboard .jsgrid .jsgrid-button {
    border: 0 !important;
    margin-left: 10px;
}

.vendor-dashboard .error-page .error-text {
    font-size: 150px;
    line-height: 1;
}

@media only screen and (max-width: 575px) {
    .vendor-dashboard .error-page .error-text {
        font-size: 80px;
    }
}

.vendor-dashboard .error-page h4 {
    font-size: 40px;
    margin-bottom: 5px;
}

@media only screen and (max-width: 575px) {
    .vendor-dashboard .error-page h4 {
        font-size: 20px;
    }
}

.vendor-dashboard .error-page p {
    font-size: 16px;
}

@media only screen and (max-width: 575px) {
    .vendor-dashboard .error-page p {
        font-size: 14px;
    }
}

.vendor-dashboard .authincation-content {
    background: #fff;
    box-shadow: 0 0 35px 0 rgba(154, 161, 171, 0.15);
    border-radius: 5px;
}

[data-theme-version=dark] .authincation-content {
    background: #1e2746;
    box-shadow: none;
}

.vendor-dashboard .welcome-content {
    /* background: url("../images/1.jpg"); */
    background-size: cover;
    background-position: center;
    height: 100%;
    padding: 75px 50px;
    position: relative;
    z-index: 1;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.vendor-dashboard .welcome-content:after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: #0e131a;
    opacity: 0.75;
    z-index: -1;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.vendor-dashboard .welcome-content .welcome-title {
    color: #fff;
    font-weight: 500;
    font-size: 20px;
}

.vendor-dashboard .welcome-content p {
    color: #fff;
}

.vendor-dashboard .welcome-content .brand-logo a {
    display: inline-block;
    margin-bottom: 20px;
    font-weight: 700;
    color: #fff;
    font-size: 20px;
}

.vendor-dashboard .welcome-content .brand-logo a img {
    width: 100px;
}

.vendor-dashboard .welcome-content .intro-social {
    position: absolute;
    bottom: 75px;
}

.vendor-dashboard .welcome-content .intro-social ul {
    margin-bottom: 0;
}

.vendor-dashboard .welcome-content .intro-social ul li {
    display: inline-block;
}

.vendor-dashboard .welcome-content .intro-social ul li a {
    color: #fff;
    font-size: 14px;
    padding: 0px 7px;
}

.vendor-dashboard .auth-form {
    padding: 50px 50px;
}

@media only screen and (max-width: 575px) {
    .vendor-dashboard .auth-form {
        padding: 30px;
    }
}

.vendor-dashboard .auth-form .btn {
    height: 50px;
    font-weight: 700;
}

.vendor-dashboard .auth-form .page-back {
    display: inline-block;
    margin-bottom: 15px;
}

.vendor-dashboard .pricing_table_content {
    background: #fff;
    text-align: center;
    border: 1px solid #e7e7e7;
    border-radius: 3px;
    padding: 25px;
    -webkit-transition: all 0.25s ease-in-out;
    transition: all 0.25s ease-in-out;
}

.vendor-dashboard .pricing_table_content .package {
    font-weight: 700;
    font-size: 18px;
}

.vendor-dashboard .pricing_table_content .price {
    font-weight: 700;
    font-size: 50px;
    line-height: 100px;
    color: #3e4954;
}

.vendor-dashboard .pricing_table_content hr {
    margin: 0;
}

.vendor-dashboard .pricing_table_content .price_list {
    padding: 30px 0;
    text-align: left;
    max-width: 175px;
    margin: 0 auto;
}

.vendor-dashboard .pricing_table_content .price_list li {
    color: #909093;
    font-size: 14px;
    line-height: 25px;
    padding: 7px 0;
}

.vendor-dashboard .pricing_table_content .price_list li i {
    margin-right: 15px;
}

.vendor-dashboard .pricing_table_content .price-btn {
    padding: 15px 50px;
    -webkit-box-shadow: none;
    box-shadow: none;
    border: 1px solid #eaeaea;
    border-radius: 5px;
    font-weight: 700;
    font-size: 14px;
    margin-bottom: 25px;
    -webkit-transition: all 0.25s ease-in-out;
    transition: all 0.25s ease-in-out;
}

.vendor-dashboard .pricing_table_content:focus,
.vendor-dashboard .pricing_table_content:hover {
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.175);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.175);
}

.vendor-dashboard .pricing_table_content:focus .price-btn,
.vendor-dashboard .pricing_table_content:hover .price-btn {
    color: #f35757;
}

.vendor-dashboard .page-timeline .timeline {
    list-style: none;
    padding: 40px 0;
    position: relative;
}

.vendor-dashboard .page-timeline .timeline-workplan.page-timeline .timeline {
    padding-bottom: 0;
    margin-bottom: 0;
}

.vendor-dashboard .page-timeline .timeline-workplan.page-timeline .timeline.timeline > li > .timeline-badge {
    top: 4.5rem;
}

.vendor-dashboard .page-timeline .timeline-workplan.page-timeline .timeline > li {
    margin-bottom: 2.6rem;
}

@media only screen and (min-width: 1200px) and (max-width: 1650px) {
    .vendor-dashboard .page-timeline .timeline-workplan.page-timeline .timeline li > p {
        max-width: 8rem;
    }
}

@media only screen and (max-width: 1199px) {
    .vendor-dashboard .page-timeline .timeline-workplan.page-timeline .timeline li > p {
        max-width: 7rem;
    }
}

@media only screen and (max-width: 991px) {
    .vendor-dashboard .page-timeline .timeline-workplan.page-timeline .timeline li > p {
        max-width: 100%;
    }
}

@media only screen and (max-width: 575px) {
    .vendor-dashboard .page-timeline .timeline-workplan.page-timeline .timeline li > p {
        max-width: 7rem;
    }
}

.vendor-dashboard .page-timeline .timeline-workplan.page-timeline .timeline:before {
    left: 20%;
    top: 6rem;
}

@media only screen and (max-width: 575px) {
    .vendor-dashboard .page-timeline .timeline-workplan.page-timeline .timeline:before {
        left: 22%;
    }
}

.vendor-dashboard .page-timeline .timeline-workplan.page-timeline .timeline .timeline-badge {
    left: 21.4%;
    height: 0.9rem;
    width: 0.9rem;
    background-color: #0e131a;
}


@media only screen and (min-width: 1200px) and (max-width: 1650px) {
    .vendor-dashboard .page-timeline .timeline-workplan.page-timeline .timeline .timeline-badge {
        left: 22.4%;
    }
}

@media only screen and (max-width: 1199px) {
    .vendor-dashboard .page-timeline .timeline-workplan.page-timeline .timeline .timeline-badge {
        left: 22.4%;
    }
}

@media only screen and (max-width: 991px) {
    .vendor-dashboard .page-timeline .timeline-workplan.page-timeline .timeline .timeline-badge {
        left: 21.7%;
    }
}

@media only screen and (max-width: 767px) {
    .vendor-dashboard .page-timeline .timeline-workplan.page-timeline .timeline .timeline-badge {
        left: 19.5%;
    }
}

@media only screen and (max-width: 575px) {
    .vendor-dashboard .page-timeline .timeline-workplan.page-timeline .timeline .timeline-badge {
        left: 21.4%;
    }
}

.vendor-dashboard .page-timeline .timeline-workplan.page-timeline .timeline .timeline-badge:after {
    position: absolute;
    width: 1.9rem;
    height: 1.9rem;
    background-color: rgba(221, 47, 110, 0.4);
    content: "";
    border-radius: 50%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.vendor-dashboard .page-timeline .timeline-workplan.page-timeline .timeline .timeline-panel {
    width: 70%;
}

.vendor-dashboard .page-timeline .timeline:before {
    top: 0;
    bottom: 0;
    position: absolute;
    content: " ";
    width: 2px;
    background-color: #ECEFF2;
    left: 50%;
    margin-left: -1.5px;
}

.vendor-dashboard .page-timeline .timeline > li {
    margin-bottom: 20px;
    position: relative;
}

.vendor-dashboard .page-timeline .timeline > li:after,
.vendor-dashboard .page-timeline .timeline > li:before {
    content: " ";
    display: table;
}

.vendor-dashboard .page-timeline .timeline > li:after {
    clear: both;
}

.vendor-dashboard .page-timeline .timeline > li:after,
.vendor-dashboard .page-timeline .timeline > li:before {
    content: " ";
    display: table;
}

.vendor-dashboard .page-timeline .timeline > li:after {
    clear: both;
}

.vendor-dashboard .page-timeline .timeline > li > .timeline-panel {
    width: 46%;
    float: left;
    border-radius: 2px;
    position: relative;
}

.vendor-dashboard .page-timeline .timeline > li > .timeline-badge {
    background-color: #f2f4fa;
    border: 1px solid #DDDFE1;
    border-radius: 50%;
    color: #3e4954;
    height: 40px;
    left: 50%;
    line-height: 40px;
    margin-left: -13px;
    position: absolute;
    text-align: center;
    top: 30px;
    width: 40px;
    z-index: 1;
    transform: translate(-25%, -3rem);
}

@media (min-width: 576px) {
    .vendor-dashboard .page-timeline .timeline > li > .timeline-badge {
        width: 50px;
        height: 50px;
        line-height: 50px;
    }
}

.vendor-dashboard .page-timeline .timeline > li.timeline-inverted > .timeline-panel {
    float: right;
}

.vendor-dashboard .page-timeline .timeline > li.timeline-inverted > .timeline-panel:before {
    border-left-width: 0;
    border-right-width: 15px;
    left: -15px;
    right: auto;
}

.vendor-dashboard .page-timeline .timeline > li.timeline-inverted > .timeline-panel:after {
    border-left-width: 0;
    border-right-width: 14px;
    left: -14px;
    right: auto;
}

.vendor-dashboard .page-timeline .timeline-title {
    margin-top: 0;
    color: inherit;
}

.vendor-dashboard .page-timeline .event_time {
    font-size: 14px;
    font-weight: 600;
}

.vendor-dashboard .page-timeline .event_vanue {
    font-size: 14px;
    margin: 5px 0;
    font-weight: 600;
}

.vendor-dashboard .page-timeline .timeline_img {
    height: 100px;
    width: 100px;
}

.vendor-dashboard .page-timeline .timeline-body > p,
.vendor-dashboard .page-timeline .timeline-body > ul {
    margin-bottom: 0;
}

@media (max-width: 767px) {
    .vendor-dashboard .page-timeline ul.timeline:before {
        left: 40px;
    }

    .vendor-dashboard .page-timeline ul.timeline > li > .timeline-panel {
        width: calc(100% - 90px);
        width: -webkit-calc(100% - 90px);
    }

    .vendor-dashboard .page-timeline ul.timeline > li > .timeline-badge {
        left: 28px;
        margin-left: 0;
        top: 16px;
    }

    .vendor-dashboard .page-timeline ul.timeline > li > .timeline-panel {
        float: right;
    }

    .vendor-dashboard .page-timeline ul.timeline > li > .timeline-panel:before {
        border-left-width: 0;
        border-right-width: 15px;
        left: -15px;
        right: auto;
    }

    .vendor-dashboard .page-timeline ul.timeline > li > .timeline-panel:after {
        border-left-width: 0;
        border-right-width: 14px;
        left: -14px;
        right: auto;
    }

    .vendor-dashboard .page-timeline .timeline_img {
        height: 30%;
        width: 30%;
    }
}

.vendor-dashboard .page-timeline .timeline-timestamp {
    text-align: center;
}

.vendor-dashboard .page-timeline .timeline-timestamp .badge {
    padding: 0.8rem 2rem;
    border-radius: 50px;
    font-size: 0.8125rem;
}

.vendor-dashboard .review-table {
    padding: 25px;
    border-radius: 0;
    height: auto;
    margin-bottom: 0;
}

.vendor-dashboard .review-table .disease {
    border-left: 1px solid #eee;
    padding-left: 20px;
}

@media only screen and (max-width: 991px) {
    .vendor-dashboard .review-table .disease {
        border-left: 0;
        padding-left: 0;
        margin-right: 10px !important;
        margin-left: 0;
    }
}

.vendor-dashboard .review-table .star-review i {
    font-size: 20px;
}

@media only screen and (max-width: 991px) {
    .vendor-dashboard .review-table .star-review i {
        font-size: 21px;
    }
}

.vendor-dashboard .review-table .media-body p {
    color: #3e4954;
    font-size: 18px;
    line-height: 1.5;
}

@media only screen and (max-width: 991px) {
    .vendor-dashboard .review-table .media-body p {
        font-size: 14px;
    }
}

@media only screen and (max-width: 991px) {
    .vendor-dashboard .review-table img {
        float: left;
        width: 80px;
    }
}

@media only screen and (max-width: 991px) {
    .vendor-dashboard .review-table {
        padding: 15px;
    }
}

.vendor-dashboard .review-tab.nav-pills {
    margin-bottom: 0;
}

.vendor-dashboard .review-tab.nav-pills li {
    display: inline-block;
}

.vendor-dashboard .review-tab.nav-pills li a.nav-link {
    color: #6b6b6b;
    background: #e9e9e9;
    box-shadow: none;
    border-radius: 0;
    font-weight: 600;
    font-size: 16px;
    padding: 15px 40px;
    margin-right: 1px;
}

.vendor-dashboard .review-tab.nav-pills li a.nav-link.active {
    color: #0e131a;
    background: #fff;
}

@media only screen and (max-width: 991px) {
    .vendor-dashboard .review-tab.nav-pills li a.nav-link {
        font-size: 14px;
        padding: 10px 15px;
    }
}

.vendor-dashboard .review-tab.nav-pills li:first-child a.nav-link {
    border-radius: 0.75rem 0 0 0;
}

.vendor-dashboard .review-tab.nav-pills li:last-child a.nav-link {
    border-radius: 0 0.75rem 0 0;
}

.vendor-dashboard .order-request tbody tr {
    display: block;
    padding: 0 20px;
    position: relative;
    box-shadow: 0 1px 0 0 #f8f8f8;
    -webkit-transition: all 0.5s;
    -ms-transition: all 0.5s;
    transition: all 0.5s;
}

.vendor-dashboard .order-request tbody tr.active,
.vendor-dashboard .order-request tbody tr:hover {
    box-shadow: 0 0 20px -3px rgba(0, 0, 0, 0.15);
    z-index: 1;
}

.vendor-dashboard .order-request tbody tr th,
.vendor-dashboard .order-request tbody tr td {
    border: 0;
    padding: 18px 8px;
}

.vendor-dashboard .order-request tbody tr th:nth-child(1),
.vendor-dashboard .order-request tbody tr td:nth-child(1) {
    width: 35%;
}

@media only screen and (max-width: 1400px) {
    .vendor-dashboard .order-request tbody tr th:nth-child(1),
    .vendor-dashboard .order-request tbody tr td:nth-child(1) {
        width: 40%;
    }
}

.vendor-dashboard .order-request tbody tr th:nth-child(2),
.vendor-dashboard .order-request tbody tr td:nth-child(2) {
    width: 17%;
}

@media only screen and (max-width: 1400px) {
    .vendor-dashboard .order-request tbody tr th:nth-child(2),
    .vendor-dashboard .order-request tbody tr td:nth-child(2) {
        width: 20%;
    }
}

.vendor-dashboard .order-request tbody tr th:nth-child(4),
.vendor-dashboard .order-request tbody tr td:nth-child(4) {
    width: 18%;
}

@media only screen and (max-width: 767px) {
    .vendor-dashboard .order-request tbody tr .media {
        width: 250px;
    }
}

@media only screen and (max-width: 575px) {
    .vendor-dashboard .order-request {
        width: 500px;
    }
}

.vendor-dashboard .wspace-no {
    white-space: nowrap;
}

.vendor-dashboard .star-review i {
    font-size: 16px;
}

.vendor-dashboard .star-review i.text-gray {
    color: #d9d9d9;
}

.vendor-dashboard .star-review span {
    color: #000;
}

.vendor-dashboard .chart-deta .col {
    display: flex;
    padding: 0;
    margin-right: 15px;
    margin-bottom: 30px;
}

.vendor-dashboard .chart-deta .col:last-child {
    margin-bottom: 0;
}

@media only screen and (max-width: 575px) {
    .vendor-dashboard .chart-deta .col {
        margin-bottom: 5px;
    }
}

.vendor-dashboard .chart-deta .col p {
    margin-bottom: 5px;
    line-height: 1.5;
    font-weight: 500;
}

@media only screen and (max-width: 575px) {
    .vendor-dashboard .chart-deta .col p {
        font-size: 14px;
        display: inline-block;
    }
}

.vendor-dashboard .chart-deta .col h3 {
    font-size: 34px;
    margin-bottom: 0;
    color: #000;
}

@media only screen and (max-width: 575px) {
    .vendor-dashboard .chart-deta .col h3 {
        font-size: 14px;
        display: inline-block;
    }
}

.vendor-dashboard .chart-deta .col [class*=bg-] {
    height: 30px;
    width: 30px;
    border-radius: 50px;
    margin-right: 25px;
}

@media only screen and (max-width: 575px) {
    .vendor-dashboard .chart-deta .col [class*=bg-] {
        height: 16px;
        width: 16px;
        margin-right: 10px;
        margin-top: 5px;
    }
}

.vendor-dashboard .trending-menus .timeline .timeline-panel {
    padding: 25px 30px 30px 30px;
    -webkit-transition: all 0.5s;
    -ms-transition: all 0.5s;
    transition: all 0.5s;
    border-bottom: 1px solid #EEEEEE;
    margin-bottom: 0;
}

@media only screen and (max-width: 575px) {
    .vendor-dashboard .trending-menus .timeline .timeline-panel {
        display: block;
        padding: 15px 15px 30px 15px;
    }
}

.vendor-dashboard .trending-menus .timeline .timeline-panel.active,
.vendor-dashboard .trending-menus .timeline .timeline-panel:hover {
    box-shadow: 0 5px 35px 0 rgba(0, 0, 0, 0.05);
}

.vendor-dashboard .trending-menus .timeline .timeline-panel .media {
    width: 60px;
    height: 60px;
    position: relative;
    overflow: unset;
    border-radius: 0.75rem;
}

@media only screen and (max-width: 575px) {
    .vendor-dashboard .trending-menus .timeline .timeline-panel .media {
        width: 80px;
        height: 80px;
        float: left;
        margin-right: 15px !important;
    }
}

.vendor-dashboard .trending-menus .timeline .timeline-panel .media img {
    border-radius: 0.75rem;
    width: 100%;
}

.vendor-dashboard .trending-menus .timeline .timeline-panel .media .number {
    width: 42px;
    height: 42px;
    border-radius: 40px;
    position: absolute;
    bottom: -25px;
    left: 50%;
    border: 3px solid #fff;
    line-height: 38px;
    transform: translateX(-50%);
    text-align: center;
    background: #ffb800;
    color: #000;
    font-size: 16px;
    font-weight: 600;
}

@media only screen and (max-width: 575px) {
    .vendor-dashboard .trending-menus .timeline .media-body {
        margin-bottom: 10px;
    }
}

.vendor-dashboard .trending-menus .timeline .social-media a {
    padding: 0;
    font-size: 18px !important;
    width: 40px;
    height: 40px;
    line-height: 40px;
}

.vendor-dashboard .trending-menus .timeline li:last-child .timeline-panel {
    padding-bottom: 20px;
}

.vendor-dashboard .form-head .search-area {
    max-width: 250px;
    border-radius: 3rem;
    border: 1px solid #EEEEEE;
}

@media only screen and (max-width: 1400px) {
    .vendor-dashboard .form-head .search-area {
        max-width: 180px;
    }
}

@media only screen and (max-width: 575px) {
    .vendor-dashboard .form-head .search-area {
        max-width: 250px;
    }
}

.vendor-dashboard .form-head .search-area .form-control {
    border: 0;
    background: transparent;
    padding: 10px 30px;
    font-size: 16px;
}

@media only screen and (max-width: 1400px) {
    .vendor-dashboard .form-head .search-area .form-control {
        height: 41px;
        padding: 10px 15px;
        font-size: 13px;
    }
}

.vendor-dashboard .form-head .search-area .input-group-append .input-group-text {
    background: transparent;
    padding: 0 30px;
}

@media only screen and (max-width: 1400px) {
    .vendor-dashboard .form-head .search-area .input-group-append .input-group-text {
        padding: 0 15px;
    }
}

.vendor-dashboard .form-head .search-area .input-group-append .input-group-text i {
    font-size: 24px;
    color: #7e7e7e;
}

@media only screen and (max-width: 1400px) {
    .vendor-dashboard .form-head .search-area .input-group-append .input-group-text i {
        font-size: 18px;
    }
}

.vendor-dashboard .form-head .dropdown .btn i {
    transform: scale(1.3);
    -moz-transform: scale(1.3);
    -webkit-transform: scale(1.3);
    -ms-transform: scale(1.3);
    -o-transform: scale(1.3);
    display: inline-block;
}

.vendor-dashboard .form-head .btn i {
    line-height: 1;
    transform: scale(1.3);
    display: inline-block;
    margin-right: 5px;
}

.vendor-dashboard .form-head .btn-outline-primary {
    border-color: #eee;
    color: #000;
}

.vendor-dashboard .form-head .btn-outline-primary:hover {
    border-color: #0e131a;
    color: #fff;
}

.vendor-dashboard .form-head .btn-outline-primary:hover svg g path {
    fill: #fff;
}

.vendor-dashboard .form-head .btn-outline-primary:hover:after {
    color: #fff;
}

.vendor-dashboard .form-head .btn-outline-primary:after {
    color: #0e131a;
    font-size: 18px;
}

@media only screen and (max-width: 1400px) {
    .vendor-dashboard .form-head > * {
        margin-right: 5px !important;
        margin-bottom: 10px;
        display: inline-block;
        vertical-align: baseline;
    }
}

@media only screen and (max-width: 991px) {
    .vendor-dashboard .form-head > * {
        margin-left: 0 !important;
    }
}

@media only screen and (max-width: 767px) {
    .vendor-dashboard .form-head {
        display: block !important;
    }
}

.vendor-dashboard .donut-chart-sale {
    position: relative;
}

.vendor-dashboard .donut-chart-sale small {
    font-size: 16px;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    display: flex;
    align-items: center;
    top: 0;
    justify-content: center;
    font-weight: 600;
    color: #525252;
}

.vendor-dashboard .rounded {
    border-radius: 0.75rem !important;
}

.vendor-dashboard .btn-link {
    font-weight: 500;
    font-size: 16px;
}

.vendor-dashboard .plus-box {
    background: #bb1e57;
    color: #fff;
    font-size: 16px;
    text-align: center;
    padding: 30px 30px;
    position: relative;
    display: block;
    overflow: hidden;
    margin: 0 50px;
    border-radius: 1.75rem;
    margin-bottom: 40px;
    margin-top: 40px;
}

.vendor-dashboard .plus-box p {
    margin-bottom: 0;
    line-height: 1.5;
}

@media only screen and (max-width: 767px) {
    .vendor-dashboard .plus-box {
        margin: 0 20px;
        margin-bottom: 20px;
        margin-top: 20px;
    }
}

.vendor-dashboard .plus-box:before,
.vendor-dashboard .plus-box:after {
    content: "";
    position: absolute;
}

.vendor-dashboard .plus-box:before {
    left: 15px;
    top: 15px;
    width: 20px;
    height: 20px;
    background-repeat: no-repeat;
    /* background-image: url("../images/dots.png"); */
    opacity: 1;
}

.vendor-dashboard .plus-box:after {
    right: 15px;
    bottom: 15px;
    height: 40px;
    width: 40px;
    /* background-image: url("../images/dots2.png"); */
    background-repeat: no-repeat;
    opacity: 1;
}

.vendor-dashboard .deznav .copyright {
    font-size: 12px;
    padding: 0 50px;
    color: #fff;
    margin-bottom: 40px;
}

.vendor-dashboard .deznav .copyright p {
    margin-bottom: 10px;
}

@media only screen and (max-width: 767px) {
    .vendor-dashboard .deznav .copyright {
        padding: 0 20px;
    }
}

.vendor-dashboard .recentOrderTable table {
    margin-bottom: 0;
    overflow: hidden;
}

.vendor-dashboard .recentOrderTable table thead th {
    font-size: 12px;
}

.vendor-dashboard .recentOrderTable table tbody td {
    color: #3e4954;
    font-weight: 500;
    line-height: 40px;
}

[data-theme-version=dark] .recentOrderTable table tbody td {
    color: #828690;
}

.vendor-dashboard .recentOrderTable table tbody td img {
    border-radius: 50px;
}

.vendor-dashboard .recentOrderTable table tbody td .custom-dropdown i {
    padding: 0 10px;
    cursor: pointer;
}

.vendor-dashboard .ps .ps__rail-x:hover,
.vendor-dashboard .ps .ps__rail-y:hover,
.vendor-dashboard .ps .ps__rail-x:focus,
.vendor-dashboard .ps .ps__rail-y:focus,
.vendor-dashboard .ps .ps__rail-x.ps--clicking,
.vendor-dashboard .ps .ps__rail-y.ps--clicking {
    background-color: transparent !important;
    opacity: 0.9;
}

.vendor-dashboard #user-activity .nav-tabs {
    border-bottom: 0px;
}

.vendor-dashboard #user-activity .nav-tabs .nav-link {
    border-radius: 0;
    margin-left: -1px;
    padding: 5px 15px;
    border: 1px solid #f4f4f4;
}

.vendor-dashboard #user-activity .nav-tabs .nav-link.active {
    background: #0e131a;
    color: #fff;
    border-color: #0e131a;
}

.vendor-dashboard #user-activity .nav-tabs li:first-child a {
    border-radius: 0.75rem 0 0 0.75rem;
}

.vendor-dashboard #user-activity .nav-tabs li:last-child a {
    border-radius: 0 0.75rem 0.75rem 0;
}

.vendor-dashboard #activeUser {
    height: 215px !important;
}

.vendor-dashboard #activeUser {
    height: 180px !important;
}

.vendor-dashboard span#counter {
    font-size: 30px;
    font-weight: 700;
    color: #0e131a;
}

.vendor-dashboard .social-graph-wrapper {
    text-align: center;
    padding: 20px;
    position: relative;
    color: #fff;
    border-top-left-radius: 0.75rem;
    border-top-right-radius: 0.75rem;
}

.vendor-dashboard .social-graph-wrapper.widget-facebook {
    background-color: #3b5998;
}

.vendor-dashboard .social-graph-wrapper.widget-twitter {
    background-color: #1da1f2;
}

.vendor-dashboard .social-graph-wrapper.widget-linkedin {
    background-color: #007bb6;
}

.vendor-dashboard .social-graph-wrapper.widget-googleplus {
    background-color: #db4439;
}

.vendor-dashboard .social-graph-wrapper .s-icon {
    font-size: 24px;
    position: relative;
    padding: 0 10px;
}

.vendor-dashboard .recent-stats i {
    font-size: 11px;
    position: relative;
    top: -1px;
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
    .vendor-dashboard #lineChart_3Kk {
        height: 140px !important;
    }
}

.vendor-dashboard .card-tabs .nav-tabs {
    border: 1px solid #EEEEEE;
    background: transparent;
    padding: 5px;
    border-radius: 30px;
    flex-wrap: unset;
}

.vendor-dashboard .card-tabs .nav-tabs .nav-link {
    border-radius: 30px;
    padding: 8px 15px;
    font-weight: 500;
    font-size: 14px;
    border: 0;
}

.vendor-dashboard .card-tabs .nav-tabs .nav-link.active {
    background: #3f4953;
    color: #fff;
}

.vendor-dashboard .card-tabs .nav-tabs .nav-item {
    display: inline-block;
}

@media only screen and (max-width: 575px) {
    .vendor-dashboard .card-tabs .nav-tabs {
        display: block;
    }
}

.vendor-dashboard .iconbox {
    position: relative;
    padding: 15px 30px 15px 70px;
    border-radius: 0.75rem;
    display: inline-block;
    background: #838d97;
    text-align: left;
}

.vendor-dashboard .iconbox i {
    width: 70px;
    height: 70px;
    line-height: 70px;
    text-align: center;
    font-size: 32px;
    color: #fff;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
}

.vendor-dashboard .iconbox p {
    margin: 0;
    color: #fff;
    font-size: 18px;
    line-height: 1.3;
    font-weight: 500;
}

.vendor-dashboard .iconbox small {
    margin-bottom: 5px;
    font-size: 16px;
    display: block;
    color: #fff;
}

.vendor-dashboard .widget-timeline-icon {
    padding: 50px;
}

@media only screen and (max-width: 1400px) {
    .vendor-dashboard .widget-timeline-icon {
        padding: 30px;
    }
}

@media only screen and (max-width: 575px) {
    .vendor-dashboard .widget-timeline-icon {
        overflow: scroll;
        padding: 15px;
    }
}

.vendor-dashboard .widget-timeline-icon .timeline {
    display: flex;
}

@media only screen and (max-width: 575px) {
    .vendor-dashboard .widget-timeline-icon .timeline {
        display: block;
        margin-left: 10px;
    }
}

.vendor-dashboard .widget-timeline-icon li {
    position: relative;
    border-top: 3px solid #0e131a;
}

@media only screen and (max-width: 575px) {
    .vendor-dashboard .widget-timeline-icon li {
        border-top: 0;
        border-left: 3px solid #0e131a;
    }
}

.vendor-dashboard .widget-timeline-icon li a {
    padding: 25px 50px 0px 0px;
    display: block;
}

@media only screen and (max-width: 1400px) {
    .vendor-dashboard .widget-timeline-icon li a {
        padding: 15px 25px 0px 0px;
    }
}

@media only screen and (max-width: 575px) {
    .vendor-dashboard .widget-timeline-icon li a {
        padding: 0px 0px 30px 30px;
    }
}

.vendor-dashboard .widget-timeline-icon li .icon {
    position: absolute;
    width: 20px;
    height: 20px;
    font-size: 24px;
    color: #fff;
    text-align: center;
    line-height: 56px;
    border-radius: 56px;
    left: -2px;
    top: -11px;
}

@media only screen and (max-width: 575px) {
    .vendor-dashboard .widget-timeline-icon li .icon {
        left: -12px;
        top: -4px;
    }
}

.vendor-dashboard .widget-timeline-icon li:last-child {
    border-top: 0;
}

@media only screen and (max-width: 575px) {
    .vendor-dashboard .widget-timeline-icon li:last-child {
        border-left: 3px solid #eee;
    }
}

@media only screen and (max-width: 767px) {
    .vendor-dashboard .order-list .media {
        width: 290px;
    }
}

.vendor-dashboard .full-map-area {
    position: relative;
    border-radius: 12px;
    overflow: hidden;
}

.vendor-dashboard .full-map-area a {
    position: absolute;
    bottom: 30px;
    right: 30px;
    background: #3f4953;
    padding: 12px;
    border-radius: 0.75rem;
}

.vendor-dashboard .full-map-area .map-text {
    position: absolute;
    top: 30px;
    right: 30px;
}

.vendor-dashboard .favorites-items img {
    width: 110px;
}

.vendor-dashboard .produtct-detail-tag {
    display: inline-block;
}

.vendor-dashboard .produtct-detail-tag a {
    font-style: 13px;
    color: #3e4954;
}

.vendor-dashboard .product-detail-content .item-tag {
    background: #828690;
    border-radius: 6px;
    display: inline-block;
    font-size: 12px;
    margin-right: 4px;
    padding: 2px 12px;
    color: #fff;
}

.vendor-dashboard .filtaring-area h4 {
    color: #1d1d1d;
    font-size: 16px;
    font-weight: 400;
    text-transform: lowercase;
}

.vendor-dashboard .plus-minus-input .input-icon {
    font-size: 13px;
    color: #3e4954;
}

.vendor-dashboard .plus-minus-input {
    display: flex;
    width: 120px;
}

.vendor-dashboard .plus-minus-input .custom-btn {
    border-radius: 0;
    height: 40px;
    padding: 12px 8px;
    background: #fff;
    border: 1px solid #d7dae3;
}

.vendor-dashboard .plus-minus-input .form-control:hover,
.vendor-dashboard .plus-minus-input .form-control:focus,
.vendor-dashboard .plus-minus-input .form-control:active {
    border: 1px solid #d7dae3;
}

.vendor-dashboard .btn-reveal-trigger .avatar-xl {
    min-width: 30px;
}

.vendor-dashboard .share-view {
    display: inline-block;
}

.vendor-dashboard .share-view ul li {
    display: inline-block;
}

.vendor-dashboard .share-view .share-icon {
    width: 40px;
    height: 40px;
    display: inline-block;
    border: 1px solid #d7dae3;
    text-align: center;
    line-height: 40px;
    font-size: 16px;
    color: #d7dae3;
    margin-right: 8px;
}

.vendor-dashboard .veritical-line {
    padding: 20px 30px;
    border-top: 1px solid #d7dae3;
    border-right: 1px solid #d7dae3;
    border-bottom: 1px solid #d7dae3;
    position: relative;
}

.vendor-dashboard .veritical-line:before {
    background: #d7dae3;
    bottom: 0;
    content: "";
    height: 100%;
    left: -1px;
    max-height: 40%;
    position: absolute;
    width: 1px;
}

.vendor-dashboard .tab-content-text p {
    color: #3e4954;
    font-size: 13px;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 25px;
}

.vendor-dashboard .tab-item-list li a {
    background: #fff;
    border-top: 1px solid #d7dae3;
    border-left: 1px solid #d7dae3;
    border-right: 1px solid #d7dae3;
    color: #3e4954;
    display: block;
    font-size: 16px;
    padding: 16px;
    text-transform: uppercase;
}

.vendor-dashboard .tab-item-list li a:hover,
.vendor-dashboard .tab-item-list li a:focus {
    background: #fff;
    color: #3e4954;
    border-right: 0px;
}

.vendor-dashboard .tab-item-list li:last-child {
    border-bottom: 1px solid #d7dae3;
}

.vendor-dashboard .tab-list li {
    margin-bottom: 7px;
    font-size: 13px;
}

.vendor-dashboard .tab-list li i {
    font-size: 13px;
    margin-right: 14px;
}

.vendor-dashboard .slide-item-list {
    text-align: center;
    margin: 0 -5px;
}

.vendor-dashboard .slide-item-list li {
    display: inline-block;
    flex: 0 0 25%;
    width: 25%;
    padding: 0 5px;
}

.vendor-dashboard .slide-item-list li a {
    display: inline-block;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    background: transparent;
    padding-bottom: 0px;
}

.vendor-dashboard .slide-item-list li a:hover,
.vendor-dashboard .slide-item-list li a:focus {
    background: transparent;
}

.vendor-dashboard .slide-item-list li a img {
    width: 100%;
}

.vendor-dashboard .product-detail-text {
    padding-top: 28px;
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 70px;
}

.vendor-dashboard .star-rating .product-review {
    font-style: 13px;
    color: #3e4954;
    font-weight: 400;
    text-decoration: underline !important;
}

.vendor-dashboard .product-detail .tab-content img {
    display: inline-block;
    width: 100%;
}

.vendor-dashboard .popular-tag ul {
    margin: 0px;
    padding: 0px;
}

.vendor-dashboard .popular-tag ul li {
    display: inline-block;
    padding: 8px 15px;
    background: #f8f8f8;
    font-size: 13px;
    color: #fff;
    margin-right: 10px;
    margin-bottom: 10px;
}

.vendor-dashboard .size-filter ul li {
    display: inline-block;
}

.vendor-dashboard .intro {
    border: 1px solid red;
    color: #1d1d1d;
}

.vendor-dashboard #listResults .slider {
    margin: 25px 0;
}

.vendor-dashboard #listResults .slider-box {
    width: 90%;
    margin: 25px auto;
}

.vendor-dashboard #listResults input {
    width: 10%;
}

.vendor-dashboard #listResults label {
    border: none;
    display: inline-block;
    margin-right: -4px;
    vertical-align: top;
    width: 30%;
}

.vendor-dashboard .plus-minus-input .input-icon {
    font-size: 13px;
    color: #aaaaaa;
}

.vendor-dashboard .plus-minus-input .custom-btn {
    border-radius: 0;
    height: 40px;
    padding: 8px 12px;
    background: #ffffff;
    border: 1px solid #c8c8c8;
}

.vendor-dashboard .plus-minus-input .custom-btn:hover,
.vendor-dashboard .plus-minus-input .custom-btn:focus,
.vendor-dashboard .plus-minus-input .custom-btn.active {
    box-shadow: none;
    outline: none;
}

.vendor-dashboard .plus-minus-input .form-control {
    height: 40px;
    border-top: 1px solid #c8c8c8;
    border-bottom: 1px solid #c8c8c8;
    border-left: 0px solid #c8c8c8;
    border-right: 1px solid #c8c8c8;
}

.vendor-dashboard .plus-minus-input .form-control:hover,
.vendor-dashboard .plus-minus-input .form-control:focus,
.vendor-dashboard .plus-minus-input .form-control:active {
    border-top: 1px solid #c8c8c8;
    border-bottom: 1px solid #c8c8c8;
    border-left: 0px solid #c8c8c8;
    border-right: 0px solid #c8c8c8;
}

.vendor-dashboard .new-arrival-product .new-arrivals-img-contnent {
    overflow: hidden;
}

.vendor-dashboard .new-arrival-product .new-arrivals-img-contnent img {
    width: 100%;
    -webkit-transition: all 0.5s;
    -ms-transition: all 0.5s;
    transition: all 0.5s;
}

.vendor-dashboard .new-arrival-product:hover .new-arrivals-img-contnent img {
    transform: scale(1.5) translateY(12%);
    -moz-transform: scale(1.5) translateY(12%);
    -webkit-transform: scale(1.5) translateY(12%);
    -ms-transform: scale(1.5) translateY(12%);
    -o-transform: scale(1.5) translateY(12%);
}

.vendor-dashboard .new-arrival-content .item {
    font-size: 12px;
    color: #3e4954;
}

.vendor-dashboard .new-arrival-content h4 {
    font-size: 16px;
    color: #828690;
    text-transform: uppercase;
    font-weight: 600;
    margin-bottom: 10px;
}

.vendor-dashboard .new-arrival-content .price {
    font-weight: 700;
    color: #0e131a;
    font-size: 22px;
    margin-bottom: 20px;
}

.vendor-dashboard .new-arrival-content p {
    font-size: 14px;
    color: #828690;
    margin-bottom: 6px;
    line-height: 24px;
}

.vendor-dashboard .new-arrival-content .text-content {
    margin-top: 18px;
}

.vendor-dashboard .success-icon {
    color: #2bc155;
    font-size: 16px;
}

.vendor-dashboard .comment-review {
    position: absolute;
    right: 0;
    top: 25px;
}

.vendor-dashboard .comment-review .client-review {
    color: #828690;
    padding-right: 20px;
    text-decoration: underline !important;
    font-size: 14px;
}

.vendor-dashboard .comment-review .span {
    color: #828690;
    font-size: 14px;
}

.vendor-dashboard .star-rating li {
    display: inline-block;
}

.vendor-dashboard .star-rating li i {
    color: gold;
}

@media (min-width: 1200px) {
    .vendor-dashboard .col-xl-33 {
        -ms-flex: 0 0 20%;
        flex: 0 0 20%;
        max-width: 20%;
    }
}

.vendor-dashboard .bg-1 {
    background: #ff3a63;
}

.vendor-dashboard .bg-2 {
    background: #d8e9f0;
}

.vendor-dashboard .bg-3 {
    background: #0e131a;
}

.vendor-dashboard .bg-4 {
    background: #8ebfd4;
}

.vendor-dashboard .bg-5 {
    background: #58719c;
}

.vendor-dashboard .widget-stat {
    cursor: pointer;
}

.vendor-dashboard .actions {
    white-space: nowrap;
    display: flex;
}

.vendor-dashboard .actions a {
    background: #151515;
    margin-right: 5px;
    color: #fff;
    padding: 2px 6px;
    border-radius: 3px;
}

.vendor-dashboard .actions a:last-child {
    margin-right: 0px;
}

.vendor-dashboard .actions a i {
    font-size: 14px;
    margin: 0 auto;
}

@media (min-width: 991px) {
    .vendor-dashboard .modal-dialog.modal-custom {
        max-width: 850px;
    }

    .vendor-dashboard .modal-dialog.modal-custom form {
        display: flex;
        flex-wrap: wrap;
        margin-right: -15px;
        margin-left: -15px;
    }

    .vendor-dashboard .modal-dialog.modal-custom form .form-group {
        flex: 0 0 50%;
        max-width: 50%;
        position: relative;
        width: 100%;
        padding-right: 15px;
        padding-left: 15px;
    }
}

.vendor-dashboard .menu img {
    width: 60px;
    -o-object-fit: cover;
    object-fit: cover;
    height: 60px;
}

.vendor-dashboard .bootstrap-select .btn {
    height: 56px;
    font-size: 14px;
    border-radius: 0.75rem;
}

.vendor-dashboard .bootstrap-select .btn .filter-option {
    display: flex;
    align-items: center;
}

.vendor-dashboard .edit .bootstrap-select .dropdown-toggle .filter-option-inner-inner {
    color: #3e4954;
}

.vendor-dashboard .custom-select {
    /* background: #fff url(../images/down-icon.jpg) no-repeat right 0.75rem center; */
    background-size: 18px;
}

.vendor-dashboard .modal-header {
    background: #151515;
}

.vendor-dashboard .modal-header .modal-title {
    color: #fff;
}

.vendor-dashboard .modal-header .close,
.vendor-dashboard .modal-header .close {
    color: #fff;
}

.vendor-dashboard .text-common {
    color: #ff3a63;
}

.vendor-dashboard .btn-common {
    background: #fff;
    color: #0e131a;
}

.vendor-dashboard .banner-bg {
    /* background-image: url(../images/restaurant-banner.jpg); */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: rgba(0, 0, 0, 0.4);
    background-blend-mode: multiply;
}

.vendor-dashboard .login .img-logo {
    width: 15%;
}

.vendor-dashboard .login-row {
    height: calc(100vh - 153px);
}

.vendor-dashboard .forgot-password .new-account p {
    font-size: 14px;
}

.vendor-dashboard .table-management .qr-code .img-qr-code {
    height: 100px;
    width: 100px;
    object-fit: cover;
}

.vendor-dashboard .dashboard .n-badge {
    color: #fff;
    border-radius: 3px;
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 2px;
    background: #ffa803;
    width: 18px;
    height: 18px;
    text-align: center;
    float: right;
    line-height: 19px;
}

.vendor-dashboard .dashboard .noti-all {
    float: left;
    width: 100%;
    margin-top: 23px;
}

.vendor-dashboard .dashboard .noti-bg {
    border-top: 1px solid #e1e1e1;
    border-left: 1px solid #e1e1e1;
    border-right: 1px solid #e1e1e1;
}

.vendor-dashboard .dashboard .noti-bg li {
    display: block;
}

.vendor-dashboard .dashboard .n-media {
    border-bottom: 1px solid #e1e1e1;
    padding-bottom: 0px;
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    align-items: flex-start;
}

.vendor-dashboard .dashboard .n-media-left {
    margin-right: 15px;
}

.vendor-dashboard .dashboard .n-media .media-body h4 {
    font-size: 15px;
    font-weight: 500;
    color: #000;
    text-transform: initial;
}

.vendor-dashboard .dashboard .n-media .media-body p {
    font-size: 14px;
    padding: 4px 0 10px;
    font-weight: 400;
    color: #847577;
}

.vendor-dashboard .dashboard .n-media .media-body .n-comment-date {
    position: relative;
    color: #847577;
    font-size: 12px;
    font-weight: 400;
    bottom: 10px;
    color: #a7a8aa;
}

.vendor-dashboard .dashboard .n-media-left img {
    width: 55px;
    height: 55px;
    object-fit: cover;
    border-radius: 50%;
}

.vendor-dashboard .table td .img-doc {
    font-size: 25px;
}

.vendor-dashboard .reports .n-media-left i {
    font-size: 60px;
}

.vendor-dashboard .actions {
    border: none !important;
}

.vendor-dashboard .table td {
    border-bottom: none !important;
}

.vendor-dashboard .btn-sm,
.vendor-dashboard .btn-group-sm > .btn {
    padding: 5px 10px;
}

.vendor-dashboard .delivered {
    color: #26bf62;
}

.vendor-dashboard .pending {
    color: #cc1a1a;
}

.vendor-dashboard .inprg {
    color: #e0aa11;
}

.vendor-dashboard .dashboard-page-header {
    margin-bottom: 30px;
    border-bottom: 1px solid #e4e4e4;
    padding-bottom: 30px;
}

.vendor-dashboard .dasboardlist .media img {
    width: 40px;
    max-width: 100%;
}

.vendor-dashboard .dashboard-list-img {
    height: 100%;
}

.vendor-dashboard .dashboard-list-img a {
    display: inline-block;
    width: 100%;
    height: 100%;
}

.vendor-dashboard .dashboard-list-img a img {
    width: 100%;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    height: 130px;
    object-fit: cover;
}

.vendor-dashboard .dashboard-list-block {
    background-color: #fff;
    margin-bottom: 20px;
    border: none;
    -webkit-box-shadow: 0 0 0 1px rgba(61, 70, 79, 0.05), 0 1px 3px 0 rgba(61, 70, 79, 0.15);
    box-shadow: 0 0 0 1px rgba(61, 70, 79, 0.05), 0 1px 3px 0 rgba(61, 70, 79, 0.15);
    border-radius: 3px;
}

.vendor-dashboard .dashboard-list-content {
    font-size: 14px;
    padding-top: 23px;
    padding-bottom: 23px;
}

.vendor-dashboard .dashboard-list-content h3 {
    font-weight: 300;
    font-size: 20px;
    line-height: 28px;
    color: #000;
}

.vendor-dashboard .dashboard-list-content h3 a {
    color: #000;
}

.vendor-dashboard .dashboard-list-content h3 a:hover {
    color: #ff4068;
}

.vendor-dashboard .dashboard-list-btn {
    padding: 29px;
    text-align: right;
}

.vendor-dashboard .btn-xs {
    padding: 4px 8px;
    font-size: 12px;
    line-height: 1.5;
}

.vendor-dashboard .btn-outline-violate {
    border: 2px solid transparent;
    color: #9047fb;
    background-color: #f2e9ff;
    border-color: #9047fb;
}

.vendor-dashboard .btn-outline-pink {
    border: 2px solid transparent;
    color: #ff7b7b;
    background-color: #ffeded;
    border-color: #ff7b7b;
}

.vendor-dashboard .mr10 {
    margin-right: 10px;
}

.vendor-dashboard .nice-select.wide {
    width: 100%;
}

.vendor-dashboard .nice-select.wide .list {
    left: 0 !important;
    right: 0 !important;
}

.vendor-dashboard .nice-select {
    -webkit-tap-highlight-color: transparent;
    background-color: #fff;
    border: 1px solid #e8e8e8;
    border-radius: 2px;
    box-sizing: border-box;
    clear: both;
    cursor: pointer;
    display: block;
    float: left;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.08);
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.08);
    -webkit-transition: border-color ease-in-out 0.15s, -webkit-box-shadow ease-in-out 0.15s;
    -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    font-family: inherit;
    font-size: 14px;
    font-weight: normal;
    height: 50px;
    line-height: 40px;
    outline: none;
    padding: 6px 14px;
    position: relative;
    text-align: left !important;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    white-space: nowrap;
    width: auto;
    color: #343031;
    margin-bottom: 16px;
}

.vendor-dashboard .nice-select .list {
    border: 1px solid #999999;
    background-color: #fff;
    border-radius: 2px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    box-shadow: 0 0 0 1px rgba(68, 68, 68, 0.11);
    box-sizing: border-box;
    margin-top: 0px;
    opacity: 0;
    overflow: hidden;
    padding: 0;
    pointer-events: none;
    position: absolute;
    top: 100%;
    left: 0;
    -webkit-transform-origin: 50% 0;
    -ms-transform-origin: 50% 0;
    transform-origin: 50% 0;
    -webkit-transform: scale(0.75) translateY(-21px);
    -ms-transform: scale(0.75) translateY(-21px);
    transform: scale(0.75) translateY(-21px);
    -webkit-transition: all 0.2s cubic-bezier(0.5, 0, 0, 1.25), opacity 0.15s ease-out;
    transition: all 0.2s cubic-bezier(0.5, 0, 0, 1.25), opacity 0.15s ease-out;
    z-index: 9;
    border: none;
}

.vendor-dashboard .bootstrap-select.wide {
    width: 100% !important;
}

.vendor-dashboard .bootstrap-select.wide button {
    width: 100%;
    border-radius: 2px !important;
}

.vendor-dashboard .aminities-form-info h3 {
    color: #252328;
    margin: 0px 0px 15px 0px;
    font-weight: 300;
}

.vendor-dashboard .aminities-form-info label {
    font-weight: 400;
    font-size: 14px;
    color: #5f5e61;
}

.vendor-dashboard .dashboard-venue-gallery h3,
.vendor-dashboard .dashboard-venue-video h3,
.vendor-dashboard .social-form-info h3 {
    font-size: 20px;
    line-height: 28px;
    font-weight: 300;
}

.vendor-dashboard .dashboard-venue-gallery label,
.vendor-dashboard .dashboard-venue-video label,
.vendor-dashboard .social-form-info label {
    font-weight: 400;
    font-size: 14px;
    color: #5f5e61;
}

.vendor-dashboard .dashboard-venue-gallery .btn-primary {
    background: linear-gradient(93.93deg, #ff4068 0%, #ff3962 100%);
}

.vendor-dashboard .gallery-upload-img {
    margin-bottom: 30px;
    position: relative;
}

.vendor-dashboard .delete-gallery-img {
    position: absolute;
    top: 6px;
    right: 14px;
    font-size: 20px;
}

.vendor-dashboard .gallery-upload-img img {
    border: 1px solid #ebebeb;
    background-color: #fff;
    padding: 4px;
    border-radius: 4px;
    position: relative;
}

.vendor-dashboard .delete-gallery-img {
    position: absolute;
    top: 6px;
    right: 14px;
    font-size: 20px;
}

.vendor-dashboard .delete-gallery-img a {
    color: #000;
}

.vendor-dashboard .btn-default {
    color: #fff;
    background: linear-gradient(93.93deg, #ff4068 0%, #ff3962 100%);
    border-color: #ff4d4d;
}

.vendor-dashboard .btn-default:focus {
    box-shadow: none !important;
}

.vendor-dashboard .btn-default:active {
    background: linear-gradient(93.93deg, #ff4068 0%, #ff3962 100%) !important;
}

.vendor-dashboard .reviewssec table tr td h4 {
    font-size: 16px;
}

.vendor-dashboard .dashboard-page-title {
       font-size: 21px;
    margin-bottom: -19px;
    font-weight: 300;
}

.vendor-dashboard .rating-area .average-rating {
    background: #ff4068;
}

.vendor-dashboard .rating-area {
    margin-bottom: 20px;
}

.vendor-dashboard .rating-area .average-rating {
    padding: 19px;
    text-align: center;
    border-radius: 0;
}

.vendor-dashboard .rating-area .average-rating h1 {
    color: #fff;
    font-weight: 500;
}

.vendor-dashboard .rating-area .average-rating .ratings i {
    color: orange;
}

.vendor-dashboard .rating-area .average-rating h4 {
    font-size: 16px;
    color: #fff;
    margin-bottom: 0;
}

.vendor-dashboard .rating-area .rating-item {
    display: flex;
    align-items: center;
    margin-bottom: 6px;
}

.vendor-dashboard .rating-area .rating-item .ratings {
    margin-left: auto;
}

.vendor-dashboard .rating-area .rating-count {
    width: 40px;
}

.vendor-dashboard .rating-area .rating-bar {
    width: 59%;
    height: 15px;
    margin: 0 20px;
    background: #d4d7de;
    position: relative;
}

.vendor-dashboard .rating-area .rating-width {
    position: absolute;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 1;
    background: #7a7d8a;
}

.vendor-dashboard .rating-area .ratings i {
    color: #eda619;
    font-size: 14px;
}

.vendor-dashboard .cmnt .comment-content {
    padding-bottom: 0;
    border-bottom: none;
}

.vendor-dashboard .reviewssec .table tbody tr td {
    max-width: 200px;
}

.vendor-dashboard .reviewssec .table tbody tr td:first-child {
    max-width: 100px;
}

.vendor-dashboard .reviewssec .table tbody tr td:first-child .media {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.vendor-dashboard .btn-primary {
    border-color: #ff4068 !important;
    background: #ff4068;
    color: #fff;
}

.vendor-dashboard .btn-primary:hover {
    color: #fff;
    background-color: #ff0c3f;
    border-color: #0062cc;
}

.vendor-dashboard .submitbtn:hover {
    color: #fff;
    background-color: #ff4068;
}

.vendor-dashboard .menu-toggle .nav-header .brand-logo {
    font-size: 11px;
}

.vendor-dashboard a {
    text-decoration: none !important;
}

.vendor-dashboard #addnew .card-footer {
    background-color: transparent;
}

.vendor-dashboard .nav-tabs .nav-item.show .nav-link,
.vendor-dashboard .nav-tabs .nav-link {
    color: #495057 !important;
    font-weight: 400;
    background: transparent !important;
    cursor: pointer;
}

.vendor-dashboard .nav-tabs .nav-item.show .nav-link,
.vendor-dashboard .nav-tabs .nav-link.active {
    color: #ff4068 !important;
    border-color: #ff4068 #ff4068 #fff !important;
}

.vendor-dashboard li.mm-active.active-no-child a span,
.vendor-dashboard li.mm-active.active-no-child a i {
    color: #ff4068;
}

.vendor-dashboard li.mm-active.active-no-child a i {
    font-size: 23px;
}

.vendor-dashboard #myprofile .prplistitem:nth-child(odd) {
    /* background-color: #f9f1eb;*/
}

.vendor-dashboard #myprofile .prplistitem > div:first-child {
    background-color: #f9f1eb;
    max-width: 200px;
    width: 100%;
    font-size: 16px;
    font-weight: 600;
}

.vendor-dashboard #myprofile .prplistitem > div {
    padding: 15px 20px;
    border-bottom: 0.1rem solid #ebebeb;
    width: 100%;
}

.vendor-dashboard .prplistitem {
    display: flex;
}

.vendor-dashboard .prplistitem:nth-child(odd) {
    /* background-color: #f9f1eb;*/
}

.vendor-dashboard .prplistitem > div:first-child {
    /* background-color: #f9f1eb;*/
    max-width: 200px;
    width: 100%;
    font-size: 16px;
    font-weight: 500;
}

.vendor-dashboard .prplistitem > div {
    padding: 20px 20px;
    border-bottom: 0.1rem solid #ebebeb;
    width: 100%;
}

.vendor-dashboard .prplistitem > div:last-child img {
    max-width: 30px;
    width: 100%;
    border-radius: 50%;
    height: 30px;
}

@media (max-width: 991px) {
    .vendor-dashboard .dashboard-list-content {
        padding-left: 23px;
    }
}

.vendor-dashboard .table.table-striped tbody tr:nth-of-type(2n+1),
.vendor-dashboard .table.table-hover tr:hover {
    background-color: #f2f4fa;
}

.vendor-dashboard .btn-outline-yellow {
    border: 2px solid transparent;
    color: #fcc627;
    background-color: #fff6da;
    border-color: #fcc627;
}

/**********Vendor Dashboard Ends*****************/

/***********Frontend Panel**********************/

body .frontend-panel {
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
}

.frontend-panel a {
    transition: all 0.5s;
}

.frontend-panel #main-wrapper {
    flex: 1 1 auto;
}

.frontend-panel .banner-inner .photo {
    padding: 2px;
    background-color: #fff;
    border-radius: 100%;
    position: absolute;
    bottom: 100px;
    max-width: 250px;
}

.frontend-panel #banner-inner-2 {
    transition: all 0.4s;
    position: absolute;
    bottom: 40px;
    width: 100%;
}

.frontend-panel .col-pad-10 {
    padding: 0 10px !important;
}

.frontend-panel html,
body .frontend-panel {
    height: 100%;
}

body .frontend-panel {
    font-family: "Poppins", sans-serif;
}

.frontend-panel a {
    cursor: pointer;
    color: #353535;
}

.frontend-panel a:hover {
    color: #525252;
    text-decoration: none;
}

.frontend-panel p {
    font-size: 16px;
    line-height: 27px;
    color: #535353;
}

.frontend-panel a {
    color: #353535;
}

.frontend-panel strong {
    color: #535353;
}

.frontend-panel span {
    color: #535353;
}

.frontend-panel h1,
.frontend-panel h2,
.frontend-panel h3,
.frontend-panel h4,
.frontend-panel h5,
.frontend-panel h6,
.frontend-panel .h1,
.frontend-panel .h2,
.frontend-panel .h3,
.frontend-panel .h4,
.frontend-panel .h5,
.frontend-panel .h6 {
    color: #353535;
}

.frontend-panel ul {
    margin: 0;
    padding: 0;
    list-style: none;
    color: #535353;
}

.frontend-panel .btn.focus,
.frontend-panel .btn:focus {
    outline: 0;
    box-shadow: none;
}

.frontend-panel .facebook:hover {
    color: #3b589e !important;
}

.frontend-panel .twitter:hover {
    color: #56d7fe !important;
}

.frontend-panel .google:hover {
    color: #dc4e41 !important;
}

.frontend-panel .rss:hover {
    color: #e78733 !important;
}

.frontend-panel .linkedin:hover {
    color: #1c82ca !important;
}

.frontend-panel .facebook-bg {
    background: #4867aa;
    color: #fff;
}

.frontend-panel .twitter-bg {
    background: #3cf;
    color: #fff;
}

.frontend-panel .google-bg {
    background: #db4437;
    color: #fff;
}

.frontend-panel .linkedin-bg {
    background: #0177b5;
    color: #fff;
}

.frontend-panel .rss-bg {
    background: #e78733;
    color: #fff;
}

.frontend-panel .pinterest-bg {
    background: #bd081c;
    color: #fff;
}

.frontend-panel .facebook-bg:hover {
    background: #3b589e;
    color: #fff;
}

.frontend-panel .twitter-bg:hover {
    background: #56d7fe;
    color: #fff;
}

.frontend-panel .linkedin-bg:hover {
    background: #1c82ca;
    color: #fff;
}

.frontend-panel .google-bg:hover {
    background: #dc4e41;
    color: #fff;
}

.frontend-panel .rss-bg:hover {
    background: #e78701;
    color: #fff;
}

.frontend-panel .pinterest-bg:hover {
    background: #a30618;
    color: #fff;
}

.frontend-panel .overview-bgi {
    position: relative;
}

.frontend-panel .overview-bgi:before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.6);
}

.frontend-panel .overview-bgi-2:before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.6);
}

.frontend-panel .delay-02s {
    animation-delay: 0.2s;
    -webkit-animation-delay: 0.2s;
}

.frontend-panel .delay-03s {
    animation-delay: 0.3s;
    -webkit-animation-delay: 0.3s;
}

.frontend-panel .delay-04s {
    animation-delay: 0.4s;
    -webkit-animation-delay: 0.4s;
}

.frontend-panel .delay-05s {
    animation-delay: 0.5s;
    -webkit-animation-delay: 0.5s;
}

.frontend-panel .delay-06s {
    animation-delay: 0.6s;
    -webkit-animation-delay: 0.6s;
}

.frontend-panel .delay-07s {
    animation-delay: 0.7s;
    -webkit-animation-delay: 0.7s;
}

.frontend-panel .delay-08s {
    animation-delay: 0.8s;
    -webkit-animation-delay: 0.8s;
}

.frontend-panel .delay-09s {
    animation-delay: 0.9s;
    -webkit-animation-delay: 0.9s;
}

.frontend-panel .delay-1s {
    animation-delay: 1s;
    -webkit-animation-delay: 1s;
}

.frontend-panel .delay-12s {
    animation-delay: 1.2s;
    -webkit-animation-delay: 1.2s;
}

.frontend-panel .delay-15s {
    animation-delay: 1.5s;
    -webkit-animation-delay: 1.5s;
}

.frontend-panel .delay-17s {
    animation-delay: 1.7s;
    -webkit-animation-delay: 1.7s;
}

.frontend-panel .delay-20s {
    animation-delay: 2s;
    -webkit-animation-delay: 2s;
}

.frontend-panel .btn-sm {
    cursor: pointer;
    padding: 7px 12px;
    letter-spacing: 1px;
    font-size: 11px;
    font-weight: 500;
    transition: 0.5s;
    border-radius: 3px;
    border: none;
    color: #fff;
}

.frontend-panel .btn-sm:hover {
    color: #fff;
    text-decoration: none;
}

.frontend-panel .btn-md {
    cursor: pointer;
    padding: 13px 26px;
    letter-spacing: 1px;
    font-size: 13px;
    font-weight: 500;
    color: #fff;
    transition: 0.5s;
    border-radius: 3px;
    border: none;
}

.frontend-panel .btn-md:hover {
    color: #fff;
}

.frontend-panel button:focus {
    outline: 0 auto -webkit-focus-ring-color;
}

.frontend-panel .btn-block i {
    color: #fff;
}

.frontend-panel .pricing-btn {
    padding: 6px 30px;
    border-radius: 50px;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 1.5px;
}

.frontend-panel .bomd {
    padding: 13px 26px;
    letter-spacing: 1px;
    font-size: 13px;
    font-weight: 500;
    transition: 0.5s;
    border-radius: 3px;
}

.frontend-panel .btn {
    border-radius: 3px;
}

.frontend-panel .btn-sm {
    padding: 7px 30px;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 1.5px;
}

.frontend-panel .btn-md {
    cursor: pointer;
    padding: 13px 30px 11px 30px;
    letter-spacing: 1px;
    font-size: 14px;
    font-weight: 500;
    border-radius: 3px;
}

.frontend-panel .btn-lg {
    cursor: pointer;
    padding: 13px 32px 11px 32px;
    letter-spacing: 1px;
    font-size: 14px;
    font-weight: 500;
}

.frontend-panel .btn:focus,
.frontend-panel .btn:active,
.frontend-panel .btn:focus {
    box-shadow: none !important;
    outline: none;
}

.frontend-panel .btn-theme,
.pricing-wrap .get-started-buttton {
    background: #ff4068;
    border-color: #d9a723;
    font-weight: 500;
    display: inline-block;
    padding: 12px 40px;
    text-align: center;
    border: 1px solid transparent;
    border-radius: 3px;
    position: relative;
    z-index: 3;
    transition: all 0.4s;
    cursor: pointer;
    outline: none;
    color: #fff;
}

.frontend-panel .btn-theme:after,
.pricing-wrap .get-started-buttton:after {
    content: "";
    width: 0;
    height: 100%;
    background: transparent;
    border-radius: 3px;
    position: absolute;
    left: 50%;
    top: 0px;
    opacity: 0.5;
    transition: all 0.4s;
    z-index: -1;
}

.frontend-panel .btn-theme:hover,
.pricing-wrap .get-started-buttton:hover {
    color: #ff5677;
    border: solid 1px #ff5677;
}

.frontend-panel .btn-theme:hover:after,
.pricing-wrap .get-started-buttton:hover:after {
    width: 100%;
    left: 0;
    opacity: 1;
    background-color: #fff;
}

.frontend-panel .btn-white-lg-outline {
    color: #fff;
    background: transparent;
    border: solid 2px #fff;
    font-weight: 500;
    padding: 10px 30px;
    font-size: 12px;
}

.frontend-panel .btn-white-lg-outline:hover {
    color: #fff;
}

.frontend-panel .btn-theme-sm-outline {
    color: #fff;
    border: solid 2px #fff;
    background: transparent;
    font-weight: 500;
    padding: 5px 18px;
    font-size: 13px;
}

.frontend-panel .btn-theme-sm-outline:hover {
    color: #fff;
    padding: 5px 18px;
}

.frontend-panel .btn-round {
    border-radius: 30px;
}

.frontend-panel .text-c {
    text-align: center;
}

.frontend-panel .text-l {
    text-align: left;
}

.frontend-panel .text-r {
    text-align: right;
}

.frontend-panel .bgburns .slides li:nth-of-type(odd) .slide-background {
    animation: bgburnsEast 25s linear infinite alternate;
    -webkit-animation: bgburnsEast 25s linear infinite alternate;
}

.frontend-panel .bgburns .slides li:nth-of-type(even) .slide-background {
    animation: bgburnsWest 25s linear infinite alternate;
    -webkit-animation: bgburnsWest 25s linear infinite alternate;
}

@keyframes bgburnsEast {
    from {
        transform: scale(1) translateX(0);
        -webkit-transform: scale(1) translateX(0);
    }
    to {
        transform: scale(1.2) translateX(100px);
        -webkit-transform: scale(1.2) translateX(100px);
    }
}

@-webkit-keyframes bgburnsEast {
    from {
        transform: scale(1) translateX(0);
        -webkit-transform: scale(1) translateX(0);
    }
    to {
        transform: scale(1.2) translateX(100px);
        -webkit-transform: scale(1.2) translateX(100px);
    }
}

@keyframes bgburnsWest {
    from {
        transform: scale(1) translateX(0);
        -webkit-transform: scale(1) translateX(0);
    }
    to {
        transform: scale(1.2) translateX(-100px);
        -webkit-transform: scale(1.2) translateX(-100px);
    }
}

@-webkit-keyframes bgburnsWest {
    from {
        transform: scale(1) translateX(0);
        -webkit-transform: scale(1) translateX(0);
    }
    to {
        transform: scale(1.2) translateX(-100px);
        -webkit-transform: scale(1.2) translateX(-100px);
    }
}

.frontend-panel #page_scroller {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-name: fadeInRight;
    animation-name: fadeInRight;
    animation-delay: 0.3s;
    -webkit-animation-delay: 0.3s;
    cursor: pointer;
    color: #fff;
    padding: 10px 11px;
    font-size: 12px;
    position: fixed;
    bottom: 25px;
    right: 25px;
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.2);
    border-radius: 3px;
}

.frontend-panel .margin-t-10 {
    margin-top: 10px;
}

.frontend-panel .checkbox {
    padding-left: 3px;
    margin-bottom: 5px;
}

.frontend-panel .checkbox label {
    display: inline-block;
    position: relative;
    padding-left: 7px;
    color: #4e4d4d;
    font-size: 13px;
}

.frontend-panel .checkbox label:before {
    content: "";
    display: inline-block;
    position: absolute;
    width: 20px;
    height: 20px;
    left: 3px;
    top: 0px;
    margin-left: -25px;
    border: 2px solid #ccc;
    border-radius: 50px;
    background-color: #fff;
    -webkit-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
    -o-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
    transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
}

.frontend-panel .checkbox-theme input[type=checkbox]:checked + label:before {
    background-color: #fff;
}

.frontend-panel .advanced-search .checkbox {
    padding-left: 5px;
    margin-bottom: 5px;
}

.frontend-panel input[type=checkbox]:checked + label:before {
    display: inline-block;
    font-family: "Font Awesome 5 Pro";
    font-size: 10px;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "\f00c";
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
    text-align: center;
    line-height: 16px;
    font-weight: 700;
}

.frontend-panel .checkbox-circle input {
    opacity: 0;
}

.frontend-panel .col-pad {
    padding: 0;
}

.frontend-panel .button-default {
    color: #555;
    background: #ddd;
}

.frontend-panel .button-default:hover {
    color: #333;
    background: #ddd;
}

.frontend-panel .button-theme {
    color: #fff !important;
    outline: none;
}

.frontend-panel .button-theme:hover {
    color: #fff;
}

.frontend-panel .read-more {
    font-weight: 500;
    font-size: 15px;
}

.frontend-panel .read-more:hover {
    color: #353535;
}

.frontend-panel .button-section .btn {
    margin-bottom: 5px;
}

.frontend-panel .form-control:focus {
    border-color: #e0e0e0;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.frontend-panel .btn.active,
.frontend-panel .btn:active {
    box-shadow: none;
}

.frontend-panel .border-btn-sm {
    cursor: pointer;
    padding: 5px 12px;
    letter-spacing: 1px;
    font-size: 11px;
    font-weight: 500;
    color: #555;
    background: #ddd;
    transition: 0.5s;
    border-radius: 3px;
    border: none;
}

.frontend-panel .border-button-default {
    color: #555;
    background: #ddd;
}

.frontend-panel .border-button-default:hover {
    color: #333;
    background: #ddd;
}

.frontend-panel .border-button-theme {
    background: transparent;
}

.frontend-panel .border-button-theme:hover {
    color: #fff;
}

.frontend-panel .alert {
    font-size: 12px;
    border-radius: 0;
    margin-bottom: 10px;
    padding: 18px 20px;
}

.frontend-panel .panel-title {
    font-size: 14px;
    font-weight: 500;
}

.frontend-panel .notice-success {
    border-left-color: #74ba28 !important;
}

.frontend-panel .notice-danger {
    border-left-color: #eb344f !important;
}

.frontend-panel .notice-info {
    border-left-color: #1db4bd !important;
}

.frontend-panel .notice-warning {
    border-left-color: #fea911 !important;
}

.frontend-panel .notice {
    padding: 15px 15px;
    background-color: #fff;
    margin-bottom: 10px;
    border-radius: 2px;
    border: 1px solid #eaeff5;
    border-left: 4px solid #838992;
    box-shadow: 0px 0px 10px 1px rgba(71, 85, 95, 0.08);
    -webkit-box-shadow: 0px 0px 10px 1px rgba(71, 85, 95, 0.08);
    -moz-box-shadow: 0px 0px 10px 1px rgba(71, 85, 95, 0.08);
    color: #4a4a4a;
}

.frontend-panel .notice strong {
    font-weight: 500;
}

.frontend-panel .progress {
    margin-bottom: 20px;
}

.frontend-panel .tab-style-2-line.tabs-below > .nav-tabs > li {
    border-top: 4px solid transparent;
}

.frontend-panel .tab-style-2-line.tabs-below > .nav-tabs > li > a {
    margin-top: 0;
}

.frontend-panel .tab-style-2-line.tabs-below > .nav-tabs > li:hover {
    border-bottom: 0;
    border-top: 4px solid #b2b1b1;
}

.frontend-panel .tab-style-2-line.tabs-below > .nav-tabs > li.active {
    margin-bottom: -2px;
    border-bottom: 0;
    border-top: 4px solid #b2b1b1;
}

.frontend-panel .tab-style-2-line.tabs-below > .tab-content {
    margin-top: -10px;
    border-top: 0;
    border-bottom: 1px solid #eee;
    padding-bottom: 15px;
}

.frontend-panel .top-header {
    padding: 5px 0;
}

.frontend-panel .top-header .top-welcome {
    margin: 0;
    color: #fff;
}

.frontend-panel .top-header .list-inline {
    padding: 8px 0;
}

.frontend-panel .top-header ul li {
    padding: 0 5px;
    display: inline-block;
    text-align: center;
    color: #aaa;
}

.frontend-panel .top-header .sign-in,
.frontend-panel .top-header .sign-in:hover {
    line-height: 36px;
    font-size: 14px;
    border-radius: 3px;
    font-weight: 500;
}

.frontend-panel .top-header ul li a {
    color: #fff;
}

.frontend-panel .top-header ul.social-list li a {
    background: transparent;
}

.frontend-panel .list-inline a {
    color: #fff;
    margin-right: 15px;
    font-size: 14px;
    font-weight: 500;
}

.frontend-panel .p1 {
    padding: 1px 0 0 0;
}

.frontend-panel .list-inline a:last-child {
    margin-right: 0;
}

.frontend-panel .list-inline a i {
    margin-right: 5px;
}

.frontend-panel .main-header {
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.12);
    position: absolute;
    top: 0;
    width: 100%;
}

.frontend-panel .main-header .logo {
    padding: 20px 0;
    float: left;
}

.frontend-panel .main-header .pad-0 {
    padding: 0;
}

.frontend-panel .main-header .btn-md {
    margin-top: 20px;
}

.frontend-panel .main-header .logo img {
    height: 50px;
}

.frontend-panel .header-shrink .logo p {
    color: #ff4068 !important;
}

.frontend-panel .header-shrink .navbar-expand-lg .navbar-nav .nav-item.login-nav .text-white {
    color: #515151 !important;
}

.frontend-panel .header-shrink .navbar-expand-lg .navbar-nav .nav-link {
    padding: 0 15px;
}

.frontend-panel .logo-3 {
    padding: 20px 0;
}

.frontend-panel .logo-3 img {
    height: 50px;
}

.frontend-panel .header-transparent {
    position: absolute;
    z-index: 9;
    width: 100%;
    border-bottom: 1px solid rgba(220, 220, 220, 0.2);
}

.frontend-panel .main-header .navbar-expand-lg .navbar-nav .nav-link {
    padding: 25px 15px;
    line-height: 20px;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 1px;
    color: #515151;
}

.frontend-panel .main-header .nav-link.dropdown-toggle {
    position: relative;
}

.frontend-panel .nav-link.dropdown-toggle {
    padding-right: 25px !important;
}

.frontend-panel .main-header .nav-link.dropdown-toggle:before {
    content: "\f078";
    font-family: "Font Awesome 5 Pro";
    font-weight: 400;
    position: absolute;
    right: 7px;
}

.frontend-panel .main-header .nav-link.dropdown-toggle:after {
    display: none;
}

.frontend-panel .main-header .navbar-expand-lg .navbar-nav .link-color:hover {
    background: transparent;
}

.frontend-panel .main-header .navbar-expand-lg .user-account {
    padding: 30px 15px;
    font-size: 14px;
    font-weight: 500;
}

.frontend-panel .main-header .navbar-expand-lg .user-account li {
    border: none;
    display: inline-block;
    font-size: 14px;
}

.frontend-panel .main-header .navbar-expand-lg .user-account li a {
    color: #353535;
}

.frontend-panel .main-header .navbar {
    padding: 0;
}

.frontend-panel .main-header .form-inline .submit {
    padding: 7px 22px;
    border-radius: 3px;
    font-size: 14px;
    margin-left: 15px;
    font-weight: 500;
}

.frontend-panel .main-header .dropdown-menu {
    min-width: 10rem;
    padding: 0;
    margin: 0;
    font-size: 13px;
    color: #212529;
    border: 1px solid transparent;
    -webkit-transition: opacity 0.4s ease 0s, visibility 0.4s linear 0s, -webkit-transform 0.4s ease 0s;
    transition: opacity 0.4s ease 0s, visibility 0.4s linear 0s, -webkit-transform 0.4s ease 0s;
    transition: opacity 0.4s ease 0s, transform 0.4s ease 0s, visibility 0.4s linear 0s;
    transition: opacity 0.4s ease 0s, transform 0.4s ease 0s, visibility 0.4s linear 0s, -webkit-transform 0.4s ease 0s;
    border-radius: 0;
    box-shadow: 0 0 35px rgba(0, 0, 0, 0.1);
}

.frontend-panel .dropdown .dropdown-item:hover > .dropdown-menu {
    display: block;
    -webkit-transition: opacity 0.4s ease 0s, visibility 0.4s linear 0s, -webkit-transform 0.4s ease 0s;
    transition: opacity 0.4s ease 0s, visibility 0.4s linear 0s, -webkit-transform 0.4s ease 0s;
    transition: opacity 0.4s ease 0s, transform 0.4s ease 0s, visibility 0.4s linear 0s;
    transition: opacity 0.4s ease 0s, transform 0.4s ease 0s, visibility 0.4s linear 0s, -webkit-transform 0.4s ease 0s;
}

.frontend-panel .main-header .dropdown-menu .dropdown-submenu .dropdown-menu {
    margin: -1px;
}

.frontend-panel .main-header .dropdown-menu a {
    padding: 10px 20px;
    color: #535353;
    transition: all 0.3s ease-in-out;
    text-transform: none;
    font-weight: 500;
    font-size: 14px;
}

.frontend-panel .main-header .dropdown-menu a:hover {
    background: transparent;
    -webkit-transform: translateX(5px);
    transform: translateX(5px);
}

.frontend-panel .bescom_vendor {
    border: 1px solid;
    padding: 2px 10px;
    display: inline-block;
}

.frontend-panel .bescom_vendor:hover {
    background-color: #fff;
    color: #ff4068 !important;
}

.frontend-panel .megamenu-li {
    position: static;
}

.frontend-panel .megamenu {
    position: absolute;
    width: 100%;
    left: 0;
    right: 0;
    padding: 15px;
}

.frontend-panel .megamenu-area {
    padding: 30px 30px 5px;
    z-index: 2;
    box-shadow: 0 0 35px rgba(0, 0, 0, 0.1);
}

.frontend-panel .megamenu-title {
    color: #ff4068;
    font-size: 14px;
    font-weight: 500;
    border-bottom: 1px solid #eee;
    padding-bottom: 10px;
    margin-bottom: 15px;
}

.frontend-panel .megamenu-section .megamenu-title {
    margin-top: 25px;
}

.frontend-panel .megamenu-section .megamenu-title:first-child {
    margin-top: 0;
}

.frontend-panel .megamenu-section {
    margin-bottom: 25px;
}

.frontend-panel .megamenu-area .dropdown-item {
    padding: 0 !important;
    margin-bottom: 8px;
    font-size: 12px;
    font-weight: 500;
}

.frontend-panel .megamenu-section p {
    font-size: 13px;
    color: #777;
}

.frontend-panel .carousel-caption {
    right: 5%;
    left: 5%;
    padding-bottom: 0;
}

.frontend-panel #banner {
    position: relative;
}

.frontend-panel .banner {
    font-family: "Poppins", sans-serif;
}

.frontend-panel .carousel-control-next,
.frontend-panel .carousel-control-prev {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 0;
    z-index: 99;
}

.frontend-panel .banner-bg {
    /* background: url("../img/banner/banner-1.html"); */
}

.frontend-panel .item-bg {
    /*background: url("../img/banner-1.jpg");
  */
    /*height: 100vh;
  */
    /*animation: bgburnsEast 25s linear infinite alternate;
  -webkit-animation: bgburnsEast 25s linear infinite alternate;
  */
}

.frontend-panel .banner-min-height {
    height: 500px;
}

.frontend-panel .banner-max-height img {
    height: 550px;
    object-fit: cover;
    object-position: top;
}

.frontend-panel .item-bg {
    /* height: 100vh;
  */
}

.frontend-panel .banner .slider-mover-left {
    width: 30px;
    height: 60px;
    line-height: 60px;
    position: absolute;
    top: 50%;
    z-index: 999;
    display: inline-block;
    left: 10px;
    text-align: center;
    -webkit-box-shadow: 0px 0px 0px 3px rgba(255, 255, 255, 0.2);
    box-shadow: 0px 0px 0px 2px rgba(255, 255, 255, 0.2);
    border-radius: 50px;
    background: rgba(0, 0, 0, 0.36);
}

.frontend-panel .banner .slider-mover-left i {
    font-size: 20px;
    color: #fff;
}

.frontend-panel .banner .slider-mover-right {
    width: 30px;
    height: 60px;
    line-height: 60px;
    position: absolute;
    top: 50%;
    z-index: 999;
    display: inline-block;
    right: 10px;
    text-align: center;
    -webkit-box-shadow: 0px 0px 0px 3px rgba(255, 255, 255, 0.2);
    box-shadow: 0px 0px 0px 2px rgba(255, 255, 255, 0.2);
    border-radius: 50px;
    background: rgba(0, 0, 0, 0.36);
}

.frontend-panel .banner .slider-mover-right i {
    font-size: 20px;
    color: #fff;
}

.frontend-panel .banner .carousel-content {
    align-self: end;
    margin: 0 auto 30px auto;
    color: #fff;
    align-self: flex-end;
}

.frontend-panel .carousel-item .text-right {
    float: right;
}

.frontend-panel .banner .banner-slider-inner {
    padding: 0;
}

.frontend-panel .banner .banner-slider-inner h1 {
    color: #fff;
    margin-bottom: 25px;
    font-size: 60px;
    font-weight: 500;
    letter-spacing: normal;
    font-family: "Poppins", sans-serif;
    text-transform: uppercase !important;
}

.frontend-panel .banner h2 {
    color: #fff;
    margin-bottom: 25px;
    font-size: 45px;
    font-weight: 500;
    letter-spacing: normal;
}

.frontend-panel .banner .banner-slider-inner .max-w {
    max-width: 490px;
}

.frontend-panel .banner .plan-price {
    font-size: 35px;
    font-weight: 500;
    padding: 0;
    position: inherit;
    bottom: 5px;
    z-index: 40;
    right: 20px;
    margin-bottom: 10px;
}

.frontend-panel .banner .plan-price sup {
    font-size: 15px;
    position: relative;
    top: -20px;
    color: #fff;
    font-weight: 500;
}

.frontend-panel .banner p {
    color: #fff;
    line-height: 1.6;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 20px;
}

.frontend-panel .banner .banner-slider-inner h1 span {
    font-weight: 900;
}

.frontend-panel .banner .banner-slider-inner .typed-text {
    font-size: 62px;
}

.frontend-panel .banner .banner-slider-inner {
    width: 100%;
    opacity: 0.7;
    background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8) 85%, #000);
    left: 0;
    right: 0;
    bottom: 0;
}

.frontend-panel .banner .banner-slider-inner .btn-lg {
    margin: 3px;
}

.frontend-panel .banner-bg-color .banner-slider-inner {
    background-color: rgba(255, 255, 255, 0.1);
}

.frontend-panel .banner .carousel-bg .carousel-item {
    height: 100vh;
    background: #3e4a5b;
}

.frontend-panel .banner_video_bg {
    max-height: 720px;
}

.frontend-panel .pattern-overlay {
    /*background-color: rgba(0, 0, 0, 0.35);
  height: 100vh;
  */
}

.frontend-panel .banner_video_bg .carousel-item {
    height: 720px;
}

.frontend-panel #particles-banner {
    width: 100%;
    height: 100vh;
    background-color: rgba(36, 42, 53, 0.7);
    position: absolute;
}

.frontend-panel #particles-banner-wrapper .banner-slider-inner {
    background-color: transparent;
}

.frontend-panel .banner-sty-2 .banner-slider-inner h1 {
    font-size: 50px;
}

.frontend-panel .search-section-2 {
    display: none;
}

.frontend-panel .banner-partycrack-box .partycrack-price {
    margin-right: 10px;
    font-weight: 500;
    color: #fff;
}

.frontend-panel .banner h3 {
    color: #fff;
    margin-bottom: 10px;
    font-size: 40px;
    font-weight: 500;
    letter-spacing: normal;
    font-family: "Poppins", sans-serif;
}

.frontend-panel .banner-partycrack-box .meta ul {
    margin-bottom: 20px;
}

.frontend-panel .banner-partycrack-box .meta ul li i {
    margin: 0 3px 3px 0;
    font-size: 16px;
}

.frontend-panel .banner-partycrack-box .meta ul li {
    display: inline-block;
    margin: 0 1px 5px 0;
    border-bottom: 3px;
    color: #fff;
    font-size: 13px;
    padding: 5px 16px;
    border-radius: 3px;
}

.frontend-panel .banner-partycrack-box .meta ul li:last-child {
    margin: 0;
}

.frontend-panel .banner-6 .rent {
    color: #fff;
}

.frontend-panel .banner-area {
    max-width: 520px;
    margin-right: auto;
}

.frontend-panel .btn-secton {
    position: absolute;
    right: 20px;
    top: 50%;
    z-index: 15;
    padding-left: 0;
    list-style: none;
    display: inline-block;
    width: 12px;
    margin: 2px 0;
}

.frontend-panel .btn-secton .carousel-indicators {
    position: relative;
    right: inherit;
    bottom: inherit;
    left: 0;
    z-index: 15;
    padding: 0;
    margin: 2px 0;
    list-style: none;
    width: 12px;
    display: inline-block;
}

.frontend-panel .btn-secton .carousel-indicators li {
    position: inherit;
    display: inline-block;
    flex: 0 1 auto;
    width: 100%;
    height: 12px;
    margin: 2px 0;
    border-radius: 0;
    background-color: rgba(255, 255, 255, 0.5);
}

.frontend-panel #search-style-2 {
    transition: all 0.4s;
    position: absolute;
    bottom: -85px;
    width: 100%;
}

.frontend-panel #search-style-1 {
    transition: all 0.4s;
}

.frontend-panel .ssa2 {
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.12);
    background-color: #fff;
    padding: 30px 30px 10px;
}

.frontend-panel .ssa2 .form-group {
    margin-bottom: 20px;
}

.frontend-panel .ssa2 .range-slider {
    margin-top: 10px;
}

.frontend-panel .search-area {
    display: none;
    width: 100%;
    bottom: 80px;
    text-align: left;
    z-index: 999;
    padding: 30px 0 15px;
}

.frontend-panel .search-area-2 {
    padding: 30px 0 0;
}

.frontend-panel .bg-grea {
    background: #e6e6e6;
}

.frontend-panel .bg-grea-2 {
    background-color: #fff;
    box-shadow: 0 0 35px rgba(0, 0, 0, 0.1);
}

.frontend-panel .bg-grea-3 {
    background: #f9f9f8;
}

.frontend-panel .bg-grea-4 {
    background: #f9f9f8 !important;
}

.frontend-panel .search-area-inner {
    margin: 0 auto;
}

.frontend-panel .search-contents {
    border-radius: 4px;
    transition: 0.9s ease-out;
}

.frontend-panel .show-search-area {
    z-index: 9999;
    transition: all 0.4s;
    display: block;
}

.frontend-panel .bootstrap-select.btn-group .dropdown-toggle .filter-option {
    display: inline-block;
    overflow: hidden;
    width: 100%;
    text-align: left;
    font-size: 13px;
    color: #777;
    font-weight: 400;
}

.frontend-panel .search-options-btn,
.frontend-panel .search-options-btn:hover {
    padding: 10px 20px 8px 20px;
    font-size: 14px;
    font-weight: 500;
    color: #fff !important;
    cursor: pointer;
    z-index: 0;
    letter-spacing: 0.5px;
    position: absolute;
    bottom: 10px;
    border-radius: 3px;
}

.frontend-panel .search-options-btn,
.frontend-panel .search-options-btn i {
    margin-left: 0;
}

.frontend-panel .search-options-btn .search-options {
    float: left;
}

.frontend-panel .search-options-btn .icon {
    float: left;
    font-weight: 400;
    margin-left: 4px;
}

.frontend-panel .search-area label {
    font-size: 12px;
    color: #555;
    font-weight: 500;
}

.frontend-panel .search-area .form-group {
    margin-bottom: 15px;
}

.frontend-panel .search-area .range-slider {
    margin-top: 10px;
}

.frontend-panel .search-fields {
    background-color: #fff;
    min-height: 45px;
    font-size: 15px;
    font-weight: 500;
    border: 1px solid #dbdbdb;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.06);
    padding: 0;
}

.frontend-panel .form-control {
    padding-left: 15px;
}

.frontend-panel .search-fields button {
    border-radius: 1px;
    box-shadow: none;
    border: none;
    background: #fff !important;
}

.frontend-panel .search-button {
    cursor: pointer;
    padding: 13px 26px 11px;
    letter-spacing: 1px;
    font-size: 14px;
    font-weight: 500;
    color: #fff;
    transition: 0.5s;
    border-radius: 3px;
    border: none;
    width: 100%;
    outline: none;
}

.frontend-panel .search-button:hover {
    color: #fff;
}

.frontend-panel .inline-search-area {
    max-width: 950px;
    padding: 0;
    margin: 0 auto;
}

.frontend-panel .inline-search-area .search-col {
    padding: 0;
    position: relative;
}

.frontend-panel .inline-search-area .icon-append {
    color: #949494;
    font-size: 15px;
    left: 10px;
    position: absolute;
    top: 18px;
}

.frontend-panel .inline-search-area .search-col:first-child .form-control {
    border-radius: 3px 0 0 3px;
}

.frontend-panel .inline-search-area .search-col .form-control,
.frontend-panel .inline-search-area button.btn-search {
    border-radius: 0;
    border: 0;
    height: 48px;
    margin-bottom: 0;
    font-size: 15px;
    font-weight: 500 !important;
    color: #777;
}

.frontend-panel .inline-search-area .search-col .form-control {
    padding-top: 12px;
}

.frontend-panel .inline-search-area .btn-search {
    border-radius: 0 3px 3px 0 !important;
    margin: 0;
}

.frontend-panel .inline-search-area .btn-search strong {
    color: #fff;
    margin-left: 5px;
}

.frontend-panel .inline-search-area .search-fields,
.frontend-panel .inline-search-area .search-fields:hover {
    border: none;
    padding: 0 !important;
}

.frontend-panel .inline-search-area .bootstrap-select button {
    padding: 12px 15px;
    border-right: solid 1px #e0e0e0 !important;
    border-radius: 0;
    margin: 0 !important;
}

.frontend-panel .inline-search-area .dropdown-toggle:after {
    margin-left: -10px;
    top: 25px;
    position: absolute;
}

.frontend-panel .main-header .inline-search-area .dropdown-menu .dropdown-item:hover {
    -webkit-transform: none;
    transform: none;
}

.frontend-panel .inline-search-area .btn-light:hover {
    color: #777;
}

.frontend-panel .inline-search-area .bootstrap-select .dropdown-toggle .filter-option {
    position: absolute;
}

.frontend-panel .inline-search-area .form-control {
    border-right: solid 1px #5a5a5a !important;
}

.frontend-panel .isa-2 {
    background-color: #fff;
    box-shadow: 0 0 35px rgba(0, 0, 0, 0.1);
}

.frontend-panel .isa-3 {
    display: none;
}

.frontend-panel .inline-search-area .isa-5 {
    display: inline;
    width: 100%;
    max-width: 950px !important;
    padding: 0;
    margin: 0 auto;
}

.frontend-panel .inline-search-area.isa-5 .bootstrap-select button {
    padding: 15px 25px 15px 20px !important;
    border-radius: 50px !important;
}

.frontend-panel .inline-search-area.isa-5 .search-fields {
    border-radius: 50px;
    font-weight: 500;
}

.frontend-panel .inline-search-area.isa-5 .btn-search {
    border-radius: 50px !important;
}

.frontend-panel .inline-search-area.isa-5 .sf {
    height: 54px;
    padding: 0 20px !important;
    color: #545454 !important;
}

.frontend-panel .inline-search-area.isa-5 .bootstrap-select button {
    color: #797979 !important;
    font-weight: 500;
}

.frontend-panel .inline-search-area.isa-5 .btn-search {
    font-size: 14px !important;
}

.frontend-panel .isa-6 {
    background-color: #fff;
    padding: 30px;
    max-width: 320px;
    margin-left: auto;
    border-radius: 0;
}

.frontend-panel .isa-6 .search-fields {
    margin-bottom: 20px;
}

.frontend-panel .isa-6 strong {
    color: #fff;
}

.frontend-panel .isa-6 .btn {
    height: 45px;
    font-weight: 500 !important;
}

.frontend-panel .isa-6 .btn i {
    margin-right: 3px;
}

.frontend-panel .bi-2 {
    margin-top: 100px;
    text-align: left;
}

.frontend-panel .bi-3 {
    margin-top: 100px;
}

.frontend-panel .bi-4 {
    margin-top: 50px;
}

.frontend-panel .filteriz-navigation {
    padding: 0;
    margin: 5px 0 26px 0;
}

.frontend-panel .filteriz-navigation li {
    font-size: 15px;
    color: #535353;
    text-align: center;
    font-weight: 500;
    padding: 5px 25px 5px 25px;
    border: 0;
    margin-bottom: 4px;
    cursor: pointer;
    border-radius: 50px !important;
    background: white !important;
    box-shadow: 0 0 7px rgba(0, 0, 0, 0.2) !important;
}

.frontend-panel .portfolio-item,
.frontend-panel .portfolio-item:focus {
    outline: none;
    margin-bottom: 30px;
    position: relative;
    cursor: pointer;
}

.frontend-panel .portfolio-item img,
.frontend-panel .portfolio-item img:focus {
    outline: none;
    width: 100%;
    height: 100%;
}

.frontend-panel .portfolio-item:hover img {
    zoom: 1;
    filter: alpha(opacity=20);
    -webkit-opacity: 0.1;
    opacity: 0.1;
}

.frontend-panel .portfolio-item:hover .portfolio-content {
    display: inline;
    -webkit-transition-delay: 0.5s;
    transition-delay: 0.5s;
    background-color: rgba(0, 0, 0, 0.9);
    color: #fff;
    z-index: -999;
}

.frontend-panel .portfolio-content {
    display: none;
    top: 0;
    width: 100%;
    text-align: center;
    padding: 20px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-transition: all 0.8s ease;
    transition: all 0.8s ease;
    color: #1c1c1c;
    height: 100%;
    position: absolute;
    right: 0;
}

.frontend-panel .portfolio-content-inner {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}

.frontend-panel .portfolio-content p {
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 1px;
    color: #fff;
}

.frontend-panel .overview-box p {
    color: #333;
    -ms-filter: "progid: DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
    -webkit-transition: all 0.5s linear;
    transition: all 0.5s linear;
}

.frontend-panel .overview {
    z-index: 9;
    cursor: default;
    overflow: hidden;
    text-align: center;
    position: relative;
    margin-bottom: 16px;
    box-shadow: 0 0 3px #ddd;
}

.frontend-panel .overview .mask,
.frontend-panel .overview .content {
    width: 100%;
    height: 100%;
    position: absolute;
    overflow: hidden;
    top: 25%;
    left: 0;
}

.frontend-panel .overview img {
    display: block;
    position: relative;
    width: 100%;
    height: 232px;
}

.frontend-panel .overview .big-img {
    height: 480px;
}

.frontend-panel .overview-box img {
    left: 10px;
    margin-left: -10px;
    position: relative;
    -webkit-transition: all 0.6s ease-in-out;
    transition: all 0.6s ease-in-out;
    border: none;
}

.frontend-panel .overview-box:hover > img {
    -webkit-transform: scale(2) rotate(10deg);
    transform: scale(2) rotate(10deg);
    -ms-filter: "progid: DXImageTransform.Microsoft.Alpha(Opacity=30)";
    filter: alpha(opacity=30);
    opacity: 0.3;
}

.frontend-panel .overview h2 {
    color: #fff;
    text-align: center;
    position: relative;
    font-size: 18px;
    padding: 10px;
    background: rgba(0, 0, 0, 0.8);
    margin: 20px 0 0 0;
    text-shadow: none;
}

.frontend-panel .overview-box h2 {
    color: #333;
    margin: 0 20px 0;
    background: transparent;
    -webkit-transform: scale(0);
    transform: scale(0);
    -webkit-transition: all 0.5s linear;
    transition: all 0.5s linear;
    -ms-filter: "progid: DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    opacity: 0;
}

.frontend-panel .overview a.btn {
    display: inline-block;
    overflow: hidden;
}

.frontend-panel .overview-box a.btn {
    -ms-filter: "progid: DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
    transition: all 0.5s linear;
    padding: 4px 20px;
}

.frontend-panel .overview-box:hover h2,
.frontend-panel .overview-box:hover p,
.frontend-panel .overview-box:hover a.btn {
    -webkit-transform: scale(1);
    transform: scale(1);
    -ms-filter: "progid: DXImageTransform.Microsoft.Alpha(Opacity=100)";
    filter: alpha(opacity=100);
    opacity: 1;
}

.frontend-panel .most-popular-places .col-pad {
    padding: 0 8px;
}

.frontend-panel .bootstrap-select {
    width: 100% !important;
    font-family: "Poppins", sans-serif;
}

.frontend-panel .bootstrap-select button {
    color: #545454 !important;
    font-weight: 500;
    border-radius: 3px;
    outline: none;
    padding: 13px 15px 8px 15px;
}

.frontend-panel .bootstrap-select .dropdown-menu li {
    padding: 0;
}

.frontend-panel .bootstrap-select .dropdown-menu li a {
    padding: 10px;
}

.frontend-panel .bootstrap-select .dropdown-menu {
    padding: 0;
    margin: 0;
    border: none;
    border-bottom: none;
    border-radius: 0;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.12);
}

.frontend-panel .bootstrap-select.btn-group.show-tick .dropdown-menu li.selected a span.check-mark {
    margin-top: 12px;
}

.frontend-panel .bootstrap-select .dropdown-menu li a {
    padding: 10px;
    font-weight: 500;
    font-size: 14px;
    outline: none;
    border: 0 !important;
}

.frontend-panel .dropdown-item.active,
.frontend-panel .dropdown-item:active {
    background-color: transparent;
}

.frontend-panel .bootstrap-select .dropdown-menu li a:hover {
    border: none;
    font-size: 14px;
    font-weight: 500;
}

.frontend-panel .bootstrap-select .btn-default.active,
.frontend-panel .bootstrap-select .btn-default:active,
.frontend-panel .bootstrap-select .open > .dropdown-toggle.btn-default {
    color: #333;
    background-color: #eee;
    border-color: transparent !important;
    box-shadow: none !important;
}

.frontend-panel .bootstrap-select .btn-default:hover {
    background: transparent !important;
    color: #777 !important;
    box-shadow: none !important;
}

.frontend-panel .bootstrap-select .btn-default.active,
.frontend-panel .bootstrap-select .btn-default:active,
.frontend-panel .bootstrap-select .open > .dropdown-toggle.btn-default {
    color: #333;
    background-color: transparent !important;
    border-color: #adadad;
}

.frontend-panel .btn-default.active,
.frontend-panel .btn-default:active,
.frontend-panel .open > .dropdown-toggle.btn-default {
    color: #333;
    background-color: transparent !important;
    box-shadow: none !important;
    border-color: #adadad;
}

.frontend-panel .bootstrap-select .dropdown-menu.inner {
    border: 0;
    border-top: none;
    box-shadow: 0 0 35px rgba(0, 0, 0, 0.1);
}

.frontend-panel .btn-light:hover {
    color: #999;
}

.frontend-panel .bootstrap-select .dropdown-toggle:focus {
    outline: none !important;
}

.frontend-panel .bootstrap-select .dropdown-menu > .active > a,
.frontend-panel .bootstrap-select .dropdown-menu > .active > a:focus,
.frontend-panel .bootstrap-select .dropdown-menu > .active > a:hover {
    text-decoration: none;
    background-color: #fff;
    outline: 0;
}

.frontend-panel .bootstrap-select .bs-actionsbox,
.frontend-panel .bootstrap-select .bs-donebutton,
.frontend-panel .bs-searchbox {
    padding: 4px 8px;
    background: #eee;
    border-bottom: 1px solid #d5d5d5;
}

.frontend-panel .bootstrap-select .bs-searchbox .form-control {
    margin-bottom: 0;
    width: 100%;
    float: none;
    height: 36px;
    border: 1px solid #ddd;
    box-shadow: none;
}

.frontend-panel .bootstrap-select .dropdown-toggle .filter-option-inner {
    overflow: hidden;
    font-size: 15px;
}

.frontend-panel .bootstrap-select .dropdown-menu li a.active:hover .text {
    color: #515151;
}

.frontend-panel .main-title {
    margin-bottom: 50px;
    text-align: center;
}

.frontend-panel .main-title h1 {
    font-size: 32px;
    font-weight: 500;
    margin-bottom: 5px;
}

.frontend-panel .main-title h2 {
    font-size: 28px;
    font-weight: 500;
    margin-bottom: 5px;
}

.frontend-panel .main-title p {
    font-size: 17px;
    margin-bottom: 5px;
}

.frontend-panel .main-title-2 {
    margin-bottom: 50px;
    text-align: center;
}

.frontend-panel .main-title-2 h3 {
    font-weight: 500;
    font-size: 23px;
    color: #333f48;
    text-align: center;
    margin-bottom: 35px;
}

.frontend-panel .main-title-3 {
    text-align: left;
    margin-bottom: 0;
}

.frontend-panel .main-title-3 h1 {
    color: #fff;
    opacity: 0.9;
}

.frontend-panel .main-title-3 p {
    color: #fff;
    opacity: 0.9;
    margin-bottom: 20px;
}

.frontend-panel .mb-35 {
    margin-bottom: 35px;
}

.frontend-panel .mt2 h1 {
    font-size: 35px;
    font-weight: 500;
    margin-bottom: 20px;
}

.frontend-panel .contact-2 .form-control {
    padding: 10px 17px;
    color: #495057 !important;
    width: 100%;
    min-height: 45px;
    font-size: 15px;
    font-weight: 500;
    border-radius: 3px;
    background-color: #fff;
    border: 1px solid #dbdbdb;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.06);
}

.frontend-panel .contact-2 .form-control:focus {
    color: #495057;
    border: 1px solid #dbdbdb;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.06);
}

.frontend-panel .contact-2 textarea {
    min-height: 200px !important;
}

.frontend-panel .contact-2 .form-group {
    margin-bottom: 30px;
}

.frontend-panel .contact-2 textarea {
    min-height: 240px;
}

.frontend-panel .contact-2 .btn-message {
    border-radius: 50px;
    padding: 10px 60px 11px;
}

.frontend-panel .contact-2 .main-title {
    margin-bottom: 60px;
}

.frontend-panel .contact-map {
    height: 350px !important;
}

.frontend-panel .contact-map-content .map-content {
    width: 250px;
}

.frontend-panel .contact-2 .contact-info .media i {
    width: 55px;
    height: 55px;
    text-align: center;
    background-color: #fff;
    line-height: 55px;
    margin-right: 20px;
    font-size: 20px;
    color: #000;
    border-radius: 3px;
}

.frontend-panel .contact-2 .contact-info .media h5 {
    letter-spacing: 1px;
    font-weight: 500;
    font-size: 15px;
    font-style: normal;
    margin: 5px 0 5px;
    color: #fff;
}

.frontend-panel .contact-2 .contact-info .media p {
    margin-bottom: 0;
    color: #fff;
    font-size: 14px;
}

.frontend-panel .contact-2 .contact-info .media p a {
    color: #fff;
}

.frontend-panel .contact-2 .contact-info .media {
    margin-bottom: 25px;
}

.frontend-panel .contact-2 .contact-info {
    padding: 30px;
    background: url(../imgs/contact-info.jpg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    border-radius: 10px;
    margin-left: 50px;
}

.frontend-panel .partycrack-box {
    position: relative;
    margin-bottom: 30px;
    box-shadow: 0 0 35px rgba(0, 0, 0, 0.1);
}

.frontend-panel .partycrack-box:hover {
    -webkit-box-shadow: 0 10px 30px 0 rgba(58, 87, 135, 0.15);
    -moz-box-shadow: 0 10px 30px 0 rgba(58, 87, 135, 0.15);
    box-shadow: 0 10px 30px 0 rgba(58, 87, 135, 0.15);
}

.frontend-panel .partycrack-thumbnail {
    position: relative;
}

.frontend-panel .partycrack-thumbnail:before {
    content: "";
    top: 0;
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 9;
    background: linear-gradient(to top, rgba(35, 35, 37, 0.3) 0%, rgba(35, 35, 37, 0.1) 35%, rgba(22, 22, 23, 0) 60%, rgba(0, 0, 0, 0) 100%);
    border-radius: 4px 4px 0 0;
    opacity: 1;
}

.frontend-panel .partycrack-thumbnail .partycrack-img img {
    height: 250px;
    object-fit: cover;
    width: 100%;
}

.frontend-panel .content_vendor {
    width: 100%;
}

.frontend-panel .partycrack-box .detail {
    padding: 20px 10px 15px 20px;
    background: white;
}

.frontend-panel .partycrack-box .detail .title {
    font-size: 21px;
    margin-bottom: 10px;
    font-weight: 500;
}

.frontend-panel .partycrack-box .detail .title a:hover {
    color: #343a40;
}

.frontend-panel .partycrack-box .detail .location {
    margin: 0;
    font-size: 14px;
    font-weight: 500;
}

.frontend-panel .partycrack-box .detail .location a {
    color: #5f5e5e;
}

.frontend-panel .partycrack-box .detail .location i {
    margin-right: 3px;
}

.frontend-panel .partycrack-box .facilities-list {
    background: #f7f7f7;
    padding: 20px 20px;
}

.frontend-panel .partycrack-box .facilities-list li {
    list-style: none;
    float: left;
    font-weight: 500;
    line-height: 25px;
    font-size: 14px;
    margin-right: 25px;
}

.frontend-panel .partycrack-box .facilities-list li:last-child {
    margin: 0;
}

.frontend-panel .partycrack-box .facilities-list li span {
    display: table;
    font-weight: 500;
}

.frontend-panel .partycrack-box .footer {
    padding: 15px 20px;
    font-size: 14px;
    font-weight: 500;
    color: #535353;
}

.frontend-panel .partycrack-box .footer a {
    color: #535353;
}

.frontend-panel .partycrack-box .footer i {
    margin-right: 5px;
}

.frontend-panel .price-box {
    color: #fff;
    text-align: right;
    font-size: 15px;
    position: absolute;
    bottom: 15px;
    right: 15px;
    z-index: 20;
}

.frontend-panel .ratings span {
    color: #fff;
}

.frontend-panel .listing-badges {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    display: block;
    font-size: 15px;
    padding: 0;
    overflow: hidden;
    height: 100px;
}

.frontend-panel .listing-badges .featured {
    float: left;
    transform: rotate(-45deg);
    left: -67px;
    top: 17px;
    position: relative;
    text-align: center;
    width: 200px;
    letter-spacing: 1px;
    font-size: 12px;
    margin: 0;
    padding: 7px 10px;
    font-weight: 500;
    color: #fff;
    box-shadow: 1px 2px 3px 0 rgba(2, 0, 0, 0.2);
    background-color: rgba(0, 0, 0, 0.5);
}

.frontend-panel .slick-slider-area {
    position: relative;
}

.frontend-panel .slick-slide-item {
    padding: 0 15px;
}

.frontend-panel .slick-arrow-buton {
    width: 35px;
    height: 35px;
    line-height: 35px;
    top: 43%;
    font-size: 20px;
    border-radius: 3px;
    position: absolute;
    cursor: pointer;
    color: #000;
    z-index: 999;
    background: white;
    cursor: pointer;
    text-align: center;
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.15);
}

.frontend-panel .slick-arrow-buton:hover {
    color: #fff;
}

.frontend-panel .slick-next {
    right: -15px;
}

.frontend-panel .slick-prev {
    left: -15px;
}

.frontend-panel .slick-slide,
.frontend-panel .slick-slide:focus * {
    outline: none !important;
}

.frontend-panel *:focus {
    outline: none !important;
}

.frontend-panel .slick-btn {
    text-align: center;
    max-width: 90px !important;
    margin: 0 auto;
    border-radius: 5px;
    position: relative;
    top: 43px;
}

.frontend-panel .slick-btn .sab-4 {
    border-radius: 50px 0 0 50px;
}

.frontend-panel .slick-btn .slick-arrow-buton-2:last-child {
    border: none;
}

.frontend-panel .slick-btn .sab-3 {
    border-radius: 0 50px 50px 0;
}

.frontend-panel .slick-btn .slick-arrow-buton-2 {
    width: 45px;
    height: 30px;
    line-height: 30px;
    cursor: pointer;
    text-align: center;
    display: block;
    float: left;
    border-right: solid 1px #e4e4e4;
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.15);
    background: white;
    font-size: 20px;
    position: absolute;
    bottom: 12px;
}

.frontend-panel .slick-btn .slick-next {
    right: 0;
}

.frontend-panel .slick-btn .slick-prev {
    left: 0;
}

.frontend-panel .partycrack-box-2 {
    box-shadow: 0 0 10px 1px rgba(71, 85, 95, 0.08);
    -webkit-box-shadow: 0 0 10px 1px rgba(71, 85, 95, 0.08);
    -moz-box-shadow: 0 0 10px 1px rgba(71, 85, 95, 0.08);
    position: relative;
    margin-bottom: 30px;
    overflow: hidden;
}

.frontend-panel .partycrack-box-2:hover {
    -webkit-box-shadow: 0 10px 30px 0 rgba(58, 87, 135, 0.15);
    -moz-box-shadow: 0 10px 30px 0 rgba(58, 87, 135, 0.15);
    box-shadow: 0 10px 30px 0 rgba(58, 87, 135, 0.15);
}

.frontend-panel .partycrack-box-2 .detail .title {
    font-size: 22px;
    margin-bottom: 10px;
    font-weight: 500;
}

.frontend-panel .partycrack-box-2 .partycrack-img img {
    width: 100%;
}

.frontend-panel .partycrack-box-2 .detail .title a:hover {
    color: #343a40;
}

.frontend-panel .partycrack-box-2 .detail .location {
    margin: 0;
}

.frontend-panel .partycrack-box-2 .detail .location a {
    font-size: 14px;
    color: #5f5e5e;
}

.frontend-panel .partycrack-box-2 .detail .location i {
    margin-right: 5px;
}

.frontend-panel .partycrack-box-2 .listing-badges .featured {
    left: -51px;
}

.frontend-panel .partycrack-box-2 .listing-time {
    position: absolute;
    z-index: 9;
    top: 8px;
    left: 30px;
}

.frontend-panel .partycrack-box-2 .facilities-list {
    background: #f7f7f7;
    padding: 20px 20px;
}

.frontend-panel .partycrack-box-2 .facilities-list li {
    list-style: none;
    float: left;
    font-weight: 500;
    line-height: 25px;
    font-size: 14px;
    margin-right: 25px;
}

.frontend-panel .partycrack-box-2 .facilities-list li span {
    display: table;
    font-weight: 500;
}

.frontend-panel .partycrack-box-2 .facilities-list li:last-child {
    margin: 0;
}

.frontend-panel .partycrack-box-2 .footer {
    padding: 15px 35px 15px 20px;
    font-size: 14px;
    color: #535353;
    font-weight: 500;
}

.frontend-panel .partycrack-box-2 .footer a {
    color: #535353;
}

.frontend-panel .partycrack-box-2 .footer i {
    margin-right: 5px;
}

.frontend-panel .partycrack-box-2 .footer span {
    float: right;
}

.frontend-panel .hdg {
    padding: 20px;
}

.frontend-panel .op-2 {
    padding: 25px 0 0;
}

.frontend-panel .partycrack-box-2 .tag {
    left: 30px;
}

.frontend-panel .partycrack-box-3 {
    position: relative;
    margin-bottom: 30px;
    background-color: #fff;
    box-shadow: 0 0 35px rgba(0, 0, 0, 0.1);
}

.frontend-panel .partycrack-box-3:hover {
    -webkit-box-shadow: 0 10px 30px 0 rgba(58, 87, 135, 0.15);
    -moz-box-shadow: 0 10px 30px 0 rgba(58, 87, 135, 0.15);
    box-shadow: 0 10px 30px 0 rgba(58, 87, 135, 0.15);
}

.frontend-panel .partycrack-box-3 .top {
    padding: 20px 20px 15px;
    background-color: #fff;
}

.frontend-panel .partycrack-box-3 .top .title {
    font-size: 20px;
    margin-bottom: 0;
    font-weight: 500;
}

.frontend-panel .partycrack-box-3 .top .title a {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    display: inline-block;
}

.frontend-panel .partycrack-box-3 .details {
    position: relative;
    padding: 0;
    z-index: 9;
}

.frontend-panel .tag {
    position: absolute;
    top: 15px;
    left: 15px;
    z-index: 20;
    font-size: 11px;
    letter-spacing: 0.8px;
    background: rgba(0, 0, 0, 0.15);
    -webkit-box-shadow: 0px 0px 0px 1px rgba(255, 255, 255, 0.2);
    box-shadow: 0px 0px 0px 1px rgba(255, 255, 255, 0.2);
    color: #fff;
    padding: 5px 14px 3px;
    font-weight: 500;
    border-radius: 3px;
}

.frontend-panel .tag:after {
    position: absolute;
    content: "";
    width: 0;
    height: 0;
    border-top: 0;
    border-bottom: 10px solid transparent;
    bottom: -10px;
    left: 0;
}

.frontend-panel .plan-price {
    font-size: 40px;
    font-weight: 500;
    padding: 0;
    position: absolute;
    bottom: 5px;
    z-index: 40;
    right: 20px;
}

.frontend-panel .plan-price sup {
    font-size: 15px;
    position: relative;
    top: -30px;
    color: #fff;
    font-weight: 500;
}

.frontend-panel .plan-price span {
    font-size: 15px;
    color: #fff;
    font-weight: 500;
}

.frontend-panel .partycrack-box-3 .top .location {
    font-size: 14px;
    font-weight: 500;
}

.frontend-panel .partycrack-box-3 .top .location a {
    color: #535353;
}

.frontend-panel .partycrack-box-3 .top .location i {
    margin-right: 5px;
}

.frontend-panel .partycrack-box-3 .facilities-list {
    background: #f7f7f7;
    padding: 10px 0;
}

.frontend-panel .partycrack-box-3 .facilities-list li {
    list-style: none;
    float: left;
    line-height: 35px;
    text-align: center;
    width: 33%;
}

.frontend-panel .partycrack-box-3 .facilities-list p {
    margin-bottom: 0;
    font-weight: 500;
    font-size: 12px;
}

.frontend-panel .partycrack-box-3 .facilities-list li i {
    font-size: 20px;
    font-weight: 500;
}

.frontend-panel .partycrack-box-3 .footer {
    background-color: #fff;
}

.frontend-panel .partycrack-box-3 .footer p {
    line-height: 48px;
    font-size: 14px;
    margin: 0 0 0 20px;
    color: #ff4068;
}

.frontend-panel .partycrack-box-3 .footer .catg p {
    color: #333;
}

.frontend-panel .partycrack-box-3 .footer ul li {
    display: inline-block;
    width: 48px;
    margin: 0 0 0 -4px;
}

.frontend-panel .partycrack-box-3 .footer ul li a {
    display: block;
    font-size: 18px;
    height: inherit;
    font-weight: 500;
    line-height: 48px;
    text-align: center;
    width: 48px;
    border-left: 1px solid #e5e5e5;
    color: #535353;
}

.frontend-panel .partycrack-box-3 .price-box {
    color: #fff;
    text-align: right;
    font-size: 15px;
    position: absolute;
    bottom: 30px;
    right: 15px;
    z-index: 20;
}

.frontend-panel .partycrack-box-3 .price-box span {
    font-size: 20px;
}

.frontend-panel .partycrack-box-3 .ratings {
    text-align: right;
    position: absolute;
    bottom: 10px;
    right: 15px;
    z-index: 20;
    font-size: 13px;
    color: #ffc12b;
}

.frontend-panel .partycrack-box-3 .ratings span {
    font-size: 14px;
    opacity: 0.9;
}

.frontend-panel .partycrack-box-4 {
    box-shadow: 0 0 35px rgba(0, 0, 0, 0.1);
    width: 100%;
    background-color: #fff;
    border-radius: 20px 20px 0 0;
    margin-bottom: 30px;
}

.frontend-panel .partycrack-box-4:hover {
    -webkit-box-shadow: 0 10px 30px 0 rgba(58, 87, 135, 0.15);
    -moz-box-shadow: 0 10px 30px 0 rgba(58, 87, 135, 0.15);
}

.frontend-panel .partycrack-box-4 .detail .heading {
    padding: 20px;
}

.frontend-panel .partycrack-box-4 .detail .heading {
    margin-bottom: 0;
}

.frontend-panel .partycrack-box-4 .detail h3 {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 10px;
}

.frontend-panel .partycrack-box-4 .detail .location {
    margin: 0;
    font-size: 15px;
    font-weight: 500;
}

.frontend-panel .partycrack-box-4 .detail .location a {
    color: #535353;
}

.frontend-panel .partycrack-box-4 .detail .location i {
    margin-right: 3px;
}

.frontend-panel .partycrack-box-4 .partycrack-listing {
    text-align: center;
    width: 100%;
    border-top: 1px solid #ebebeb;
    border-bottom: 0;
}

.frontend-panel .partycrack-box-4 .partycrack-listing ul li {
    color: #5a5959;
    line-height: 40px !important;
    text-align: center;
    width: 33.3%;
    float: left;
    font-size: 14px;
    font-weight: 500;
    border-right: 1px solid #ebebeb;
}

.frontend-panel .partycrack-box-4 .partycrack-listing ul li:last-child {
    border-right: none;
}

.frontend-panel .partycrack-box-4 .partycrack-listing span:last-child {
    border: none;
}

.frontend-panel .partycrack-box-4 .partycrack-photo {
    position: relative;
}

.frontend-panel .partycrack-box-4 .partycrack-photo img {
    border-radius: 0;
}

.frontend-panel .partycrack-box-4 .partycrack-photo:before {
    content: "";
    top: 0;
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 9;
    background: linear-gradient(to top, rgba(35, 35, 37, 0.9) 0%, rgba(35, 35, 37, 0.45) 35%, rgba(22, 22, 23, 0) 60%, rgba(0, 0, 0, 0) 100%);
    border-radius: 4px 4px 0 0;
    opacity: 1;
}

.frontend-panel .partycrack-box-4 .price-box {
    color: #fff;
    text-align: right;
    font-size: 15px;
    position: absolute;
    bottom: 10px;
    right: 15px;
    z-index: 20;
}

.frontend-panel .partycrack-box-4 .tag {
    position: absolute;
    top: 15px;
    left: 15px;
    z-index: 20;
    font-size: 11px;
    letter-spacing: 1px;
    background: rgba(0, 0, 0, 0.15);
    -webkit-box-shadow: 0px 0px 0px 1px rgba(255, 255, 255, 0.2);
    box-shadow: 0px 0px 0px 1px rgba(255, 255, 255, 0.2);
    color: #fff;
    padding: 5px 14px 3px;
    font-weight: 500;
    border-radius: 3px;
}

.frontend-panel .partycrack-box-5 {
    width: 100%;
    background-color: #fff;
    margin-bottom: 30px;
    box-shadow: 0 0 35px rgba(0, 0, 0, 0.1);
}

.frontend-panel .partycrack-box-5 .partycrack-photo {
    position: relative;
}

.frontend-panel .partycrack-box-5 .partycrack-photo:before {
    content: "";
    top: 0;
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 9;
    background: linear-gradient(to top, rgba(35, 35, 37, 0.9) 0%, rgba(35, 35, 37, 0.45) 35%, rgba(22, 22, 23, 0) 60%, rgba(0, 0, 0, 0) 100%);
    border-radius: 4px 4px 0 0;
    opacity: 1;
}

.frontend-panel .partycrack-box-5:hover {
    -webkit-box-shadow: 0 10px 30px 0 rgba(58, 87, 135, 0.15);
    -moz-box-shadow: 0 10px 30px 0 rgba(58, 87, 135, 0.15);
    box-shadow: 0 10px 30px 0 rgba(58, 87, 135, 0.15);
}

.frontend-panel .partycrack-box-5 .detail h3 {
    font-size: 21px;
    margin-bottom: 10px;
    font-weight: 500;
}

.frontend-panel .partycrack-box-5 .detail .location {
    margin-bottom: 15px;
    font-size: 15px;
    font-weight: 500;
}

.frontend-panel .partycrack-box-5 .detail .location a {
    color: #535353;
}

.frontend-panel .partycrack-box-5 .detail h3 a:hover {
    color: #535353;
}

.frontend-panel .partycrack-box-5 .detail {
    padding: 20px;
    display: inline-block;
    width: 100%;
}

.frontend-panel .partycrack-box-5 .detail .heading {
    margin-bottom: 20px;
    width: 100%;
    display: inline-block;
}

.frontend-panel .partycrack-box-5 .tag-2 {
    position: absolute;
    top: 20px;
    left: 20px;
    z-index: 20;
    font-size: 13px;
    letter-spacing: 0.5px;
    color: #fff;
    padding: 5px 14px 4px;
    font-weight: 500;
    border-radius: 3px 3px 3px 0;
}

.frontend-panel .partycrack-box-5 .tag-2:after {
    position: absolute;
    content: "";
    width: 0;
    height: 0;
    border-top: 0;
    border-bottom: 10px solid transparent;
    bottom: -10px;
    left: 0;
}

.frontend-panel .partycrack-box-5 .facilities-list li {
    list-style: none;
    width: 33.33%;
    float: left;
    font-weight: 500;
    line-height: 32px;
    font-size: 15px;
    color: #535353;
}

.frontend-panel .about-text {
    padding: 15px 0 0 15px;
}

.frontend-panel .about-text p {
    margin-bottom: 20px;
}

.frontend-panel .about-text h3 {
    font-weight: 500;
    margin-bottom: 20px;
    font-size: 30px;
}

.frontend-panel .about-info {
    position: relative;
    padding-right: 15px;
}

.frontend-panel .about-info img {
    border-radius: 5px;
}

.frontend-panel .about-info .partycrack-info {
    text-align: center;
    max-width: 100%;
    z-index: 9;
    position: absolute;
    bottom: 10px;
    right: 0;
    left: -7.5px;
}

.frontend-panel .about-info .partycrack-info ul li {
    display: inline-block;
    width: 19%;
    padding: 10px 0;
    opacity: 0.9;
    border-radius: 5px;
    color: #fff !important;
}

.frontend-panel .about-info .partycrack-info ul li h4 {
    font-size: 12px;
    font-weight: 500;
    margin: 5px 0;
    color: #fff;
}

.frontend-panel .about-info .partycrack-info ul li i {
    font-size: 20px;
    color: #fff;
}

.frontend-panel .service-box {
    padding: 15px;
    margin-bottom: 30px;
    box-shadow: 0 0 35px rgba(0, 0, 0, 0.1);
    border: none;
}

.frontend-panel .service-box .detail {
    padding: 20px 0 0;
}

.frontend-panel .service-box img {
    border-radius: 0;
}

.frontend-panel .service-box p {
    font-size: 15px;
    line-height: 25px;
    margin-bottom: 10px;
}

.frontend-panel .service-box .heading {
    display: block;
    margin-bottom: 10px;
}

.frontend-panel .service-box .heading .number {
    margin-right: 10px;
    float: left;
}

.frontend-panel .service-box .heading .number h5 {
    font-size: 35px;
    font-weight: 500;
    margin: 0;
    line-height: 35px;
}

.frontend-panel .service-box .title {
    float: left;
}

.frontend-panel .service-box .heading .title h4 {
    font-size: 16px;
    font-weight: 500;
    line-height: 38px;
    margin: 0;
}

.frontend-panel .service-box .read-more {
    font-weight: 500;
    font-size: 14px;
}

.frontend-panel .our-agent-sidebar .team-1 {
    margin: 0 auto 0;
    box-shadow: none;
    border-bottom: solid 1px #f7f7f7;
}

.frontend-panel .our-agent-sidebar .carousel-indicators li {
    width: 10px;
    height: 10px;
    background-color: #8a8989;
    border-radius: 50px;
}

.frontend-panel .our-agent-sidebar .carousel-indicators {
    bottom: 5px;
}

.frontend-panel .our-agent-sidebar .p-20 {
    padding: 20px 20px 0;
}

.frontend-panel .our-agent-sidebar {
    margin-bottom: 20px;
}

.frontend-panel .our-agent-sidebar .team-1 .team-details {
    text-align: center;
    padding: 25px 20px 45px;
}

.frontend-panel .pricing-1 {
    margin-bottom: 30px;
    box-shadow: 0 0 35px rgba(0, 0, 0, 0.1);
}

.frontend-panel .pricing-1 .plan-header {
    background-color: #fff;
    padding: 40px 22px 0;
    border-width: 1px;
    position: relative;
    text-align: center;
}

.frontend-panel .pricing-1 .plan-header h5 {
    margin-bottom: 5px;
    font-weight: 500;
}

.frontend-panel .pricing-1 .plan-header .plan-price sup {
    font-size: 24px;
    position: relative;
    top: -30px;
    color: #535353;
    font-weight: 500;
}

.frontend-panel .pricing-1 .plan-header .plan-price {
    font-size: 60px;
    font-weight: 500;
    position: relative;
    text-align: center;
    padding: 0;
}

.frontend-panel .pricing-1 .plan-header .plan-price span {
    font-size: 16px;
    color: #535353;
    font-weight: 500;
}

.frontend-panel .pricing-1 .plan-list {
    background-color: #fff;
    border-width: 0 1px 1px;
    padding: 20px 0 40px;
}

.frontend-panel .pricing-1 .plan-list ul {
    display: table;
    margin: 0 auto;
    padding: 0 40px;
}

.frontend-panel .pricing-1 .plan-list ul li {
    list-style: none;
    border-bottom: 1px dashed #eaeceb;
    line-height: 42px;
    font-size: 15px;
    font-weight: 500;
}

.frontend-panel .pricing-1 .plan-list ul li i {
    margin-right: 12px;
}

.frontend-panel .pricing-1 .plan-list ul li:last-child {
    border-bottom: none;
}

.frontend-panel .pricing-1 .plan-button {
    padding-top: 20px;
}

.frontend-panel .pricing-1 .pricing-btn {
    border-radius: 3px;
}

.frontend-panel .team-1 {
    margin: 0 auto 30px;
    background: white;
    box-shadow: 0 0 35px rgba(0, 0, 0, 0.1);
}

.frontend-panel .team-1:hover {
    box-shadow: 0 10px 30px 0 rgba(58, 87, 135, 0.15);
}

.frontend-panel .team-1 img {
    width: 100%;
}

.frontend-panel .team-1 .team-details {
    text-align: center;
    padding: 25px 20px 25px;
}

.frontend-panel .team-1 .team-details h5 {
    margin-bottom: 10px;
    font-weight: 500;
}

.frontend-panel .team-1 .team-details h5 a {
    font-size: 18px;
}

.frontend-panel .team-1 .team-details h6 {
    font-size: 15px;
    margin-bottom: 0;
    color: #535353;
}

.frontend-panel .team-1 .team-details h4 {
    font-size: 15px;
    margin: 10px 0 0;
    color: #535353;
}

.frontend-panel .team-1 .team-details h4 a {
    color: #535353;
}

.frontend-panel .team-1 .team-photo {
    position: relative;
}

.frontend-panel .team-1 .team-photo .social-list {
    position: absolute;
    top: 15px;
    text-align: center;
    z-index: 20;
    border-radius: 5px 5px 0 0;
    left: 15px;
    width: 44px;
    color: #fff;
}

.frontend-panel .team-1 .team-photo .social-list a {
    color: #fff;
    width: 40px;
    height: 40px;
    line-height: 40px;
    display: inline-block;
    margin-bottom: 10px;
    border-radius: 3px;
    background: rgba(0, 0, 0, 0.15);
    -webkit-box-shadow: 0px 0px 0px 2px rgba(255, 255, 255, 0.2);
    box-shadow: 0px 0px 0px 2px rgba(255, 255, 255, 0.2);
}

.frontend-panel .team-1 .team-photo .social-list a:hover {
    box-shadow: none;
}

.frontend-panel .our-agent-sidebar .team-1 .team-details h5 a {
    font-size: 20px;
}

.frontend-panel .team-1 .facebook-bg:hover {
    background: #4867aa !important;
}

.frontend-panel .team-1 .twitter-bg:hover {
    background: #56d7fe !important;
}

.frontend-panel .team-1 .google-bg:hover {
    background: #dc4e41 !important;
}

.frontend-panel .team-2 {
    margin: 0 1px;
    margin-bottom: 30px;
    background: white !important;
    box-shadow: 0 0 35px rgba(0, 0, 0, 0.1);
}

.frontend-panel .team-2:hover {
    box-shadow: 0 10px 30px 0 rgba(58, 87, 135, 0.15);
}

.frontend-panel .team-2 .detail {
    padding: 10px 20px 10px 25px;
}

.frontend-panel .team-2 img {
    width: 100%;
}

.frontend-panel .team-2 .detail h5 {
    margin: 0 0 20px;
    font-size: 13px;
    font-weight: 500;
    color: #5f5e5e;
}

.frontend-panel .team-2 .detail h4 {
    margin: 0 0 10px;
    font-size: 20px;
    font-weight: 500;
}

.frontend-panel .team-2 .photo {
    position: relative;
}

.frontend-panel .team-2 .detail .contact {
    margin-bottom: 20px;
}

.frontend-panel .team-2 .detail .contact ul li {
    margin-bottom: 10px;
    font-size: 15px;
    position: relative;
    padding-left: 22px;
}

.frontend-panel .team-2 .detail .contact ul li a {
    font-weight: 500;
    color: #5f5e5e;
}

.frontend-panel .team-2 .detail .contact ul li i {
    left: 0;
    position: absolute;
    line-height: 30px;
}

.frontend-panel .team-2 .detail .social-list li {
    display: inline-block;
    font-size: 16px;
}

.frontend-panel .team-2 .detail .social-list li a {
    margin-right: 0px;
    font-size: 14px;
    width: 35px;
    height: 35px;
    border-radius: 0px;
    display: inline-block;
    text-align: center;
    line-height: 35px;
}

.frontend-panel .team-3 .team-photo img {
    width: 100%;
}

.frontend-panel .team-3 {
    margin: 0 auto 50px;
    text-align: center;
}

.frontend-panel .team-3 img {
    width: 100%;
    border-radius: 100%;
    max-width: 200px;
    margin: auto;
}

.frontend-panel .team-3 .details {
    padding-top: 20px;
}

.frontend-panel .team-3 .details h4 {
    margin-bottom: 7px;
    font-weight: 500;
    font-size: 18px;
}

.frontend-panel .team-3 .details h5 {
    font-size: 15px;
    color: #535353;
    font-weight: 500;
    margin-bottom: 10px;
}

.frontend-panel .team-3 .details h6 {
    font-size: 15px;
    margin-bottom: 0;
}

.frontend-panel .team-3 .details h6 a {
    color: #535353;
}

.frontend-panel .team-4 {
    margin: 0 auto 30px;
    background: white;
    box-shadow: 0 0 35px rgba(0, 0, 0, 0.1);
}

.frontend-panel .team-4:hover {
    -webkit-box-shadow: 0 10px 30px 0 rgba(58, 87, 135, 0.15);
    -moz-box-shadow: 0 10px 30px 0 rgba(58, 87, 135, 0.15);
}

.frontend-panel .team-4 .team-photo img {
    width: 100%;
}

.frontend-panel .team-4 .team-photo {
    position: relative;
}

.frontend-panel .team-4 .team-details {
    padding: 30px 25px;
}

.frontend-panel .team-4 .team-details ul li {
    margin-bottom: 10px;
    font-size: 15px;
    position: relative;
    font-weight: 500;
    padding-left: 22px;
}

.frontend-panel .team-4 .team-details ul li:last-child {
    margin-bottom: 0;
}

.frontend-panel .team-4 .team-details ul li i {
    left: 0;
    position: absolute;
    line-height: 30px;
}

.frontend-panel .team-4 .team-details ul li a {
    color: #535353;
}

.frontend-panel .team-4 p {
    padding-left: 20px;
    position: relative;
    font-size: 14px;
    margin-bottom: 0;
    line-height: 30px;
}

.frontend-panel .team-4 p a {
    color: #535353;
}

.frontend-panel .team-4 p i {
    position: absolute;
    left: 0;
    line-height: 30px;
}

.frontend-panel .team-4 h6 {
    font-size: 13px;
    color: #535353;
    margin-bottom: 20px;
    font-weight: 500;
}

.frontend-panel .team-4 h5 {
    margin-bottom: 10px;
    font-weight: 500;
    font-size: 20px;
}

.frontend-panel .team-4 .social-list {
    position: absolute;
    top: 20px;
    text-align: center;
    z-index: 20;
    border-radius: 5px 5px 0 0;
    right: 20px;
    width: 45px;
    color: #fff;
}

.frontend-panel .team-4 .social-list ul {
    max-width: 45px;
    border-radius: 50px 50px 0 0;
    color: #fff;
}

.frontend-panel .team-4 .social-list a {
    color: #fff;
    width: 40px;
    height: 40px;
    line-height: 40px;
    display: inline-block;
    margin-bottom: 10px;
    border-radius: 3px;
    background: rgba(0, 0, 0, 0.15);
    -webkit-box-shadow: 0px 0px 0px 2px rgba(255, 255, 255, 0.2);
    box-shadow: 0px 0px 0px 2px rgba(255, 255, 255, 0.2);
}

.frontend-panel .team-4 .team-photo .social-list a:hover {
    box-shadow: none;
    background-color: #fff;
}

.frontend-panel .our-team-4 {
    /* background: rgba(0, 0, 0, 0.04) url(../img/map.jpg) top left repeat; */
    z-index: 0;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}

.frontend-panel .our-team-4 h1 {
    color: #353535;
    opacity: 1;
}

.frontend-panel .our-team-4 p {
    color: #535353;
    opacity: 1;
}

.frontend-panel .best-team .media img {
    width: 75px;
    border-radius: 3px;
}

.frontend-panel .best-team .media h5 {
    margin-bottom: 7px;
    font-weight: 400;
}

.frontend-panel .best-team .media h5 a {
    font-size: 16px;
    font-weight: 500;
    color: #353535;
}

.frontend-panel .best-team .media li {
    margin-bottom: 3px;
    font-size: 15px;
    font-weight: 500;
    color: #535353;
}

.frontend-panel .best-team .media li a {
    color: #535353;
}

.frontend-panel .best-team .media .media-body li i {
    margin-right: 5px;
    font-size: 13px;
}

.frontend-panel .agent-experience {
    font-size: 15px;
    position: relative;
    padding-left: 22px;
}

.frontend-panel .agent-experience i {
    left: 0;
    position: absolute;
}

.frontend-panel .agent-experience i {
    top: 7px;
}

.frontend-panel .rating-area {
    margin-bottom: 20px;
}

.frontend-panel .rating-area .average-rating {
    padding: 19px;
    text-align: center;
    border-radius: 0;
}

.frontend-panel .rating-area .average-rating h1 {
    color: #fff;
    font-weight: 500;
}

.frontend-panel .rating-area .average-rating .ratings i {
    color: orange;
}

.frontend-panel .rating-area .average-rating h4 {
    font-size: 16px;
    color: #fff;
    margin-bottom: 0;
}

.frontend-panel .rating-area .rating-item {
    display: flex;
    align-items: center;
    margin-bottom: 6px;
}

.frontend-panel .rating-area .rating-item .ratings {
    margin-left: auto;
}

.frontend-panel .rating-area .rating-count {
    width: 40px;
}

.frontend-panel .rating-area .rating-bar {
    width: 59%;
    height: 15px;
    margin: 0 20px;
    background: #d4d7de;
    position: relative;
}

.frontend-panel .rating-area .rating-width {
    position: absolute;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 1;
    background: #7a7d8a;
}

.frontend-panel .rating-area .ratings i {
    color: #eda619;
    font-size: 14px;
}

.frontend-panel .cmnt .comment-content {
    padding-bottom: 0;
    border-bottom: none;
}

.frontend-panel .testimonial {
    opacity: 1;
    /* background: rgba(0, 0, 0, 0.04) url(../img/img-5.jpg) top left repeat; */
    z-index: 0;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}

.frontend-panel .testimonial:before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.35);
}

.frontend-panel .testimonial .testimonial-info-box {
    margin: 50px 0 0;
    border-radius: 0;
    border: none;
    box-shadow: 0 0 1px rgba(0, 0, 0, 0.1);
    position: relative;
    padding: 70px 20px 20px;
    text-align: center;
    background-color: #fff;
}

.frontend-panel .testimonial .testimonial-info-box .profile-user {
    position: absolute;
    left: 0;
    top: -50px;
    width: 100%;
}

.frontend-panel .testimonial .testimonial-info-box .avatar {
    max-width: 100px !important;
    margin: 0 auto;
}

.frontend-panel .testimonial .testimonial-info-box .avatar img {
    width: 100%;
    border-radius: 100px;
}

.frontend-panel .testimonial .testimonial-info-box h5 {
    margin-bottom: 7px;
    font-weight: 500;
    font-size: 18px;
}

.frontend-panel .testimonial .testimonial-info-box p {
    font-size: 14px;
}

.frontend-panel .testimonial .testimonial-info-box h6 {
    margin-bottom: 15px;
    font-size: 15px;
}

.frontend-panel .testimonial-2 {
    background: #f9f9f8;
    /* background: rgba(0, 0, 0, 0.04) url(../img/map.jpg) top left repeat; */
    z-index: 0;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}

.frontend-panel .testimonial-2:before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: inherit;
}

.frontend-panel .testimonial-2 h1 {
    color: #353535;
    opacity: 1;
}

.frontend-panel .testimonial-2 .main-title p {
    color: #535353;
    opacity: 1;
}

.frontend-panel .testimonial-2 .main-title {
    margin-bottom: 50px;
    text-align: center;
}

.frontend-panel .testimonial-3 {
    padding: 100px 0 110px;
}

.frontend-panel .testimonial-3 .testimonial-info {
    max-width: 600px;
    margin: 0 auto 20px;
    text-align: center;
}

.frontend-panel .testimonial-3 .testimonial-info img {
    max-width: 90px;
    border-radius: 100%;
}

.frontend-panel .testimonial-3 .testimonial-info p {
    font-size: 15px;
}

.frontend-panel .testimonial-3 .carousel-indicators li {
    position: relative;
    -webkit-box-flex: 0;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    width: 12px;
    height: 12px;
    border-radius: 50px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    background-color: #000;
    border: 0px;
}

.frontend-panel .testimonial-3 .carousel-indicators {
    bottom: -10px;
    margin-bottom: 0;
}

.frontend-panel .testimonial-3 .testimonial-info h5 {
    margin: 20px 0 10px;
}

.frontend-panel .testimonial-3 .testimonial-info h6 {
    font-size: 13px;
    font-weight: 500;
    color: #535353;
}

.frontend-panel .testimonial-3 .testimonial-info p {
    margin-bottom: 20px;
}

.frontend-panel .t4 .main-title {
    margin-bottom: 0;
    text-align: left;
}

.frontend-panel .service-info {
    padding: 0 20px;
    margin: 0 auto 30px;
    text-align: center;
}

.frontend-panel .service-info .icon {
    padding: 0;
    z-index: 0;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    max-width: 80px;
    height: 80px;
    margin: 0 auto;
    border-radius: 100%;
}

.frontend-panel .service-info .icon i {
    font-size: 30px;
    line-height: 80px;
    color: #fff !important;
}

.frontend-panel .service-info h3 {
    font-size: 20px;
    margin: 20px 0 15px;
    font-weight: 500;
}

.frontend-panel .service-info p {
    margin-bottom: 0;
    font-size: 15px;
}

.frontend-panel .service-info-2 {
    padding: 25px 20px;
    box-shadow: 0 0 35px rgba(0, 0, 0, 0.1);
    margin: 0 auto 30px;
    position: relative;
    text-align: left;
    background-color: #fff;
}

.frontend-panel .service-info-2:hover {
    box-shadow: 0 10px 30px 0 rgba(58, 87, 135, 0.15);
}

.frontend-panel .service-info-2:before {
    opacity: 0.8;
    content: "";
    display: block;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    position: absolute;
    border-radius: 10px;
}

.frontend-panel .service-info-2 .icon i {
    font-size: 37px;
    line-height: normal;
}

.frontend-panel .service-info-2 .detail {
    margin-left: 60px;
}

.frontend-panel .service-info-2 .icon {
    position: absolute;
    font-size: 40px;
    height: auto;
    background: transparent;
}

.frontend-panel .service-info-2 .number {
    font-size: 170px;
    line-height: 1;
    color: rgba(58, 48, 48, 0.11);
    display: inline-block;
    position: absolute;
    z-index: 0;
    right: 0;
    font-weight: 500;
    top: 5px;
    margin-top: -25px;
    font-family: "Poppins", sans-serif;
}

.frontend-panel .service-info-2 p {
    position: relative;
    font-size: 15px;
    margin-bottom: 0;
}

.frontend-panel .service-info-2 h3 {
    position: relative;
    margin: 0 0 10px;
}

.frontend-panel .service-info-2 h3 {
    font-weight: 500;
    margin: 0 0 15px;
    font-size: 20px;
}

.frontend-panel .services-2 {
    z-index: 0;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: rgba(0, 0, 0, 0.5);
    background-blend-mode: overlay;
}

.frontend-panel .service-info-3 {
    margin-bottom: 30px;
    background-color: #fff;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    padding: 25px 20px;
    border-radius: 0;
}

.frontend-panel .service-info-3:hover {
    box-shadow: 0 10px 30px 0 rgba(58, 87, 135, 0.15);
}

.frontend-panel .service-info-3 .rm-btn {
    font-weight: 500;
    font-size: 15px;
}

.frontend-panel .service-info-3 .rm-btn:hover {
    color: #535353;
}

.frontend-panel .service-info-3 .icon {
    margin-right: 20px;
    font-size: 50px;
}

.frontend-panel .service-info-3 h2 {
    font-weight: 500;
    margin: 0 0 15px;
    font-size: 20px;
}

.frontend-panel .service-info-3 p {
    margin-bottom: 10px;
    font-size: 15px;
}

.frontend-panel .service-info-3 h4 {
    position: absolute;
    top: 100px;
    left: 47px;
    z-index: 20;
    margin-bottom: 0;
    font-weight: 500;
    border-radius: 3px 3px 3px 0;
    color: #d4d0d0;
}

.frontend-panel .service-info-4 {
    margin: 0 0 30px 0;
    width: 100%;
    background-color: #fff;
    padding: 50px 20px 40px;
    box-shadow: 0 0 35px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.frontend-panel .service-info-4:hover {
    box-shadow: 0 10px 30px 0 rgba(58, 87, 135, 0.15);
}

.frontend-panel .service-info-4 i {
    font-size: 50px;
    margin-bottom: 20px;
    line-height: normal;
}

.frontend-panel .service-info-4 h3 {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 15px;
}

.frontend-panel .service-info-4 p {
    font-size: 15px;
    line-height: 25px;
}

.frontend-panel .service-info-4 .icon {
    max-width: 70px;
    margin: 0 auto 20px;
}

.frontend-panel .pages-404 {
    top: 0;
    width: 100%;
    bottom: 0;
    opacity: 1;
    min-height: 100vh;
    position: relative;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px 0;
}

.frontend-panel .pages-404 .error404 {
    line-height: 0.8;
    margin-bottom: 40px;
    font-weight: 500;
    font-size: 270px;
    opacity: 2;
    color: #eee;
    text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.4);
}

.frontend-panel .pages-404 .nobottomborder h1 {
    font-size: 35px;
    font-weight: 500;
    margin-bottom: 20px;
}

.frontend-panel .pages-404 .nobottomborder {
    padding-left: 20px;
}

.frontend-panel .pages-404 .hr {
    width: 50px;
    height: 10px;
    background: #eee;
    text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.4);
    margin: 0px 0 20px;
    border-radius: 10px;
}

.frontend-panel .pages-404 .nobottomborder p {
    line-height: 30px;
    font-size: 17px;
}

.frontend-panel .pages-404 .form-control {
    width: 69%;
    height: 45px;
    padding: 10px 15px;
    border-radius: 0;
    font-size: 15px;
    outline: 0;
    background-color: #eee;
    border-color: #eee;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    color: #6c757c;
    font-weight: 500;
}

.frontend-panel .pages-404 .btn,
.frontend-panel .pages-404 .btn:hover,
.frontend-panel .pages-404 .btn:active {
    width: 31%;
    border-radius: 0;
    height: 45px;
    line-height: 45px;
    padding: 0 15px;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    font-size: 15px;
    font-weight: 500;
    letter-spacing: 1px;
    cursor: pointer;
    color: #fff;
}

.frontend-panel .mr-0 {
    margin-right: 0;
}

.frontend-panel .date-box span {
    font-family: "Poppins", sans-serif;
    font-size: 19px;
    display: block;
    line-height: 22px;
    color: #fff;
}

.frontend-panel .blog-1 {
    margin-bottom: 30px;
    border-radius: 0;
    border: none;
    background: transparent;
    box-shadow: 0 0 35px rgba(0, 0, 0, 0.1);
}

.frontend-panel .blog-1:hover {
    box-shadow: 0 10px 30px 0 rgba(58, 87, 135, 0.15);
}

.frontend-panel .blog-1 .blog-photo {
    position: relative;
}

.frontend-panel .blog-1 .date-box {
    position: absolute;
    top: 20px;
    font-size: 13px;
    padding: 6px 15px 4px;
    text-align: center;
    right: 20px;
    font-weight: 500;
    background-color: #fff;
    border-radius: 3px;
    font-family: "Poppins", sans-serif;
}

.frontend-panel .blog-1 .detail {
    padding: 30px 20px 20px;
}

.frontend-panel .blog-1 .detail h3 {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 10px;
}

.frontend-panel .blog-1 .post-meta {
    width: 100%;
    margin-bottom: 10px;
}

.frontend-panel .blog-1 .detail .post-meta span {
    margin-right: 20px;
    font-size: 13px;
    font-weight: 500;
}

.frontend-panel .blog-1 .detail .post-meta span a i {
    margin-right: 5px;
}

.frontend-panel .blog-1 .detail .post-meta span:last-child {
    margin-right: 0;
}

.frontend-panel .blog-1 img {
    width: 100%;
}

.frontend-panel .blog-1 .detail p {
    font-size: 15px;
    margin-bottom: 5px;
}

.frontend-panel .blog-2 {
    margin-bottom: 30px;
    border-radius: 0;
    border: none;
    box-shadow: 0 0 35px rgba(0, 0, 0, 0.1);
    background-color: #fff;
}

.frontend-panel .blog-2 .blog-photo {
    position: relative;
}

.frontend-panel .blog-2:hover {
    -webkit-box-shadow: 0 10px 30px 0 rgba(58, 87, 135, 0.15);
    -moz-box-shadow: 0 10px 30px 0 rgba(58, 87, 135, 0.15);
    box-shadow: 0 10px 30px 0 rgba(58, 87, 135, 0.15);
}

.frontend-panel .blog-2 .date-box {
    position: absolute;
    top: 15px;
    font-size: 13px;
    color: #fff;
    width: 60px;
    height: 60px;
    text-align: center;
    padding-top: 10px;
    right: 19px;
    border-radius: 5px 5px 0 5px;
}

.frontend-panel .blog-2 .date-box:after {
    position: absolute;
    content: "";
    width: 0;
    height: 0;
    border-top: 0;
    border-bottom: 10px solid transparent;
    bottom: -10px;
    right: 0;
}

.frontend-panel .blog-2 .profile-user {
    position: absolute;
    bottom: -23px;
    left: 20px;
    display: block;
    background-color: #fff;
    border-radius: 50%;
    padding: 2px;
    z-index: 999 !important;
}

.frontend-panel .blog-2 .profile-user img {
    width: 45px;
    height: 45px;
    border-radius: 50%;
}

.frontend-panel .blog-2 .detail {
    padding: 30px 20px 10px;
}

.frontend-panel .blog-2 .post-meta {
    width: 100%;
    margin-bottom: 10px;
}

.frontend-panel .blog-2 .post-meta ul li {
    margin: 0 15px 0 0;
    display: inline-block;
    font-size: 13px;
    font-weight: 500;
    color: #5d5d5d;
    line-height: 26px;
}

.frontend-panel .blog-2 .post-meta ul li strong {
    font-weight: 500;
}

.frontend-panel .blog-2 .post-meta ul li strong a {
    color: #525252;
}

.frontend-panel .blog-2 .post-meta ul li a {
    color: #403e3e;
}

.frontend-panel .blog-2 .post-meta ul li i {
    margin-right: 5px;
    color: #403e3e;
}

.frontend-panel .blog-2 p {
    font-size: 15px;
}

.frontend-panel .blog-2 h4 a {
    font-size: 21px;
    margin-bottom: 10px;
    font-weight: 500;
}

.frontend-panel .blog-2 h4 a:hover {
    color: #444243;
}

.frontend-panel .blog-3 .date-box {
    background: #c79153;
    color: #fff;
    padding: 5px 15px;
    -webkit-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    transform: rotate(-90deg);
    white-space: nowrap;
    display: inline-block;
    position: absolute;
    left: -68px;
    top: 70px;
    z-index: 999;
    border-radius: 5px;
}

.frontend-panel .b-photo {
    /* background: url(../img/partycrack/partycrack-1.jpg); */
}

.frontend-panel .b-photo1 {
    /* background: url(../img/blog/blog-6.png); */
}

.frontend-panel .b-photo2 {
    /* background: url(../img/blog/blog-7.png); */
}

.frontend-panel .b-photo3 {
    /* background: url(../img/blog/blog-8.png); */
}

.frontend-panel .blog-3:before {
    content: "";
    top: 0;
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 9;
    border-radius: 10px;
    background: linear-gradient(to top, rgba(2, 2, 2, 0.82) 0%, rgba(35, 35, 37, 0.37) 35%, rgba(42, 42, 43, 0.69) 60%, rgba(0, 0, 0, 0) 100%);
    opacity: 1;
}

.frontend-panel .blog-3 {
    z-index: 0;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    height: 450px;
    border-radius: 10px;
    margin-bottom: 30px;
}

.frontend-panel .blog-3 p {
    color: #dcdcdc;
    margin-bottom: 0;
}

.frontend-panel .blog-3 h3 {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 15px;
    color: #dcdcdc;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.frontend-panel .blog-3 h3 a {
    color: #fff;
}

.frontend-panel .blog-3 .blog-info {
    position: absolute;
    bottom: 30px;
    left: 25px;
    right: 25px;
    z-index: 999;
}

.frontend-panel .blog-4 {
    margin: 25px 0 30px;
    border-radius: 0;
    border: none;
    box-shadow: 0 0 35px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    position: relative;
    padding: 60px 30px 20px;
}

.frontend-panel .blog-4:hover {
    -webkit-box-shadow: 0 10px 30px 0 rgba(58, 87, 135, 0.15);
    -moz-box-shadow: 0 10px 30px 0 rgba(58, 87, 135, 0.15);
    box-shadow: 0 10px 30px 0 rgba(58, 87, 135, 0.15);
}

.frontend-panel .blog-4 h5 {
    font-size: 14px;
    font-weight: 500;
}

.frontend-panel .blog-4 h3 {
    margin-bottom: 15px;
}

.frontend-panel .blog-4 h3 a {
    font-size: 20px;
    font-weight: 500;
}

.frontend-panel .blog-4 .b-btn {
    font-weight: 500;
}

.frontend-panel .blog-4 p {
    font-size: 15px;
}

.frontend-panel .blog-4 .profile-user {
    position: absolute;
    top: -25px;
    z-index: 20;
}

.frontend-panel .blog-4 .profile-user img {
    box-shadow: 1px 2px 3px 0 rgba(0, 0, 0, 0.4);
    width: 65px;
    height: 65px;
    margin-bottom: 20px;
    webkit-border-radius: 50%/14%;
    border-radius: 50%/14%;
}

.frontend-panel .blog-big:hover {
    box-shadow: 0 0 35px rgba(0, 0, 0, 0.1);
}

.frontend-panel .blog-big .detail {
    padding: 30px 20px 20px;
}

.frontend-panel .blog-big {
    margin-bottom: 50px;
}

.frontend-panel .blog-big .detail h3 {
    font-size: 23px;
}

.frontend-panel footer {
    background: #111;
    padding-top: 80px;
    background: url(../imgs/footer.jpg);
    z-index: 0;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    background: #111ff0;
}

.frontend-panel .footer h4 {
    font-size: 22px;
    margin-bottom: 30px;
    font-weight: 500;
    color: #b2b3b5;
}

.frontend-panel .footer .footer-item {
    margin-bottom: 50px;
}

.frontend-panel .footer .footer-inner .links li {
    margin-bottom: 15px;
    font-size: 15px;
    font-weight: 500;
}

.frontend-panel .footer .footer-inner .links li a {
    color: #b2b3b5;
}

.frontend-panel .footer .footer-inner .links li a:hover {
    color: #9c9da0;
}

.frontend-panel .footer .footer-inner p {
    color: #b2b3b5;
    font-size: 15px;
}

.frontend-panel .footer .footer-inner .contact-info li {
    margin-bottom: 15px;
    font-size: 15px;
    color: #b2b3b5;
    font-weight: 500;
    position: relative;
    padding-left: 25px;
}

.frontend-panel .footer .footer-inner .contact-info li i {
    left: 0;
    position: absolute;
    line-height: 22px;
}

.frontend-panel .footer .footer-inner .contact-info li a {
    color: #b2b3b5;
}

.frontend-panel .footer .text {
    margin-right: 30px;
}

.frontend-panel .footer .text p {
    line-height: 30px;
}

.frontend-panel .footer .f-logo {
    margin-bottom: 10px;
    height: 50px;
}

.frontend-panel .sub-footer {
    margin-top: 30px;
}

.frontend-panel .sub-footer {
    padding: 20px 0;
    border-top: solid 1px #5f5f5f 54;
}

.frontend-panel .sub-footer .copy {
    margin: 0;
    font-size: 14px;
    color: #b2b3b5;
    line-height: 40px;
}

.frontend-panel .sub-footer .copy a {
    color: #b2b3b5;
}

.frontend-panel .sub-footer .copy a:hover {
    text-decoration: underline;
}

.frontend-panel .sub-footer .social-list {
    float: right;
}

.frontend-panel .sub-footer .social-list li {
    display: inline-block;
    font-size: 16px;
    text-align: center;
}

.frontend-panel .sub-footer .social-list li a {
    margin: 0 2px;
    color: #fff;
    width: 40px;
    height: 40px;
    line-height: 40px;
    display: inline-block;
    border-radius: 3px;
    background: rgba(0, 0, 0, 0.15);
    -webkit-box-shadow: 0px 0px 0px 2px rgba(255, 255, 255, 0.2);
    box-shadow: 0px 0px 0px 2px rgba(255, 255, 255, 0.2);
}

.frontend-panel .sub-footer .social-list li a:hover {
    box-shadow: none;
}

.frontend-panel .sub-footer .facebook-bg:hover {
    background: #4867aa !important;
}

.frontend-panel .sub-footer .twitter-bg:hover {
    background: #56d7fe !important;
}

.frontend-panel .sub-footer .google-bg:hover {
    background: #dc4e41 !important;
}

.frontend-panel .sub-footer .linkedin-bg:hover {
    background: #0177b5 !important;
}

.frontend-panel .footer .footer-inner form .form-control {
    width: 81%;
    height: 45px;
    padding: 10px 15px;
    border-radius: 0;
    font-size: 16px;
    outline: 0;
    border: 1px solid #fff;
}

.frontend-panel .footer .form-inline .btn {
    width: 19%;
    border-radius: 0;
    padding: 11px 13px 10px;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    font-size: 15px;
    font-weight: 500;
}

.frontend-panel .footer .footer-inner .f-border {
    width: 30px;
    height: 2px;
    background: #b2b3b5;
    margin-bottom: 30px;
    border-radius: 50px;
}

.frontend-panel .posts-by-category ul {
    margin: 0;
    padding: 0;
    text-align: left;
}

.frontend-panel .posts-by-category ul li {
    padding-bottom: 7px;
    margin-bottom: 7px;
    border-bottom: dashed 1px #ededed;
}

.frontend-panel .posts-by-category ul li a {
    color: #535353;
    font-weight: 500;
    font-size: 15px;
}

.frontend-panel .posts-by-category ul li a span {
    float: right;
    color: inherit;
}

.frontend-panel .posts-by-category ul li:last-child {
    border-bottom: none;
    padding: 0;
    margin: 0;
}

.frontend-panel .intro-section {
    padding: 30px 0 25px;
}

.frontend-panel .intro-text h3 {
    font-size: 27px;
    margin: 0;
    line-height: 50px;
    color: #fff;
    font-weight: 300;
}

.frontend-panel .intro-section .btn {
    background-color: #fff;
    float: right;
    color: #353535;
    border-radius: 100px;
    box-shadow: 0 10px 30px 0 rgba(58, 87, 135, 0.15);
    padding: 15px 30px 9px 30px;
}

.frontend-panel .intro-section .btn:hover {
    box-shadow: none;
}

.frontend-panel .intro-section-2 {
    /* background: url(../img/img-2.jpg); */
    z-index: 0;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    padding: 30px 15px 25px;
    border-radius: 5px;
    margin: 0 0 30px;
}

.frontend-panel .intro-section-2:before {
    opacity: 0.8;
    content: "";
    border-radius: 5px;
    display: block;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    position: absolute;
}

.frontend-panel .intro-section-2 p {
    color: #fff !important;
    margin-bottom: 0;
    font-weight: 400;
    font-size: 16px;
}

.frontend-panel .intro-section-2 form .form-control {
    width: 68%;
    height: 45px;
    padding: 10px 15px;
    font-size: 15px;
    border-radius: 50px 0 0 50px;
    outline: 0;
    font-weight: 500;
    border: 1px solid #fff;
}

.frontend-panel .intro-section-2 .btn {
    width: 32%;
    padding: 11px 5px 10px;
    text-align: center;
    border-radius: 0 50px 50px 0;
    font-size: 15px;
    font-weight: 500;
    color: #fff;
}

.frontend-panel .intro-section-2 .intro-text h3 {
    font-size: 27px;
    margin-bottom: 10px;
    color: #fff;
    line-height: normal;
    font-weight: 500;
}

.frontend-panel .intro-section-2 .Subscribe-box {
    padding: 2px;
    border-radius: 50px;
    background-color: #fff;
    box-shadow: 0 0 35px rgba(0, 0, 0, 0.1);
    margin-top: 9.5px;
}

.frontend-panel .tags-box ul li {
    display: inline-block;
}

.frontend-panel .tags-box ul li a {
    border-radius: 3px;
    color: #535353;
    display: inline-block;
    font-size: 14px;
    margin: 0 0 5px;
    padding: 5px 12px;
    font-weight: 500;
    text-transform: capitalize;
    border: 1px solid #f7f7f7;
    background: #f7f7f7;
}

.frontend-panel .tags-box ul li a:hover {
    color: #fff;
}

.frontend-panel .recent-partycrack .media img {
    width: 60px;
    border-radius: 3px;
}

.frontend-panel .recent-partycrack .media h5 {
    margin-bottom: 7px;
    font-weight: 500;
}

.frontend-panel .recent-partycrack .media h5 a {
    font-size: 18px;
}

.frontend-panel .recent-partycrack .listing-post-meta {
    font-size: 14px;
    font-weight: 500;
    color: #535353;
}

.frontend-panel .recent-partycrack .listing-post-meta a {
    color: #535353;
}

.frontend-panel .counters {
    padding: 60px 0 20px;
    /* background: url(../img/img-6.jpg); */
    z-index: 0;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}

.frontend-panel .counter-box {
    margin-bottom: 40px;
    text-align: center;
}

.frontend-panel .counter-box i {
    font-size: 45px;
    color: #fff;
    line-height: inherit;
}

.frontend-panel .counter-box h1 {
    font-weight: 500;
    color: #fff;
    margin: 10px 0 5px;
    font-size: 30px;
}

.frontend-panel .counter-box p {
    color: #fff;
}

.frontend-panel .counters-2 {
    padding: 100px 0 60px;
    /* background: url(../img/img-6.jpg); */
    z-index: 0;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}

.frontend-panel .counters-2 .counter-box p {
    margin: 0;
    font-size: 12px;
    letter-spacing: 1px;
    color: #fff;
    font-weight: 500;
}

.frontend-panel .counters-2 .counter-box {
    margin-bottom: 40px;
    border-radius: 5px;
}

.frontend-panel .counters-2 .counter-box .icon {
    width: 75px;
    border-radius: 2px;
    margin-right: 20px;
    height: 75px;
    text-align: center;
    line-height: 75px;
    border: dashed 1px #b5b5b5;
    font-size: 35px;
    -webkit-border-radius: 50%/14%;
    border-radius: 50%/14%;
}

.frontend-panel .counters-2 .counter-box h2 {
    font-weight: 500;
    color: #fff;
    margin: 0 0 10px;
    font-size: 30px;
    text-align: left;
}

.frontend-panel .counters-2 .counter-box p {
    margin: 0;
    text-align: left;
    line-height: normal;
    font-size: 12px;
    letter-spacing: 1px;
    color: #fff;
    font-weight: 500;
}

.frontend-panel .counters-3 {
    background: #f9f9f8;
}

.frontend-panel .counters-3 .counter-box .icon {
    font-size: 45px;
    line-height: 100px;
    width: 100px;
    height: 100px;
    margin: 0 auto 20px;
    background-color: #fff;
    border-radius: 100%;
    box-shadow: 0 0 35px rgba(0, 0, 0, 0.1);
}

.frontend-panel .counters-3 .counter-box .icon i {
    color: #000;
    margin-bottom: 0;
}

.frontend-panel .counters-3 .counter-box p {
    color: #484848;
}

.frontend-panel .counters-3 .counter-box .clip-home {
    position: relative;
    box-shadow: 0 0 35px rgba(0, 0, 0, 0.1);
}

.frontend-panel .counters-4 {
    padding: 0;
    /* background: rgba(0, 0, 0, 0.04) url(../img/img-6.jpg) top left repeat; */
    z-index: 0;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}

.frontend-panel .counters-4 .counters-info-section:before {
    opacity: 0.8;
    content: "";
    display: block;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    position: absolute;
    background: rgba(0, 0, 0, 0.84);
}

.frontend-panel .counters-4 .counters-info-section {
    padding: 100px 80px 60px 95px;
}

.frontend-panel .counters-4 .counters-info-section.clip-home {
    clip-path: polygon(0 0, 100% 0, 100% 0%, 90% 100%, 0 100%);
}

.frontend-panel .counters-4 h1 {
    font-size: 35px;
    font-weight: 500;
    margin-bottom: 15px;
    color: #fff;
}

.frontend-panel .counters-4 p {
    font-size: 18px;
    margin-bottom: 0;
    color: #fff;
}

.frontend-panel .counters-4 .counter-box {
    max-width: 240px;
    margin: 0 auto 40px;
    border-radius: 5px;
}

.frontend-panel .counters-photo {
    padding: 0 95px 0 40px;
}

.frontend-panel .from-main {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 25px 0;
}

.frontend-panel .from-main .login-box {
    background-color: #fff;
    box-shadow: 0 0 35px rgba(0, 0, 0, 0.1);
}

.frontend-panel .from-main .form-section {
    padding: 45px 40px 0 40px;
    border-radius: 10px 0 0 10px;
    height: 100%;
}

.frontend-panel .from-main .bg-img {
    background-color: rgba(0, 0, 0, 0.5);
    background-blend-mode: overlay;
    background-size: cover;
    background-position: center;
    top: 0;
    bottom: 0;
    opacity: 1;
    width: 100%;
    min-height: 550px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.frontend-panel .from-main-bg {
    background: #f7f7f7;
}

.frontend-panel .from-main .pad-0 {
    padding: 0;
}

.frontend-panel .from-main .info {
    padding: 30px;
    text-align: center;
    border: solid 5px #fff;
    box-shadow: 0 0 35px rgba(0, 0, 0, 0.1);
    max-width: 300px;
    margin: 0 auto;
}

.frontend-panel .from-main .info h3 {
    font-size: 18px;
    margin-bottom: 20px;
    color: #fff;
}

.frontend-panel .from-main .form-section ul {
    list-style: none;
    padding: 0;
    margin: 0 0 20px;
}

.frontend-panel .from-main .form-section .social-list li {
    display: inline-block;
    margin-bottom: 5px;
}

.frontend-panel .from-main .logo-2 img {
    margin-bottom: 10px;
    height: 45px;
}

.frontend-panel .from-main .form-section .thembo {
    margin-left: 4px;
}

.frontend-panel .from-main .form-section h3 {
    font-size: 25px;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
}

.frontend-panel .from-main .form-section .form-group {
    margin-bottom: 20px;
    position: relative;
    display: inline-block;
    width: 100%;
}

.frontend-panel .form-group.icon_input > i {
    position: absolute;
    left: 15px;
    color: #828282;
    top: 17px;
    font-size: 16px;
}

.frontend-panel .form-group.icon_input input {
    padding-left: 45px !important;
}

.frontend-panel .form-group.icon_input .unfill {
    position: absolute;
    background: #d5d5d5;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    color: #fff;
    right: 8px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 12px;
}

.frontend-panel .from-main .form-section .input-text {
    width: 100%;
    padding: 10px 15px;
    border: 1px solid #ccc;
    padding-right: 20px;
    font-size: 15px;
    outline: none;
    height: 50px;
    color: #616161;
    border-radius: 5px;
    background-color: #fff;
}

.frontend-panel .from-main .form-section .checkbox .terms {
    margin-left: 3px;
}

.frontend-panel .from-main .form-section .btn-md {
    cursor: pointer;
    padding: 8px 15px;
    letter-spacing: 1px;
    font-size: 15px;
    font-weight: 500;
    border-radius: 3px;
}

.frontend-panel .btn-md.btn-theme {
    display: inline-block;
}

.frontend-panel .from-main .form-section input[type=checkbox],
.frontend-panel input[type=radio] {
    margin-right: 3px;
}

.frontend-panel .from-main .form-section button:focus {
    outline: none;
    outline: 0 auto -webkit-focus-ring-color;
}

.frontend-panel .from-main .none-2 {
    display: none;
}

.frontend-panel .from-main .logo {
    top: 40px;
    position: absolute;
    left: 65px;
}

.frontend-panel .from-main .form-section .terms {
    margin-left: 3px;
}

.frontend-panel .from-main .form-section .terms p {
    font-size: 14px;
    text-align: center;
}

.frontend-panel .from-main .form-section .terms p a {
    color: #ff4068;
}

.frontend-panel .from-main .form-section .other_register p {
    font-size: 16px;
    text-align: center;
}

.frontend-panel .from-main .form-section .other_register p a {
    color: #ff4068;
}

.frontend-panel .from-main .btn-section {
    border-radius: 50px;
    margin-bottom: 25px;
    display: inline-block;
    background-color: #fff;
}

.frontend-panel .from-main .btn-section .btn-1 {
    border-radius: 50px 0 0 50px;
    border-right: solid 2px #e6e6e6;
}

.frontend-panel .from-main .btn-section .link-btn {
    font-size: 15px;
    float: left;
    background: transparent;
    font-weight: 500;
    text-align: center;
    text-decoration: none;
    text-decoration: blink;
    line-height: 30px;
    width: 90px;
    color: #505050;
}

.frontend-panel .from-main .btn-section .btn-2 {
    border-radius: 0 50px 50px 0;
}

.frontend-panel .from-main .form-section .checkbox {
    font-size: 14px;
}

.frontend-panel .from-main .form-section .form-check {
    float: left;
    margin-bottom: 0;
}

.frontend-panel .from-main .form-section .form-check a {
    color: #fff;
    float: right;
}

.frontend-panel .from-main .form-section .form-check-input {
    position: absolute;
    margin-left: 0;
}

.frontend-panel .from-main .form-section .form-check label:before {
    content: "";
    display: inline-block;
    position: absolute;
    width: 18px;
    height: 18px;
    top: 2px;
    margin-left: -25px;
    border: 1px solid #c5c3c3;
    border-radius: 3px;
    background-color: #fff;
}

.frontend-panel .from-main .form-section .form-check-label {
    padding-left: 25px;
    margin-bottom: 0;
    font-size: 15px;
    font-weight: 500;
    color: #fff;
}

.frontend-panel .from-main .form-section input[type=checkbox]:checked + label:before {
    font-weight: 300;
    color: #f3f3f3;
    line-height: 15px;
    font-size: 14px;
    content: "âœ“";
}

.frontend-panel .from-main .form-section input[type=checkbox],
.frontend-panel input[type=radio] {
    margin-top: 4px;
}

.frontend-panel .from-main .form-section a.forgot-password {
    font-size: 15px;
    color: #535353;
    float: right;
    line-height: 50px;
    font-weight: 500;
}

.frontend-panel .from-main .social-list a {
    height: 40px;
    line-height: 40px;
    text-align: center;
    display: inline-block;
    font-size: 17px;
    margin: 2px;
    border-radius: 3px;
    background-color: #fff;
    box-shadow: 0 0 35px rgba(0, 0, 0, 0.1);
    padding: 0 20px;
    min-width: 140px;
}

.frontend-panel .from-main .social-list a:hover {
    color: #fff;
}

.frontend-panel .from-main .facebook-bg {
    background: #4867aa !important;
}

.frontend-panel .from-main .twitter-bg {
    background: #3cf !important;
}

.frontend-panel .from-main .google-bg {
    background: #db4437 !important;
}

.frontend-panel .from-main .linkedin-bg {
    color: #2392e0;
}

.frontend-panel .from-main .linkedin-bg:hover {
    background: #1c82ca;
}

.frontend-panel .login_form {
    display: flex;
    flex-direction: column;
}

.frontend-panel .login_form .other_login {
    margin-top: auto;
    margin-bottom: 30px;
}

.frontend-panel .login_form .other_login p {
    display: flex;
    align-items: center;
    white-space: nowrap;
    justify-content: center;
    margin: 0;
}

@media (max-width: 991px) {
    .frontend-panel .navbar .navbar-collapse {
        background-color: #fff;
        position: fixed !important;
        height: 100vh;
        top: 0;
        padding: 0;
        left: -100%;
        width: 300px;
        opacity: 0;
        visibility: hidden;
        overflow-y: auto;
        -webkit-transition: all 0.5s;
        -moz-transition: all 0.5s;
        -ms-transition: all 0.5s;
        -o-transition: all 0.5s;
        transition: all 0.5s;
        display: block;
        z-index: 9;
    }

    .frontend-panel .navbar .navbar-collapse.menu-show {
        left: 0;
        opacity: 1;
        visibility: visible;
        z-index: 999;
    }

    .frontend-panel .main-header .navbar-expand-lg .navbar-nav .nav-link,
    .frontend-panel .header-shrink .navbar-expand-lg .navbar-nav .nav-link {
        padding: 12px 15px;
    }

    .frontend-panel .main-header .navbar-expand-lg .navbar-nav .nav-link.dropdown-toggle {
        padding-right: 30px;
    }

    .frontend-panel .main-header .navbar-expand-lg .navbar-nav .nav-link.dropdown-toggle:after {
        display: none;
    }

    .frontend-panel .main-header .nav-link.dropdown-toggle:before {
        right: 15px;
    }

    .frontend-panel .megamenu-area {
        box-shadow: none;
        padding: 5px 15px;
    }

    .frontend-panel .nav-item.login-nav {
        padding: 10px 0;
    }

    .frontend-panel .main-header .navbar-expand-lg .navbar-nav .nav-item.login-nav .text-white {
        color: #515151 !important;
    }

    .frontend-panel .main-header .dropdown-menu {
        box-shadow: none;
    }

    .frontend-panel .menu-open .main-header:before {
        content: "";
        position: fixed;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.6);
        z-index: 99;
        -webkit-transition: all 0.5s;
        -moz-transition: all 0.5s;
        -ms-transition: all 0.5s;
        -o-transition: all 0.5s;
        transition: all 0.5s;
    }
}

@media (max-width: 992px) {
    .frontend-panel .inline-search-area .dropdown-toggle:after {
        top: 22px;
    }

    .frontend-panel .from-main .form-section {
        width: 100%;
    }

    .frontend-panel .from-main .bg-img {
        min-height: 100%;
        border-radius: 5px;
    }

    .frontend-panel .inline-search-area.isa-5 .btn-search {
        height: 54px;
    }

    .frontend-panel .counters-photo {
        display: none;
    }

    .frontend-panel .counters-2.counters-4 {
        padding: 0;
    }

    .frontend-panel .none-992 {
        display: none !important;
    }

    .frontend-panel .main-title p {
        font-size: 16px;
    }

    .frontend-panel .testimonial-3 {
        padding: 80px 0 90px;
    }

    .frontend-panel .sub-footer .social-list {
        float: inherit;
    }

    .frontend-panel .main-title-3 {
        text-align: center;
    }

    .frontend-panel .main-title-3 .btn {
        display: none;
    }

    .frontend-panel .testimonial .main-title {
        text-align: center;
        margin-bottom: 40px;
    }

    .frontend-panel .testimonial .main-title .btn {
        display: none;
    }

    .frontend-panel .logo-3 {
        padding: 15px 0;
    }

    .frontend-panel .logo-3 img {
        height: 40px;
    }

    .frontend-panel .partycrack-details-Slider .slider-listing {
        height: 375px !important;
    }

    .frontend-panel .intro-section-2 p {
        margin-bottom: 10px;
    }

    .frontend-panel .intro-section-2 {
        padding: 30px 15px 30px;
        margin: 0 0 30px;
        text-align: center;
    }

    .frontend-panel .service-info {
        padding: 30px 20px;
        box-shadow: 0 0 35px rgba(0, 0, 0, 0.1);
        background-color: #fff;
    }
}

.frontend-panel .partycrack .col-pad {
    padding: 0 7.5px;
}

.frontend-panel .category {
    overflow: hidden;
    position: relative;
    text-align: center;
    margin: 0 0 15px 0;
    border-radius: 3px;
}

.frontend-panel .category:hover:before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    content: "";
    background-color: #353535;
    opacity: 0.4;
    z-index: -11;
    transition: 0.3s ease-out;
}

.frontend-panel .category_bg_box {
    transition: all 0.35s;
    border-radius: 3px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 220px;
}

.frontend-panel .category_long_bg {
    height: 455px;
}

.frontend-panel .cat-1-bg {
    /* background-image: url(../img/popular-places/popular-places-1.jpg); */
}

.frontend-panel .cat-2-bg {
    /* background-image: url(../img/popular-places/popular-places-2.jpg); */
}

.frontend-panel .cat-3-bg {
    /* background-image: url(../img/popular-places/popular-places-3.jpg); */
}

.frontend-panel .cat-4-bg {
    /* background-image: url(../img/popular-places/popular-places-5.jpg); */
}

.frontend-panel .cat-5-bg {
    /* background-image: url(../img/popular-places/popular-places-4.jpg); */
}

.frontend-panel .category:hover .category_bg_box {
    transform: scale(1.06);
    transition: transform 0.35s ease-out;
}

.frontend-panel .category .category-overlay {
    border-radius: 3px;
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: background-color 0.15s linear;
    background-color: rgba(36, 42, 53, 0.5);
}

.frontend-panel .category-subtitle {
    white-space: nowrap;
    color: #fff;
    font-weight: 500;
    font-size: 14px;
    text-align: left;
    letter-spacing: 1px;
}

.frontend-panel .category-content {
    display: block;
    left: 25px;
    position: absolute;
    top: 15px;
    transition: all 0.15s linear;
}

.frontend-panel .category-content h3 {
    margin-bottom: 5px;
    text-align: left;
}

.frontend-panel .category-content h3 a {
    color: #fff;
    font-size: 20px;
}

.frontend-panel .category:hover .category-view-more {
    visibility: visible;
}

.frontend-panel .listings-parallax {
    /* background: url(../img/listings-parallax.html); */
    z-index: 0;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    color: #efefef;
    padding-top: 80px;
}

.frontend-panel .listings-parallax .text {
    display: inline-block;
}

.frontend-panel .social-links .social-list li {
    display: inline-block;
    font-size: 16px;
}

.frontend-panel .social-links .social-list li a {
    margin-right: 5px;
    font-size: 14px;
    width: 40px;
    height: 40px;
    border-radius: 3px;
    display: inline-block;
    text-align: center;
    line-height: 40px;
}

.frontend-panel .option-bar {
    margin-bottom: 30px;
    padding: 10px 15px;
    background-color: #fff;
    box-shadow: 0 0 35px rgba(0, 0, 0, 0.1);
}

.frontend-panel .option-bar .sort {
    color: #353535;
    font-size: 14px;
    font-weight: 500;
    margin-right: 10px;
    line-height: 35px;
    white-space: nowrap;
}

.frontend-panel .sorting-options span {
    line-height: 35px;
}

.frontend-panel .sorting-options {
    float: right;
}

.frontend-panel .sorting-options2 {
    display: flex;
    align-items: center;
}

.frontend-panel .sorting-options2 .bootstrap-select {
    width: 200px !important;
    font-family: "Poppins", sans-serif;
}

.frontend-panel .sorting-options2 .search-fields {
    min-height: 36px;
    padding: 0;
}

.frontend-panel .sorting-options2 .custom-select.search-fields {
    max-width: 200px;
}

.frontend-panel .sorting-options2 .dropdown-toggle:after {
    margin-left: 0em;
}

.frontend-panel .sorting-options2 .bootstrap-select > .dropdown-toggle {
    padding-right: 30px;
}

.frontend-panel .sorting-options2 .bootstrap-select button {
    color: #545454 !important;
    font-weight: 500;
    border-radius: 3px;
    outline: none;
    padding: 9px 15px 3px 15px;
}

.frontend-panel .change-view-btn {
    border-radius: 3px;
    background: transparent;
    padding: 5px 9px;
    margin: 0 2px;
    display: inline-block;
}

.frontend-panel .change-view-btn:hover {
    color: #fff;
}

.frontend-panel .active-view-btn {
    color: #fff !important;
    padding: 5px 9px;
}

.frontend-panel .active-view-btn:hover {
    background: transparent;
}

.frontend-panel .mCS-minimal-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
    background-color: #ccc !important;
    filter: "alpha(opacity=20)";
    -ms-filter: "alpha(opacity=20)";
}

.frontend-panel .comments {
    list-style: none;
    margin: 0;
    padding: 0;
}

.frontend-panel .comment {
    margin: 0 0 30px 0;
    position: relative;
    display: inline-block;
    width: 100%;
}

.frontend-panel .comment p {
    font-size: 15px;
    line-height: 25px;
}

.frontend-panel .comment h5 {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 5px;
}

.frontend-panel .comment-author {
    border-radius: 50%;
    height: 60px;
    width: 60px;
    left: 0;
    position: absolute;
    top: 0;
}

.frontend-panel .comment-author a img {
    bottom: 2px;
    border-radius: 7%;
    display: block;
    width: 100%;
    position: absolute;
    top: 2px;
    box-shadow: 1px 2px 3px 0 rgba(0, 0, 0, 0.4);
}

.frontend-panel .t-s {
    margin-top: 40px;
}

.frontend-panel .t-s h2 {
    font-weight: 500;
    font-size: 22px;
    margin: 0 0 20px;
}

.frontend-panel .comment-content {
    padding-bottom: 10px;
    border-bottom: dashed 1px #d6d6d6;
    margin: 0 0 0 77px;
}

.frontend-panel .mrg-bdr {
    margin-bottom: 0;
    border-bottom: none;
    padding: 0;
}

.frontend-panel .comment-content h3 {
    font-size: 17px;
    font-weight: 500;
    margin-bottom: 5px;
}

.frontend-panel .comment-content .comment-rating {
    color: orange;
}

.frontend-panel .comment-meta {
    margin-bottom: 10px;
    font-size: 14px;
    color: #535353;
    font-weight: 500;
}

.frontend-panel .comment-meta a {
    margin-left: 10px;
    font-weight: 500;
}

.frontend-panel .comments ul {
    list-style: none;
    margin: 0 0 0 80px;
    padding: 0;
}

.frontend-panel .comment-b .comment-content {
    padding-bottom: 0;
    border-bottom: transparent;
    margin: 0 0 0 85px;
}

.frontend-panel .comment-b .comment-content p {
    margin-bottom: 0;
}

.frontend-panel .comment-b {
    margin-bottom: 0;
}

.frontend-panel .comment-content ul {
    margin-bottom: 20px;
}

.frontend-panel .comment-content ul li {
    font-weight: 500;
    color: #50596e;
    line-height: 30px;
    font-size: 14px;
}

.frontend-panel .comment-content ul li span {
    font-weight: 500;
    color: #737780;
}

.frontend-panel .comment-content ul li a {
    color: #737780;
}

.frontend-panel .comment-content .btn-1 {
    border: none;
    cursor: pointer;
    padding: 6px 20px;
    display: inline-block;
    outline: none;
    font-size: 14px;
    border-radius: 25px;
    font-weight: 500;
    background: #e9ecef;
    color: #535353;
}

.frontend-panel .coming-soon-bg {
    /* background: rgba(0, 0, 0, 0.04) url(../img/banner/img-5.jpg) top left repeat; */
    background-size: cover;
    top: 0;
    width: 100%;
    bottom: 0;
    opacity: 1;
    min-height: 100vh;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 25px 0;
}

.frontend-panel .coming-soon-bg:before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.4);
}

.frontend-panel .coming-soon-inner img {
    margin-bottom: 20px;
    height: 40px;
}

.frontend-panel .coming-soon-inner {
    text-align: center;
    margin: 0 auto;
    max-width: 820px;
    color: #fff;
}

.frontend-panel .coming-soon-inner h1 {
    font-weight: 500;
    font-size: 50px;
    margin-bottom: 50px;
    color: #fff;
}

.frontend-panel .coming-soon-inner h6 {
    color: #fff;
    font-size: 16px;
    margin-bottom: 30px;
    letter-spacing: 1px;
}

.frontend-panel .coming-soon-inner ul li {
    display: inline-block;
}

.frontend-panel .coming-soon-inner ul li a {
    color: #fff;
}

.frontend-panel .coming-soon ul li a {
    color: #fff;
    width: 45px;
    font-size: 18px;
    height: 45px;
    display: inline-block;
    line-height: 45px;
    margin: 2px;
    border-radius: 3px;
    background: rgba(0, 0, 0, 0.36);
    -webkit-box-shadow: 0px 0px 0px 2px rgba(255, 255, 255, 0.2);
    box-shadow: 0px 0px 0px 2px rgba(255, 255, 255, 0.2);
}

.frontend-panel .coming-soon ul li a:hover {
    background-color: #fff;
    -webkit-box-shadow: 0px 0px 0px 2px #fff;
    box-shadow: 0px 0px 0px 2px #fff;
}

.frontend-panel .coming-soon-inner .coming-form {
    max-width: 55%;
    margin: 30px auto;
}

.frontend-panel .coming-soon-inner .bordered-right {
    border-right: 1px solid #848484;
}

.frontend-panel .coming-soon-inner .form-control {
    width: 69%;
    height: 50px;
    padding: 10px 15px;
    border-radius: 0;
    font-size: 16px;
    font-weight: 500;
    outline: 0;
    border: 1px solid #ebebeb;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
}

.frontend-panel .coming-soon-inner .btn,
.frontend-panel .coming-soon-inner .btn:hover,
.frontend-panel .coming-soon-inner .btn:active {
    width: 31%;
    border-radius: 0;
    height: 50px;
    line-height: 50px;
    padding: 0 15px;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 1px;
    cursor: pointer;
    color: #fff;
}

.frontend-panel .coming-soon-counter {
    margin-bottom: 15px;
}

.frontend-panel .coming-soon-counter div {
    display: inline-block;
    font-size: 50px;
    font-weight: 500;
    text-align: center;
    width: 20%;
    margin: 0 2%;
    padding-bottom: 10px;
    text-shadow: 2px 2px rgba(12, 11, 11, 0.56);
    margin-bottom: 30px;
    border-radius: 5px;
    background: rgba(0, 0, 0, 0.12);
    -webkit-box-shadow: 0px 0px 0px 3px rgba(255, 255, 255, 0.2);
    box-shadow: 0px 0px 0px 3px rgba(255, 255, 255, 0.2);
}

.frontend-panel .coming-soon-counter div:last-child {
    border: none;
}

.frontend-panel .coming-soon-counter div span {
    display: block;
    font-weight: 500;
    text-align: center;
    font-size: 14px;
    color: #fff;
}

.frontend-panel .ca .team-2 .detail {
    padding: 20px 20px 20px 25px;
    left: 0;
}

.frontend-panel .social-media-box .social-list li {
    float: left;
}

.frontend-panel .social-media-box .social-list li a {
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    display: inline-block;
    font-size: 18px;
    color: #fff;
    margin: 0 6px 4px 0;
    border-radius: 3px;
}

.frontend-panel .financing-calculator .form-label {
    margin-bottom: 8px;
    font-size: 12px;
    font-weight: 500;
    color: #535353;
}

.frontend-panel .financing-calculator .form-group {
    margin-bottom: 20px;
}

.frontend-panel .brand-box {
    text-align: center;
    display: inline-block;
    width: 100%;
    margin-bottom: 30px;
    padding: 15px 10px 20px;
    box-shadow: 0 0 35px rgba(0, 0, 0, 0.1);
}

.frontend-panel .brand-box img {
    height: 49px;
    margin-bottom: 5px;
}

.frontend-panel .brand-box h5 {
    font-weight: 500;
    margin: 0;
    font-size: 15px;
    color: #535353;
}

.frontend-panel .view-all {
    text-align: center;
}

.frontend-panel .view-all h3 {
    margin-bottom: 20px;
    font-size: 20px;
    font-weight: 500;
}

.frontend-panel .view-all ul {
    margin-bottom: 20px;
}

.frontend-panel .view-all ul li {
    display: inline-block;
}

.frontend-panel .view-all ul li a {
    width: 38px;
    height: 38px;
    line-height: 40px;
    border-radius: 3px;
    margin-bottom: 5px;
    text-align: center;
    display: inline-block;
    font-size: 16px;
    font-weight: 500;
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.1);
    background: white;
    color: #535353;
}

.frontend-panel .search-brand .form-search .form-control {
    padding: 10px 17px;
    color: #495057 !important;
    min-height: 50px;
    font-size: 17px;
    width: 100%;
    outline: none;
    border-radius: 3px;
    font-weight: 500;
    border: 1px solid #ccc;
    background: #fff !important;
}

.frontend-panel .mt-30 {
    margin-top: 30px;
}

.frontend-panel .content-area {
    padding-top: 20px;
    padding-bottom: 20px;
}

.frontend-panel .content-area-2 {
    margin-bottom: 100px;
}

.frontend-panel .content-area-3 {
    margin-bottom: 70px;
}

.frontend-panel .content-area-4 {
    padding: 100px 0 50px;
}

.frontend-panel .content-area-5 {
    padding: 100px 0;
}

.frontend-panel .content-area-6 {
    padding: 100px 0 50px;
}

.frontend-panel .content-area-7 {
    padding: 100px 0 85px;
}

.frontend-panel .content-area-8 {
    padding: 187px 0 70px;
}

.frontend-panel .content-area-9 {
    padding: 100px 0 90px;
}

.frontend-panel .content-area-10 {
    padding: 100px 0 60px;
}

.frontend-panel .content-area-11 {
    padding: 100px 0;
}

.frontend-panel .theme img {
    margin-bottom: 30px;
}

.frontend-panel .content-area-12 {
    padding: 100px 0 130px;
}

.frontend-panel .content-area-13 {
    padding: 100px 0 50px;
}

.frontend-panel .pagination {
    padding-left: 0;
    border-radius: 4px;
    display: inline-block;
}

.frontend-panel .pagination li {
    display: inline-block;
}

.frontend-panel .pagination > li > a {
    color: #353535;
    font-size: 17px;
    background-color: #fff;
    box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.1);
    font-weight: 500;
}

.frontend-panel .pagination > li > a,
.frontend-panel .pagination > li > span {
    border-radius: 0;
    margin: 0 2px 5px;
    padding: 12px 19px;
    text-align: center;
    border: none;
}

.frontend-panel .pagination > li > a:hover {
    color: #fff;
}

.frontend-panel .pagination > li > .active {
    color: #fff;
    box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.1);
}

.frontend-panel .page-link:focus {
    box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.1);
}

.frontend-panel .show-more-options {
    font-weight: 500;
    font-size: 12px;
    margin-bottom: 20px;
    display: block;
}

.frontend-panel .sidebar-right .show-more-options {
    font-size: 15px;
}

.frontend-panel .sidebar-left .show-more-options {
    font-size: 14px;
}

.frontend-panel .map_filters.sidebar-left {
    position: fixed;
    top: 0;
    right: -100%;
    width: 400px;
    max-width: 100%;
    opacity: 0;
    height: 100vh;
    visibility: hidden;
    overflow-y: auto;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
}

.active_sidebar {
    overflow: hidden;
}

.active_sidebar:after {
    content: "";
    position: fixed;
    background: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 99;
}

.active_sidebar .map_filters.sidebar-left {
    right: 0;
    opacity: 1;
    visibility: visible;
    z-index: 999;
}

.frontend-panel .map_filters .sidebar-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 15px;
    border-bottom: 1px solid #ff2654;
    background: #ff2654;
}

.frontend-panel .map_filters .sidebar-header .sidebar-title {
    color: #fff;
    margin: 0;
}

.frontend-panel .map_filters .sidebar-header .sidebar_close {
    background: transparent;
    border: none;
    padding: 0;
    color: #fff;
    font-size: 26px;
    line-height: 26px;
}

.frontend-panel .map_filters #options-content {
    padding: 0;
}

.frontend-panel .map_filters #options-content .sidebar-title {
    font-size: 16px;
    padding: 20px;
}

.frontend-panel .map_filters #options-content .sidebar-title:before {
    content: "\f078";
    font-family: "Font Awesome 5 Pro";
    position: absolute;
    right: 20px;
    font-weight: 400;
}

.frontend-panel .map_filters #options-content .s-border,
.frontend-panel .map_filters #options-content .m-border {
    display: none;
}

.frontend-panel .map_filters.sidebar-left .max-widget {
    max-height: 350px;
    overflow-y: auto;
    padding: 0 20px 20px 20px;
}

.frontend-panel .partners {
    text-align: center;
    background: #f9f9f8;
    padding: 20px 0 10px;
}

.frontend-panel .partners img {
    max-width: 160px;
    margin: 0 auto;
}

.frontend-panel .navbar-buttons img {
    width: 45px;
    border-radius: 50px;
    margin: 20px 15px 20px 0;
}

.frontend-panel .navbar-buttons {
    float: right;
    text-align: right;
}

.frontend-panel .navbar-buttons ul li {
    float: left;
    margin-left: 35px;
}

.frontend-panel .navbar-buttons .btn-md {
    border-radius: 50px;
    letter-spacing: normal;
    padding: 12px 30px 10px 30px;
}

.frontend-panel .header-2 {
    padding: 0 15px;
}

.frontend-panel .sidebar-right {
    margin: 0 0 30px 0;
}

.frontend-panel .sidebar-left {
    margin: 0 0 30px 0;
    background-color: #fff;
    box-shadow: 0 0 35px rgba(0, 0, 0, 0.1);
}

.frontend-panel .widget {
    padding: 25px 20px;
    border-bottom: solid 1px #eaeaea;
}

.frontend-panel .widget-2 {
    margin-bottom: 40px;
    padding: 20px;
}

.frontend-panel .widget-3 {
    padding: 30px 20px;
    border-bottom: none;
}

.frontend-panel .widget .search-fields {
    min-height: 45px;
    padding: 0;
    border-radius: 3px;
    box-shadow: none;
    border: 1px solid #e0e0e0;
    background-color: #fff;
}

.frontend-panel .widget .bootstrap-select button {
    color: #545454 !important;
    font-weight: 500;
    border-radius: 3px;
    outline: none;
    padding: 13px 30px 8px 15px;
}

.frontend-panel .single-block {
    margin-bottom: 30px;
}

.frontend-panel .sidebar-title {
    font-size: 21px;
    position: relative;
    margin: 0 0 7px;
    font-weight: 500;
}

.frontend-panel .s-border {
    width: 40px;
    height: 1px;
    margin-bottom: 2px;
    border-radius: 50px;
    background: #c5c5c5;
}

.frontend-panel .m-border {
    width: 20px;
    height: 1px;
    margin-bottom: 25px;
    border-radius: 50px;
    background: #c5c5c5;
}

.frontend-panel .sidebar .search-box {
    padding: 20px 20px 0 20px;
    margin-bottom: 0;
}

.frontend-panel .form-search {
    position: relative;
}

.frontend-panel .form-search .form-control {
    padding: 10px 17px;
    color: #495057 !important;
    width: 100%;
    min-height: 45px;
    font-size: 15px;
    font-weight: 500;
    border-radius: 3px;
    background-color: #fff;
    border: 1px solid #dbdbdb;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.06);
}

.frontend-panel .form-search .btn {
    background: none;
    position: absolute;
    right: 0;
    top: 0;
    padding: 12px 12px 9px 13px;
}

.frontend-panel .form-search .btn:active,
.frontend-panel .form-search .btn:focus {
    box-shadow: none;
    outline: none;
}

.frontend-panel .form-search .btn i {
    color: #777;
}

.frontend-panel .form-search .form-group,
.frontend-panel .form-search .form-control {
    width: 100%;
}

.frontend-panel .range-slider .ui-slider {
    position: relative;
    text-align: left;
}

.frontend-panel .range-slider label {
    font-size: 13px;
    color: #535353;
    font-weight: 500;
    margin-bottom: 12px;
}

.frontend-panel .range-slider .ui-slider .ui-slider-handle {
    position: absolute;
    z-index: 2;
    width: 10px;
    height: 10px;
    border-radius: 50px;
    border: 2px solid #66676b;
    cursor: w-resize;
    outline: none;
    top: -4px;
    margin-left: -8px;
    box-shadow: none !important;
}

.frontend-panel .range-slider .ui-slider .ui-slider-range {
    position: absolute;
    z-index: 1;
    display: block;
    border: 0;
    background-position: 0 0;
}

.frontend-panel .range-slider .ui-widget-content {
    background-color: #ccc;
}

.frontend-panel .range-slider .ui-slider.ui-state-disabled .ui-slider-handle,
.frontend-panel .range-slider .ui-slider.ui-state-disabled .ui-slider-range {
    filter: inherit;
}

.frontend-panel .range-slider .ui-slider-horizontal {
    height: 3px;
    border-radius: 10px;
    width: calc(100% - 16px);
    margin-left: 8px;
}

.frontend-panel .range-slider .ui-slider-horizontal .ui-slider-range {
    top: 0;
    height: 100%;
}

.frontend-panel .range-slider .ui-slider-horizontal .ui-slider-range-min {
    left: 0;
}

.frontend-panel .range-slider .ui-slider-horizontal .ui-slider-range-max {
    right: 0;
}

.frontend-panel .min-value:focus,
.frontend-panel .max-value:focus,
.frontend-panel .min-value,
.frontend-panel .max-value {
    width: 40%;
    display: inline-block;
    border: 0;
    background-color: transparent;
    padding: 0;
    margin: 12px 0 0 0;
    cursor: default;
}

.frontend-panel .min-value {
    float: left;
    margin-left: -8px;
    width: 40%;
    margin-right: -8px;
    font-size: 12px;
    color: #535353;
    font-weight: 500;
    margin-top: 15px;
}

.frontend-panel .max-value {
    float: right;
    width: 60%;
    text-align: right;
    margin-right: -8px;
    font-size: 12px;
    color: #535353;
    font-weight: 500;
    margin-top: 15px;
}

.frontend-panel .sidebar .recent-partycrack-box .detail header {
    padding-bottom: 0;
}

.frontend-panel .sub-banner {
    background-size: cover;
    height: 180px;
    background-position: center center;
    background-repeat: no-repeat;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);
    background-blend-mode: overlay;
}

.frontend-panel .sub-banner .breadcrumb-area {
    text-align: center;
}

.frontend-panel .sub-banner .breadcrumb-area h1 {
    margin: 0 0 10px;
    font-weight: 500;
    color: #fff;
    line-height: normal;
    font-size: 30px;
}

.frontend-panel .sub-banner .breadcrumbs li {
    display: inline-block;
    list-style: none;
    font-size: 16px;
    font-weight: 500;
}

.frontend-panel .sub-banner .breadcrumbs li a {
    color: #fff;
}

.frontend-panel .sub-banner .breadcrumbs .active {
    margin-left: 7px;
    color: #fff;
}

.frontend-panel .sub-banner .breadcrumbs .active:before {
    content: "\f105";
    font-family: "Font Awesome 5 Pro";
    font-weight: 400;
    font-size: 14px;
    margin-right: 7px;
}

.frontend-panel .blog-social-list {
    float: right;
}

.frontend-panel .blog-social-list a {
    text-align: center;
    display: inline-block;
    list-style: none;
}

.frontend-panel .blog-social-list span {
    margin-right: 10px;
    font-weight: 500;
    color: #353535;
}

.frontend-panel .blog-social-list a {
    width: 33px;
    height: 33px;
    line-height: 35px;
    text-align: center;
    display: inline-block;
    font-size: 15px;
    color: #fff;
    margin-bottom: 5px;
    border-radius: 5%;
}

.frontend-panel .blog-tags a {
    border-radius: 3px;
    color: #535353;
    display: inline-block;
    font-size: 14px;
    margin: 0 0 5px;
    padding: 5px 12px;
    font-weight: 500;
    text-transform: capitalize;
    border: 1px solid #f7f7f7;
    background: #f7f7f7;
}

.frontend-panel .blog-tags span {
    margin-right: 10px;
    font-weight: 500;
    color: #353535;
}

.frontend-panel .my-address form .form-group {
    margin-bottom: 20px;
}

.frontend-panel .my-address form .input-text {
    width: 100%;
    padding: 10px 17px;
    font-size: 14px;
    border: 1px solid #e8e7e7;
    outline: none;
    height: 45px;
    border-radius: 3px;
    line-height: 30px;
}

.frontend-panel .my-address form textarea {
    min-height: 150px;
}

.frontend-panel .my-address form label {
    display: inline-block;
    max-width: 100%;
    margin-bottom: 5px;
    font-size: 12px;
    font-weight: 500;
    color: #827f7f;
}

.frontend-panel .my-address {
    background-color: #fff;
    padding: 20px;
    box-shadow: 0 0 10px 1px rgba(71, 85, 95, 0.08);
}

.frontend-panel .edit-profile-photo {
    position: relative;
    background-color: #fff;
    box-shadow: 1px 2px 3px 0 rgba(0, 0, 0, 0.23);
    padding: 2px;
}

.frontend-panel .change-photo-btn {
    display: inline-block;
    position: absolute;
    font-size: 13px;
    bottom: -22px;
    left: 40%;
    width: auto;
    transition: 0.3s;
    cursor: pointer;
    line-height: 28px;
}

.frontend-panel .photoUpload {
    position: relative;
    overflow: hidden;
    padding: 6px 15.5px 10px;
    cursor: pointer;
    font-size: 14px;
    background: #e8e8e8;
    box-shadow: 0 0 35px rgba(0, 0, 0, 0.1);
}

.frontend-panel .photoUpload.clip-home {
    clip-path: polygon(0 0, 100% 0, 100% 80%, 50% 100%, 0 80%);
    position: relative;
}

.frontend-panel .photoUpload span {
    font-weight: 500;
}

.frontend-panel .photoUpload input.upload {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    padding: 0;
    cursor: pointer;
    opacity: 0;
    filter: alpha(opacity=0);
}

.frontend-panel .my-address {
    margin-bottom: 30px;
}

.frontend-panel .footer .listing-meta span a {
    text-decoration: none;
    float: right;
    margin-left: 15px;
    color: #525252;
}

.frontend-panel .footer .listing-meta span a i {
    margin-right: 4px;
}

.frontend-panel .footer .listing-meta span a:hover {
    color: #8c8c8c;
}

.frontend-panel .heading {
    margin: 0 0 30px;
    /* font-size: 20px;
  */
    font-weight: 500;
}

.frontend-panel .heading-2 {
    margin: 0 0 20px;
    font-size: 21px;
    font-weight: 500;
}

.frontend-panel .form-group label,
.frontend-panel .checkbox label {
    font-size: 15px;
    color: #353535;
}

.frontend-panel .advanced-search .form-group label,
.frontend-panel .checkbox label {
    color: #5f5e5e;
}

.frontend-panel .dashboard {
    position: relative;
}

.frontend-panel .fixed-header {
    position: fixed;
    width: 100%;
    z-index: 9999;
    background-color: #fff;
}

.frontend-panel .dashboard-nav {
    background-color: #2a2a2a;
    display: block;
    height: 100%;
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.12);
    z-index: 11;
    position: fixed;
    min-height: 100vh;
    overflow: hidden;
    min-width: 280px;
}

.frontend-panel .dashboard-nav h4 {
    margin-bottom: 0;
    color: #999;
    font-weight: 500;
    font-size: 12px;
    letter-spacing: 1px;
    padding: 15px 30px 15px;
}

.frontend-panel .dashboard-nav ul {
    border-bottom: 1px solid #343a40;
}

.frontend-panel .dashboard-nav ul li {
    border-left: 3px solid transparent;
    line-height: 25px;
    font-weight: 400;
    font-size: 15px;
}

.frontend-panel .dashboard-nav ul li a {
    color: #b2b3b5;
    display: block;
    padding: 11px 27px;
    transition: 0.3s;
    cursor: pointer;
}

.frontend-panel .dashboard-nav ul li a i {
    padding-right: 8px;
    width: 20px;
}

.frontend-panel .dashboard-content {
    padding: 30px 30px 0;
    position: relative;
    z-index: 10;
    margin-left: 280px;
    width: 100%;
}

.frontend-panel .dashboard-header {
    margin-bottom: 20px;
}

.frontend-panel .dashboard-header .breadcrumb-nav {
    right: 15px;
    display: inline-block;
    font-size: 15px;
    font-weight: 400;
    float: right;
}

.frontend-panel .dashboard-header .breadcrumb-nav ul li {
    display: inline-block;
    list-style: none;
    margin: 0 0 0 15px;
    font-weight: 500;
}

.frontend-panel .dashboard-header .breadcrumb-nav ul li a {
    color: #353535;
}

.frontend-panel .dashboard-header h4 {
    font-weight: 500;
    font-size: 21px;
}

.frontend-panel .dash-form {
    box-shadow: 0 0 35px rgba(0, 0, 0, 0.1);
    padding: 0;
    margin-bottom: 30px;
    display: inline-block;
    width: 100%;
}

.frontend-panel .dash-form h3 {
    padding: 15px 25px;
    border-bottom: 1px solid #eee;
    font-size: 18px;
    margin: 0;
    width: 100%;
    font-weight: 500;
}

.frontend-panel .dashboard-message {
    float: left;
    padding: 25px 25px;
    position: relative;
    border-bottom: 1px solid #eee;
    width: 100%;
}

.frontend-panel .dash-form h4 {
    padding: 15px 25px;
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
    font-size: 18px;
    font-weight: 500;
    margin: 0;
}

.frontend-panel .responsive-table {
    padding: 20px;
    display: inline-block;
    border-bottom: 1px solid #eee;
    width: 100%;
}

.frontend-panel .dashboard-nav ul li span.nav-tag {
    font-family: open sans;
    font-weight: 400;
    display: inline-block;
    font-size: 11px;
    line-height: 20px;
    color: #fff;
    padding: 0 7px;
    text-align: center;
    min-width: 20px;
    height: 20px;
    border-radius: 50px;
    margin-left: 5px;
}

.frontend-panel .dash-form .comment-content {
    padding-bottom: 10px;
    margin-bottom: 0;
}

.frontend-panel .sub-banner-2 {
    margin: 0 15px 30px;
    font-size: 15px;
}

.frontend-panel .manage-table .listing-photoo {
    width: 20%;
}

.frontend-panel .manage-table .listing-photoo img {
    width: 100%;
    border-radius: 5px;
}

.frontend-panel .manage-table .title-container {
    width: 55%;
    padding: 0 20px;
}

.frontend-panel .manage-table .title-container h2 {
    font-weight: 500;
    font-size: 20px;
    margin-bottom: 12px;
}

.frontend-panel .manage-table .title-container h5 {
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 12px;
    color: #535353;
}

.frontend-panel .manage-table .title-container h6 {
    font-size: 15px;
    font-weight: 500;
    color: #535353;
}

.frontend-panel .manage-table .expire-date {
    font-size: 15px;
    width: 15%;
    font-weight: 500;
    padding: 0 20px 0 0;
    color: #535353;
}

.frontend-panel .manage-table .action {
    width: 10%;
}

.frontend-panel .manage-table .action a {
    display: block;
    font-size: 14px;
    margin: 3px 0;
    font-weight: 500;
    color: #535353;
}

.frontend-panel .manage-table .action a i {
    margin-right: 3px;
}

.frontend-panel .manage-table .title-container h2 a:hover {
    color: #4a4747;
}

.frontend-panel .invoice {
    width: 100%;
    display: inline-block;
}

.frontend-panel .invoice .address-info {
    margin-bottom: 30px;
}

.frontend-panel .invoice .address-info .strong {
    font-weight: 500;
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: solid 1px #dee2e6;
    color: #353535;
}

.frontend-panel .invoice .address-info p {
    margin-bottom: 0;
    font-size: 15px;
}

.frontend-panel .invoice strong {
    color: #fff;
    font-weight: 500;
}

.frontend-panel .invoice .order {
    text-align: right;
    line-height: 40px;
    font-size: 22px;
}

.frontend-panel .invoice .logo img {
    height: 40px;
}

.frontend-panel .invoice-mb-30 {
    margin-bottom: 30px;
}

.frontend-panel .invoice .table {
    margin-bottom: 0;
}

.frontend-panel .tab-box-2 .nav-pills .nav-link.active,
.frontend-panel .nav-pills .show > .nav-link {
    background: transparent;
    border-radius: 0;
}

.frontend-panel .tab-box-2 .nav-pills .nav-link {
    padding: 0 5px 10px;
    font-weight: 500;
}

.frontend-panel .tab-box-2 .nav-item {
    float: right;
    display: inline-block;
}

.frontend-panel .comments-tr {
    border-bottom: 1px solid #eee;
}

.frontend-panel .comments-tr span {
    font-size: 16px;
    margin: 0;
    font-weight: 500;
    color: #353535;
}

.frontend-panel .tab-box-2 .comment-content {
    padding-bottom: 0;
    border-bottom: none;
}

.frontend-panel .tab-box-2 .comment-content p {
    margin-bottom: 0;
    font-size: 15px;
    line-height: 25px;
}

.frontend-panel .tab-box .nav-link {
    display: block;
    padding: 7px 30px;
    font-weight: 500;
    border-radius: 50px !important;
}

.frontend-panel .tab-box p {
    font-size: 15px;
    margin-bottom: 0;
}

.frontend-panel .ui-item {
    position: relative;
    width: 100%;
    display: inline-block;
    padding: 30px;
    color: #fff;
    border-radius: 5px;
    margin-bottom: 50px;
}

.frontend-panel .ui-item i {
    font-size: 70px;
    position: absolute;
    right: 20px;
    top: 20px;
    color: rgba(255, 255, 255, 0.41);
}

.frontend-panel .ui-item p {
    color: #fff;
    font-weight: 500;
    margin-bottom: 0;
}

.frontend-panel .ui-item h4 {
    color: #fff;
    font-weight: 500;
    font-size: 30px;
}

.frontend-panel .alert-2 {
    margin-bottom: 50px;
}

.frontend-panel .typography .heading {
    margin: 0 0 20px;
}

.frontend-panel .typography .sd p {
    margin-bottom: 40px;
}

.frontend-panel .partycrack-amenities ul li {
    margin-bottom: 10px;
    font-size: 15px;
    padding-left: 27px;
    position: relative;
    font-weight: 500;
}

.frontend-panel .partycrack-amenities ul li i {
    left: 0;
    position: absolute;
    top: 3px;
}

.frontend-panel .search-banner .show-more-options {
    margin-bottom: 20px;
    display: block;
}

.frontend-panel .partycrack-details ul li {
    font-weight: 500;
    margin-bottom: 15px;
    font-size: 13px;
}

.frontend-panel .partycrack-details ul li strong {
    margin-right: 5px;
    font-weight: 500;
}

.frontend-panel .partycrack-details-page .heading {
    margin-bottom: 20px;
    font-size: 22px;
    font-weight: 500;
}

.frontend-panel .partycrack-details-page iframe,
.frontend-panel .modal-body iframe .element.style {
    height: 350px;
}

.frontend-panel .partycrack-details-page iframe,
.frontend-panel .modal-body iframe {
    width: 100%;
    border: none;
}

.frontend-panel .heading-partycrack {
    letter-spacing: 1px;
    width: 100%;
    top: 0;
    right: 0;
    left: 0;
}

.frontend-panel .heading-partycrack h3 {
    margin-top: 0;
    font-size: 22px;
}

.frontend-panel .heading-partycrack p {
    margin-bottom: 0;
    font-size: 14px;
}

.frontend-panel .heading-partycrack h3 span {
    font-weight: 500;
}

.frontend-panel .heading-partycrack h5 {
    text-align: right;
    color: #fff;
    font-size: 14px;
    line-height: 26px;
    margin: 0;
}

.frontend-panel .heading-partycrack-2 {
    margin-bottom: 20px;
}

.frontend-panel .heading-partycrack-2 h1 {
    font-size: 30px;
    font-weight: 500;
    margin: 0 0 10px;
}

.frontend-panel .heading-partycrack-2 ul li {
    float: left;
    margin-right: 20px;
    font-weight: 500;
    font-size: 15px;
}

.frontend-panel .heading-partycrack-2 .ratings-2 i {
    color: orange;
}

.frontend-panel .heading-partycrack-2 .ratings-2 span {
    padding: 5px 10px;
    border-radius: 3px;
    font-size: 12px;
    font-weight: 500;
    color: #fff;
    margin-left: 10px;
}

.frontend-panel .heading-partycrack-3 {
    margin-bottom: 20px;
}

.frontend-panel .heading-partycrack-3 h1 {
    font-size: 25px;
    font-weight: 500;
    margin: 0 0 5px;
}

.frontend-panel .heading-partycrack-3 h1 span {
    float: right;
}

.frontend-panel .heading-partycrack-3 p {
    font-size: 16px !important;
    margin-bottom: 0;
}

.frontend-panel .heading-partycrack-3 p span {
    float: right;
}

.frontend-panel .partycrack-details-sliders .list-inline {
    white-space: nowrap;
    width: 100% !important;
}

.frontend-panel .partycrack-details-sliders .carousel-indicators {
    position: static;
    left: initial;
    width: initial;
    margin-left: initial;
    margin-top: 0;
}

.frontend-panel .partycrack-details-sliders .carousel-indicators a {
    cursor: pointer;
}

.frontend-panel .partycrack-details-sliders .carousel-indicators > li {
    height: initial;
    text-indent: initial;
    margin-right: 0;
    margin-left: 0;
}

.frontend-panel .dd.partycrack-details-sliders .carousel-indicators > li {
    width: initial;
    height: initial;
    text-indent: initial;
}

.frontend-panel .smail-partycrack .list-inline-item {
    width: 20%;
}

.frontend-panel .smail-partycrack .list-inline-item a img {
    width: 100%;
    border: none;
    border-radius: 0;
}

.frontend-panel .partycrack-details-sliders .carousel-indicators > li.active img {
    opacity: 0.7;
}

.frontend-panel .partycrack-details-sliders .right {
    position: absolute;
    right: 15px;
    top: 45%;
}

.frontend-panel .partycrack-details-sliders .left {
    left: 10px;
    top: 47%;
    width: 30px;
    height: 30px;
    line-height: 33px;
    position: absolute;
    z-index: 5;
    display: inline-block;
    right: -30px;
    text-align: center;
    background-color: #fff;
    border-radius: 5%;
}

.frontend-panel .partycrack-details-sliders .left i {
    font-size: 20px;
    color: #353535;
}

.frontend-panel .partycrack-details-sliders .right {
    right: 10px;
    top: 47%;
    width: 30px;
    height: 30px;
    line-height: 33px;
    position: absolute;
    z-index: 5;
    display: inline-block;
    text-align: center;
    background-color: #fff;
    border-radius: 5%;
}

.frontend-panel .partycrack-details-sliders .right i {
    font-size: 20px;
    color: #353535;
}

.frontend-panel .list-inline-item:not(:last-child) {
    margin-right: 1px;
}

.frontend-panel .smail-partycrack {
    width: 100%;
}

.frontend-panel .partycrack-details-sliders .overview-bgi-2:before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.29);
}

.frontend-panel .partycrack-details-sliders .heading-partycrack-2 {
    letter-spacing: 1px;
    position: absolute;
    width: 100%;
    top: 33%;
    margin-bottom: 0;
}

.frontend-panel .partycrack-details-sliders .heading-partycrack-2 h1 {
    font-size: 30px;
    font-weight: 500;
    margin: 0 0 10px;
    color: #fff;
}

.frontend-panel .partycrack-details-sliders .heading-partycrack-2 ul li {
    color: #fff;
}

.frontend-panel .partycrack-details-sliders .heading-partycrack-2 .informeson {
    max-width: 600px;
    margin-right: auto;
    padding: 25px;
    background: rgba(0, 0, 0, 0.23);
}

.frontend-panel .partycrack-details-sliders .heading-partycrack-2 .informeson ul {
    margin-bottom: 15px;
}

.frontend-panel .partycrack-details-sliders .heading-partycrack-2 .informeson ul li {
    line-height: 27px;
}

.frontend-panel .partycrack-details-sliders .heading-partycrack-2 .informeson ul li:last-child {
    margin-right: 0;
}

.frontend-panel .partycrack-details-Slider .list-inline {
    position: absolute;
    bottom: 20px;
    text-align: center;
    z-index: 20;
    margin: 0 auto;
    width: 100%;
}

.frontend-panel .partycrack-details-Slider .carousel-indicators > li {
    height: initial;
    text-indent: initial;
}

.frontend-panel .partycrack-details-Slider .smail-partycrack .list-inline-item {
    width: 70px;
    margin: 5px;
    box-shadow: 1px 2px 3px 0 rgba(0, 0, 0, 0.4);
}

.frontend-panel .partycrack-details-Slider .location {
    font-size: 15px;
    font-weight: 500;
    color: #fff;
    margin-bottom: 10px;
}

.frontend-panel .partycrack-details-Slider .ratings-2 {
    font-size: 13px;
    font-weight: 500;
    margin-bottom: 10px;
}

.frontend-panel .partycrack-details-Slider .ratings-2 span {
    color: #fff;
}

.frontend-panel .partycrack-details-Slider .price {
    font-weight: 500;
}

.frontend-panel .partycrack-details-Slider .price .month {
    color: #fff;
    font-weight: 400;
}

.frontend-panel .partycrack-details-Slider .ratings-2 i {
    color: orange;
}

.frontend-panel .partycrack-details-Slider h2 {
    color: #fff;
}

.frontend-panel .pds-2 .heading-listing-3 {
    margin: 0 0 0 20px;
    letter-spacing: 1px;
    position: absolute;
    bottom: 30px;
}

.frontend-panel .floor-plans table {
    width: 100%;
    margin-bottom: 40px;
    border: 1px solid rgba(0, 0, 0, 0.07);
}

.frontend-panel .floor-plans table tr:first-child {
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.frontend-panel .floor-plans table td,
.frontend-panel table th {
    padding: 10px 15px;
    border-right: 1px solid rgba(0, 0, 0, 0.07);
}

.frontend-panel .floor-plans table td {
    font-size: 15px;
    font-weight: 400;
    color: #535353;
}

.frontend-panel .floor-plans table strong {
    font-weight: 500;
}

.frontend-panel .tabbing-box .tab-pane {
    padding-top: 30px;
}

.frontend-panel .tabbing-box .nav-tabs {
    background-color: #fff;
    border-bottom: solid 1px #fff;
}

.frontend-panel .tabbing-box .nav-tabs li {
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.5px;
}

.frontend-panel .tabbing-box .nav-tabs .nav-item.show .nav-link,
.frontend-panel .nav-tabs .nav-link {
    border-radius: 50px;
    padding: 11px 20px 9px;
}

.frontend-panel .tabbing-box .nav-tabs .nav-item.show .nav-link,
.frontend-panel .nav-tabs .nav-link.active {
    color: #fff;
    border-radius: 50px;
}

.frontend-panel .tabbing-box .nav-tabs .nav-item.show .nav-link,
.frontend-panel .nav-tabs .nav-link:hover {
    border-radius: 50px;
    color: #fff;
}

.frontend-panel .tabbing-box .nav-tabs .nav-link {
    border: none;
}

.frontend-panel .partycrack-details-page p {
    font-size: 15px;
}

.frontend-panel .compare-table tr {
    border: 1px solid #ececec;
}

.frontend-panel .compare-table tr:first-child {
    border-top: none;
}

.frontend-panel .compare-table tr td:first-child {
    width: 350px;
    background: #f8f9fa;
}

.frontend-panel .compare-table tr td {
    box-sizing: border-box;
    font-size: 15px;
    padding: 20px 15px;
    text-align: center;
    width: 395px;
    font-weight: 500;
}

.frontend-panel .compare-table tr td .fa-times {
    color: red;
}

.frontend-panel .compare-table tr td .fa-check {
    color: green;
}

.frontend-panel .search-compare {
    margin-bottom: 20px;
}

.frontend-panel .search-compare .form-group {
    margin-bottom: 10px;
}

.frontend-panel .search-compare h4 {
    font-size: 25px;
    line-height: 26px;
    text-align: center;
    margin-bottom: 25px;
}

.frontend-panel .search-compare .search-fields {
    min-height: 50px;
    padding: 0 !important;
    font-size: 15px;
    width: 100%;
    outline: none;
    color: #616161;
    border-radius: 3px;
    font-weight: 500;
    border: 1px solid #ccc;
    background-color: #fff;
}

.frontend-panel .search-compare .bootstrap-select button {
    color: #545454 !important;
    font-weight: 500;
    width: 100%;
    border-radius: 3px;
    outline: none;
    padding: 15px 35px 9px 20px;
}

.frontend-panel .search-compare .search-button {
    cursor: pointer;
    padding: 15px 26px 14px;
    font-size: 14px;
    font-weight: 500;
    color: #fff;
    transition: 0.5s;
    border-radius: 3px;
    border: none;
    width: 100%;
    outline: none;
}

.frontend-panel .faq-box {
    border: 1px solid transparent;
    border-radius: 0;
    margin-bottom: 0;
}

.frontend-panel .faq-box h5 .btn-link {
    padding: 25px 20px;
    width: 100%;
    text-align: left;
    display: inline-block;
    text-decoration: none;
    font-size: 16px;
    color: #535353;
}

.frontend-panel .faq-box .card-header {
    padding: 0;
    margin: 0;
    font-size: 14px;
    border-bottom: solid 1px #d8d8d8;
}

.frontend-panel .faq-box .card-body p {
    line-height: 27px;
}

.frontend-panel .faq-box .card-body span {
    font-size: 14px;
}

.frontend-panel .faq-box .card-body span a {
    font-weight: 500;
}

.frontend-panel .faq-accordion .card {
    margin-bottom: 0;
    border: none;
}

.frontend-panel .faq-accordion .p-text {
    padding: 20px 0 50px;
    line-height: 30px;
    font-weight: 500;
    color: #535353;
    font-size: 15px;
}

.frontend-panel .faq-accordion .card-header {
    padding: 0;
    background: transparent;
}

.frontend-panel .bd-none {
    border-bottom: 1px none;
}

.frontend-panel .faq-accordion .card-title {
    margin-bottom: 0;
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    background-color: transparent;
    letter-spacing: 0;
    font-size: 16px;
    font-weight: 500;
    line-height: 60px;
    cursor: pointer;
}

.frontend-panel .faq-accordion .card-header {
    border: none;
    margin-bottom: 10px;
    border-radius: 3px;
    background: #f8f9fa;
}

.frontend-panel .faq-accordion .card-header a {
    width: 100%;
    color: #535353;
}

.frontend-panel .faq-accordion .card-block {
    padding: 0;
}

.frontend-panel .faq-accordion .card-header .card-title:before {
    font-family: "Font Awesome 5 Pro";
    content: "\f077";
    font-size: 13px;
    font-weight: 300;
    width: 60px;
    height: 60px;
    background: #eeeeef;
    float: left;
    text-align: center;
    line-height: 60px;
}

.frontend-panel .faq-accordion .card-header .collapsed.card-title:before {
    content: "\f078";
    font-size: 13px;
    font-weight: 300;
}

.frontend-panel .foq-info p {
    font-size: 14px;
    line-height: 30px;
}

.frontend-panel .foq-info {
    padding: 15px 0 35px;
}

.frontend-panel .submit-partycrack .checkbox {
    padding-left: 0;
    margin-bottom: 10px;
}

.frontend-panel .submit-partycrack .heading-2 {
    margin: 0 0 20px;
    font-size: 21px;
    font-weight: 500;
}

.frontend-panel .dash-form .input-text {
    padding: 10px 17px;
    color: #495057 !important;
    width: 100%;
    min-height: 45px;
    font-size: 15px;
    font-weight: 500;
    border-radius: 3px;
    background-color: #fff;
    border: 1px solid #dbdbdb;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.06);
}

.frontend-panel .dash-form .search-fields {
    min-height: 45px;
    border-radius: 3px;
    font-size: 15px;
    font-weight: 500;
    border: 1px solid #dbdbdb;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.06);
    padding: 0;
}

.frontend-panel .dash-form .bootstrap-select .dropdown-toggle .filter-option-inner {
    overflow: hidden;
    font-size: 15px;
}

.frontend-panel .dash-form .bootstrap-select button {
    color: #7b7b7b !important;
    font-weight: 500;
    border-radius: 3px;
    outline: none;
    padding: 11px 30px 9px 15px;
    min-height: 43px;
    font-size: 15px !important;
}

.frontend-panel .dash-form textarea {
    min-height: 160px;
}

.frontend-panel .dash-form label {
    display: inline-block;
    max-width: 100%;
    margin-bottom: 5px;
    font-weight: 500;
    font-size: 15px;
    color: #353535;
}

.frontend-panel .pad-20 {
    padding: 25px;
}

.frontend-panel .dash-form .form-group {
    margin-bottom: 20px;
}

.frontend-panel .dash-form textarea {
    height: 180px;
}

.frontend-panel .dropzone-design {
    border: 2px dashed #d0d0d0;
    min-height: 100px;
}

.frontend-panel .dropzone-design,
.frontend-panel .dropzone-design * {
    box-sizing: border-box;
}

.frontend-panel .dropzone-design {
    min-height: 160px;
    border: 2px solid rgba(0, 0, 0, 0.3);
    background: white;
    padding: 20px 20px;
}

.frontend-panel .dropzone-design.dz-clickable {
    cursor: pointer;
}

.frontend-panel .dropzone-design.dz-clickable * {
    cursor: default;
}

.frontend-panel .dropzone-design.dz-clickable .dz-message,
.frontend-panel .dropzone-design.dz-clickable .dz-message * {
    cursor: pointer;
}

.frontend-panel .dropzone-design.dz-started .dz-message {
    display: none;
}

.frontend-panel .dropzone-design.dz-drag-hover {
    border-style: solid;
}

.frontend-panel .dropzone-design.dz-drag-hover .dz-message {
    opacity: 0.5;
}

.frontend-panel .dropzone-design .dz-preview {
    position: relative;
    display: inline-block;
    vertical-align: top;
    margin: 16px;
    min-height: 100px;
}

.frontend-panel .dropzone-design .dz-preview:hover {
    z-index: 1000;
}

.frontend-panel .dropzone-design .dz-preview:hover .dz-details {
    opacity: 1;
}

.frontend-panel .dropzone-design .dz-preview.dz-file-preview .dz-image {
    border-radius: 3px;
    background: #999;
    background: linear-gradient(to bottom, #eee, #ddd);
}

.frontend-panel .dropzone-design .dz-preview.dz-file-preview .dz-details {
    opacity: 1;
}

.frontend-panel .dropzone-design .dz-preview.dz-image-preview {
    background: white;
}

.frontend-panel .dropzone-design .dz-preview.dz-image-preview .dz-details {
    -webkit-transition: opacity 0.2s linear;
    -moz-transition: opacity 0.2s linear;
    -ms-transition: opacity 0.2s linear;
    -o-transition: opacity 0.2s linear;
    transition: opacity 0.2s linear;
}

.frontend-panel .dropzone-design .dz-preview .dz-remove {
    font-size: 14px;
    text-align: center;
    display: block;
    cursor: pointer;
    border: none;
}

.frontend-panel .dropzone-design .dz-preview .dz-remove:hover {
    text-decoration: underline;
}

.frontend-panel .dropzone-design .dz-preview:hover .dz-details {
    opacity: 1;
}

.frontend-panel .dropzone-design .dz-preview .dz-details {
    z-index: 20;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    font-size: 13px;
    min-width: 100%;
    max-width: 100%;
    padding: 2em 1em;
    text-align: center;
    color: rgba(0, 0, 0, 0.9);
    line-height: 150%;
}

.frontend-panel .dropzone-design .dz-preview .dz-details .dz-size {
    margin-bottom: 1em;
    font-size: 16px;
}

.frontend-panel .dropzone-design .dz-preview .dz-details .dz-filename {
    white-space: nowrap;
}

.frontend-panel .dropzone-design .dz-preview .dz-details .dz-filename:hover span {
    border: 1px solid rgba(200, 200, 200, 0.8);
    background-color: rgba(255, 255, 255, 0.8);
}

.frontend-panel .dropzone-design .dz-preview .dz-details .dz-filename:not(:hover) {
    overflow: hidden;
    text-overflow: ellipsis;
}

.frontend-panel .dropzone-design .dz-preview .dz-details .dz-filename:not(:hover) span {
    border: 1px solid transparent;
}

.frontend-panel .dropzone-design .dz-preview .dz-details .dz-filename span,
.frontend-panel .dropzone-design .dz-preview .dz-details .dz-size span {
    background-color: rgba(255, 255, 255, 0.4);
    padding: 0 0.4em;
    border-radius: 3px;
}

.frontend-panel .dropzone-design .dz-preview:hover .dz-image img {
    -webkit-transform: scale(1.05, 1.05);
    -moz-transform: scale(1.05, 1.05);
    -ms-transform: scale(1.05, 1.05);
    -o-transform: scale(1.05, 1.05);
    transform: scale(1.05, 1.05);
    -webkit-filter: blur(8px);
    filter: blur(8px);
}

.frontend-panel .dropzone-design .dz-preview .dz-image {
    border-radius: 3px;
    overflow: hidden;
    width: 120px;
    height: 120px;
    position: relative;
    transition: all 0.3s;
    display: block;
    z-index: 10;
}

.frontend-panel .dropzone-design .dz-preview .dz-image img {
    display: block;
    filter: blur(0px);
    transition: all 0.4s;
}

.frontend-panel .dropzone-design .dz-preview.dz-success .dz-success-mark {
    -webkit-animation: passing-through 3s cubic-bezier(0.77, 0, 0.175, 1);
    -moz-animation: passing-through 3s cubic-bezier(0.77, 0, 0.175, 1);
    -ms-animation: passing-through 3s cubic-bezier(0.77, 0, 0.175, 1);
    -o-animation: passing-through 3s cubic-bezier(0.77, 0, 0.175, 1);
    animation: passing-through 3s cubic-bezier(0.77, 0, 0.175, 1);
}

.frontend-panel .dropzone-design .dz-preview.dz-error .dz-error-mark {
    opacity: 1;
    -webkit-animation: slide-in 3s cubic-bezier(0.77, 0, 0.175, 1);
    -moz-animation: slide-in 3s cubic-bezier(0.77, 0, 0.175, 1);
    -ms-animation: slide-in 3s cubic-bezier(0.77, 0, 0.175, 1);
    -o-animation: slide-in 3s cubic-bezier(0.77, 0, 0.175, 1);
    animation: slide-in 3s cubic-bezier(0.77, 0, 0.175, 1);
}

.frontend-panel .dropzone-design .dz-preview .dz-success-mark,
.frontend-panel .dropzone-design .dz-preview .dz-error-mark {
    pointer-events: none;
    opacity: 0;
    z-index: 500;
    position: absolute;
    display: block;
    top: 50%;
    left: 50%;
    margin-left: -27px;
    margin-top: -27px;
}

.frontend-panel .dropzone-design .dz-preview .dz-success-mark svg,
.frontend-panel .dropzone-design .dz-preview .dz-error-mark svg {
    display: block;
    width: 54px;
    height: 54px;
}

.frontend-panel .dropzone-design .dz-preview.dz-processing .dz-progress {
    opacity: 1;
    -webkit-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    -ms-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    transition: all 0.2s linear;
}

.frontend-panel .dropzone-design .dz-preview.dz-complete .dz-progress {
    opacity: 0;
    -webkit-transition: opacity 0.4s ease-in;
    -moz-transition: opacity 0.4s ease-in;
    -ms-transition: opacity 0.4s ease-in;
    -o-transition: opacity 0.4s ease-in;
    transition: opacity 0.4s ease-in;
}

.frontend-panel .dropzone-design .dz-preview:not(.dz-processing) .dz-progress {
    -webkit-animation: pulse 6s ease infinite;
    -moz-animation: pulse 6s ease infinite;
    -ms-animation: pulse 6s ease infinite;
    -o-animation: pulse 6s ease infinite;
    animation: pulse 6s ease infinite;
}

.frontend-panel .dropzone-design .dz-preview .dz-progress {
    opacity: 1;
    z-index: 1000;
    pointer-events: none;
    position: absolute;
    height: 16px;
    left: 50%;
    top: 50%;
    margin-top: -8px;
    width: 80px;
    margin-left: -40px;
    background: rgba(255, 255, 255, 0.9);
    -webkit-transform: scale(1);
    border-radius: 8px;
    overflow: hidden;
}

.frontend-panel .dropzone-design .dz-preview .dz-progress .dz-upload {
    background: #333;
    background: linear-gradient(to bottom, #666, #444);
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 0;
    -webkit-transition: width 300ms ease-in-out;
    -moz-transition: width 300ms ease-in-out;
    -ms-transition: width 300ms ease-in-out;
    -o-transition: width 300ms ease-in-out;
    transition: width 300ms ease-in-out;
}

.frontend-panel .dropzone-design .dz-preview.dz-error .dz-error-message {
    display: block;
}

.frontend-panel .dropzone-design .dz-preview.dz-error:hover .dz-error-message {
    opacity: 1;
    pointer-events: auto;
}

.frontend-panel .dropzone-design .dz-preview .dz-error-message {
    pointer-events: none;
    z-index: 1000;
    position: absolute;
    display: block;
    display: none;
    opacity: 0;
    -webkit-transition: opacity 0.3s ease;
    -moz-transition: opacity 0.3s ease;
    -ms-transition: opacity 0.3s ease;
    -o-transition: opacity 0.3s ease;
    transition: opacity 0.3s ease;
    border-radius: 8px;
    font-size: 13px;
    top: 130px;
    left: -10px;
    width: 140px;
    background: #be2626;
    background: linear-gradient(to bottom, #be2626, #a92222);
    padding: 0.5em 1.2em;
    color: white;
}

.frontend-panel .dropzone-design .dz-preview .dz-error-message:after {
    content: "";
    position: absolute;
    top: -6px;
    left: 64px;
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 6px solid #be2626;
}

.frontend-panel .dropzone-design {
    position: relative;
    border: 2px dashed #d0d0d0;
    border-radius: 3px;
    background: #fcfcfc;
    transition: all 0.3s linear;
    display: inline-block;
    width: 100%;
}

.frontend-panel .dropzone-design:before {
    position: absolute;
    height: 100%;
    width: 100%;
    content: "";
    top: 0;
    left: 0;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0.95), rgba(255, 255, 255, 0.9));
    background-color: #66676b;
    opacity: 0;
    z-index: 10;
    transition: all 0.3s linear;
    border-radius: 3px;
}

.frontend-panel .dropzone-design:hover:before {
    opacity: 0.4;
}

.frontend-panel .dropzone-design .dz-message {
    text-align: center;
    margin: 50px 0;
    font-size: 17px;
    color: #888;
    transition: all 0.3s linear;
    position: relative;
    z-index: 11;
}

.frontend-panel .dropzone-design:hover .dz-message {
    color: #66676b;
}

.frontend-panel .dz-message i {
    display: block;
    font-size: 32px;
    margin-bottom: 15px;
}

.frontend-panel .dropzone-design .dz-default.dz-message span {
    display: inline;
    line-height: 117px;
}

.frontend-panel .book-btn {
    display: block;
    height: 41px;
    margin-bottom: 15px;
    border-radius: 3px;
}

.frontend-panel .no-js #loader {
    display: none;
}

.frontend-panel .js #loader {
    display: block;
    position: absolute;
    left: 100px;
    top: 0;
}

.frontend-panel .page_loader {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 999999;
    background: url(../imgs/loader.gif) center no-repeat #fff;
}

.frontend-panel .slider-section .simple-slider {
    margin-bottom: 30px;
}

.frontend-panel .icon-lists .heading {
    margin: 0 0 10px 0;
}

.frontend-panel .glyph {
    text-align: center;
    padding: 20px 15px;
    font-size: 15px;
    font-weight: 500;
    color: #555;
}

.frontend-panel .glyph .glyph-icon {
    font-size: 24px;
    font-weight: 500;
    color: #555;
}

.frontend-panel .glyph fieldset,
.frontend-panel .glyph .hidden-true {
    display: none;
}

.frontend-panel .glyph .bshadow0 .lnr {
    font-size: 24px;
    color: #555;
    font-weight: 500;
    display: block;
    line-height: 35px;
}

.frontend-panel .icon-lists mark {
    margin-bottom: 10px;
    display: inline-block;
}

.frontend-panel .font-awesome-icons .col-print-4 {
    margin-bottom: 15px;
    font-size: 15px;
    font-weight: 500;
}

.frontend-panel .font-awesome-icons {
    font-size: 14px;
    color: #555;
}

.frontend-panel .font-awesome-icons .text-muted {
    display: none;
}

.frontend-panel .portfolio-item {
    margin-bottom: 30px;
}

.frontend-panel .pg-4 .portfolio-item {
    margin-bottom: 0;
}

.frontend-panel .option-panel {
    z-index: 9999;
    width: 215px;
    position: fixed;
    right: 0;
    background-color: #fff;
    top: 20%;
    padding: 20px 15px;
    -webkit-transition: all 1s ease;
    -moz-transition: all 1s ease;
    -ms-transition: all 1s ease;
    -o-transition: all 1s ease;
    transition: all 1s ease;
}

.frontend-panel .option-panel-collased {
    right: -215px;
}

.frontend-panel .option-panel h2 {
    font-size: 10px;
    margin: 0 0 0 0;
    text-align: center;
    color: #555;
    padding: 8px 0;
    font-weight: 500;
}

.frontend-panel .option-panel .color-plate {
    width: 24px;
    height: 24px;
    float: left;
    margin: 3px;
    border-radius: 0;
    cursor: pointer;
}

.frontend-panel .default-plate {
    background: #ff4068;
}

.frontend-panel .blue-plate {
    background: #2048bb;
}

.frontend-panel .yellow-plate {
    background: #ffb400;
}

.frontend-panel .red-plate {
    background: #f0151f;
}

.frontend-panel .green-light-plate {
    background: #1abc9c;
}

.frontend-panel .green-plate {
    background: #7dba21;
}

.frontend-panel .yellow-light-plate {
    background: #faaa5d;
}

.frontend-panel .green-light-2-plate {
    background: #0dcdbd;
}

.frontend-panel .olive-plate {
    background: #b3c211;
}

.frontend-panel .purple-plate {
    background: #8e44ad;
}

.frontend-panel .midnight-blue-plate {
    background: #376bff;
}

.frontend-panel .brown-plate {
    background: #a14c10;
}

.frontend-panel .setting-button {
    position: absolute;
    font-size: 20px;
    text-align: center;
    width: 45px;
    height: 45px;
    line-height: 45px;
    left: -45px;
    color: #2b303b;
    top: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    cursor: pointer;
    background-color: #fff;
    box-shadow: 0 0 35px rgba(0, 0, 0, 0.1);
}

.frontend-panel #full-page-search {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    -webkit-transform: translate(0px, -100%) scale(0, 0);
    -moz-transform: translate(0px, -100%) scale(0, 0);
    -o-transform: translate(0px, -100%) scale(0, 0);
    -ms-transform: translate(0px, -100%) scale(0, 0);
    transform: translate(0px, -100%) scale(0, 0);
    opacity: 0;
    z-index: 99999;
}

.frontend-panel #full-page-search.open {
    -webkit-transform: translate(0px, 0px) scale(1, 1);
    -moz-transform: translate(0px, 0px) scale(1, 1);
    -o-transform: translate(0px, 0px) scale(1, 1);
    -ms-transform: translate(0px, 0px) scale(1, 1);
    transform: translate(0px, 0px) scale(1, 1);
    opacity: 1;
}

.frontend-panel #full-page-search input[type=search] {
    position: absolute;
    top: 50%;
    width: 100%;
    color: #fff;
    background: rgba(0, 0, 0, 0);
    font-weight: 300;
    text-align: center;
    border: 0;
    margin: 0 auto;
    margin-top: -51px;
    padding-left: 30px;
    padding-right: 30px;
    outline: none;
    font-size: 28px;
}

.frontend-panel #full-page-search .btn {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: 20px;
    margin-left: -45px;
}

.frontend-panel #full-page-search .close {
    position: fixed;
    top: 15px;
    right: 15px;
    color: #fff;
    opacity: 1;
    padding: 10px 17px;
    font-size: 17px;
    outline: 0 auto -webkit-focus-ring-color;
    cursor: pointer;
    border-radius: 3px;
    background: #3a3a3a;
}

.frontend-panel .mtb-50 {
    margin: 50px 0;
}

.frontend-panel .mtb-30 {
    margin: 30px 0;
}

.frontend-panel .mt-20 {
    margin-top: 20px;
}

.frontend-panel .mb-40 {
    margin-bottom: 40px;
}

.frontend-panel .mb-35 {
    margin-bottom: 35px;
}

.frontend-panel .mb-50 {
    margin-bottom: 50px;
}

.frontend-panel .mb-30 {
    margin-bottom: 30px !important;
}

.frontend-panel .mb-20 {
    margin-bottom: 20px;
}

.frontend-panel .mb-10 {
    margin-bottom: 10px;
}

.frontend-panel .option-panel {
    z-index: 9999;
    width: 193px;
    position: fixed;
    right: 0;
    top: 20%;
    background-color: #fff;
    box-shadow: 0 0 35px rgba(0, 0, 0, 0.1);
    padding: 20px;
    -webkit-transition: all 1s ease;
    -moz-transition: all 1s ease;
    -ms-transition: all 1s ease;
    -o-transition: all 1s ease;
    transition: all 1s ease;
}

.frontend-panel .option-panel-collased {
    right: -193px;
}

.frontend-panel .option-panel h2 {
    font-size: 16px;
    margin: 0 0 0 0;
    text-align: center;
    padding: 0 0 10px;
    font-weight: 500;
    color: #353535;
}

.frontend-panel .option-panel .color-plate {
    width: 32px;
    height: 32px;
    float: left;
    margin: 3px;
    border-radius: 0;
    cursor: pointer;
}

@media all and (min-width: 1200px) {
    .frontend-panel .partycrack-box-2 .footer {
        position: absolute;
        width: 100%;
        bottom: 0px;
    }
}

@media all and (min-width: 992px) {
    .frontend-panel {
        /*animation: bgburnsEast 25s linear infinite alternate;
    -webkit-animation: bgburnsEast 25s linear infinite alternate;
    */
    }

    .frontend-panel .dropdown:hover > .dropdown-menu {
        display: block;
        max-width: 600px;
        margin: auto;
    }

    .frontend-panel .dropdown .dropdown-item:hover > .dropdown-menu {
        display: block;
    }

    .frontend-panel .dropdown-submenu:hover > .dropdown-menu {
        display: block;
    }

    .frontend-panel .header-shrink {
        background-color: #fff;
        -webkit-transition: 0.5s;
        -moz-transition: 0.5s;
        -ms-transition: 0.5s;
        transition: 0.5s;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
        top: 0px !important;
        position: fixed;
        width: 100%;
        z-index: 999;
        animation: slide-down 0.7s;
    }

    .frontend-panel .header-shrink .navbar-expand-lg .navbar-nav .nav-link {
        color: #515151;
    }

    .frontend-panel .header-shrink .open-offcanvas {
        color: #515151;
    }

    .frontend-panel .hide-carat .nav-link:after {
        display: none !important;
    }
}

@media (max-width: 1200px) {
    .frontend-panel .banner .banner-slider-inner h1 {
        font-size: 51px;
        margin-bottom: 20px;
    }

    .frontend-panel .banner p {
        margin-bottom: 18px;
    }

    .frontend-panel .partycrack-box-2 .facilities-list {
        padding: 15px 20px;
    }

    .frontend-panel .partycrack-box .facilities-list li {
        margin-right: 20px;
    }

    .frontend-panel .pages-404 .nobottomborder h1 {
        font-size: 34px;
    }

    .frontend-panel .inline-search-area {
        max-width: 870px;
    }

    .frontend-panel .about-text h3 {
        margin-bottom: 10px;
        font-size: 26px;
    }

    .frontend-panel .about-text {
        padding: 0;
    }

    .frontend-panel .about-info {
        padding-right: 0;
    }

    .frontend-panel .counters-4 .counters-info-section {
        padding: 100px 50px 60px 40px;
    }

    .frontend-panel .counters-4 .counters-info-section.clip-home {
        clip-path: polygon(0 0, 100% 0, 100% 0%, 93% 100%, 0 100%);
    }
}

@media (max-width: 992px) {
    .frontend-panel .content-area-2 {
        margin-bottom: 80px;
    }

    .frontend-panel .content-area-3 {
        margin-bottom: 50px;
    }

    .frontend-panel .content-area-4 {
        padding: 80px 0 50px;
    }

    .frontend-panel .content-area-5 {
        padding: 80px 0;
    }

    .frontend-panel .content-area-6 {
        padding: 80px 0 50px;
    }

    .frontend-panel .content-area-7 {
        padding: 80px 0 65px;
    }

    .frontend-panel .content-area-8 {
        padding: 80px 0 50px;
    }

    .frontend-panel .content-area-9 {
        padding: 80px 0 70px;
    }

    .frontend-panel .content-area-10 {
        padding: 80px 0 40px;
    }

    .frontend-panel .content-area-11 {
        padding: 50px 0;
    }

    .frontend-panel .content-area-12 {
        padding: 80px 0 110px;
    }

    .frontend-panel .content-area-13 {
        padding: 80px 0 30px;
    }

    .frontend-panel .contact-2 .contact-info {
        margin: 50px 0 0 0px;
    }

    .frontend-panel .counters-4 .counters-info-section.clip-home {
        clip-path: polygon(0 0, 100% 0, 100% 0%, 100% 100%, 0 100%);
    }

    .frontend-panel .counters-4 .counters-info-section {
        padding: 80px 15px 40px 15px;
    }

    .frontend-panel .partycrack-details-Slider .smail-partycrack .list-inline-item {
        width: 50px;
        margin: 3px;
    }

    .frontend-panel .counters-2 {
        padding: 80px 0 40px;
    }

    .frontend-panel .partycrack-details-sliders .heading-partycrack-2 .informeson {
        padding: 20px;
    }

    .frontend-panel .sub-banner {
        background-position: inherit;
        background-repeat: inherit;
    }

    .frontend-panel .bi-3 {
        margin-top: 50px;
    }

    .frontend-panel .isa-6 {
        display: none;
    }

    .frontend-panel .bi-2 {
        text-align: center;
    }

    .frontend-panel .bi-2 .btn-lg {
        margin-bottom: 35px;
    }

    .frontend-panel .inline-search-area {
        padding: 0;
    }

    .frontend-panel .ss-2 {
        display: none;
    }

    .frontend-panel .search-compare {
        margin-bottom: 5px;
    }

    .frontend-panel .isa-3 {
        width: 100%;
        display: inline;
    }

    .frontend-panel .isa-4 {
        display: none;
    }

    .frontend-panel .isa-3 .search-fields {
        width: 100% !important;
    }

    .frontend-panel .partycrack-details-sliders .heading-partycrack-2 {
        top: 30%;
    }

    .frontend-panel .inline-search-area .search-col .form-control,
    .frontend-panel .inline-search-area button.btn-search {
        height: 48px;
        font-size: 14px;
    }

    .frontend-panel .dashboard-content {
        padding: 30px 30px 0;
        position: relative;
        z-index: 10;
        height: 100%;
        margin-left: 0px;
    }

    .frontend-panel .dashboard-nav {
        display: none !important;
    }

    .frontend-panel #banner-inner-2 {
        bottom: 30px;
    }

    .frontend-panel .sub-footer {
        text-align: center;
    }

    .frontend-panel .sub-footer .copy {
        line-height: 25px;
        margin-bottom: 20px;
    }

    .frontend-panel .banner-partycrack-info {
        display: none;
    }

    .frontend-panel .banner-inner .banner-btn {
        float: inherit;
    }

    .frontend-panel .banner-inner .photo {
        position: inherit;
        bottom: 260px;
        max-width: 230px;
        margin: 0 auto;
    }

    .frontend-panel .banner-inner h6 {
        margin-bottom: 15px;
    }

    .frontend-panel .tab-box-2 .nav-link {
        display: block;
        padding: 0.5rem 0.3rem;
    }

    .frontend-panel .ui-item {
        margin-bottom: 30px;
    }

    .frontend-panel .alert-2 {
        margin-bottom: 30px;
    }

    .frontend-panel .header-2 {
        padding: 0;
    }

    .frontend-panel .main-title {
        margin-bottom: 40px;
    }

    .frontend-panel .main-title h1 {
        margin-bottom: 10px;
    }

    .frontend-panel .mt2 h1 {
        margin-bottom: 15px;
    }

    .frontend-panel .sub-banner {
        height: 300px;
    }

    .frontend-panel .search-area {
        padding: 25px 20px 10px;
        border-radius: 0;
    }

    .frontend-panel .search-area .form-group {
        margin-bottom: 15px;
    }

    .frontend-panel .about-info {
        margin-bottom: 40px;
    }

    .frontend-panel .about-text h3 {
        font-size: 23px;
    }

    .frontend-panel .search-area-2 .range-slider {
        margin-top: 15px;
        margin-bottom: 1rem;
    }

    .frontend-panel .bg-grea .range-slider {
        margin-top: 15px;
        margin-bottom: 1rem;
    }

    .frontend-panel .search-area-2 {
        padding: 30px 0 10px;
    }

    .frontend-panel .t-c {
        text-align: center;
    }

    .frontend-panel .heading-partycrack-3 h1 {
        font-size: 22px;
    }

    .frontend-panel .sub-banner .breadcrumb-area h1 {
        margin: 0 0 10px;
        font-size: 27px;
    }

    .frontend-panel .main-title h1 {
        font-size: 29px;
    }

    .frontend-panel .hidden-mb-20 {
        margin-bottom: 20px;
    }

    .frontend-panel .category_long_bg {
        height: 350px;
    }

    .frontend-panel .search-compare h4 {
        font-size: 20px;
        margin-bottom: 20px;
        text-align: center;
    }

    .frontend-panel .search-compare .form-group {
        margin-bottom: 20px;
    }

    .frontend-panel .service-info-3 .detail {
        padding: 30px 20px 30px;
    }

    .frontend-panel .header-transparent {
        z-index: 999;
        width: 100%;
    }

    .frontend-panel .banner .banner-slider-inner h1 {
        font-size: 43px;
        margin-bottom: 20px;
    }

    .frontend-panel .banner h2 {
        font-size: 40px;
    }

    .frontend-panel .banner p {
        font-size: 14px;
    }

    .frontend-panel .banner .banner-slider-inner {
        background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8) 70%, #000);
    }

    .frontend-panel .banner .banner-opt .banner-slider-inner h1 {
        font-size: 20px;
    }

    .frontend-panel .banner .banner-opt .banner-slider-inner p {
        line-height: 22px;
        font-size: 14px;
        max-width: 90%;
        margin: 0px auto;
        margin-bottom: 5px;
    }

    .frontend-panel .pages-404 .nobottomborder h1 {
        font-size: 30px;
    }

    .frontend-panel .pb-hediin-60 {
        padding-bottom: 60px;
    }

    .frontend-panel .pb-hediin-12 {
        padding-bottom: 12px;
    }

    .frontend-panel .service-info.h-bdr {
        border: solid 1px #efefef;
    }

    .frontend-panel .pages-404 .error404 {
        font-size: 150px;
    }

    .frontend-panel .contact-2 .main-title {
        margin-bottom: 40px;
    }

    .frontend-panel .contect-agent-photo img {
        margin-top: 40px;
    }

    .frontend-panel .team-2 .detail {
        padding: 25px;
        left: 0;
    }

    .frontend-panel .about-text {
        padding: 0;
    }

    .frontend-panel .inline-search-area .bootstrap-select button {
        border-bottom: solid 1px #e0e0e0 !important;
    }

    .frontend-panel .banner h3 {
        font-size: 40px;
    }

    .frontend-panel .banner h3 br {
        display: none;
    }

    .frontend-panel .b p {
        margin-bottom: 0;
    }

    .frontend-panel .search-options-btn,
    .frontend-panel .search-options-btn:hover {
        position: absolute;
        z-index: 10;
    }

    .frontend-panel .sub-footer-2 .copy {
        text-align: center;
        margin-bottom: 10px;
    }

    .frontend-panel .sub-footer-2 {
        text-align: center;
    }

    .frontend-panel .sub-footer-2 .social-list {
        float: none;
    }

    .frontend-panel .inline-search-area .bootstrap-select button {
        padding: 10px 15px 10px 15px;
        font-weight: 500;
    }

    .frontend-panel .inline-search-area .bootstrap-select .dropdown-toggle .filter-option-inner {
        overflow: hidden;
        font-size: 15px;
    }

    .frontend-panel .header-shrink {
        background-color: #fff;
        -webkit-transition: 0.5s;
        -moz-transition: 0.5s;
        -ms-transition: 0.5s;
        transition: 0.5s;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
        top: 0px !important;
    }

    .frontend-panel .heading-partycrack-2 h1 {
        font-size: 25px;
    }

    .frontend-panel .heading-partycrack-2 ul li {
        margin-right: 15px;
        font-size: 14px;
    }

    .frontend-panel .heading-partycrack-2 .ratings-2 {
        font-size: 14px;
    }

    .frontend-panel .partycrack-details-sliders .heading-partycrack-2 h1 {
        font-size: 25px;
        font-weight: 500;
        margin: 0 0 10px;
        color: #fff;
    }
}

@media (max-width: 768px) {
    .frontend-panel .text-c {
        text-align: center;
    }

    .frontend-panel .text-l {
        text-align: center !important;
    }

    .frontend-panel .text-r {
        text-align: center !important;
    }

    .frontend-panel .main-title p {
        font-size: 15px;
    }

    .frontend-panel .banner h3 {
        font-weight: 500;
    }

    .frontend-panel .banner-partycrack-box .meta ul li i {
        font-size: 15px;
    }

    .frontend-panel .btn-secton {
        right: 10px;
    }

    .frontend-panel .banner .plan-price {
        font-size: 30px;
    }

    .frontend-panel .banner .plan-price sup {
        top: -15px;
    }

    .frontend-panel .filteriz-navigation li {
        font-size: 14px;
        padding: 5px 22px 4px 22px;
    }

    .frontend-panel .banner .btn-lg {
        padding: 13px 25px 11px 25px;
    }

    .frontend-panel .banner .btn-white-lg-outline {
        font-weight: 500;
        padding: 12px 23px 8px;
    }

    .frontend-panel .banner-inner .photo {
        max-width: 190px;
    }

    .frontend-panel .partycrack-details-sliders .heading-partycrack-2 .informeson {
        padding: 0;
        background: transparent;
    }

    .frontend-panel .testimonial .main-title {
        margin-bottom: 30px;
    }

    .frontend-panel .testimonial-3 {
        padding: 60px 0 70px;
    }

    .frontend-panel .pds-2 .heading-listing-3 {
        margin: 0 0 20px 0;
    }

    .frontend-panel .coming-soon-counter div span {
        margin-bottom: 10px;
    }

    .frontend-panel .intro-section-2 {
        padding: 30px 5px 30px;
    }

    .frontend-panel .counters-4 .counters-info-section {
        padding: 60px 15px 20px 15px;
    }

    .frontend-panel .intro-section {
        padding: 30px 0;
        text-align: center;
    }

    .frontend-panel .intro-section .btn {
        float: none;
    }

    .frontend-panel .title-wwtn {
        display: inline-block;
        width: 100%;
    }

    .frontend-panel .services-photo {
        display: none;
    }

    .frontend-panel .intro-section .intro-text h3 {
        margin-bottom: 15px;
        line-height: normal;
    }

    .frontend-panel .bi-4 {
        margin-top: 0px;
    }

    .frontend-panel .sub-footer {
        margin-top: 10px;
    }

    .frontend-panel .counters-2 {
        padding: 60px 0 20px;
    }

    .frontend-panel .counters-2 .counter-box {
        max-width: 227px;
        margin: 0 auto 40px;
    }

    .frontend-panel .bi-2 {
        margin-top: 50px;
    }

    .frontend-panel .main-title h1 {
        font-size: 26px;
    }

    .frontend-panel .comment-content .btn-1 {
        padding: 6px 15px;
        font-size: 12px;
        margin-bottom: 3px;
    }

    .frontend-panel .faq-accordion .card-title {
        padding: 0 10px 0 15px;
        font-size: 16px;
        font-weight: 400;
        line-height: 45px;
    }

    .frontend-panel .compare-table tr td {
        box-sizing: border-box;
        font-size: 13px;
        padding: 10px 7px;
    }

    .frontend-panel .faq-accordion .card-header .card-title:before {
        font-size: 12px;
        width: 45px;
        height: 45px;
        line-height: 45px;
    }

    .frontend-panel footer {
        padding-top: 60px;
    }

    .frontend-panel .dashboard-content {
        padding: 30px 15px 0;
        overflow: hidden;
    }

    .frontend-panel .partycrack-box-2 .price-box {
        right: 30px;
    }

    .frontend-panel .invoice .text-right {
        text-align: left !important;
    }

    .frontend-panel .invoice .order {
        text-align: left;
        line-height: normal;
    }

    .frontend-panel .invoice-mb-30 {
        margin-bottom: 20px;
    }

    .frontend-panel .manage-table .listing-photoo {
        width: 100%;
        float: left;
        padding-left: 0;
    }

    .frontend-panel .manage-table .listing-photoo img {
        max-width: 280px;
        margin: 0 auto;
    }

    .frontend-panel .manage-table .title-container {
        padding: 20px 0 0;
        width: 100%;
        float: left;
    }

    .frontend-panel .manage-table .expire-date {
        width: 100%;
        float: left;
        margin-bottom: 10px;
    }

    .frontend-panel .responsive-table {
        text-align: center;
    }

    .frontend-panel .manage-table .action {
        width: 100%;
        float: left;
        padding: 0;
    }

    .frontend-panel .manage-table .action a {
        display: inline-block;
        margin: 3px 0;
        padding: 3px 15px;
        background: #f5f6f7;
        border-radius: 50px;
    }

    .frontend-panel .manage-table .action a i {
        margin-right: 0;
    }

    .frontend-panel .dashboard-header .breadcrumb-nav {
        right: 15px;
        display: inline-block;
        font-size: 15px;
        float: left;
    }

    .frontend-panel .dashboard-header .breadcrumb-nav ul li {
        display: inline-block;
        list-style: none;
        margin: 0 15px 0 0;
    }

    .frontend-panel .edit-profile-photo {
        width: 160px;
        margin-bottom: 40px;
    }

    .frontend-panel .invoice .table td,
    .frontend-panel .table th {
        padding: 10px 5px;
    }

    .frontend-panel .main-title {
        margin-bottom: 30px;
    }

    .frontend-panel .search-area-2 .form-group {
        margin-bottom: 10px;
    }

    .frontend-panel .partycrack-meta {
        padding: 20px 5px;
    }

    .frontend-panel .search-area-2 {
        padding: 30px 20px 10px;
    }

    .frontend-panel .coming-soon-inner .form-control {
        width: 65%;
    }

    .frontend-panel .coming-soon-inner .btn,
    .frontend-panel .coming-soon-inner .btn:hover,
    .frontend-panel .coming-soon-inner .btn:active {
        width: 35%;
    }

    .frontend-panel .partycrack-meta ul li {
        font-size: 14px;
    }

    .frontend-panel .partycrack-meta ul li i {
        margin-right: 7px;
        font-size: 17px;
    }

    .frontend-panel .partycrack-box-2 .footer {
        padding: 15px 35px 15px 35px;
    }

    .frontend-panel .partycrack-box-2 .facilities-list {
        padding: 15px 35px;
    }

    .frontend-panel .partycrack-box-2 .hdg {
        padding: 20px 35px;
    }

    .frontend-panel .sub-banner {
        height: 270px;
    }

    .frontend-panel .sub-banner .breadcrumb-area h1 {
        font-size: 23px;
    }

    .frontend-panel .sub-banner .breadcrumbs li {
        font-size: 14px;
        font-weight: 400;
    }

    .frontend-panel .partners img {
        max-width: 140px;
        margin: 0 auto;
    }

    .frontend-panel .sub-footer {
        text-align: center;
    }

    .frontend-panel .about-info .partycrack-info ul li i {
        font-size: 18px;
    }

    .frontend-panel .about-info .partycrack-info ul li h4 {
        font-size: 12px;
    }

    .frontend-panel .coming-soon-inner {
        margin: 0 auto;
    }

    .frontend-panel .coming-soon-counter div {
        font-size: 32px;
        margin-bottom: 20px;
        padding: 0;
        background: transparent;
    }

    .frontend-panel .coming-soon-inner h1 {
        font-size: 32px;
        margin-bottom: 40px;
    }

    .frontend-panel .coming-soon-inner .coming-form {
        max-width: 75%;
        margin: 20px auto;
    }

    .frontend-panel .banner .banner-slider-inner h1 {
        font-size: 35px;
    }

    .frontend-panel .banner h2 {
        font-size: 35px;
    }

    .frontend-panel .banner-partycrack-box .mb-30 {
        margin-bottom: 20px;
    }

    .frontend-panel .banner h3 {
        font-size: 32px;
    }

    .frontend-panel .banner .btn-md {
        padding: 9px 15px;
        font-size: 12px;
    }

    .frontend-panel .form-content-box {
        margin: 40px auto;
    }

    .frontend-panel .form-content-box h3 {
        margin: 0 0 20px;
        font-size: 17px;
    }

    .frontend-panel .blog-social-list {
        float: left;
        margin-top: 5px;
    }

    .frontend-panel .countdown .top {
        font-size: 30px;
    }

    .frontend-panel .pages-404 .error404 {
        margin-bottom: 30px;
        font-size: 150px;
    }

    .frontend-panel .pages-404 .nobottomborder h4 {
        font-size: 16px;
    }

    .frontend-panel .pages-404 .nobottomborder {
        margin-bottom: 0;
    }

    .frontend-panel .setting-button {
        font-size: 14px;
        width: 36px;
        height: 36px;
        line-height: 38px;
        left: -35px;
    }

    .frontend-panel .inside-partycrack iframe {
        height: 220px;
    }

    .frontend-panel .breadcrumbs {
        font-size: 12px;
    }

    .frontend-panel .back-to-listings {
        height: 35px;
        line-height: 35px;
        width: 35px;
        font-size: 15px;
    }

    .frontend-panel .search-area label {
        display: none;
    }

    .frontend-panel .listings-parallax h1 {
        font-size: 25px;
        margin: 0 0 20px;
    }

    .frontend-panel .listings-parallax {
        text-align: center;
        padding-top: 60px;
    }

    .frontend-panel .contact-details .media .media-left {
        padding-right: 20px;
    }

    .frontend-panel .search-area-inner {
        margin: 0 -15px;
    }

    .frontend-panel .search-contents .col-xs-12,
    .frontend-panel .search-contents .col-xs-6 {
        padding: 3px 10px;
    }

    .frontend-panel .search-box .form-group {
        margin-bottom: 0;
    }

    .frontend-panel .blog-box .detail .tags-box {
        margin-bottom: 20px;
    }

    .frontend-panel .blog-box .detail .blog-share {
        float: left;
        text-align: left;
    }

    .frontend-panel .comments ul {
        margin: 0 0 0 0;
    }

    .frontend-panel .comment-body {
        margin: 10px 0 0 0;
        line-height: 20px;
        padding: 10px 0 0 0;
        font-size: 13px;
        text-align: justify;
    }

    .frontend-panel .blog-banner {
        text-align: center;
        padding: 40px 0;
    }

    .frontend-panel .comment-content {
        margin: 0 0 0 80px;
    }

    .frontend-panel .comment-author {
        height: 60px;
        width: 60px;
    }

    .frontend-panel .form-content-box .details {
        padding: 30px 20px;
    }

    .frontend-panel .sec-title-three h2 {
        margin-top: 0;
    }

    .frontend-panel .content-area-2 {
        margin-bottom: 60px;
    }

    .frontend-panel .content-area-3 {
        margin-bottom: 30px;
    }

    .frontend-panel .content-area-4 {
        padding: 60px 0 30px;
    }

    .frontend-panel .content-area-5 {
        padding: 60px 0;
    }

    .frontend-panel .content-area-6 {
        padding: 60px 0 30px;
    }

    .frontend-panel .content-area-7 {
        padding: 60px 0 45px;
    }

    .frontend-panel .content-area-8 {
        padding: 60px 0 30px;
    }

    .frontend-panel .content-area-10 {
        padding: 60px 0 20px;
    }

    .frontend-panel .content-area-11 {
        padding: 30px 0 20px;
    }

    .frontend-panel .content-area-12 {
        padding: 60px 0 90px;
    }

    .frontend-panel .content-area-9 {
        padding: 60px 0 50px;
    }

    .frontend-panel .content-area-13 {
        padding: 60px 0 10px;
    }

    .frontend-panel .about-text {
        padding: 0;
    }

    .frontend-panel .search-area .form-group {
        margin-bottom: 10px;
    }

    .frontend-panel .recent-partycrack-box .detail .location a {
        font-size: 14px;
    }

    .frontend-panel .about-box {
        max-width: 100%;
    }

    .frontend-panel .my-partycrack-box .detail .location {
        font-size: 14px;
    }

    .frontend-panel .partycrack-amenities ul li {
        list-style: none;
        margin-bottom: 10px;
    }

    .frontend-panel .partycrack-condition ul li {
        margin-bottom: 10px;
    }

    .frontend-panel .heading-partycrack h3 {
        font-size: 18px;
    }

    .frontend-panel .heading-partycrack p {
        font-size: 13px;
    }

    .frontend-panel .heading-partycrack h5 {
        font-size: 13px;
    }

    .frontend-panel .typography-page p {
        font-size: 13px;
        line-height: 25px;
        text-align: justify;
    }

    .frontend-panel .breadcrumb-area .btn-md {
        padding: 10px 20px;
    }

    .frontend-panel .banner .slider-mover-right {
        display: none;
    }

    .frontend-panel .banner .slider-mover-left {
        display: none;
    }

    .frontend-panel .heading-partycrack-2 .pull-left {
        display: inline-block;
        width: 100%;
    }

    .frontend-panel .heading-partycrack-2 h1 {
        font-size: 22px;
    }

    .frontend-panel .heading-partycrack-2 .pull-right {
        float: left;
    }

    .frontend-panel .heading-partycrack-2 ul {
        margin-bottom: 5px;
        display: inline-block;
    }

    .frontend-panel .partycrack-details-sliders .heading-partycrack-2 .informeson ul {
        margin-bottom: 5px;
    }
}

@media (max-width: 580px) {
    .frontend-panel .inline-search-area .icon-append {
        top: 12px;
    }

    .frontend-panel .inline-search-area .btn {
        margin: 0 !important;
    }

    .frontend-panel .partycrack-box-4 .price-box {
        bottom: 35px;
    }

    .frontend-panel .setting-button {
        display: none;
    }
}

@media (max-width: 500px) {
    .frontend-panel .banner h3 {
        font-size: 24px;
    }

    .frontend-panel .banner .banner-slider-inner h1 {
        font-size: 27px;
    }

    .frontend-panel .banner h2 {
        font-size: 27px;
    }
}

@media (max-width: 380px) {
    .frontend-panel .heading-partycrack-3 .pull-right {
        float: left;
    }

    .frontend-panel .heading-partycrack-3 .pull-left {
        display: inline-block;
        width: 100%;
    }

    .frontend-panel .heading-partycrack-3 span {
        float: left;
    }

    .frontend-panel .heading-partycrack-3 h1 span {
        margin-bottom: 10px;
    }

    .frontend-panel .partycrack-details-page p {
        margin-bottom: 0;
    }

    .frontend-panel .heading-partycrack-3 {
        margin-bottom: 20px;
    }
}

.frontend-panel .slide-v1.owl-theme .owl-nav [class*=owl-] {
    width: 35px;
    height: 35px;
    line-height: 35px;
    top: 50%;
    font-size: 20px;
    border-radius: 3px;
    position: absolute;
    cursor: pointer;
    color: #000;
    z-index: 999;
    background: white;
    cursor: pointer;
    text-align: center;
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.15);
    left: -15px;
    margin-top: -35px;
}

.frontend-panel .slide-v1.owl-theme .owl-nav .owl-next {
    left: auto;
    right: -15px;
}

.frontend-panel .slide-v1.owl-theme .owl-nav [class*=owl-]:hover {
    background: #ff4068;
}

.frontend-panel .slide-v1.owl-theme .owl-nav [class*=owl-]:hover span {
    color: #fff;
}

.frontend-panel .nav-item.login-nav {
    display: flex;
    align-items: center;
}

.frontend-panel .logo p {
    color: #ff4068 !important;
    font-weight: 500;
}

.frontend-panel .weddy-cont {
    display: flex;
}

.frontend-panel .weddy-comn {
    float: left;
    padding: 10px;
}

.frontend-panel .weddy-img img {
    width: 98px;
    height: 98px;
    object-fit: cover;
    border: 2px solid #fff;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
}

.frontend-panel .weddy-text {
    padding-left: 10px;
}

.frontend-panel .weddy-text a {
    font-weight: 500;
    font-size: 18px;
    margin: 0;
    display: inline-block;
    width: 100%;
}

.frontend-panel .weddy-text p {
    font-weight: 400;
    font-size: 12px;
    line-height: normal;
    margin-top: 2px;
}

.frontend-panel .select_pop .select_pick {
    background-color: #fff;
    color: #545454;
    min-height: 48px;
    font-size: 15px;
    font-weight: 500;
    border: 1px solid #dbdbdb;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.06);
    width: 100%;
    padding: 0 20px;
    position: relative;
    display: flex;
    align-items: center;
}

.frontend-panel .select_pop .select_pick:before {
    display: inline-block;
    margin-left: -10px;
    content: "\f078";
    font-family: "Font Awesome 5 Pro";
    font-weight: 400;
    position: absolute;
    right: 15px;
}

.frontend-panel .form-search {
    position: relative;
    width: 100%;
}

.frontend-panel .select_modal {
    padding: 0 !important;
}

.frontend-panel .select_modal .modal-dialog {
    width: 1000px;
    max-width: 100%;
    margin: 50px auto;
    padding: 5px;
}

.frontend-panel .select_modal .modal-body {
    padding: 25px;
}

.frontend-panel .select_modal .modal-header {
    background-color: #ff4068;
    padding: 10px 15px;
}

.frontend-panel .select_modal .modal-header .modal-title {
    color: #fff;
    font-size: 18px;
}

.frontend-panel .select_modal .close {
    opacity: 1;
}

.frontend-panel .select_modal .close span {
    color: #fff;
}

.frontend-panel .input-search {
    padding: 15px 35px 15px 15px;
    width: 100%;
    font-size: 16px;
    font-weight: 500;
    color: #fd7f71;
    background-color: #fff;
    transition: 0.8s 0.3s ease-out;
    border: 1px solid #ddd;
    border-radius: 5px;
}

.frontend-panel .button-search {
    position: absolute;
    right: 0;
    height: 100%;
    background: transparent;
    border: none;
    padding: 0 15px;
    transition: 0.4s 0.6s ease-out;
    color: #ff4068;
    font-size: 18px;
}

.frontend-panel .button-search:hover,
.frontend-panel .button-search:focus {
    transform: scale(1.125);
}

.frontend-panel .select_modal .megamenu-title {
    margin-bottom: 0;
}

.frontend-panel .serch-menu li a {
    display: inline-block;
    width: 100%;
    font-size: 14px;
    padding: 6px 0;
    color: #626262;
}

.frontend-panel .mobile_menu li a img {
    height: 25px;
    width: 25px;
    object-fit: contain;
    object-position: center;
    margin-right: 10px;
}

.frontend-panel .float_whatsapp {
    position: fixed;
    background-color: #25d366;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    right: 20px;
    bottom: 20px;
    color: #fff;
    z-index: 99;
    display: flex;
    align-items: center;
    justify-content: center;
}

.frontend-panel .float_whatsapp i {
    font-size: 22px;
}

.frontend-panel .divider {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    overflow: hidden;
    margin: 3rem auto;
    color: #e5e5e5;
    width: 100%;
}

.frontend-panel .divider:after,
.frontend-panel .divider:before {
    content: "";
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
    height: 1px;
    background-color: #eee;
}

.frontend-panel .divider:before {
    display: none;
    margin-right: 0.5rem;
}

.frontend-panel .divider.divider-center:before {
    display: block;
}

.frontend-panel .divider:after,
.frontend-panel .divider:before {
    content: "";
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
    height: 1px;
    background-color: #eee;
}

.frontend-panel .divider:after {
    margin-left: 0.5rem;
}

.frontend-panel .text-theme {
    color: #ff4068;
}

.frontend-panel .vendor-listing-wrap {
    background: #faf8f6;
    margin-bottom: 2.5rem;
}

.frontend-panel .vendor-listing-wrap .vendor-img {
    position: relative;
}

.frontend-panel .vendor-listing-wrap .vendor-img .overlay-box {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.frontend-panel .vendor-listing-wrap .vendor-img img {
    width: 100%;
    height: 250px;
    object-fit: cover;
    object-position: center;
}

.frontend-panel .vendor-listing-wrap .vendor-icon {
    position: absolute;
    pointer-events: none;
    bottom: -20px;
    right: 20px;
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.8rem;
    color: #fff;
    background: #ff4068;
    border-radius: 50%;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.frontend-panel .vendor-listing-wrap .content {
    padding: 20px;
}

.frontend-panel .vendor-listing-wrap .content a {
    color: #353535;
    font-size: 22px;
}

.frontend-panel .vendor-listing-wrap .content .location {
    font-size: 14px;
    margin: 0;
}

.frontend-panel .vendor-listing-wrap:hover .vendor-img .overlay-box {
    opacity: 1;
}

.frontend-panel .text-muted {
    color: #aaa !important;
}

.frontend-panel .form-section .select2-container {
    width: 100% !important;
}

.frontend-panel .form-section .select2-container--default .select2-selection--single {
    width: 100%;
    padding: 10px 15px;
    padding-right: 20px;
    font-size: 15px;
    outline: none;
    height: 50px;
    border: 1px solid #ccc;
    color: #616161;
    border-radius: 5px;
    background-color: #fff;
}

.frontend-panel .form-section .select2-container--default .select2-selection--single .select2-selection__rendered {
    color: #ababab;
    padding: 0;
}

.frontend-panel .form-section .select2-container--default .select2-selection--single .select2-selection__arrow b {
    border: none;
    margin-left: 0;
}

.frontend-panel .form-section .select2-container--default .select2-selection--single .select2-selection__arrow b:before {
    content: "\f078";
    font-family: "Font Awesome 5 Pro";
    font-weight: 400;
    position: absolute;
    right: 7px;
}

.frontend-panel .feature {
    margin-top: 40px;
}

.frontend-panel .pinside80 {
    padding: 80px !important;
}

.frontend-panel .circle-xxxl {
    height: 240px;
    width: 240px;
}

.frontend-panel .shape-circle {
    border-radius: 34% 66% 67% 33%/49% 46% 54% 51% !important;
}

.frontend-panel .bg-light-default {
    background-color: #fff3f3 !important;
    color: #ff3a3a;
}

.frontend-panel .icon-5x {
    font-size: 75px;
}

.frontend-panel .process-number-second {
    position: absolute;
    left: 30%;
    bottom: -23px;
    text-align: center;
    font-weight: 500;
    line-height: 1.8;
}

.frontend-panel .process-number-second .icon-circle {
    line-height: 3.5;
}

.frontend-panel .circle-lg {
    height: 56px;
    width: 56px;
}

.frontend-panel .icon-circle {
    border: 2px solid #fff;
    text-align: center;
    padding: 50px;
    border-radius: 100%;
    position: relative;
}

.frontend-panel .bg-default {
    background-color: #ff4068 !important;
}

.frontend-panel .process-arrow {
    position: absolute;
    top: 117px;
    right: -45px;
}

.frontend-panel .mb40 {
    margin-bottom: 40px;
}

.frontend-panel .text-default {
    color: #ff4068 !important;
}

.frontend-panel .video-click {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.frontend-panel .video-btn {
    display: inline-block;
    width: 60px;
    height: 60px;
    line-height: 69px;
    text-align: center;
    border-radius: 50%;
    color: #06a042;
    position: relative;
    z-index: 1;
    background: rgba(255, 255, 255, 0.6);
}

.frontend-panel .video-btn i {
    font-size: 20px;
    font-weight: 700;
    color: #3a3a3a;
    opacity: 1 !important;
    position: absolute;
    top: 0;
    left: 3px;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    height: 100%;
}

.frontend-panel .video-btn:after,
.frontend-panel .video-btn:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;
    bottom: 0;
    left: 0;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.6);
}

.frontend-panel .video-btn:before {
    -webkit-animation: ripple 1.6s ease-out infinite;
    animation: ripple 1.6s ease-out infinite;
}

.frontend-panel .video-btn:after {
    -webkit-animation: ripple 1.6s ease-out infinite;
    animation: ripple 1.6s ease-out infinite;
}

.frontend-panel .video-btn:hover {
    background: rgba(255, 255, 255, 0.6);
}

.frontend-panel .video-click:hover .video-btn i {
    color: #333;
}

@-webkit-keyframes ripple {
    0%,
    35% {
        -webkit-transform: scale(0);
        transform: scale(0);
        opacity: 1;
    }
    50% {
        -webkit-transform: scale(1.5);
        transform: scale(1.5);
        opacity: 0.8;
    }
    100% {
        opacity: 0;
        -webkit-transform: scale(2);
        transform: scale(2);
    }
}

@keyframes ripple {
    0%,
    35% {
        -webkit-transform: scale(0);
        transform: scale(0);
        opacity: 1;
    }
    50% {
        -webkit-transform: scale(1.5);
        transform: scale(1.5);
        opacity: 0.8;
    }
    100% {
        opacity: 0;
        -webkit-transform: scale(2);
        transform: scale(2);
    }
}

.frontend-panel .partycrackmodal_mobile_menu .dropdown.megamenu-li .dropdown-toggle {
    display: inline-block;
    width: 100%;
    padding: 5px;
    margin: 10px 0;
    position: relative;
    font-weight: 500;
}

.frontend-panel .partycrackmodal_mobile_menu .dropdown.megamenu-li .dropdown-toggle:after {
    content: "\f078";
    font-family: "Font Awesome 5 Pro";
    font-weight: 400;
    position: absolute;
    right: 0;
    border: none;
}

.frontend-panel .partycrackmodal_mobile_menu .dropdown.megamenu-li.show .dropdown-toggle,
.frontend-panel .partycrackmodal_mobile_menu .dropdown.megamenu-li.show .dropdown-toggle i {
    color: #ff4068;
}

.frontend-panel .partycrackmodal_mobile_menu .dropdown.megamenu-li .dropdown-toggle img {
    width: 25px;
    margin-right: 10px;
}

.frontend-panel .partycrackmodal_mobile_menu .dropdown.megamenu-li .dropdown-menu {
    position: unset !important;
    transform: none !important;
    width: 100%;
    border: none;
    margin: 0;
    padding: 0;
}

.frontend-panel .partycrackmodal_mobile_menu .dropdown.megamenu-li .dropdown-menu .dropdown-item {
    padding: 5px 15px;
    margin-bottom: 5px;
    font-weight: 400;
    font-size: 14px;
}

.frontend-panel .max-widget {
    max-height: 210px;
    overflow-y: auto;
}

.frontend-panel .irs {
    position: relative;
    display: block;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.frontend-panel .irs-line {
    position: relative;
    display: block;
    overflow: hidden;
    outline: none !important;
}

.frontend-panel .irs-line-left,
.frontend-panel .irs-line-mid,
.frontend-panel .irs-line-right {
    position: absolute;
    display: block;
    top: 0;
}

.frontend-panel .irs-line-left {
    left: 0;
    width: 11%;
}

.frontend-panel .irs-line-mid {
    left: 9%;
    width: 82%;
}

.frontend-panel .irs-line-right {
    right: 0;
    width: 11%;
}

.frontend-panel .irs-bar {
    position: absolute;
    display: block;
    left: 0;
    width: 0;
}

.frontend-panel .irs-bar-edge {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
}

.frontend-panel .irs-shadow {
    position: absolute;
    display: none;
    left: 0;
    width: 0;
}

.frontend-panel .irs-slider {
    position: absolute;
    display: block;
    cursor: default;
    z-index: 1;
}

.frontend-panel .irs-slider.type_last {
    z-index: 2;
}

.frontend-panel .irs-min {
    position: absolute;
    display: block;
    left: 0;
    cursor: default;
}

.frontend-panel .irs-max {
    position: absolute;
    display: block;
    right: 0;
    cursor: default;
}

.frontend-panel .irs-from,
.frontend-panel .irs-to,
.frontend-panel .irs-single {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    cursor: default;
    white-space: nowrap;
}

.frontend-panel .irs-grid {
    position: absolute;
    display: none;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 20px;
}

.frontend-panel .irs-with-grid .irs-grid {
    display: block;
}

.frontend-panel .irs-grid-pol {
    position: absolute;
    top: 0;
    left: 0;
    width: 1px;
    height: 8px;
    background: #000;
}

.frontend-panel .irs-grid-pol.small {
    height: 4px;
}

.frontend-panel .irs-grid-text {
    position: absolute;
    bottom: 0;
    left: 0;
    white-space: nowrap;
    text-align: center;
    font-size: 9px;
    line-height: 9px;
    padding: 0 3px;
    color: #000;
}

.frontend-panel .irs-disable-mask {
    position: absolute;
    display: block;
    top: 0;
    left: -1%;
    width: 102%;
    height: 100%;
    cursor: default;
    background: rgba(0, 0, 0, 0);
    z-index: 2;
}

.frontend-panel .lt-ie9 .irs-disable-mask {
    background: #000;
    filter: alpha(opacity=0);
    cursor: not-allowed;
}

.frontend-panel .irs-disabled {
    opacity: 0.4;
}

.frontend-panel .irs-hidden-input {
    position: absolute !important;
    display: block !important;
    top: 0 !important;
    left: 0 !important;
    width: 0 !important;
    height: 0 !important;
    font-size: 0 !important;
    line-height: 0 !important;
    padding: 0 !important;
    margin: 0 !important;
    outline: none !important;
    z-index: -9999 !important;
    background: none !important;
    border-style: solid !important;
    border-color: transparent !important;
}

.frontend-panel .irs {
    height: 55px;
}

.frontend-panel .irs-with-grid {
    height: 75px;
}

.frontend-panel .irs-line {
    height: 10px;
    top: 33px;
    background: #eee;
    background: linear-gradient(to bottom, #ddd -50%, #fff 150%);
    border: 1px solid #ccc;
    border-radius: 16px;
    -moz-border-radius: 16px;
}

.frontend-panel .irs-line-left {
    height: 8px;
}

.frontend-panel .irs-line-mid {
    height: 8px;
}

.frontend-panel .irs-line-right {
    height: 8px;
}

.frontend-panel .irs-bar {
    height: 10px;
    top: 33px;
    border-top: 1px solid #ff4068;
    border-bottom: 1px solid #ff4068;
    background: #428bca;
    background: linear-gradient(to top, #ff4068 0%, #ff2553 100%);
}

.frontend-panel .irs-bar-edge {
    height: 10px;
    top: 33px;
    width: 14px;
    border: 1px solid #428bca;
    border-right: 0;
    background: #428bca;
    background: linear-gradient(to top, #428bca 0%, #7fc3e8 100%);
    border-radius: 16px 0 0 16px;
    -moz-border-radius: 16px 0 0 16px;
}

.frontend-panel .irs-shadow {
    height: 2px;
    top: 38px;
    background: #000;
    opacity: 0.3;
    border-radius: 5px;
    -moz-border-radius: 5px;
}

.frontend-panel .lt-ie9 .irs-shadow {
    filter: alpha(opacity=30);
}

.frontend-panel .irs-slider {
    top: 28px;
    width: 20px;
    height: 20px;
    border: 1px solid #aaa;
    background: #ddd;
    background: linear-gradient(to bottom, white 0%, gainsboro 20%, white 100%);
    border-radius: 27px;
    -moz-border-radius: 27px;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
    cursor: pointer;
}

.frontend-panel .irs-slider.state_hover,
.frontend-panel .irs-slider:hover {
    background: #fff;
}

.frontend-panel .irs-min,
.frontend-panel .irs-max {
    color: #333;
    font-size: 12px;
    line-height: 1.333;
    text-shadow: none;
    top: 0;
    padding: 1px 5px;
    background: rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    -moz-border-radius: 3px;
}

.frontend-panel .lt-ie9 .irs-min,
.frontend-panel .lt-ie9 .irs-max {
    background: #ccc;
}

.frontend-panel .irs-from,
.frontend-panel .irs-to,
.frontend-panel .irs-single {
    color: #fff;
    font-size: 14px;
    line-height: 1.333;
    text-shadow: none;
    padding: 1px 5px;
    background: #428bca;
    border-radius: 3px;
    -moz-border-radius: 3px;
}

.frontend-panel .lt-ie9 .irs-from,
.frontend-panel .lt-ie9 .irs-to,
.frontend-panel .lt-ie9 .irs-single {
    background: #999;
}

.frontend-panel .irs-grid {
    height: 27px;
}

.frontend-panel .irs-grid-pol {
    opacity: 0.5;
    background: #428bca;
}

.frontend-panel .irs-grid-pol.small {
    background: #999;
}

.frontend-panel .irs-grid-text {
    bottom: 5px;
    color: #99a4ac;
}

.frontend-panel .extra-controls {
    display: flex;
}

.frontend-panel .js-input-from.form-control,
.frontend-panel .js-input-to.form-control {
    max-width: 100%;
    width: 46%;
}

.frontend-panel .js-input-from.form-control {
    margin: 0 4% 0 0;
}

.frontend-panel .js-input-to.form-control {
    margin: 0 0 0 4%;
}

.frontend-panel .strike_cus {
    text-decoration: line-through;
}

.frontend-panel .rating-5 {
    background-color: #5ba829;
}

.frontend-panel .StarRating.regular {
    min-width: 50px;
    max-width: 50px;
    height: 25px;
    font-size: 14px;
    margin-bottom: 5px;
}

.frontend-panel .StarRating {
    border-radius: 2px;
    color: #fff;
}

.frontend-panel .margin-r-5 {
    margin-right: 5px;
}

.frontend-panel .reat_reviews {
    padding: 20px 20px 0 0;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    align-items: end;
    text-align: right;
}

.frontend-panel .reat_reviews small {
    color: #777;
}

.frontend-panel .whatsapp-contact {
    background: #25d366;
    color: #fff !important;
    padding: 2px 8px;
    max-width: 120px;
}

.frontend-panel .whatsapp-contact i {
    font-size: 18px;
}

.frontend-panel .partycrack-thumbnail .favourites {
    position: absolute;
    /* top: 5px; */
    /* right: 5px; */
    top: 10px;
    right: 10px;
    width: 30px;
    height: 30px;
    background: #fff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ff4068;
    z-index: 99;
}

.frontend-panel .partycrack-thumbnail .favourites.active i:before {
    font-weight: 900;
}

.frontend-panel .filters_main,
.frontend-panel .filters_main:hover,
.frontend-panel .filter_btn,
.frontend-panel .filter_btn:hover {
    display: inline-block;
    background: #ff4068;
    padding: 5px 10px;
    border-radius: 3px;
    color: #fff;
}

.frontend-panel #partycrackDetailsSlider .carousel-inner .carousel-item img {
    width: 100%;
}

.frontend-panel .pull-left {
    float: left;
}

.frontend-panel .pull-right {
    float: right;
}

.frontend-panel .async-image-holder {
    position: relative;
    overflow: hidden;
}

.frontend-panel .async-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    opacity: 0;
    transition: opacity 0.3s ease-out 0s;
}

.frontend-panel .async-image.loaded {
    opacity: 1;
}

.frontend-panel .async-image.low-res {
    filter: blur(3px);
}

@keyframes swgl-spinner {
    from {
        transform: translate(-50%, -50%) rotate(0deg);
    }
    to {
        transform: translate(-50%, -50%) rotate(360deg);
    }
}

.frontend-panel .gallery {
    display: flex;
    flex-flow: wrap row;
    justify-content: space-between;
}

.frontend-panel .gallery:after {
    content: "";
    display: inline-block;
    flex: auto;
}

.frontend-panel .gallery-image {
    width: 49.57%;
    padding-top: 35%;
    margin: 0.2%;
    background-color: rgba(20, 20, 20, 0.3);
}

.frontend-panel #swgl-overlay {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    opacity: 0;
    background-color: rgba(0, 0, 0, 0.9);
    transition: max-height 0s linear 270ms, opacity 250ms linear 0s;
    max-height: 0px;
    overflow: hidden;
}

.frontend-panel #swgl-overlay.active {
    opacity: 1;
    z-index: 99;
    max-height: 100vh;
    transition: max-height 0s linear 0s, opacity 250ms linear 0s;
}

.frontend-panel #swgl-controls {
    position: absolute;
    top: 0px;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 5000;
}

.frontend-panel .swgl-digitizer {
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    z-index: -1;
}

.frontend-panel .swgl-btn {
    position: absolute;
    border-width: 0;
    font-size: 35px;
    color: white;
    text-shadow: 1px 2px 5px rgba(0, 0, 0, 0.2);
    padding: 156px 16px;
    text-align: center;
    background-color: transparent;
    cursor: pointer;
}

.frontend-panel .swgl-btn:focus {
    outline: none !important;
}

.frontend-panel .swgl-btn:active {
    outline: none !important;
}

.frontend-panel .swgl-nav-btn {
    top: 50%;
    transform: translateY(-50%);
    width: 110px;
    color: rgba(255, 255, 255, 0.6);
    transition: background-color 0.4s ease 0s, color 0.4s ease 0s;
}

.frontend-panel .swgl-nav-btn:hover {
    color: white;
    background-color: rgba(60, 60, 60, 0.4);
}

.frontend-panel #swgl-close-btn {
    top: 0px;
    right: 0px;
    padding: 7px 15px;
    font-size: 45px;
    z-index: 4000;
}

.frontend-panel #swgl-prev-btn {
    left: 0px;
    text-align: left;
}

.frontend-panel #swgl-next-btn {
    right: 0px;
    text-align: right;
}

.frontend-panel #swgl-slide-wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
}

.frontend-panel .swgl-loading-spinner {
    position: absolute;
    display: block;
    width: 65px;
    height: auto;
    top: 50%;
    left: 50%;
    animation-name: swgl-spinner;
    animation-iteration-count: infinite;
    animation-duration: 2s;
}

.frontend-panel .swgl-slide {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    max-width: 400px;
    max-height: 260px;
    top: 50%;
    left: 50%;
    border-radius: 2px;
    border: 2px solid white;
    background: #e4e4e4;
    overflow: hidden;
    transform: translate(-50%, -50%);
    transform-origin: 50% 50%;
    transition: opacity 500ms linear 0s, transform 500ms ease-out 0s, max-height 500ms ease 0s, max-width 500ms ease 0s;
}

.frontend-panel .swgl-slide.loaded {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
}

.frontend-panel .swgl-left {
    opacity: 0;
    transform: translate(-155%, -50%) !important;
}

.frontend-panel .swgl-right {
    opacity: 0;
    transform: translate(55%, -50%) !important;
}

.frontend-panel .swgl-center {
    opacity: 0;
}

.frontend-panel .swgl-slide-img {
    display: block;
    width: auto;
    height: auto;
    max-height: 98vh;
    max-width: 98vw;
    opacity: 0;
    transition: opacity 0.4s ease-out 0s;
}

.frontend-panel .swgl-slide-img.loaded {
    opacity: 1;
    width: auto;
    height: auto;
}

.frontend-panel .swgl-caption {
    display: none;
}

.frontend-panel .box_main {
    padding: 20px;
    background: #fff;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
}

.frontend-panel .about_vendor {
    display: flex;
}

.frontend-panel .about_vendor .reat_reviews {
    width: 110px;
}

.frontend-panel .about_vendor .vendor_location a {
    display: flex;
}

.frontend-panel .tg-sellercontactdetail {
    width: 100%;
    margin: 0 0 30px;
}

.frontend-panel .tg-sellertitle {
    width: 100%;
    padding: 10px 20px;
    background: #f7f7f7;
    border: 1px solid #eaedef;
    border-bottom: 0;
}

.frontend-panel .tg-sellertitle h1 {
    margin: 0;
    color: #363b4d;
    font-size: 18px;
    line-height: 27px;
}

.frontend-panel .tg-sellercontact {
    width: 100%;
    position: relative;
    background: #fcfcfc;
    padding: 15px;
    border: 1px solid #eaedef;
}

.frontend-panel .tg-memberinfobox {
    width: 100%;
    padding: 0 0 30px;
    display: flex;
    align-items: center;
}

.frontend-panel .tg-memberinfobox figure {
    margin: 0;
    overflow: hidden;
    position: relative;
    border-radius: 50%;
}

.frontend-panel .tg-memberinfobox figure a {
    display: block;
    overflow: hidden;
    border-radius: 50%;
}

.frontend-panel .tg-memberinfobox figure:before {
    top: 0;
    left: 0;
    content: "";
    width: 100%;
    height: 100%;
    border-radius: 50%;
    position: absolute;
    border: 6px solid rgba(255, 255, 255, 0.5);
}

.frontend-panel .tg-memberinfo {
    overflow: hidden;
    padding: 10px 0 0 10px;
}

.frontend-panel .tg-memberinfo h3 {
    margin: 0 0 2px;
    font-size: 20px;
    line-height: 23px;
}

.frontend-panel .tg-memberinfo h3 a {
    color: #363b4d;
}

.frontend-panel .tg-memberinfo span {
    color: #666;
    display: block;
    font-size: 14px;
    line-height: 20px;
    padding: 0 0 5px;
}

.frontend-panel .tg-btnseeallads {
    color: #55acee;
    font-size: 14px;
    line-height: 14px;
}

.frontend-panel .tg-sellercontact .tg-btnphone {
    overflow: hidden;
    position: relative;
    color: #fff;
    border-radius: 3px;
    background: #ff4068;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48%;
    margin: 0 1%;
    padding: 10px;
    min-width: 150px;
}

.frontend-panel .tg-sellercontact .tg-btnphone.whatsapp {
    background: #41c452;
}

.frontend-panel .tg-sellercontact .tg-btnphone i {
    font-size: 20px;
}

.frontend-panel .tg-sellercontact .tg-btnphone > span {
    width: 100%;
    color: #fff;
}

.frontend-panel .tg-sellercontact .tg-btnphone span em,
.frontend-panel .vendor-button .tg-btnphone em {
    font-size: 14px;
    line-height: 20px;
    padding: 0 0 0 8px;
    width: 100%;
    float: left;
    font-style: normal;
    font-weight: 600;
}

.frontend-panel .tg-sellercontact .tg-btnphone span span {
    font-size: 14px;
    line-height: 14px;
    width: 100%;
    float: left;
    font-style: normal;
    color: #fff;
}

.frontend-panel .banquet .section-left .img_sec img {
    width: 50px;
}

.frontend-panel .banquet .section-left .img_sec .help-text {
    font-size: 12px;
    color: #555;
    text-align: center;
}

.frontend-panel .banquet {
    align-items: center;
}

.frontend-panel .banquet .section-right h6 {
    font-size: 14px;
}

.frontend-panel .star-rating {
    direction: rtl;
    display: inline-block;
    margin-bottom: 20px;
}

.frontend-panel .star-rating input[type=radio] {
    display: none;
}

.frontend-panel .star-rating label {
    color: #bbb;
    font-size: 22px;
    padding: 0;
    cursor: pointer;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.frontend-panel .star-rating label:hover,
.frontend-panel .star-rating label:hover ~ label,
.frontend-panel .star-rating input[type=radio]:checked ~ label {
    color: #f2b600;
}

.frontend-panel .dashboard_menu {
    box-shadow: 0 0px 4px 0 #e9e9e9;
}

.frontend-panel .dashboard_menu .nav-tabs li.nav-item a {
    text-align: left;
    padding: 12px 20px;
    border-radius: 0;
    border-bottom: 1px solid #efefef;
    color: #333;
}

.frontend-panel .dashboard_menu .nav-tabs li.nav-item a.active,
.frontend-panel .dashboard_menu .nav-tabs li.nav-item a:hover {
    background-color: #ff4068;
    color: #fff;
}

.frontend-panel .dashboard_menu .nav-tabs li.nav-item a i {
    margin-right: 8px;
    vertical-align: middle;
}

.frontend-panel .dashboard_content {
    margin: 0;
}

.frontend-panel .dashboard_content .card-header {
    background-color: transparent;
    border-color: #f0f0f0;
}

.frontend-panel .dashboard_content .card-header h3 {
    margin: 0;
    font-size: 22px;
}

.frontend-panel .dashboard_content .card {
    border-radius: 0;
    border: 0;
    box-shadow: 0 0px 4px 0 #e9e9e9;
}

.frontend-panel .dashboard_content .card-body p:last-child {
    margin: 0;
}

.frontend-panel .dashboard_content .table {
    margin: 0;
}

.frontend-panel .dashboard_content .table thead th {
    border: 0;
    padding-top: 0;
    font-weight: 600;
}

.frontend-panel .dashboard_content .table tbody tr:last-child td {
    padding-bottom: 0;
}

.frontend-panel .dashboard_content .table tbody tr td {
    vertical-align: middle;
    white-space: nowrap;
}

.frontend-panel .dashboard_content label {
    color: #292b2c;
}

.frontend-panel .dashboard_content label .required {
    color: #ff0000;
}

.frontend-panel .icon_box_style4 {
    background-color: #fff;
    padding: 20px;
    text-align: center;
    display: inline-block;
    width: 100%;
}

.frontend-panel .icon_box_style4 .icon i {
    background-color: #ff4068;
    color: #fff;
    width: 60px;
    height: 60px;
    display: inline-block;
    line-height: 60px;
    border-radius: 100%;
    font-size: 24px;
}

.frontend-panel .icon_box {
    margin-bottom: 30px;
}

.frontend-panel .box_shadow1,
.frontend-panel .box_shadow1:hover {
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.1) !important;
}

.frontend-panel .icon_box_content h5 {
    font-size: 16px;
    margin-top: 12px;
}

.frontend-panel .vendor-user-profile {
    padding: 30px 24px 20px;
    line-height: 1;
    display: inline-block;
    width: 100%;
}

.frontend-panel .vendor-profile-img {
    margin-bottom: 10px;
    float: left;
    margin-right: 20px;
    width: 70px;
    height: 70px;
}

.frontend-panel .vendor-profile-img img {
    width: 70px;
}

.frontend-panel .vendor-profile-name {
    margin-bottom: 0px;
    font-size: 16px;
    margin-top: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.frontend-panel .picture-container {
    position: relative;
    cursor: pointer;
    text-align: center;
    display: flex;
    align-items: center;
}

.frontend-panel .picture {
    width: 106px;
    height: 106px;
    background-color: #999999;
    border: 4px solid #CCCCCC;
    color: #FFFFFF;
    border-radius: 50%;
    margin: 0px 15px 0px 0px;
    overflow: hidden;
    transition: all 0.2s;
    -webkit-transition: all 0.2s;
}

.frontend-panel .picture:hover {
    border-color: #2ca8ff;
}

.frontend-panel .content.ct-wizard-green .picture:hover {
    border-color: #05ae0e;
}

.frontend-panel .content.ct-wizard-blue .picture:hover {
    border-color: #3472f7;
}

.frontend-panel .content.ct-wizard-orange .picture:hover {
    border-color: #ff9500;
}

.frontend-panel .content.ct-wizard-red .picture:hover {
    border-color: #ff3b30;
}

.frontend-panel .picture input[type=file] {
    cursor: pointer;
    display: block;
    height: 100%;
    left: 0;
    opacity: 0 !important;
    position: absolute;
    top: 0;
    width: 100%;
}

.frontend-panel .picture-src {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.frontend-panel .map_results {
    overflow-y: auto;
}

.frontend-panel .map_results {
    scrollbar-width: thin;
    scrollbar-color: #ff4068 #ccc;
}

.frontend-panel .map_results:-webkit-scrollbar {
    width: 5px;
}

.frontend-panel .map_results:-webkit-scrollbar-track {
    background: #ccc;
}

.frontend-panel .map_results:-webkit-scrollbar-thumb {
    background-color: #333;
    border-radius: 20px;
    border: 3px solid #333;
}

.frontend-panel .map-frame {
    min-height: 500px;
}

.frontend-panel .footer_map {
    padding: 0;
}

.frontend-panel .footer_map .sub-footer {
    margin-top: 0;
    padding: 10px 0;
}

.frontend-panel .footer_map .row {
    align-items: center;
}

.frontend-panel .footer_map .sub-footer .copy {
    line-height: normal;
    margin-bottom: 0;
    text-align: left;
}

.frontend-panel .footer_map .sub-footer .social-list {
    float: right;
}

.frontend-panel .details_carousel_slides,
.frontend-panel .details_carousel_thum {
    margin: 0;
}

.frontend-panel .flex-direction-nav a {
    background: #fff;
    display: flex;
    align-items: center;
    border-radius: 50%;
    text-indent: 15px;
    width: 35px;
    height: 35px;
}

.frontend-panel .flex-direction-nav a.flex-prev {
    text-indent: 13px;
}

.frontend-panel .flex-direction-nav a:before {
    font-family: "Font Awesome 5 Pro";
    font-weight: 400;
    font-size: 26px;
}

.frontend-panel .flex-direction-nav a.flex-prev:before {
    content: "\f104";
}

.frontend-panel .flex-direction-nav a.flex-next:before {
    content: "\f105";
}

.frontend-panel .more_img_butn {
    position: absolute;
    right: 15px;
    bottom: 15px;
    z-index: 99;
}

.frontend-panel .more_img_butn .see_all_butn {
    display: inline-block;
    background: #ff4068;
    padding: 8px 20px;
    font-size: 14px;
    font-weight: 500;
    border-radius: 3px;
    color: #fff;
}

.frontend-panel .partycrack-box .text-vendor .text-top {
    padding: 10px 10px 20px 10px;
}

.frontend-panel .partycrack-box .text-vendor .heading-part {
    margin: 0 0 5px 0;
    max-width: calc(100% - 100px);
}

.frontend-panel .partycrack-box .text-vendor .heading-part a.ventor_title {
    font-weight: 500;
    font-size: 16px;
    color: #454545;
    line-height: 1;
    padding: 0 15px 0 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: inline-block;
    width: 100%;
}

.frontend-panel .partycrack-box .text-vendor .heading-part .location-wrap {
    position: relative;
    padding: 0 15px 0 15px;
    font-weight: 400;
    font-size: 13px;
    color: #4a4a4a;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: inline-block;
    width: 100%;
}

.frontend-panel .partycrack-box .text-vendor .heading-part .location-wrap i {
    position: absolute;
    left: 0;
}

.frontend-panel .pb_review {
    display: flex;
    flex-direction: column;
    align-items: end;
    align-items: flex-end;
}

.frontend-panel .partycrack-box .text-vendor .text-top .pb_review small {
    font-weight: 400;
    font-size: 13px;
    color: #4a4a4a;
}

.frontend-panel .partycrack-box .text-vendor .text-top .pb_review .StarRating i {
    font-size: 13px;
}

.frontend-panel .partycrack-box .text-vendor .text-top .pb_review .StarRating {
    margin: 0;
}

.frontend-panel .partycrack-box .text-vendor .text-foot {
    border-top: 1px dashed rgba(144, 144, 144, 0.4);
    padding: 10px 10px 10px 10px;
}

.frontend-panel .partycrack-box .text-vendor .text-foot .strike {
    font-weight: 400;
    font-size: 13px;
    color: #4a4a4a;
}

.frontend-panel .partycrack-box .text-vendor .text-foot .whatsapp-contact {
    font-size: 14px;
    border-radius: 2px;
    padding: 5px 10px;
}

.frontend-panel .partycrack-box .text-vendor .text-foot .whatsapp-contact i {
    font-size: 16px;
    margin-right: 5px;
}

.frontend-panel .partycrack__feature {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

.frontend-panel .partycrack__feature.pf_strikethrough {
    align-items: start;
}

.frontend-panel .partycrack__feature .pf_icon {
    margin-right: 10px;
}

.frontend-panel .partycrack__feature img {
    object-fit: contain;
    width: 20px;
    height: 20px;
}

.frontend-panel .partycrack__feature.pf_strikethrough .pf_icon {
    color: #acaeb9;
}

.frontend-panel .partycrack__feature.pf_strikethrough .partycrack__feature__name {
    position: relative;
    color: #acaeb9;
    text-decoration: line-through;
}

.frontend-panel .new-options a {
    width: 33.33%;
}

.frontend-panel .new-options .StarRating {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0;
}

.frontend-panel .new-options .shortlist i,
.frontend-panel .new-options .share i {
    display: block;
    font-size: 20px;
}

.frontend-panel .partycrack-key-information .key-point-inner {
    padding: 15px 15px;
    text-align: center;
    border-radius: 8px;
    cursor: pointer;
}

.frontend-panel .partycrack-key-information .key-point-inner:hover {
    background: #f1f2f3;
}

.frontend-panel .partycrack-key-information .key-point-inner .text-wrap h4 {
    font-weight: 600;
    text-align: center;
    color: #666060;
    font-size: 16px;
    margin: 0;
}

.frontend-panel .partycrack-key-information .key-point-inner .img-wrap {
    margin: 0 0 10px 0;
}

.frontend-panel .partycrack-key-information .key-point-inner .img-wrap img {
    opacity: 0.4;
    width: 40px;
    height: 40px;
}

.frontend-panel .partycrack-key-information .key-point-inner p {
    font-weight: 400;
    text-align: center;
    color: #717484;
    font-size: 14px;
    margin: 0;
    line-height: 1.4;
}

.frontend-panel .map_filters.sidebar-left .toggle-button {
    font-size: 16px;
    padding: 20px;
    background: transparent;
    border: none;
    width: 100%;
    display: flex;
    justify-content: space-between;
    color: #353535;
    font-weight: 500;
}

.frontend-panel .custom-card-body {
    padding: 0 20px 20px 20px;
}

.frontend-panel .cinmaya-pagination .pagination .page-item {
    float: left;
}

.frontend-panel .cinmaya-pagination .pagination .page-item.active .page-link,
.frontend-panel .cinmaya-pagination .pagination .page-item .page-link:hover,
.frontend-panel .cinmaya-pagination .pagination .page-item:hover .page-link {
    background: #ff4068;
    color: #fff !important;
}

.frontend-panel .cinmaya-pagination .pagination .page-item span {
    color: inherit;
}

.frontend-panel .partycrack-key-information .unavailable .key-point-inner .img-wrap {
    opacity: .3;
}

.frontend-panel .partycrack-key-information .unavailable .key-point-inner .text-wrap {
    opacity: .3;
}

.frontend-panel .partycrack-key-information .unavailable .key-point-inner .text-wrap h4 {
    font-weight: 400;
    text-align: center;
    color: #717484;
    font-size: 14px;
    margin: 0;
    line-height: 1.4;
}

.lg-icon {
    font-family: 'lg' !important;
}

/****Frontend Panel Ends*****/

/****Frontend Panel Responisve*****/

@media screen and (min-width: 500px) {
    .frontend-panel .gallery-image {
        width: 33.1%;
        padding-top: 23.3709%;
        margin: 0.1%;
    }
}

@media screen and (min-width: 700px) {
    .frontend-panel .gallery-image {
        width: 24.8%;
        padding-top: 17.5105%;
        margin: 0.1%;
    }
}

@media (min-width: 768px) {
    .frontend-panel .partycrack-thumbnail {
        height: auto !important;
    }
}

@media (min-width: 991px) {
    .frontend-panel .main-header .navbar-expand-lg .navbar-nav .nav-item.login-nav .nav-link {
        padding-right: 0;
    }
}

@media (max-width: 991px) {
    .frontend-panel .map_results {
        overflow-y: revert;
    }

    .frontend-panel .map-box iframe {
        min-height: 95vh;
    }

    .frontend-panel .menu-open {
        overflow: hidden;
    }

    .frontend-panel .sub-banner {
        height: 50px;
    }

    .frontend-panel .sub-banner .breadcrumb-area {
        text-align: left;
        display: flex;
        align-items: center;
    }

    .frontend-panel .sub-banner .breadcrumb-area h1 {
        margin-right: auto;
        margin-bottom: 0;
    }

    .frontend-panel .navbar-nav.ml-auto.d-lg-none.mobile_menu {
        /* padding-bottom: 50px; */
        /* margin-bottom: 50px; */
        height: 83vh !important;
        overflow-y: auto;
    }

    .frontend-panel .main-header .navbar-toggler span {
        color: #ff4068;
    }

    .frontend-panel .main-header .dropdown-menu a:hover {
        background: transparent;
        transform: none;
    }

    .frontend-panel .navbar-nav.ml-auto.d-lg-none.mobile_menu {
        padding-bottom: 30px;
    }

    .frontend-panel .banner-max-height img {
        height: 75vh;
    }

    .frontend-panel .sticky-header {
        background: #fff;
    }

    .frontend-panel .sticky-header .navbar {
        justify-content: normal;
    }

    .frontend-panel .main-header .navbar-light .navbar-toggler {
        border: none;
        background: transparent;
        margin-right: 15px;
        padding: 7px 5px 5px 0;
        z-index: 99;
        font-size: 24px;
    }

    .frontend-panel .main-header .logo {
        padding: 15px 0;
    }

    .frontend-panel .main-header .nav-item.login-mobile .nav-link {
        color: #fff;
        font-size: 16px;
    }

    .frontend-panel .flex-direction-nav {
        display: none;
    }

    .frontend-panel .bgburns .slides li .slide-background {
        -webkit-animation: none !important;
        animation: none !important;
    }

    .frontend-panel .menu-open .navbar-toggler .fal.fa-bars:before {
        content: "ï€";
    }

    .frontend-panel .banner .carousel-content {
        margin-bottom: 50px;
    }

    .frontend-panel .select_modal .modal-dialog {
        padding: 0;
        margin: 0;
    }

    .frontend-panel .main-header .dropdown-menu a {
        padding-left: 53px;
    }

    .frontend-panel .from-main .form-section {
        padding: 40px 15px;
    }

    .frontend-panel .login_form .other_login p {
        flex-direction: column;
    }

    .frontend-panel .login_form .other_login p span {
        margin-bottom: 20px;
    }

    .frontend-panel .login_form .other_login {
        margin-top: 50px;
        margin-bottom: 0;
    }

    .frontend-panel .sidebar-left {
        position: fixed;
        top: 0;
        right: -100%;
        width: 100%;
        opacity: 0;
        height: 100vh;
        visibility: hidden;
        overflow-y: auto;
        transition: all 0.5s;
    }

    .active_sidebar {
        overflow: hidden;
    }

    .active_sidebar .sidebar-left {
        right: 0;
        opacity: 1;
        visibility: visible;
        z-index: 999;
    }

    .frontend-panel .sidebar-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 15px;
        border-bottom: 1px solid #ff2654;
        background: #ff2654;
    }

    .frontend-panel .sidebar-header .sidebar-title {
        color: #fff;
        margin: 0;
    }

    .frontend-panel .sidebar-header .sidebar_close {
        background: transparent;
        border: none;
        padding: 0;
        color: #fff;
        font-size: 26px;
        line-height: 26px;
    }

    .frontend-panel #options-content {
        padding: 0;
    }

    .frontend-panel #options-content .sidebar-title {
        font-size: 16px;
        padding: 20px;
    }

    .frontend-panel #options-content .sidebar-title:before {
        content: "ï¸";
        font-family: "Font Awesome 5 Pro";
        position: absolute;
        right: 20px;
        font-weight: 400;
    }

    .frontend-panel #options-content .s-border,
    .frontend-panel #options-content .m-border {
        display: none;
    }

    .frontend-panel .max-widget {
        padding: 0 20px 20px 20px;
    }
}

@media (max-width: 768px) {
    .frontend-panel .map_results.max-map {
        height: auto !important;
    }

    .frontend-panel .reat_reviews {
        padding: 0 0 0 20px;
        align-items: flex-start;
        align-items: start;
    }

    .frontend-panel .partycrack-box {
        display: flex;
    }

    .frontend-panel .partycrack-box .partycrack-thumbnail img {
        height: 100%;
    }
}

@media (max-width: 767.98px) {
    /**Feedback 15-02-2021**/
    .frontend-panel .vendor-button .tg-btnphone em {
        display: none;
    }

    .frontend-panel .vendor-button .tg-btnphone {
        width: 30%;
        min-width: inherit !important;
    }

    .frontend-panel .banquet .section-left .img_sec .help-text {
        display: none;
    }

    .frontend-panel .banquet .section-right p {
        line-height: 1.3;
    }

    .frontend-panel .details_carousel_thum {
        display: none;
    }
}

@media (max-width: 480px) {
    /**Feedback 15-02-2021**/
    /**Old**/
    .frontend-panel .banquet .section-right h6 {
        font-size: 13px;
    }

    .frontend-panel .banquet .section-right p {
        line-height: 1.3;
        font-size: 12px;
    }

    .frontend-panel .partycrack-box .text-vendor .heading-part a.ventor_title {
        font-size: 14px;
    }

    .frontend-panel .partycrack-box .vc_main {
        display: inline-block !important;
        width: 100%;
    }

    .frontend-panel .partycrack-box .text-vendor {
        overflow: hidden;
    }

    .frontend-panel .partycrack-box .partycrack-thumbnail {
        position: relative;
        width: 180px;
    }

    .frontend-panel .partycrack-box .partycrack-thumbnail img {
        height: 100%;
        -o-object-fit: contain;
        object-fit: contain;
        background: #ccc;
    }

    .frontend-panel .partycrack-box .text-vendor .heading-part {
        max-width: 100%;
    }

    .frontend-panel .pb_review {
        flex-direction: row;
        align-items: center;
        flex-wrap: wrap;
    }

    .frontend-panel .partycrack-box .text-vendor .text-top .pb_review .StarRating {
        margin-right: 10px;
    }

    .frontend-panel .partycrack-box .text-vendor .text-foot {
        flex-wrap: wrap;
    }

    .frontend-panel .partycrack-box .text-vendor .text-foot .strike {
        width: 100%;
        margin-bottom: 10px;
    }
}

@media (max-width: 575px) {
    .frontend-panel .breadcrumb-area {
        flex-direction: column;
        align-items: flex-start !important;
        justify-content: flex-start;
        align-content: start !important;
        justify-content: start;
    }

    .frontend-panel .sub-banner .breadcrumb-area h1 {
        font-size: 14px;
    }

    .frontend-panel .sorting-options2 .sort {
        font-size: 12px;
    }

    .frontend-panel .sorting-options2 .custom-select.search-fields {
        max-width: 130px;
        font-size: 12px;
    }

    .frontend-panel .bescom_vendor {
        padding: 2px 6px;
        font-size: 14px;
    }

    .frontend-panel .logo p {
        font-size: 18px !important;
    }
}

@media (max-width: 360px) {
    .frontend-panel .bescom_vendor {
        font-size: 12px;
    }

    .frontend-panel .logo p {
        font-size: 16px !important;
    }

    .frontend-panel .select_pop .select_pick {
        font-size: 13px;
        padding: 0 20px 0 14px;
    }
}

/****Frontend Panel Responisve Ends*****/

.accordion-wrap {
    -webkit-box-shadow: 0 0 0 1px rgba(61, 70, 79, 0.05), 0 1px 3px 0 rgba(61, 70, 79, 0.15);
    box-shadow: 0 0 0 1px rgba(61, 70, 79, 0.05), 0 1px 3px 0 rgba(61, 70, 79, 0.15);
    border-radius: 3px;
}

.vendor-dashboard .accordion-wrap .dashboard-list-block {
    -webkit-box-shadow: none;
    box-shadow: none;
    border-radius: 0;
}

.accordion-wrap .accordion-body {
    padding: 20px;
    border-top: 1px solid #eee;
}

.frontend-panel .vendor-profile-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.vendor-dashboard .availability-day .custom-control-label {
    line-height: 1.5;
}

.vendor-dashboard .availability-day .custom-switch {
    padding-left: 2.25rem;
}

.vendor-dashboard .availability-day .custom-control-label:before {
    left: -2.25rem;
    width: 1.75rem;
    pointer-events: all;
    border-radius: .5rem !important;
}

.vendor-dashboard .availability-day .custom-control-label:after {
    top: 50%;
    left: calc(-2.25rem + 2px);
    width: calc(1rem - 4px);
    height: calc(1rem - 4px);
    background-color: #adb5bd;
    border-radius: .5rem;
    transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, -webkit-transform .15s ease-in-out;
    transition: transform .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    transition: transform .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, -webkit-transform .15s ease-in-out;
    transform: translate(0%, -50%);
    -webkit-transform: translate(0%, -50%);
}

.vendor-dashboard .availability-day .custom-control-input:checked ~ .custom-control-label:after {
    background-color: #fff !important;
    -webkit-transform: translate(.75rem, -50%);
    transform: translate(.75rem, -50%);
}

.aminities-form-info .heading h4 {
    font-weight: 500;
    font-size: 14px;
    color: #252328;
}

.aminities-form-info .data-wrap h4 {
    font-weight: 500;
    font-size: 14px;
    color: #5f5e61;
}

.aminities-form-info .data-wrap .form-control {
    font-size: 13px;
    height: 45px;
}

.fliter-map .dropdown-menu .dropdown-item {
    font-size: 14px;
    padding-left: 10px;
    padding-right: 10px;
}

.filter-map-wrapper {
    padding-bottom: 34px;
    position: relative;
}

.filter-map-wrapper .fliter-map {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: 0;
}

.filter-map-wrapper .fliter-map.fixfilter {
    -webkit-transition: 0.5s;
    -moz-transition: 0.5s;
    -ms-transition: 0.5s;
    transition: 0.5s;
    top: 20px;
    position: fixed;
    z-index: 999;
    animation: slide-down 0.7s;
    width: 100%;
    left: 0;
    padding: 0 15px;
}

.vendor-dashboard textarea.form-control {
    height: auto;
}

/***Pricing Table***/

.pricing-wrap .plan-wrap {
    background: #FFF;
    width: 100%;
    padding: 0px;
}

.pricing-wrap {
    max-width: 1000px;
    margin: auto;
}

.pricing-wrap .plan-wrap .plan-inner .title-top {
    margin: 0 0 15px 0;
    padding: 35px 35px 0 35px;
}

.pricing-wrap .plan-wrap .plan-inner .title-top h3 {
    font-weight: 700;
    margin: 0;
    font-size: 20px;
}

.pricing-wrap .plan-wrap .plan-inner .title-top p {
    color: #ff4068;
    margin: 5px 0 0 0;
    font-size: 14px;
    line-height: 1.3;
}

.pricing-wrap .plan-wrap .plan-inner .rate {
    border-top: 1px solid rgba(0, 0, 0, 0.07);
    padding: 20px 0;
}

.pricing-wrap .plan-wrap .plan-inner .rate h2 {
    display: inline-block;
    font-size: 50px;
    line-height: 50px;
    margin: 0 0 10px 0;
    position: relative;
    font-weight: 700;
}

.pricing-wrap .plan-wrap .plan-inner .rate h2 span {
    position: absolute;
    left: -22px;
    top: 5px;
    line-height: 25px;
    font-size: 25px;
}

.pricing-wrap .plan-wrap .plan-inner .rate .tagg {
    display: table;
    background: #ebebeb;
    margin: 10px 0 0;
    padding: 8px 14px 6px;
    -webkit-border-radius: 50px;
    border-radius: 50px;
    font-weight: 500;
    font-size: 10.6px;
    margin: auto;
}

.pricing-wrap .plan-wrap .plan-inner .text-wrap p {
    font-size: 14px;
    color: #888;
    line-height: 1.4;
    margin-bottom: 0;
}

.pricing-wrap .plan-wrap .plan-inner ul {
    border-top: 1px solid rgba(0, 0, 0, 0.07);
}

.pricing-wrap .plan-wrap .plan-inner .text-wrap {
    margin: 0 0 20px 0;
}

.pricing-wrap .plan-wrap .plan-inner ul li {
    border-bottom: 1px solid rgba(0, 0, 0, 0.07);
    padding: 8px 0;
    font-size: 13px;
    font-weight: 500;
}

.pricing-wrap .plan-wrap .plan-inner ul li img {
    width: 15px;
    height: 15px;
    margin: 0 5px 0 0;
}

.pricing-wrap .plan-wrap .plan-inner ul li.cancel {
    opacity: .5;
}

.pricing-wrap .button-wrap {
    margin-top: 30px;
}

.choose-type-wrap .type-wrap {
    background: #f9f9f9;
    width: 100%;
    padding: 30px;
}

.choose-type-wrap .type-wrap h2 {
    font-size: 26px;
    font-weight: 700;
    margin: 0 0 15px 0;
}

.vendor-dashboard h1,
.vendor-dashboard h2,
.vendor-dashboard h3,
.vendor-dashboard h4,
.vendor-dashboard h5,
.vendor-dashboard h6 {
    font-family: 'Poppins', sans-serif !important;
}

.vendor-dashboard .gallery-upload-img img {
    width: 100%;
    height: 300px;
    object-fit: cover;
}

body .vendor-dashboard.menu-toggle .small-logo {
    display: flex;
    display: -ms-flexbox;
}

body .vendor-dashboard.menu-toggle .main-logo {
    display: none;
}

body .vendor-dashboard:not(.menu-toggle) .small-logo {
    display: none;
}

body .vendor-dashboard:not(.menu-toggle) .main-logo {
    display: flex;
    display: -ms-flexbox;
}

.pricing-wrap .silver {
    overflow: hidden;
    border-radius: 20px 0 0 20px;
}

.pricing-wrap .gold {
    transform: scale(1.1);
    transition: .2s;
    border-radius: 20px;
    overflow: hidden;
}

.pricing-wrap {
    padding: 100px 0;
}

.pricing-wrap .non-popular,
.pricing-wrap .popular-plan {
    box-shadow: 0 10px 30px rgba(0, 0, 0, .1);
}

.pricing-wrap .plan-wrap .plan-inner .title-top .pricing-block-badge {
    transition: .3s;
    width: auto;
    border-radius: 10px;
    padding: 5px 35px;
    color: #363445;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5;
    background: #f8f8f8;
    display: inline-table;
    margin: 0 auto 35px;
}

.pricing-wrap .plan-wrap .plan-inner .title-top .pricing-block-price {
    margin-bottom: 35px;
    display: flex;
    justify-content: center;
}

.pricing-wrap .plan-wrap .plan-inner .title-top {
    display: flex;
    align-content: center;
    flex-direction: column;
}

.pricing-wrap .plan-wrap .plan-inner .title-top .pricing-block-price .price-currency {
    font-size: 24px;
    color: #263238;
    margin-top: auto;
}

.pricing-wrap .plan-wrap .plan-inner .title-top .pricing-block-price .price-num {
    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-auto-flow: column;
}

.pricing-wrap .plan-wrap .plan-inner .title-top .pricing-block-price .price-num-after {
    font-size: 64px;
    color: #263238;
    font-weight: 500;
    line-height: 1;
    grid-column: 1;
    grid-row: 1/3;
}

.pricing-wrap .plan-wrap .plan-inner .title-top .pricing-block-price .price-num-before {
    font-size: 24px;
    color: #263238;
    font-weight: 600;
    line-height: 1.3;
    margin-left: 7px;
}

.pricing-wrap .plan-wrap .plan-inner .title-top .pricing-block-price .price-period {
    font-size: 14px;
    color: #263238;
    margin-left: 7px;
}

.pricing-wrap .plan-wrap .plan-inner .title-top .pricing-block-media {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 70px;
    width: 70px;
    border-radius: 15px;
    background-color: #fff;
    box-shadow: 0px 15px 30px 0px rgba(214.00000000000003, 214.00000000000003, 214.00000000000003, .29);
    margin: auto;
}

.pricing-wrap .plan-wrap .plan-inner .title-top .pricing-block-media i {
    font-size: 32px;
    color: #4d479c;
}

@keyframes slide-down {
    0% {
        opacity: 0;
        transform: translateY(-100%);
    }
    100% {
        opacity: 0.9;
        transform: translateY(0);
    }
}

@-webkit-keyframes slide-down {
    0% {
        opacity: 0;
        transform: translateY(-100%);
    }
    100% {
        opacity: 0.9;
        transform: translateY(0);
    }
}

/* Large devices (desktops, less than 1200px) */

@media (max-width: 1499.98px) {
    .vendor-dashboard .gallery-upload-img img {
        height: 200px;
    }
}

@media (max-width: 1199.98px) {
}

/* Medium devices (tablets, less than 992px) */

@media (max-width: 991.98px) {
    .vendor-dashboard .availability-day .custom-control-label {
        line-height: 1.4;
    }

    .plans-list .partycrack__feature span {
        font-size: 14px;
    }

    .pricing-wrap .plan-wrap {
        margin: 0 0 30px 0;
    }
}

/* Small devices (landscape phones, less than 768px) */

@media (max-width: 767.98px) {
    .vendor-dashboard .availability-day .custom-control-label {
        line-height: 1.5;
    }

    .vendor-dashboard .gallery-upload-img img {
        height: 150px;
    }
}

/* Extra small devices (portrait phones, less than 576px) */

@media (max-width: 575.98px) {
    .vendor-dashboard .gallery-upload-img img {
        height: 90px;
    }
}
